<div class="portfolio-website-moneyguru-body">
    <section class="comman-section comman-section-100vh moneyguru-sec-website1 scroll-div top-section-wrapper"
        id="section1" data-section-name="data-section1">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-7 order-xs-last order-md-last-adj">
                    <div class="moneyguru-sec-left portfolio-info-wrap2 portfolio-info-banner-l xxs-text-center">
                        <ul class="list-inline comman-card-top-ul" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <li class="list-inline-item h6">Moneyguru</li>
                            <li class="list-inline-item h6">Fintech, Mutual Fund Investments</li>
                        </ul>
                        <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1"
                            style="white-space: normal;" class="hide-mobile"> Establishing Trust &amp; Delivering the
                            Best Online Investment Experience</h1>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-5">
                    <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1" style="white-space: normal;"
                        class="show-mobile"> Establishing Trust &amp; Delivering the Best Online Investment Experience
                    </h1>
                    <div class="portfolio-info-banner-r xs-mt35 xs-mb10" data-aos="fade-in" data-aos-delay="1400"
                        data-aos-anchor="#section1"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-moneyguru/moneyguru-banner.svg"
                            alt="Moneyguru - Fintech" class="moneyguru-banner-img lazy"> <img
                            src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-moneyguru/moneyguru-banner-after.svg"
                            alt="Moneyguru - Fintech" class="moneyguru-banner-after lazy"></div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs"> <a href="#" class="next">Scroll<div class="c-scrolldown">
                    <div class="c-line"></div>
                </div> </a></div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div moneyguru-sec-website2" id="section2"
        data-section-name="data-section2">
        <div class="ani-wrap100-top" style="background-color: #F1F3F7;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section2"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="moneyguru-sec-center overview-sec-center portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2">Overview</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2"> Moneyguru is
                            a mutual fund investment platform focusing on millennial generation to empower them with
                            technology, tools and unique market insight. Prismic Reflections(registered is missing here)
                            was selected strategic design partner to create a mobile app &amp; a marketing website where
                            user can understand product features, market insights &amp; about the company.</p>
                    </div>
                    <div class="text-center" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2"> <img
                            src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-moneyguru/moneyguru-overview.svg"
                            alt="Moneyguru - Overview" class="img-fluid moneyguru-overview lazy hide-mobile" /></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge moneyguru-sec3 scroll-div"
        id="section3" style="overflow: hidden;" data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #1E1E1E;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3"></div>
        <div class="ani-wrap50" style="background: #fff;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section3"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-end">
                <div class="col-sm-12 col-md-6 col-lg-6 col-sm-6">
                    <div class="moneyguru-sec3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3">Challenges</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">Focusing on
                            smart savers Focusing on smart savers i.e. millenials in India primarily, the objective was
                            to increase in-app downloads through the marketing website. The Branding &amp; Marketing
                            department also wanted to create a trust, spread awareness &amp; knowledge about the mutual
                            fund market insights.</p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-sm-6">
                    <div class="moneyguru-sec3-right portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3" class="text-white">
                            Solution</h3>
                        <p data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3" class="text-white p2">
                            Insights from research led us to focus on first time investors, retail investors &amp;
                            potential investors. Helping these targeted user groups to understand the product in a
                            single line with its value proposition &amp; helping them to perform their primary tasks -
                            browse product features, platform credibility, read market insight &amp; importantly
                            download the app through the website.</p> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-sec3-bg.svg"
                            alt="Moneyguru - Solution" class="moneyguru-sec3-bg lazy">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section moneyguru-sec-website4 scroll-div comman-section-100vh viewer-section" id="section4"
        data-section-name="data-section4">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section4" style="background-color: #1E1E1E;"></div>
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-md-12">
                    <div class="moneyguru-webapp-sec4-center portfolio-info-wrap2 text-center">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4"> Information
                            Architecture</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section4"> Grouping of
                            relevant information, immediate exposure to the information &amp; <br> call to action for
                            website visitors based on their needs.</p>
                    </div>
                </div>
                <div class="col-md-12 bottom-col-set" data-aos="fade-in" data-aos-delay="1500"
                    data-aos-anchor="#section4">
                    <div class="moneyguru-architecture-bottom">
                        <div class="viewer no-select" id="viewer">
                            <img src="assets/images/portfolio/portfolio-website-moneyguru/moneyguru-architecture.png" alt="Moneyguru - Information Architecture" class="moneyguru-architecture-img" draggable="false" id="photo">
                        </div>
                        <!-- <div ngDraggable [bounds]="myBounds" [inBounds]="true" [outOfBounds]="outOfBounds">
                            <img src="assets/images/portfolio/portfolio-website-moneyguru/moneyguru-architecture.png" alt="Moneyguru - Information Architecture" class="moneyguru-architecture-img" draggable="false" id="photo" style="position: static;max-height: unset;">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh moneyguru-sec-website5 bottom-col-section scroll-div"
        data-section-name="data-section5" id="section5">
        <div class="ani-wrap100-top" style="background-color: #F1F3F7;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section5"></div>
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-sm-12">
                    <div class="moneyguru-landing-center portfolio-info-wrap2 text-center">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section5"> Landing Page</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5"> Platform
                            credibility &amp; features at a glance. <br> Clear call to action leading towards app
                            downloads.</p>
                    </div>
                </div>
                <div class="col-sm-12 bottom-col-set" data-aos="fade-in" data-aos-delay="1200"
                    data-aos-anchor="#section5">
                    <div class="moneyguru-landing-outer"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-moneyguru/moneyguru-landing.png"
                            alt="Moneyguru - Landing Page" class="img-fluid moneyguru-landing lazy" /></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh moneyguru-sec-website6 scroll-div" id="section6"
        data-section-name="data-section6">
        <div class="ani-wrap100-top-2" style="background-color: #F1F3F7;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section6"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-sm-12 order-xs-last-adj">
                    <div class="moneyguru-insights-right" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section6"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-moneyguru/moneyguru-platform.png"
                            alt="Moneyguru - Platform" class="img-fluid moneyguru-platform lazy" /></div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="moneyguru-insights-left portfolio-info-wrap2 float-right-lg">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6"> Platform at its <br />
                            Depth, Explained!</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section6"> Product
                            detail page that gives comprehensive<br class="hidden-xs hidden-md" /> information about
                            core functional features.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh moneyguru-sec-website7 scroll-div" id="section7"
        data-section-name="data-section7">
        <div class="ani-wrap100-top bg-yellow3" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section7"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-start">
                <div class="col-md-8 col-sm-6">
                    <div class="moneyguru-insights-left portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section7">
                            Categorising Your <br /> Investment Needs</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section7">
                            Select category to browse &amp; search for particular fund.</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 moneyguru-insights-right-col">
                    <div class="moneyguru-insights-right" data-aos="fade-in" data-aos-delay="1500"
                        data-aos-anchor="#section7"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-moneyguru/moneyguru-insights.png"
                            alt="Moneyguru - Categorising" class="img-fluid moneyguru-insights lazy" /></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh moneyguru-sec-website8 scroll-div" id="section8"
        data-section-name="data-section8">
        <div class="ani-wrap100-top-2 bg-yellow3" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section8"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-start">
                <div class="col-md-8 col-sm-6">
                    <div class="moneyguru-insights-left portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section8">
                            Detailed Filtering</h3>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 moneyguru-insights-right-col">
                    <div class="moneyguru-insights-right" data-aos="fade-in" data-aos-delay="1500"
                        data-aos-anchor="#section8"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-moneyguru/moneyguru-filtering.png"
                            alt="Moneyguru - Detailed Filtering" class="img-fluid moneyguru-insights lazy" /></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh moneyguru-sec-website9 bottom-col-section scroll-div"
        data-section-name="data-section9" id="section9">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section9" style="background-color: #ffaa04;"></div>
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-sm-12">
                    <div class="moneyguru-landing-outer-center portfolio-info-wrap2 text-center">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section9"> Comprehensive Details
                            &amp; Data For Advanced Users</h3>
                    </div>
                </div>
                <div class="col-sm-12 bottom-col-set" data-aos="fade-in" data-aos-delay="1500"
                    data-aos-anchor="#section9">
                    <div class="moneyguru-landing-outer"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-moneyguru/moneyguru-details.png"
                            alt="Moneyguru - Comprehensive"
                            class="img-fluid moneyguru-landing lazy z-index5 position-relative" /> <img
                            src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-moneyguru/mytrux-details-up.svg"
                            alt="Moneyguru - Comprehensive" class="img-fluid mytrux-details-up lazy" /></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div bagshaha-sec5 no-padding xs-pt60" id="section10"
        data-section-name="data-section10">
        <div class="container h-100">
            <div class="row h-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="bagshaha-sec5-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section10">Color Scheme</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section10"> The brand
                            speak for trust and assurity, hence we choose blue color which evokes a dependable identity.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12"></div>
            </div>
        </div>
        <div class="bagshaha-sec5-right">
            <table class="bagshaha-sec5-right-tbl color-pallete-tbl">
                <tbody>
                    <tr>
                        <td rowspan="2" class="td1">
                            <p class="p2" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section10">#F87C06
                            </p>
                            <div class="ani-wrap100-top" style="background-color: #F87C06;" data-aos="new-animation3"
                                data-aos-delay="1200" data-aos-anchor="#section10"></div>
                        </td>
                        <td class="td2">
                            <p class="p2" data-aos="fade-in" data-aos-delay="1900">#ffffff</p>
                            <div class="ani-wrap100-top" style="background-color: #ffffff;" data-aos="new-animation3"
                                data-aos-delay="1500" data-aos-anchor="#section10"></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="td3">
                            <p class="p2" data-aos="fade-in" data-aos-delay="2200" data-aos-anchor="#section10">#FDBB1F
                            </p>
                            <div class="ani-wrap100-top" style="background-color: #FDBB1F;" data-aos="new-animation3"
                                data-aos-delay="1700" data-aos-anchor="#section10"></div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="td4">
                            <p class="p2" data-aos="fade-in" data-aos-delay="2600" data-aos-anchor="#section10">#242424
                            </p>
                            <div class="ani-wrap100-top" style="background-color: #242424;" data-aos="new-animation3"
                                data-aos-delay="2100" data-aos-anchor="#section10"></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
    <section class="comman-section comman-section-100vh moneyguru-sec12 scroll-div" id="section11"
        data-section-name="data-section11">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="moneyguru-sec12-left portfolio-info-wrap2">
                        <h3 class="xs-text-center" data-aos="fade-in" data-aos-delay="300" data-aos-anchor="#section11">
                            Custom Illustrations</h3>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="moneyguru-sec12-right xs-mt30">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-6" data-aos="fade-in" data-aos-delay="900"
                                data-aos-anchor="#section11">
                                <div class="moneyguru-illustrations-icon" id="moneyguru-illustrations1"> <video
                                        muted="muted" autoplay loop="loop" class="comman-video" id="vidoe12-1"
                                        poster="assets/images/portfolio/portfolio-mobile-app-moneyguru/vidoe11.svg">
                                        <source type="video/mp4"
                                            src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-illustrations-1/no-stock-found.mp4">
                                    </video></div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-6" data-aos="fade-in" data-aos-delay="1200"
                                data-aos-anchor="#section11">
                                <div class="moneyguru-illustrations-icon" id="moneyguru-illustrations2"> <video
                                        muted="muted" autoplay loop="loop" class="comman-video" id="vidoe12-2"
                                        poster="assets/images/portfolio/portfolio-mobile-app-moneyguru/vidoe12.svg">
                                        <source type="video/mp4"
                                            src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-illustrations-2/stock-already-added.mp4">
                                    </video></div>
                            </div>
                        </div>
                        <div class="row mt70">
                            <div class="col-md-6 col-sm-6 col-6" data-aos="fade-in" data-aos-delay="1500"
                                data-aos-anchor="#section11">
                                <div class="moneyguru-illustrations-icon" id="moneyguru-illustrations3"> <video
                                        muted="muted" autoplay loop="loop" class="comman-video" id="vidoe12-3"
                                        poster="assets/images/portfolio/portfolio-mobile-app-moneyguru/vidoe13.svg">
                                        <source type="video/mp4"
                                            src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-illustrations-3/slow-internet.mp4">
                                    </video></div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-6" data-aos="fade-in" data-aos-delay="1800"
                                data-aos-anchor="#section11">
                                <div class="moneyguru-illustrations-icon" id="moneyguru-illustrations4"> <video
                                        muted="muted" autoplay loop="loop" class="comman-video" id="vidoe12-4"
                                        poster="assets/images/portfolio/portfolio-mobile-app-moneyguru/vidoe14.svg">
                                        <source type="video/mp4"
                                            src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-illustrations-4/contact-us.mp4">
                                    </video></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh impact-section-upd scroll-div" id="section-testimonial"
        data-section-name="data-section-testimonial">
        <div class="ani-wrap100-top" style="background-color: #242424;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="comman-impact-bottom" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section-testimonial" style="margin: auto !important;">
                        <div class="card-img-wrap"> <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/testimonial/harsh-thakrar.jpg" alt="Harsh Thakrar"
                                class="comman-impact-img lazy" /></div>
                        <h6 class="text-white">Harsh Thakrar</h6>
                        <h6 class="text-white" style="opacity: 0.5;">Managing Director</h6>
                        <p class="p3 text-white"> <span> <i class="fas fa-quote-left"></i> We couldn't be happier with
                                their services. Their responsiveness and attention to detail when it comes to my
                                feedback was amazing. Instead of blindly executing what I told them, they suggested good
                                improvements to my initial thinking. <i class="fas fa-quote-right"></i> </span></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh insisive-website-sec11 failure-section scroll-div"
        id="section-learning" data-section-name="data-section-learning">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section-learning" style="background-color: #1E1E1E;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-7 col-lg-6 col-sm-12">
                    <div class="insisive-sec-left portfolio-info-wrap2 failure-sec-left">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section-learning">Failure &amp;
                            Learnings</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section-learning"> Few
                            user journeys were created, approved but failed in internal testing. Lesson re highlighted!
                        </p>
                        <ul class="failure-ul p2" data-aos="fade-in" data-aos-delay="1200"
                            data-aos-anchor="#section-learning">
                            <li> We are not the users</li>
                            <li> Remain detached to our work</li>
                            <li> Iterate, Iterate &amp; Iterate.</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-5 col-lg-6"></div>
            </div>
        </div>
        <div class="failure-sec-right" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section-learning">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-sec14-bg.png"
                alt="Failure &amp; Learnings" class="img-fluid lazy failure-sec-right-img" /></div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6> 
                        <!-- <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/website/mytrux"> <span>MyTrux</span></a> -->
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/website/insisive"> <span>Insisive</span></a>
                        <p class="p2">Website for Connected Digital Transport</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>