<div class="portfolio-branding-phycheme-body">
  <section
    class="comman-section comman-section-100vh scroll-div fligital-sec1"
    id="section1"
    data-section-name="data-section1"
  >
    <div
      class="comman-bg-overlay"
      data-aos="comman-bg-overlay-animation"
      data-aos-delay="300"
      data-aos-anchor="#section1"
      style="background-color: #0f0f24"
    ></div>
    <div class="container">
      <div
        class="row vh100-100 align-items-center justify-content-center text-center"
      >
        <div class="col-sm-12">
          <div
            class="fligital-log-center-wrap portfolio-info-banner-c xs-mt15"
            style="min-height: 58px"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section1"
          >
            <img
              src="assets/images/portfolio/portfolio-fligital/filigital-banner-logo.png"
              alt="Phycheme - logo"
              class="img-fluid fligital-logo-img logo-img"
            />
          </div>
          <h1
            class="text-white"
            data-aos="fade-in"
            data-aos-delay="900"
            data-aos-anchor="#section1"
          >
            Redefining <br />
            Transporting Experience
          </h1>
        </div>
      </div>
    </div>
    <div class="arrow-scroll-abs">
      <a href="#" class="next">
        Scroll
        <div class="c-scrolldown">
          <div class="c-line"></div>
        </div>
      </a>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh insisive-website-sect6 scroll-div"
    data-section-name="data-section2"
    id="section2"
  >
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-6 col-lg-6 col-sm-12">
          <div class="portfolio-info-wrap2">
            <h3
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section2"
            >
              Overview
            </h3>
            <p
              class="p2"
              data-aos="fade-in"
              data-aos-delay="900"
              data-aos-anchor="#section2"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries.
            </p>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12">
          <div
            class="insisive-website-sect8-right"
            data-aos="fade-in"
            data-aos-delay="1200"
            data-aos-anchor="#section2"
          >
            <img src="assets/images/portfolio/portfolio-fligital/overview.png"
              alt="Insisive - Logo Identity"
              class="img-fluid lazy insisive-website-sect6-1"
            />
            <img src="assets/images/portfolio/portfolio-website-insisive/insisive-website-sect6-2.svg"
              alt="Insisive - Logo Identity"
              class="img-fluid lazy insisive-website-sect6-2"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh comman-solution-challenge moneyguru-sec3 scroll-div"
    id="section3"
    style="overflow: hidden"
    data-section-name="data-section3"
  >
    <div
      class="ani-wrap50-2"
      style="background: #2e56fd"
      data-aos="new-animation2"
      data-aos-delay="1200"
      data-aos-anchor="#section3"
    ></div>
    <div
      class="ani-wrap50"
      style="background: #fff"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section3"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-end">
        <div class="col-sm-12 col-md-6 col-lg-6 col-sm-6">
          <div class="moneyguru-sec3-left portfolio-info-wrap2">
            <h3
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section3"
            >
              Challenges
            </h3>
            <p
              class="p2"
              data-aos="fade-in"
              data-aos-delay="900"
              data-aos-anchor="#section3"
            >
              Focusing on smart savers in India, the objective was to create a
              seamless, easy and an extremely simple experience for the user.
              Having a challenge of handling complex, multiple data points &
              technical concepts, the application was expected to serve the
              needs of new as well as advanced users at the same time.
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-sm-6">
          <div class="moneyguru-sec3-right portfolio-info-wrap2 ml85">
            <h3
              data-aos="fade-in"
              data-aos-delay="2000"
              data-aos-anchor="#section3"
              class="text-white"
            >
              Creative Brief
            </h3>
            <p
              data-aos="fade-in"
              data-aos-delay="2300"
              data-aos-anchor="#section3"
              class="text-white p2"
            >
              Insights from research led us to focus on 2 primary personas and
              their 3-4 key tasks to prioritize in the application. While
              focusing on ease of use & uncomplicating the data representation,
              creating a sense of trust and security was pivotal in design
              process.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  
  <section
    class="comman-section comman-section-100vh comman-solution-challenge moneyguru-sec3 scroll-div"
    id="section4"
    style="overflow: hidden"
    data-section-name="data-section4"
  >
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-end">
        <div class="col-sm-12 col-md-6 col-lg-6 col-sm-6">
          <div class="moneyguru-sec3-left portfolio-info-wrap2">
            <h3
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section4"
            >
              Brainstorming & Ideas
            </h3>
            <p
              class="p2"
              data-aos="fade-in"
              data-aos-delay="900"
              data-aos-anchor="#section4"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam, ut
              quis sapien nulla suspendisse interdum felis. Sed neque, urna
              dignissim sociis cras id. Odio et elementum risus facilisis odio
              non. Pulvinar ac, condimentum fringilla et enim in nam massa.
            </p>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12">
          <div
            class="insisive-website-sect8-right"
            data-aos="fade-in"
            data-aos-delay="1600"
            data-aos-anchor="#section4"
          >
            <img src="assets/images/portfolio/portfolio-fligital/brainstormin-img.png"
              alt="Insisive - Logo Identity"
              class="img-fluid lazy insisive-website-sect6-1"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  

  <section
    class="comman-section comman-section-100vh scroll-div concerro-sec6"
    id="section5"
    data-section-name="data-section5"
  >
    <div
      class="ani-wrap100-top"
      data-aos="new-animation3"
      data-aos-delay="300"
      data-aos-anchor="#section5"
      style="background-color: #fff"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-12">
          <div
            class="concerro-left-wrap portfolio-info-wrap2 logo-mark-mob-adj mb-3"
          >
            <h3
              data-aos="fade-in"
              data-aos-delay="900"
              data-aos-anchor="#section5"
              class="xs-text-center text-dark"
            >
              Logo Mark
            </h3>
            <p
              class="p2"
              data-aos="fade-in"
              data-aos-delay="1000"
              data-aos-anchor="#section5"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam, ut
              quis sapien nulla suspendisse interdum felis.
            </p>
          </div>
          <div
            data-aos="fade-in"
            data-aos-delay="1100"
            data-aos-anchor="#section5"
            class="concerro-right-wrap"
          >
            <div
              data-aos="fade-in"
              data-aos-delay="1500"
              data-aos-anchor="#section5"
            >
              <img src="assets/images/portfolio/portfolio-fligital/logo-mark-2.png"
                class="logo-mark-img-adj1 lazy img-fluid"
                alt="logo-mark-img"
              />
            </div>

              <img src="assets/images/portfolio/portfolio-fligital/logo-mark-1.png"
                class="logo-mark-img-adj2 lazy img-fluid"
                alt="logo-mark-img"
                data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section5"
              />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh scroll-div mytrux-logo-sec6 overflow-hidden"
    style="background-color: #0f0f24 !important"
    id="section6"
    data-section-name="data-section6"
  >
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-sm-12">
          <div class="portfolio-info-wrap2" style="margin: auto">
            <h3
              data-aos="fade-in"
              data-aos-delay="300"
              data-aos-anchor="#section6"
              class="xl-mb100 text-white"
            >
              Color Palette
            </h3>
          </div>
          <div class="row" id="fligital-bottom-stick">
            <div class="col-md-12 position-relative">
              <img
                src="assets/images/portfolio/portfolio-fligital/color-palette-bg.png"
                alt="Palette-background"
                class="w-100 color-palette-background-adj"
                data-aos="fade-in"
                data-aos-delay="500"
                data-aos-anchor="#section6"
              />
               
              <div class="color-adj color-adj-w-1">
              <img
                src="assets/images/portfolio/portfolio-fligital/pink.svg"
                alt="Palette-background"
                class="color-img-adj"
                data-aos="fade-in"
                data-aos-delay="700"
                data-aos-anchor="#section6"
              />
              <p class="color-adj1">#F80263</p>
            </div>

            <div class="color-adj color-adj-w-2">
              <img
                src="assets/images/portfolio/portfolio-fligital/purple.svg"
                alt="Palette-background"
                class="color-img-adj"
                data-aos="fade-in"
                data-aos-delay="900"
                data-aos-anchor="#section6"
              />
              <p class="color-adj1">#F80264</p>
            </div>

            <div class="color-adj color-adj-w-3">
              <img
                src="assets/images/portfolio/portfolio-fligital/blue.svg"
                alt="Palette-background"
                class="color-img-adj"
                data-aos="fade-in"
                data-aos-delay="1100"
                data-aos-anchor="#section6"
              />
              <p class="color-adj1">#F80265</p>
            </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh scroll-div fligital-logo-sec7 no-padding comman-comparison hide-mobile"
    id="section7"
    data-section-name="data-section7"
  >
    <div
      class="comman-bg-overlay-2"
      data-aos="comman-bg-overlay-animation"
      data-aos-delay="300"
      data-aos-anchor="#section7"
      style="background-color: #37bbfe"
    ></div>
    <div
      id="comparison"
      data-aos="fade-in"
      data-aos-delay="600"
      data-aos-anchor="#section7"
    >
      <figure>
        <div id="handle"></div>
        <div id="divisor"></div>
      </figure>
      <input
        type="range"
        min="0"
        max="100"
        value="50"
        id="slider"
        (input)="divisorChanged($event.target.value)"
      />
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh scroll-div fligital-sec9 comman-bg-section"
    id="section8"
    data-section-name="data-section8"
  >
    <div
      class="comman-bg-overlay"
      style="background-color: #1c419a !important"
      data-aos="comman-bg-overlay-animation"
      data-aos-delay="1800"
      data-aos-anchor="#section8"
    ></div>
    <div class="container h-100"></div>
  </section>

  <section
    class="comman-section comman-section-100vh mytrux-webapp-sec6 scroll-div xs-pb0"
    data-section-name="data-section9"
    id="section9"
  >
    <div
      class="ani-wrap100-top"
      style="background-color: #fff"></div>
    
      <div class="container h-100 position-relative">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-sm-12">
          <div class="mytrux-webapp-sec6-center portfolio-info-wrap2">
            <h3
              data-aos="fade-in"
              data-aos-delay="900"
              data-aos-anchor="#section9"
            >
              Marketing Website Redesign
            </h3>
            <p
              class="p2"
              data-aos="fade-in"
              data-aos-delay="1200"
              data-aos-anchor="#section9"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam, ut
              quis sapien nulla suspendisse interdum felis. Sed neque, urna
              dignissim sociis cras id. Odio et elementum risus facilisis odio
              non. Pulvinar ac, condimentum fringilla et enim in nam massa.
            </p>
          </div>
        </div>
      </div>

      <div class="right-wrap">
        <div
          class="marketing-website-img-1 hide-mobile"
          data-aos="fade-in"
          data-aos-delay="1900"
          data-aos-anchor="#section9"
        >
          <img src="assets/images/portfolio/portfolio-fligital/F2.png"
            alt="MyTrux - Wireframing"
            class="lazy"
          />
        </div>

        <div
          class="marketing-website-img-2 hide-mobile"
          data-aos="fade-in"
          data-aos-delay="1600"
          data-aos-anchor="#section9"
        >
          <img src="assets/images/portfolio/portfolio-fligital/F1.png"
            alt="MyTrux - Wireframing"
            class="lazy"
          />
        </div>


      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh comman-solution-challenge moneyguru-sec3 scroll-div"
    id="section10"
    style="overflow: hidden"
    data-section-name="data-section10"
  >
    <div
      class="ani-wrap50-2"
      style="background: #6c3da7"
      data-aos="new-animation2"
      data-aos-delay="1200"
      data-aos-anchor="#section10"
    ></div>
    <div
      class="ani-wrap50"
      style="background: #fff"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section10"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-end">
        <div class="col-sm-12 col-md-6 col-lg-6 col-sm-6">
          <div class="moneyguru-sec3-left portfolio-info-wrap2">
            <h3
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section10"
            >
              Branding & Marketing goals
            </h3>
            <p
              class="p2"
              data-aos="fade-in"
              data-aos-delay="900"
              data-aos-anchor="#section10"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam, ut
              quis sapien nulla suspendisse interdum felis. Sed neque, urna
              dignissim sociis cras id. Odio et elementum risus facilisis odio
              non. Pulvinar ac, condimentum fringilla et enim in nam massa.
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-sm-6">
          <div class="user-persona hide-mobile">
            <h3
              data-aos="fade-in"
              data-aos-delay="900"
              data-aos-anchor="#section10"
              class="text-white text text-center mb-5"
            >
              User Personas
            </h3>
            <div class="d-flex justify-content-around w-100">
              <div
                data-aos="fade-in"
                data-aos-delay="1500"
                data-aos-anchor="#section10"
              >
                <img src="assets/images/portfolio/portfolio-fligital/G1.png"
                  alt="MyTrux - Overview"
                  class="img-fluid scheduling-img"
                />
              </div>

              <div
                data-aos="fade-in"
                data-aos-delay="1800"
                data-aos-anchor="#section10"
              >
                <img src="assets/images/portfolio/portfolio-fligital/G2.png"
                  alt="MyTrux - Overview"
                  class="img-fluid scheduling-img"
                />
              </div>

              <div
                data-aos="fade-in"
                data-aos-delay="2100"
                data-aos-anchor="#section10"
              >
                <img src="assets/images/portfolio/portfolio-fligital/G3.png"
                  alt="MyTrux - Overview"
                  class="img-fluid scheduling-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh mytrux-webapp-sec7 bottom-col-section scroll-div"
    data-section-name="data-section11"
    id="section11"
  >
    <div
      class="ani-wrap100-top-2"
      style="background-color: #0f0f24"
      data-aos="new-animation3"
      data-aos-delay="100"
      data-aos-anchor="#section11"
    ></div>
    <div class="container-fluid h-100">
      <div class="row h-100">
        <div class="col-sm-12">
          <div class="mytrux-webapp-sec7-center portfolio-info-wrap2">
            <h3
              class="text-white lg-mt-60"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section11"
            >
              Landing Page
            </h3>
          </div>
        </div>
        <div
          class="col-sm-12 text-center"
          data-aos="fade-in"
          data-aos-delay="1200"
          data-aos-anchor="#section11"
        >
          <div class="mytrux-webapp-sec7-1-outer position-relative">
          
            <img
              src="assets/images/portfolio/portfolio-fligital/H1.png"
              width="1000px"
              alt="MyTrux - Landing Page"
              class="img-fluid mytrux-webapp-sec7-1"
            />

            <!-- <img
                            src="assets/images/portfolio/portfolio-website-insisive/insisive-website-sect7.png"
                            alt="Crubba - Visual Design" class="insisive-website-sect7-lazy" style="display: inline;"> -->

            <div _ngcontent-atl-c100="" class="fligital-website-sect7-hover hide-mobile"></div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh mytrux-webapp-sec8 bottom-col-section comman-solution-challenge scroll-div hide-mobile"
    data-section-name="data-section12"
    id="section12"
  >
    <div
      class="ani-wrap100-top"
      style="background-color: #6c3da7"
      
    ></div>
    <div class="container-fluid h-100">
      <div class="row h-100">
        <div
          class="col-md-12 bottom-col-set"
      
        >
          <div class="mytrux-webapp-sec8-b row clearfix">
            <div class="mytrux-webapp-sec8-1 col-md-4 col-sm-4 text-right">
              <img
                src="assets/images/portfolio/portfolio-fligital/I2.png"
                alt="MyTrux - Driver's Registration"
                class="img-fluid"
                data-aos="fade-in"
                data-aos-delay="1200"
                data-aos-anchor="#section12"
              />
            </div>
            <div class="mytrux-webapp-sec8-2 col-md-4 col-sm-4 text-center">
              <img
                src="assets/images/portfolio/portfolio-fligital/I1.png"
                alt="MyTrux - Driver's Registration"
                class="img-fluid lazy shadow-adj"
                data-aos="fade-in"
                data-aos-delay="1900"
                data-aos-anchor="#section12"
              />
            </div>
            <div
              class="mytrux-webapp-sec8-3 col-md-4 col-sm-4 text-left"
              style="margin-top: 50px"
            >
              <img
                src="assets/images/portfolio/portfolio-fligital/I3.png"
                alt="MyTrux - Driver's Registration"
                class="img-fluid"
                data-aos="fade-in"
                data-aos-delay="1500"
                data-aos-anchor="#section12"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh impact-section-upd scroll-div"
    id="section-testimonial"
    data-section-name="data-section-testimonial"
  >
    <div
      class="ani-wrap100-top"
      style="background-color: #242424"
      data-aos="new-animation3"
      data-aos-delay="100"
      data-aos-anchor="#section-testimonial"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-12">
          <div
            class="comman-impact-bottom"
            data-aos="fade-in"
            data-aos-delay="900"
            data-aos-anchor="#section-testimonial"
            style="margin: auto !important"
          >
            <div class="card-img-wrap">
              <img
                src="assets/images/testimonial/praveen-potipatti.png"
                alt="Dev Tripathi"
                class="comman-impact-img"
              />
            </div>
            <h6 class="text-white">Mr. Praveen Pottipati</h6>
            <h6 class="text-white" style="opacity: 0.5">Founder, Fligital</h6>
            <p class="p3 text-white text-center">
              <span>
                <i class="fas fa-quote-left"></i>" Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam. "<i
                  class="fas fa-quote-right"
                ></i>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
