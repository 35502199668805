<div class="industries-page-body">
    <section class="comman-section process-section1 industries-section1 scroll-div top-section-wrapper pb0 xs-pb60"
        data-section-name="data-section1" id="section1">
        <div class="container">
            <div class="row process-section1-row align-items-center justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="comman-card-info-left sec-heading-wrap-xs">
                        <h1 class="h1 sec-heading xs-text-center" data-aos="fade-in" data-aos-delay="300"
                            data-aos-anchor="#section1"> Industries <br /> We Served..</h1>
                    </div>
                </div>
                <div
                    class="col-sm-12 col-md-12 col-lg-6 position-relative process-left-sec industries-left-sec-height xs-mt50">
                    <div class="comman-scrollbar-wrap">
                        <div class="comman-card-info lg-mt185" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <div class="process-img-wrapper2"> <img src="assets/images/industries/icon-bfsi.svg" alt="BFSI" class="process-image lazy" />
                            </div>
                            <h3>BFSI</h3>
                            <ul class="industries-list p2">
                                <li>Metropolitan Stock Exchange (MSEI)</li>
                                <li>Motilal Oswal</li>
                                <li>Angel Broking</li>
                                <li>HDFC Credila</li>
                                <li>63 moons technologies</li>
                                <li>MyCplan</li>
                                <li>Nidhi Consultant</li>
                                <li>Rocky Mountain Ventures, Llc.</li>
                                <li>Time Capital Ltd.</li>
                                <li>Triveni Macfin Ltd.</li>
                            </ul>
                        </div>
                        <div class="comman-card-info" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <div class="process-img-wrapper2"> <img src="assets/images/industries/icon-fintech.svg" alt="Fintech"
                                    class="process-image lazy" /></div>
                            <h3>Fintech</h3>
                            <ul class="industries-list p2">
                                <li>DSP Mutual Funds</li>
                                <li>MoneyGuru</li>
                                <li>EasyPlan</li>
                                <li>Symphony Fintech</li>
                            </ul>
                        </div>

                    




                        <div class="comman-card-info" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <div class="process-img-wrapper2"> <img src="assets/images/industries/icon-healthcare.svg" alt="Healthcare"
                                    class="process-image lazy" /></div>
                            <h3>HealthCare</h3>
                            <ul class="industries-list p2">
                                <li>Afya Arabia (Kuwait)</li>
                                <li>Alexion Pharmaceuticals</li>
                                <li>Angel Wellness Pvt. Ltd.</li>
                                <li>Indian Institute Of Paramedical Science Ltd</li>
                                <li>Unicare Heart &amp; Research</li>
                            </ul>
                        </div>
                        <div class="comman-card-info" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <div class="process-img-wrapper2"> <img src="assets/images/industries/icon-real-estate.svg" alt="Real Estate"
                                    class="process-image lazy" /></div>
                            <h3>Real Estate</h3>
                            <ul class="industries-list p2">
                                <li>Horse Properties USA</li>
                                <li>USA Waterviews</li>
                                <li>Karda Construction Pvt.Ltd</li>
                                <li>Samraat Group</li>
                                <li>Subhadra Properties Pvt.Ltd</li>
                                <li>Adhya Properties</li>
                                <li>Shubham Properties</li>
                            </ul>
                        </div>
                        <div class="comman-card-info" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <div class="process-img-wrapper2"> <img src="assets/images/industries/icon-tech-software.svg" alt="Technology &amp; software"
                                    class="process-image lazy" /></div>
                            <h3>Technology &amp; software</h3>
                            <ul class="industries-list p2">
                                <li> Datamatics Global Services Ltd. (IT &amp; BPO)</li>
                                <li> ProKarma</li>
                                <li> MyTrux</li>
                                <li> Engati</li>
                                <li> Authntick</li>
                                <li> Landmark Systems And Solutions Pvt. Ltd.</li>
                                <li> Bynry</li>
                                <li> NotionEdge</li>
                                <li> Insisive Cloud</li>
                                <li> LogicalDNA Solutions</li>
                                <li> Vigan Technologies</li>
                                <li> Optim Consultancy</li>
                                <li> Ethosh (AR VR experience)</li>
                                <li> Crave Infotech</li>
                                <li> Colour Car (Animation, VFX)</li>
                                <li> Revamp Consulting Pvt. Ltd</li>
                            </ul>
                        </div>
                        <div class="comman-card-info" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <div class="process-img-wrapper2"> <img src="assets/images/industries/icon-ecommerce.svg" alt="ECommerce"
                                    class="process-image lazy" /></div>
                            <h3>ECommerce</h3>
                            <ul class="industries-list p2">
                                <li> Beyond Enough</li>
                                <li> Gadget Sheildz</li>
                            </ul>
                        </div>
                        <div class="comman-card-info" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <div class="process-img-wrapper2"> <img src="assets/images/industries/icon-food.svg" alt="Food &amp; Beverages"
                                    class="process-image lazy" /></div>
                            <h3>Food &amp; Beverages</h3>
                            <ul class="industries-list p2">
                                <li> Empire Spices &amp; Foods</li>
                                <li> Crubba</li>
                                <li> Ambika Sweets</li>
                                <li> Hotel Pooja International</li>
                            </ul>
                        </div>
                        <div class="comman-card-info" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <div class="process-img-wrapper2"> <img src="assets/images/industries/icon-e-learning.svg" alt="ECommerce"
                                    class="process-image lazy" /></div>
                            <h3>E-learning &amp; edutech</h3>
                            <ul class="industries-list p2">
                                <li>Enyota Learning</li>
                                <li>Durban University of Technology</li>
                            </ul>
                        </div>
                        <div class="comman-card-info" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <div class="process-img-wrapper2"> <img src="assets/images/industries/icon-other.svg" alt="Others"
                                    class="process-image lazy" /></div>
                            <h3>Others</h3>
                            <ul class="industries-list p2">
                                <li> WNS</li>
                                <li> Art of Living</li>
                                <li> Pharmadesk</li>
                                <li> Interview Mocha</li>
                                <li> Afya Arabia</li>
                                <li> PhyChem</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>