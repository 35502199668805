<div class="portfolio-mobile-app-moneyguru-body">
    <section class="comman-section comman-section-100vh moneyguru-sec1 scroll-div top-section-wrapper" id="section1"
        data-section-name="data-section1">
        <div class="bg-l-grey ani-wrap75-1" data-aos="new-animation4" data-aos-delay="100" data-aos-anchor="#section1">
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-6 col-md-6 col-lg-7">
                    <div class="moneyguru-sec1-left portfolio-info-wrap2 portfolio-info-banner-l xxs-text-center xs-mb30">
                        <ul class="list-inline comman-card-top-ul hide-mobile-xxs" data-aos="fade-in"
                            data-aos-delay="600" data-aos-anchor="#section1">
                            <li class="list-inline-item h6">Moneyguru</li>
                            <li class="list-inline-item h6">Fintech, Mutual Fund Investments</li>
                        </ul>
                        <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1"
                            style="white-space: normal;" class="xs-mt30 no-wrap-lg">
                            Empowering <br>
                            Smart Savers with <br>
                            Tech &amp; Insights
                        </h1>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-5">
                    <div class="portfolio-info-wrap2" data-aos="fade-in" data-aos-delay="1400"
                        data-aos-anchor="#section1">
                        <img src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-banner.png" alt="Moneyguru - Empowering Smart Savers with The Best Mutual Fund Investing Experience" class="moneyguru-banner-img lazy">
                    </div>
                    <ul class="list-inline comman-card-top-ul show-mobile-xxs text-center">
                        <li class="list-inline-item h6">Moneyguru</li>
                        <li class="list-inline-item h6">Fintech, Mutual Fund Investments</li>
                    </ul>

                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge moneyguru-sec2 scroll-div" id="section2" data-section-name="data-section2">
        <div class="ani-wrap100-top-2" style="background-color: #f1f3f7;" data-aos="new-animation3" data-aos-delay="300"
            data-aos-anchor="#section2">
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 order-xs-last">
                    <div class="moneyguru-sec2-left" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section2">
                        <img src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-sec2-3.png"
                            alt="Moneyguru - Overview" class="moneyguru-sec2-3 img-fluid lazy">
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="moneyguru-sec2-right portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2">Overview </h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section2">
                            Moneyguru is a mutual fund investment platform focused on smart savers to empower them with
                            technology, tools and unique market insights.
                            <br>
                            Prismic Reflections&reg; was selected as the strategic design partner to create a relevant
                            and engaging experience for the primary touch point i.e. android and iOS mobile application.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge moneyguru-sec3 scroll-div"
        id="section3" style="overflow: hidden;" data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #1E1E1E;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3"></div>
        <div class="ani-wrap50" style="background: #fff;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section3"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-end">
                <div class="col-sm-12 col-md-6 col-lg-6 col-sm-6">
                    <div class="moneyguru-sec3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3">Challenges </h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">Focusing on
                            smart savers
                            in India, the objective was to create a seamless, easy and an
                            extremely simple experience for the user.
                            Having a challenge of handling complex, multiple data points &amp; technical concepts, the
                            application was expected to serve the needs of new as well as advanced users at the same
                            time.
                        </p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-sm-6">
                    <div class="moneyguru-sec3-right portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3" class="text-white">
                            Solution
                        </h3>
                        <p data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3" class="text-white p2">
                            Insights from research led us to focus on 2 primary personas and their 3-4 key tasks to
                            prioritize in the application. While focusing on ease of use &amp; uncomplicating the data
                            representation, creating a sense of trust and security was pivotal in design process.
                        </p>
                        <img src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-sec3-bg.svg"
                            alt="Moneyguru - Solution" class="moneyguru-sec3-bg lazy">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section moneyguru-sec4 scroll-div comman-section-100vh viewer-section" id="section4"
        data-section-name="data-section4">
        <div class="comman-bg-overlay-2" style="background-color: #1E1E1E !important;"
            data-aos="comman-bg-overlay-animation" data-aos-delay="300" data-aos-anchor="#section4"></div>
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-md-12">
                    <div class="moneyguru-sec4-top portfolio-info-wrap2" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section4">
                        <h3>
                            What Goes Where - Wireframes
                        </h3>
                    </div>
                </div>
                <div class="col-md-12 bottom-col-set" data-aos="fade-in" data-aos-delay="1200"
                    data-aos-anchor="#section4">
                    <div class="moneyguru-sec4-bottom">
                        <div class="viewer no-select" id="viewer">
                            <img src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-sec4-img.png"
                                alt="Moneyguru - What Goes Where - Wireframes" class="moneyguru-sec4-img"
                                draggable="false" id="photo">
                        </div>
                        <!-- <div ngDraggable lockAxis="y" [bounds]="myBounds" [inBounds]="true" [outOfBounds]="outOfBounds">
                            <img src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-sec4-img.png"
                                alt="Moneyguru - What Goes Where - Wireframes" class="moneyguru-sec4-img"
                                draggable="false" id="photo">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh moneyguru-story-section scroll-div overflow-hidden" id="section5"
        data-section-name="data-section5">
        <div class="ani-wrap100-top bg-l-grey" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section5">
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-sm-6 order-xs-last moneyguru-story-left-outer-col order-xs-last">
                    <div class="moneyguru-story-left-outer" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section5">
                        <div class="moneyguru-story-left">
                            <div class="moneyguru-story-left-inner">
                                <owl-carousel-o [options]="customOptions" class="owl-caroual-comman-fade">
                                    <ng-template carouselSlide>
                                        <div class="invest-now-item">
                                            <img src="assets/images/portfolio/portfolio-mobile-app-moneyguru/invest-now1.gif"
                                                alt="Moneyguru - The All-In-One App" class="invest-now-img">
                                            <h6>The All-In-One App</h6>
                                            <p class="p3">Invest in direct mutual funds, read daily news and track your favourite stocks, all in one place
                                            </p>
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="invest-now-item">
                                            <img src="assets/images/portfolio/portfolio-mobile-app-moneyguru/invest-now2.gif"
                                                alt="Moneyguru - Investing For Free" class="invest-now-img">
                                            <h6>Investing For Free</h6>
                                            <p class="p3">Get up to 1% higher returns by investing in direct mutual funds - Completely Transparent &amp; Absolutely Free!
                                            </p>
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="invest-now-item">
                                            <img src="assets/images/portfolio/portfolio-mobile-app-moneyguru/invest-now3.gif"
                                                alt="Moneyguru - Stay Ahead Of The Crowd" class="invest-now-img">
                                            <h6>Stay Ahead Of The Crowd</h6>
                                            <p class="p3"> Read daily summarised news stories and get access to insightful financial articles to stay informed
                                            </p>
                                        </div>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <div class="invest-now-item">
                                            <img src="assets/images/portfolio/portfolio-mobile-app-moneyguru/invest-now4.gif"
                                                alt="Moneyguru - Performance At Your Fingertips" class="invest-now-img">
                                            <h6>Performance At Your Fingertips</h6>
                                            <p class="p3">Track all favourite stocks from NSE and BSE and monitor your portfolio performance like a pro
                                            </p>
                                        </div>
                                    </ng-template>
                                </owl-carousel-o>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <div class="moneyguru-story-right portfolio-info-wrap2">
                        <h3 class="no-wrap-md" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section5">
                            Starting with a Story
                        </h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section5">
                            The beginning started with engaging illustrations with short animated stories to
                            convey the features of the app, before signing up.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh moneyguru-insights-section scroll-div" id="section6"
        data-section-name="data-section6">
        <div class="ani-wrap100-top-2 bg-yellow3" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section6">
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-start">
                <div class="col-md-7 col-sm-12">
                    <div class="moneyguru-insights-left portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6">
                            Latest Market Insights &amp; Personalised Stock Updates
                        </h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section6">
                            An intelligent single screen dashboard with daily news <br class="hidden-xs hidden-md" />
                            and
                            customisable stock
                            tickers list to track your<br class="hidden-xs hidden-md" /> favourite stocks.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 col-sm-12 moneyguru-insights-right-col">
                    <div class="moneyguru-insights-right comman-video-outer" data-aos="fade-in" data-aos-delay="1500"
                        data-aos-anchor="#section6">
                        <video muted="muted" autoplay loop="loop" class="comman-video" id="vidoe6"
                            poster="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-insights.png">
                            <source type="video/mp4"
                                src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-insights.mp4">
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh moneyguru-investments-section scroll-div overflow-hidden" id="section7"
        data-section-name="data-section7">
        <div class="ani-wrap100-top bg-l-grey" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section7">
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-end">
                <div class="col-md-6 col-sm-12 moneyguru-investments-left-col order-xs-last">
                    <div class="moneyguru-investments-left comman-video-outer" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section7">
                        <video muted="muted" autoplay loop="loop" class="comman-video" id="vidoe7"
                            poster="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-investments.png">
                            <source type="video/mp4"
                                src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-investments.mp4">
                        </video>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="moneyguru-investments-right portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section7">
                            Take Control of Your Investments Right Here!
                        </h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section7">
                            Create and access your investment portfolio, view portfolio summary for stock and
                            mutual funds from one place.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh moneyguru-gateway-section scroll-div no-padding" id="section8"
        data-section-name="data-section8" data-aos-anchor="#section8">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-start">
                <div class="col-md-6 col-sm-12">
                    <div class="moneyguru-gateway-left portfolio-info-wrap2" data-aos="fade-in" data-aos-delay="300"
                        data-aos-anchor="#section8">
                        <h3 class="xs-text-center">
                            Gateway to All Your Investment Options
                        </h3>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 moneyguru-gateway-right-col">
                    <div class="moneyguru-gateway-right comman-video-outer" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section8">
                       
                       
                       
                       
                        <video muted="muted" autoplay loop="loop" class="comman-video" id="vidoe8"
                            poster="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-gateway.png">
                            <source type="video/mp4"
                                src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-gateway.mp4">
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh moneyguru-investing-section scroll-div xs-pb0" id="section9"
        data-section-name="data-section9">
        <div class="ani-wrap100-top bg-l-grey" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section9">
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-end">
                <div class="col-md-6 col-sm-12 moneyguru-investing-left-col order-xs-last">
                    <div class="moneyguru-investing-left comman-video-outer" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section9">
                        <video muted="muted" autoplay loop="loop" class="comman-video" id="vidoe9"
                            poster="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-investing.png">
                            <source type="video/mp4"
                                src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-investing.mp4">
                        </video>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="moneyguru-investing-right portfolio-info-wrap2" data-aos="fade-in" data-aos-delay="1200"
                        data-aos-anchor="#section9">
                        <h3>
                            Investing Made <br class="d-block" /> Simple &amp; Quick!
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section moneyguru-comprehensive-section scroll-div position-relative overflow-hidden"
        id="section10" data-section-name="data-section10">
        <div class="ani-wrap100-top-2 bg-l-grey" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section10">
        </div>
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="moneyguru-comprehensive-top text-center mt80-adj">
                        <div class="portfolio-info-wrap2 xs-text-left">
                            <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section10">
                                Comprehensive <br class="show-mobile" /> Mutual Fund Data
                            </h3>
                            <p class="p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section10">
                                Get detailed information about a mutual fund like the fund rating, rating,<br
                                    class="hidden-xs hidden-md" />
                                historical performance, investment strategy etc.
                            </p>
                        </div>
                    </div>

                    <div class="moneyguru-comprehensive-bottom text-center comman-video-outer" data-aos="fade-in"
                        data-aos-delay="1500" data-aos-anchor="#section10">
                        <video muted="muted" autoplay loop="loop" class="comman-video" id="vidoe11"
                            poster="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-comprehensive.png">
                            <source type="video/mp4"
                                src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-comprehensive.mp4">
                        </video>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh moneyguru-sec12 scroll-div" id="section11"
        data-section-name="data-section11">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="moneyguru-sec12-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="300" data-aos-anchor="#section11">Custom Illustrations
                        </h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section11"
                            style="max-width: 450px;">
                            Get detailed information about a mutual fund like the fund rating, rating,
                            historical performance, investment strategy etc.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="moneyguru-sec12-right xs-mt30">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-6" data-aos="fade-in" data-aos-delay="900"
                                data-aos-anchor="#section11">
                                <div class="moneyguru-illustrations-icon" id="moneyguru-illustrations1">
                                    <video muted="muted" autoplay loop="loop" class="comman-video" id="vidoe12-1"
                                        poster="assets/images/portfolio/portfolio-mobile-app-moneyguru/vidoe11.svg">
                                        <source type="video/mp4"
                                            src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-illustrations-1/no-stock-found.mp4">
                                    </video>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 col-6" data-aos="fade-in" data-aos-delay="1200"
                                data-aos-anchor="#section11">
                                <div class="moneyguru-illustrations-icon" id="moneyguru-illustrations2">
                                    <video muted="muted" autoplay loop="loop" class="comman-video" id="vidoe12-2"
                                        poster="assets/images/portfolio/portfolio-mobile-app-moneyguru/vidoe12.svg">
                                        <source type="video/mp4"
                                            src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-illustrations-2/stock-already-added.mp4">
                                    </video>
                                </div>
                            </div>
                        </div>
                        <div class="row mt70">
                            <div class="col-md-6 col-sm-6 col-6" data-aos="fade-in" data-aos-delay="1500"
                                data-aos-anchor="#section11">
                                <div class="moneyguru-illustrations-icon" id="moneyguru-illustrations3">
                                    <video muted="muted" autoplay loop="loop" class="comman-video" id="vidoe12-3"
                                        poster="assets/images/portfolio/portfolio-mobile-app-moneyguru/vidoe13.svg">
                                        <source type="video/mp4"
                                            src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-illustrations-3/slow-internet.mp4">
                                    </video>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-6" data-aos="fade-in" data-aos-delay="1800"
                                data-aos-anchor="#section11">
                                <div class="moneyguru-illustrations-icon" id="moneyguru-illustrations4">
                                    <video muted="muted" autoplay loop="loop" class="comman-video" id="vidoe12-4"
                                        poster="assets/images/portfolio/portfolio-mobile-app-moneyguru/vidoe14.svg">
                                        <source type="video/mp4"
                                            src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-illustrations-4/contact-us.mp4">
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh impact-section-upd scroll-div" id="section-testimonial"
        data-section-name="data-section-testimonial">
        <div class="ani-wrap50-2" style="background-color: #1E1E1E;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section-testimonial"></div>
        <div class="ani-wrap50" style="background-color: #242424;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="moneyguru-sec13-left portfolio-info-wrap2">
                        <ul class="list-inline comman-card-top-ul">
                            <li class="list-inline-item h6 text-white hide-after">Success Metrics</li>
                        </ul>
                        <h3 class="text-white">Impact</h3>
                        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section-testimonial">
                            <ul class="list-inline comman-impact-list-inline">
                                <li class="list-inline-item">
                                    <img src="assets/images/svg/impact-dowload.svg"
                                        alt="Downloads" class="lazy">
                                    <h3 class="text-white"><span class="counter1">10</span>k+</h3>
                                    <p class="text-white p2">Downloads</p>
                                </li>
                                <li class="list-inline-item">
                                    <img src="assets/images/svg/impact-star.png"
                                        alt="Downloads" class="lazy">
                                    <h3 class="text-white"><span class="counter2">4.7</span></h3>
                                    <p class="text-white p2">Star Rating</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="comman-impact-bottom xs-mt40 ml85" data-aos="fade-in" data-aos-delay="1500"
                        data-aos-anchor="#section-testimonial">
                        <div class="card-img-wrap">
                            <img src="assets/images/testimonial/harsh-thakrar.jpg"
                                alt="Harsh Thakrar" class="comman-impact-img lazy" />
                        </div>
                        <h6 class="text-white">Harsh Thakrar</h6>
                        <h6 class="text-white" style="opacity: 0.5;">Managing Director</h6>
                        <p class="p3 text-white">
                            <span>
                                <i class="fas fa-quote-left"></i>
                                We couldn't be happier with their services. Their responsiveness and attention to detail
                                when it comes
                                to my feedback was amazing. Instead of blindly executing what I told them, they
                                suggested good
                                improvements to my initial thinking.
                                <i class="fas fa-quote-right"></i>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh insisive-website-sec11 failure-section scroll-div"
        id="section-learning" data-section-name="data-section-learning">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section-learning" style="background-color: #1E1E1E;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-7 col-lg-6 col-sm-12">
                    <div class="insisive-sec-left portfolio-info-wrap2 failure-sec-left">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section-learning">Failure &amp;
                            Learnings</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section-learning">
                            Few user journeys were created, approved but failed in internal testing. Lesson re
                            highlighted :
                        </p>
                        <ul class="failure-ul p2" data-aos="fade-in" data-aos-delay="1200"
                            data-aos-anchor="#section-learning">
                            <li>
                                We are not the users
                            </li>
                            <li>
                                Remain detached to our work
                            </li>
                            <li>
                                Iterate, Iterate &amp; Iterate.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-5 col-lg-6">

                </div>
            </div>
        </div>
        <div class="failure-sec-right" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section-learning">
            <img src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-sec14-bg.png"
                alt="Failure &amp; Learnings" class="img-fluid lazy failure-sec-right-img" />
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/mobile-app/aaloch">
                            <span>Aaloach</span>
                        </a>
                        <p class="p2">Let's Track Our Nasty Friends</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>