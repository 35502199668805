<div class="portfolio-website-insisive2-body">
    <section class="comman-section comman-section-100vh insisive-website-sect1 scroll-div top-section-wrapper"
        data-section-name="data-section1" id="section1">
        <div class="ani-wrap100-top" style="background-color: #13152C;" data-aos="new-animation3" data-aos-delay="300"
            data-aos-anchor="#section1"></div>
        <ul class="white-line">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-9 col-md-12 col-lg-12 col-xl-9 order-md-last-adj">
                    <div class="insisive-website-sect1">
                        <ul class="list-inline comman-card-top-ul" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <li class="list-inline-item h6 text-white">Insisive</li>
                            <li class="list-inline-item h6 text-white">Enterprise Cloud, AI</li>
                        </ul>
                        <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1"
                            class="text-white hide-mobile-xxs xl-no-wrap"> Helping Enterprises To <br> Monitor, Optimise
                            &amp; <br> Reduce Their Cloud Spent</h1>
                    </div>
                </div>
                <div class="col-sm-3 col-md-12 col-lg-12 col-xl-3">
                    <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1"
                        class="text-white show-mobile-xxs xs-text-center xs-mt30"> Helping Enterprises To <br> Monitor,
                        Optimise &amp; <br> Reduce Their Cloud Spent.</h1>
                    <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section1"> <img
                            src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-insisive/insisive-website-sect1-1.png"
                            alt="Overview" class="img-fluid insisive-website-sect1-1 lazy"
                            style="display: inline;max-width: inherit;" /></div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs"> <a href="#" class="next">Scroll<div class="c-scrolldown">
                    <div class="c-line"></div>
                </div> </a></div>
    </section>
    <section class="comman-section comman-section-100vh insisive-website-sect2 bottom-col-section scroll-div"
        data-section-name="data-section2" id="section2">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section2" style="background-color: #ffffff;"></div>
        <div class="container-fluid h-100">
            <div class="row h-100 align-items-center justify-content-end">
                <div class="col-sm-12">
                    <div class="insisive-website-sect2-center overview-sec-center">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2">Overview</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2"> Insisive
                            Cloud is an AI SaaS based enterprise application that helps medium and large enterprises to
                            monitor, reduce &amp; optimise their cloud usage through advanced automation &amp; data
                            driven actionable insights.</p>
                    </div>
                </div>
            </div>
        </div> <img src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-website-insisive/insisive-website-sect2.svg" alt="Overview"
            class="img-fluid insisive-website-sect2-img lazy" />
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge insisive-website-sect3 scroll-div"
        id="section3" style="overflow: hidden; background: #F5F7F9;" data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #13152C;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3"></div>
        <div class="container h-100">
            <div class="row h-100 align-items-center justify-content-end">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="insisive-website-sect3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3">Challenges</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3"> The whole
                            market of Cloud Computing is often less understood due to its complexity in different data
                            points, visibility, deployment, tracking etc. There were challenges to make super simple,
                            easy to configure setup process and enable user to take a fast and efficient decision.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="insisive-website-sect3-right portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3" class="text-white">
                            Solution</h3>
                        <p data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3" class="text-white p2">
                            Keeping DevOps engineers, R &amp; D Managers &amp; IT Analyst in the center, an overall UX
                            UI design experience crafted to declutter the complex data sets and translated into a
                            self-guided setup journey. Immediate data visibility &amp; transparency for effective
                            decisions are kept on high priority.</p>
                    </div>
                </div>
            </div>
        </div> <img src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-website-insisive/insisive-website-sect3.svg"
            alt="Insisive - Challenges" class="img-fluid insisive-website-sect3-img lazy hide-mobile" />
    </section>
    <section class="comman-section comman-section-100vh insisive-website-sect4 scroll-div"
        data-section-name="data-section4" id="section4">
        <div class="ani-wrap100-top" style="background-color: #F16D05;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section4"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-4">
                    <div class="insisive-website-sect4-center portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section4">
                            Designing <br> For Data</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4"
                            style="max-width: 330px;"> Complex sets of data, presented in a simplified, actionable &amp;
                            engaging way.</p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-8">
                    <div class="insisive-website-sect4-outer" data-aos="fade-in" data-aos-delay="1200"
                        data-aos-anchor="#section4"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-insisive/insisive-website-sect4.png"
                            alt="Designing For Data" class="img-fluid insisive-website-sect4-img lazy" /></div>
                </div>
            </div>
        </div> <img src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-website-insisive/insisive-website-sect4-2.svg"
            alt="Insisive - Challenges" class="img-fluid insisive-website-sect4-2-img lazy hide-mobile" />
    </section>
    <section class="comman-section comman-section-100vh insisive-website-sect5 scroll-div"
        data-section-name="data-section5" id="section5">
        <div class="ani-wrap100-top" data-aos="new-animation5" style="background-color: #FCAB1C;" data-aos-delay="100"
            data-aos-anchor="#section5"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-6">
                    <div class="insisive-website-sect5-1-outer" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section5"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-insisive/insisive-website-sect5.svg"
                            alt="Insisive - Everyone is Different" class="img-fluid insisive-website-sect5-img lazy" />
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="insisive-website-sect5-right portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5" class="no-wrap-md">
                            Everyone is <br> Different</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section5"> And so are
                            their needs, expectations &amp; goals . Different dashboards with tailored flows are created
                            for different types of users.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh insisive-website-sect6 scroll-div xs-pt0"
        data-section-name="data-section6" id="section6">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section6">Logo Identity</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6"> And so are
                            their needs, expectations &amp; goals . Different dashboards with tailored flows are created
                            for different types of users.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="insisive-website-sect8-right" data-aos="fade-in" data-aos-delay="1200"
                        data-aos-anchor="#section6"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-insisive/insisive-website-sect6-1.svg"
                            alt="Insisive - Logo Identity" class="img-fluid lazy insisive-website-sect6-1" /> <img
                            src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-insisive/insisive-website-sect6-2.svg"
                            alt="Insisive - Logo Identity" class="img-fluid lazy insisive-website-sect6-2" /></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh insisive-website-sect7 scroll-div"
        data-section-name="data-section7" id="section7">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12 text-center">
                    <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section7">Marketing Website</h3>
                    <div class="insisive-website-sect7-wrap" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section7"> <img
                            src="assets/images/portfolio/portfolio-website-insisive/insisive-website-sect7.png"
                            alt="Crubba - Visual Design" class="insisive-website-sect7-lazy" style="display: inline;">
                        <div class="insisive-website-sect7-hover"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh insisive-website-sect8 scroll-div xs-pt0"
        data-section-name="data-section8" id="section8">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12 text-center">
                    <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section8">Solution &amp; Use Cases
                    </h3>
                    <div class="insisive-website-sect8-wrap" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section8">
                        <owl-carousel-o [options]="customOptions" class="insisive-website-sect8-carousel owl-caroual-comman-fade2">
                            <ng-template carouselSlide>
                                <img class="img-flud" src="assets/images/portfolio/portfolio-website-insisive/insisive-solution-1.png" alt="Solution &amp; Use Cases" />
                            </ng-template>
                            <ng-template carouselSlide>
                                <img class="img-flud" src="assets/images/portfolio/portfolio-website-insisive/insisive-solution-2.png" alt="Solution &amp; Use Cases" />
                            </ng-template>
                            <ng-template carouselSlide>
                                <img class="img-flud" src="assets/images/portfolio/portfolio-website-insisive/insisive-solution-3.png" alt="Solution &amp; Use Cases" />
                            </ng-template>
                            <ng-template carouselSlide>
                                <img class="img-flud" src="assets/images/portfolio/portfolio-website-insisive/insisive-solution-4.png" alt="Solution &amp; Use Cases" />
                            </ng-template>
                            <ng-template carouselSlide>
                                <img class="img-flud" src="assets/images/portfolio/portfolio-website-insisive/insisive-solution-5.png" alt="Solution &amp; Use Cases" />
                            </ng-template>
                            <ng-template carouselSlide>
                                <img class="img-flud" src="assets/images/portfolio/portfolio-website-insisive/insisive-solution-6.png" alt="Solution &amp; Use Cases" />
                            </ng-template>
                        </owl-carousel-o>
                        <img src="assets/images/portfolio/portfolio-website-insisive/insisive-website-sect8.png" alt="Crubba - Visual Design" class="insisive-website-sect8-lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh insisive-website-sect9 failure-section scroll-div"
        id="section-learning" data-section-name="data-section-learning">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section-learning" style="background-color: #1E1E1E;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-7 col-lg-6 col-sm-12">
                    <div class="insisive-sec-left portfolio-info-wrap2 failure-sec-left">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section-learning">Failure &amp;
                            Learnings</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section-learning"> We
                            struggled a little (actually quite a lot :-) ) in understanding the world of Cloud
                            Infrastructure. For their website, understanding the technical terms of their usage was
                            completely new for us. As designers, we learned to be more patient about domain
                            technicalities &amp; listening to domain experts from where they are coming and then apply
                            our thinking considering given constraints.</p>
                    </div>
                </div>
                <div class="col-md-5 col-lg-6"></div>
            </div>
        </div>
        <div class="failure-sec-right" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section-learning">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-sec14-bg.png"
                alt="Failure &amp; Learnings" class="img-fluid lazy failure-sec-right-img" /></div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6> 
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/website/moneyguru"><span>Moneyguru</span> </a>
                        <p class="p2">Establishing Trust &amp; Delivering the Best Online Investment Experience</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>