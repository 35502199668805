<div class="services-page-body">
    <section class="comman-section comman-section-100vh scroll-div expertise-sect1 top-section-wrapper" id="section1"
        data-section-name="data-section1">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-7 order-md-last-adj">
                    <div class="comman-card-info xs-mt-30">
                        <h1 class="h1 sec-heading hide-mobile xl-no-wrap" data-aos="fade-in" data-aos-delay="700"
                            data-aos-anchor="#section1"> Brand Strategy <br> &amp; Identity Design</h1>
                        <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                            <p class="p1 text-light-dark-c"> A well defined &amp; strategic brand helps you to <br
                                    class="hide-mobile" /> <span class="text-orange">make human connections, build
                                    trust</span> and <br class="hide-mobile" /> <span class="text-orange"> nurture the
                                    relations</span> with your customers.</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-5">
                    <div>
                        <h1 class="h1 sec-heading show-mobile xs-text-center xl-no-wrap" data-aos="fade-in"
                            data-aos-delay="700" data-aos-anchor="#section1"> Brand Strategy <br> &amp; Identity Design
                        </h1>
                        <div class="comman-card-img-wrap services-brand-strategy-identity-design-wrap hide-mobile"> <img
                                src="assets/images/expertise/services-brand-strategy-identity-design-1.png"
                                alt="User Experience Design" class="services-brand-strategy-identity-design-1 lazy"
                                data-aos="fade-down" data-aos-delay="2800" data-aos-anchor="#section1" /> <img
                                src="assets/images/expertise/services-brand-strategy-identity-design-2.png"
                                alt="User Experience Design" class="services-brand-strategy-identity-design-2 lazy"
                                data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section1" /> 
                                <!-- <img
                                src="assets/images/expertise/services-brand-strategy-identity-design-3.png"
                                alt="User Experience Design" class="services-brand-strategy-identity-design-3 lazy"
                                data-aos="fade-up" data-aos-delay="2400" data-aos-anchor="#section1" />  -->
                                <img
                                src="assets/images/expertise/services-brand-strategy-identity-design-4.svg"
                                alt="User Experience Design"
                                class="services-brand-strategy-identity-design-4 lazy floatingX-l" data-aos="fade-in"
                                data-aos-delay="1200" data-aos-anchor="#section1" /> <img
                                src="assets/images/expertise/services-brand-strategy-identity-design-5.svg"
                                alt="User Experience Design"
                                class="services-brand-strategy-identity-design-5 lazy floatingX-r" /></div>
                        <div class="comman-card-img-wrap-upd2 show-mobile text-center"> <img
                                src="assets/images/expertise/services-brand-strategy-identity-design-xs.png"
                                alt="User Experience Design"
                                class="services-brand-strategy-identity-design-xs lazy mb30 mt20" /></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs"> <a href="#" class="next">Scroll<div class="c-scrolldown">
                    <div class="c-line"></div>
                </div> </a></div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div expertise-sect2 xs-pt0" id="section2"
        data-section-name="data-section2">
        <div class="container expertise-container">
            <div class="row expertise-sect-row expertise-sect-row-1-wrap">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="comman-card-info expertise-sect-left">
                        <div class="expertise-sect-left-head">
                            <h3 class="sec-heading txt-adj"> Make your mark!</h3>
                        </div>
                        <p class="p2 sec-para txt-adj"> At Prismic Reflections&reg;, we help you to conduct this
                            activity inside-out, starting with your internal team to gain clarity. We help you to define
                            a solid Human-like personality of your brand, create a clear messaging, voice, tone and
                            visual language that the audience can resonate &amp; relate with.</p>
                        <div class="expertise-sect-left-body">
                            <ul class="list-inline no-wrap">
                                <li class="list-inline-item">
                                    <a [routerLink]="['/our-work']" class="bttn btn-line btn-primary-custom text-center"><span>View Case Studies</span></a>
                                </li>
                                <li class="list-inline-item">
                                    <a [routerLink]="['/contact']" class="bttn btn-line btn-primary-custom text-center"><span>Let's Talk</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="expertise-sect-right">
                        <h6 class="font-weight-medium"> Here How it Goes....</h6>
                        <ul class="expertise-ul-list ul-purple-adj p3">
                            <li> Understanding your business, customers, influencers &amp; competition</li>
                            <li> Surface the deeper WHY to identify the Purpose, Mission &amp; Vision behind the brand
                            </li>
                            <li> Identify &amp; define your differentiator and positioning statement</li>
                            <li> Designing a unique &amp; compelling Logo Identity</li>
                            <li> Creating an entire system &amp; identity design language with communication assets like
                                corporate stationary, social media creatives, emailers etc.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div expertise-sect-9 xs-pt0 xs-pb0 pb0" id="section3"
        data-section-name="data-section3">
        <div class="container">
            <div class="row vh100-100 align-items-center justify-content-center overflow-hidden">
                <div class="col-sm-12 text-center">
                    <div class="row">
                        <div class="col-sm-12">
                            <h3> Engagement Models</h3>
                        </div>
                        <div class="col-sm-12">
                            <div class="comman-scrollbar-wrap">
                                <table class="table-expertise">
                                    <tr>
                                        <td>
                                            <div class="comman-card-info text-center">
                                                <div class="process-img-wrapper2"> <img
                                                        src="assets/images/preloader/ajax-loader.png"
                                                        data-src="assets/images/process/evolving-design.svg" alt="Discover"
                                                        class="process-image lazy mb25" /></div>
                                                <h5>Evolving<br class="hide-mobile"> Design</h5>
                                                <p class="p3 sec-para text-light-dark-c"> When you are on idea level,
                                                    there is absence of clear scope documentation and ongoing innovation
                                                    is required; this model is a right fit.</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="comman-card-info text-center">
                                                <div class="process-img-wrapper2"> <img
                                                        src="assets/images/preloader/ajax-loader.png"
                                                        data-src="assets/images/process/fixed-scope-projects.svg"
                                                        alt="Discover" class="process-image lazy mb25" /></div>
                                                <h5>Fixed Scope <br class="hide-mobile">Projects</h5>
                                                <p class="p3 sec-para text-light-dark-c"> This is well suited for the
                                                    project and those are well documented, boundary lines defined &amp;
                                                    easy for assessment. A Lump Sum fee can be given for such projects.
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="comman-card-info text-center">
                                                <div class="process-img-wrapper2"> <img
                                                        src="assets/images/preloader/ajax-loader.png"
                                                        data-src="assets/images/process/strategic-consultancy.svg"
                                                        alt="Discover" class="process-image lazy mb25" /></div>
                                                <h5>Strategic <br class="hide-mobile"> Consultancy</h5>
                                                <p class="p3 sec-para text-light-dark-c"> Where there is already
                                                    something built but needs 3rd person/expert overview or you have an
                                                    in-house team that needs external expert hand holding you can opt
                                                    for this. Fee Structure: Per expert, per hour with minimum
                                                    engagement criteria.</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="comman-card-info text-center">
                                                <div class="process-img-wrapper2"> <img
                                                        src="assets/images/preloader/ajax-loader.png"
                                                        data-src="assets/images/process/dedicated-resource.svg"
                                                        alt="Discover" class="process-image lazy mb25" /></div>
                                                <h5>Dedicated <br class="hide-mobile"> Resource</h5>
                                                <p class="p3 sec-para text-light-dark-c"> You hire a dedicated person
                                                    with specific skill sets (UX Researcher, Visual designer, UI
                                                    engineer etc), assign any task and manage them by your own. Fee
                                                    Structure: Monthly Per resource with minimum engagement criteria.
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6> <a class="page-foot-link h3 st btn-line" [routerLink]="['/services/design-research-consultancy']" >
                            <span>Design Research &amp; Consultancy</span> </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>