<div class="about-page-body">
  <section
    class="comman-section comman-section-100vh scroll-div about-sec1 top-section-wrapper position-relative"
    id="section1"
    data-section-name="data-section1"
  >
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-7 order-md-last-adj">
          <div class="comman-card-info">
            <h1
              class="h1 sec-heading hide-mobile"
              data-aos="fade-in"
              data-aos-delay="300"
              data-aos-anchor="#section1"
            >
              Breathing &amp; <br class="d-block" />
              Living <span class="restlessRegularText">Design</span>
            </h1>
            <p
              class="p1 sec-para"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section1"
            >
              Individuals from diverse backgrounds - Art, Creativity, Product
              Design, Management &amp; Strategy. Constantly pushing the
              boundaries to solve &amp; innovate.
            </p>
            <!-- <ul class="list-inline about-head-card-top-ul" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1"> -->
            <ul class="list-inline about-head-card-top-ul">
              <li class="list-inline-item">
                <h5 class=""><span class="counter1">750</span>+</h5>
                <h6 class="btn-line active"><span>Projects</span></h6>
              </li>
              <li class="list-inline-item">
                <h5 class=""><span class="counter2">30</span>+</h5>
                <h6 class="btn-line active"><span>Domains</span></h6>
              </li>
              <li class="list-inline-item">
                <h5 class=""><span class="counter3">8</span>+</h5>
                <h6 class="btn-line active">
                  <span>Honors &amp; Awards</span>
                </h6>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-5">
          <h1
            class="h1 sec-heading show-mobile xs-text-center"
            data-aos="fade-in"
            data-aos-delay="300"
            data-aos-anchor="#section1"
          >
            Breathing &amp; <br class="d-block" />
            Living <span class="restlessRegularText">Design</span>
          </h1>
          <div class="comman-card-img-wrap-upd">
            <div
              data-aos="fade-in"
              data-aos-delay="1200"
              class="about-sect-1-wrap"
            >
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/banner/about-sect-1.png"
                alt="Breathing, &amp; Living Design"
                class="about-sect-1 lazy img-fluid"
              />
              <div class="text-center p1">Years of Experience.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="arrow-scroll-abs">
      <a href="#" class="next"
        >Scroll
        <div class="c-scrolldown">
          <div class="c-line"></div>
        </div>
      </a>
    </div>
  </section>
  <section
    class="comman-section comman-section-100vh scroll-div about-sect2 comman-bg-section"
    id="section2"
    data-section-name="data-section2"
  >
    <div
      class="comman-bg-overlay-2"
      style="background-color: #fff !important"
      data-aos="comman-bg-overlay-animation"
      data-aos-delay="300"
      data-aos-anchor="#section2"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-sm-12 text-center">
          <div
            class="card-img-inner-wrap xs-pt100"
            data-aos="fade-in"
            data-aos-delay="100"
            data-section-name="data-section2"
          >
            <h2
              class="text-white"
              data-aos="fade-in"
              data-aos-delay="900"
              data-aos-anchor="#section2"
            >
              Design is a Mindset!
            </h2>
            <p
              class="p2 text-white"
              data-aos="fade-in"
              data-aos-delay="1200"
              data-aos-anchor="#section2"
            >
              And it's a method of solving problems, to tap into opportunities &
              communicate effectively.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh scroll-div about-sec3"
    id="section3"
    data-section-name="data-section3"
  >
    <div
      class="ani-wrap50-2 bg-black"
      data-aos="new-animation2"
      data-aos-delay="1200"
      data-aos-anchor="#section3"
    ></div>
    <div
      class="ani-wrap50 bg-black2"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section3"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="comman-card-info comman-card-left">
            <h3 data-aos="fade-in" data-aos-delay="900">Vision</h3>
            <p
              class="p2 text-white"
              data-aos="fade-in"
              data-aos-delay="1200"
              data-aos-anchor="#section3"
            >
              We aim to design experiences that empower users and thereby
              transform brands, businesses &amp; the world in a progressive way.
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="comman-card-info comman-card-right">
            <h3 data-aos="fade-in" data-aos-delay="2300">Mission</h3>
            <p
              class="p2 text-white"
              data-aos="fade-in"
              data-aos-delay="2600"
              data-aos-anchor="#section3"
            >
              As a digital design studio, we are on a mission be the world's
              best and most-respected Design Studio by creating highly impactful
              experiences for anything that humans interact with.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    class="comman-section scroll-div about-sec4"
    id="section4"
    data-section-name="data-section4"
  >
    <div
      class="ani-wrap50-3 bg-white"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section4"
    ></div>
    <div class="container">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="comman-card-info ml-20-adj">
            <ul
              class="list-inline comman-card-top-ul"
              data-aos="fade-in"
              data-aos-delay="300"
              data-aos-anchor="#section4"
            >
              <li class="list-inline-item h6 text-white hide-after">
                Our Values
              </li>
            </ul>
            <h2
              class="h2"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor-placement="top-top"
              data-aos-anchor="#section4"
            >
              Our Sacred <br class="d-block" />
              Guiding Principle
            </h2>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 our-values-wrap-outer">
          <div class="comman-scrollbar-wrap">
            <div class="our-values-wrap">
              <ul>
                <li
                  data-aos="fade-in"
                  data-aos-delay="1600"
                  data-aos-anchor="#section4"
                >
                  <div class="process-img-wrapper2">
                    <img
                      src="assets/images/about/master-fundamental.png"
                      alt="master-fundamental"
                      class="process-image lazy about-img-adj"
                    />
                  </div>
                  <div class="our-values-card">
                    <h5>Master the Fundamentals</h5>
                    <p class="p2">
                      Master the fundamentals &amp; principles before going
                      beyond.
                    </p>
                  </div>
                </li>
                <li
                  data-aos="fade-in"
                  data-aos-delay="1800"
                  data-aos-anchor="#section4"
                >
                  <div class="process-img-wrapper2">
                    <img
                      src="assets/images/about/be-valuable.png"
                      alt="be-valuable"
                      class="process-image lazy about-img-adj"
                    />
                  </div>
                  <div class="our-values-card">
                    <h5>Be Valuable</h5>
                    <p class="p2">
                      Be a valuable resource to humanity &amp; the world.
                    </p>
                  </div>
                </li>
                <li
                  data-aos="fade-in"
                  data-aos-delay="2000"
                  data-aos-anchor="#section4"
                >
                  <div class="process-img-wrapper2">
                    <img
                      src="assets/images/about/respect-trust.png"
                      alt="respect-trust"
                      class="process-image lazy about-img-adj"
                    />
                  </div>
                  <div class="our-values-card">
                    <h5>Respect &amp; Trust</h5>
                    <p class="p2">We all are ONE, disciplined family.</p>
                  </div>
                </li>
                <li
                  data-aos="fade-in"
                  data-aos-delay="2200"
                  data-aos-anchor="#section4"
                >
                  <div class="process-img-wrapper2">
                    <img
                      src="assets/images/about/benifit-people.png"
                      alt="Benefit People"
                      class="process-image lazy about-img-adj"
                    />
                  </div>
                  <div class="our-values-card">
                    <h5>Benefit People</h5>
                    <p class="p2">
                      Work for the benefit of the masses with a broader
                      perspective.
                    </p>
                  </div>
                </li>
                <li
                  data-aos="fade-in"
                  data-aos-delay="2400"
                  data-aos-anchor="#section4"
                >
                  <div class="process-img-wrapper2">
                    <img
                      src="assets/images/about/result-impact.png"
                      alt="result-impact"
                      class="process-image lazy about-img-adj"
                    />
                  </div>
                  <div class="our-values-card">
                    <h5>Results &amp; Impact Matters</h5>
                    <p class="p2">
                      Just knowing is not enough, results matter.
                    </p>
                  </div>
                </li>
                <li
                  data-aos="fade-in"
                  data-aos-delay="2600"
                  data-aos-anchor="#section4"
                >
                  <div class="process-img-wrapper2">
                    <img
                      src="assets/images/about/ownership.png"
                      alt="ownership"
                      class="process-image lazy about-img-adj"
                    />
                  </div>
                  <div class="our-values-card">
                    <h5>Ownership</h5>
                    <p class="p2">
                      Accountability &amp; Ownership is the only way.
                    </p>
                  </div>
                </li>
                <li
                  data-aos="fade-in"
                  data-aos-delay="2800"
                  data-aos-anchor="#section4"
                >
                  <div class="process-img-wrapper2">
                    <img
                      src="assets/images/about/creative-collaboration.png"
                      alt="Creativity to Collaboration"
                      class="process-image lazy about-img-adj"
                    />
                  </div>
                  <div class="our-values-card">
                    <h5>Creativity to Collaboration</h5>
                    <p class="p2">
                      Creativity, Innovation, Excellence &amp; Collaboration is
                      our daily doing.
                    </p>
                  </div>
                </li>
                <li
                  data-aos="fade-in"
                  data-aos-delay="3000"
                  data-aos-anchor="#section4"
                >
                  <div class="process-img-wrapper2">
                    <img
                      src="assets/images/about/start-with-you.png"
                      alt="It starts with you"
                      class="process-image lazy about-img-adj"
                    />
                  </div>
                  <div class="our-values-card">
                    <h5>It starts with you!</h5>
                    <p class="p2">
                      Let the transformation happen, start with yourself first.
                    </p>
                  </div>
                </li>
                <li
                  data-aos="fade-in"
                  data-aos-delay="3200"
                  data-aos-anchor="#section4"
                >
                  <div class="process-img-wrapper2">
                    <img
                      src="assets/images/about/share-share.png"
                      alt="Share"
                      class="process-image lazy about-img-adj"
                    />
                  </div>
                  <div class="our-values-card">
                    <h5>Share, Share &amp; Share</h5>
                    <p class="p2">Share to start receiving.</p>
                  </div>
                </li>
                <li
                  data-aos="fade-in"
                  data-aos-delay="3400"
                  data-aos-anchor="#section4"
                >
                  <div class="process-img-wrapper2">
                    <img
                      src="assets/images/about/ego-to-push-up.png"
                      alt="Ego to Push You Up"
                      class="process-image lazy about-img-adj"
                    />
                  </div>
                  <div class="our-values-card">
                    <h5>Ego to Push You Up!</h5>
                    <p class="p2">
                      Use your ego to push you &amp; your work, upward
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- wajahat new code added -->

  <section
    class="comman-section comman-section-100vh scroll-div home-sec4"
    id="section4"
    data-section-name="data-section4"
  >
    <div
      class="ani-wrap50-3 bg-white"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section4"
    ></div>
    <div class="container h-100">
      <div
        class="row vh100-100 align-items-center justify-content-center overflow-hidden"
      >
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div
            class="comman-card-info"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section4"
          >
            <ul class="list-inline comman-card-top-ul">
              <li class="list-inline-item h6 text-white hide-after">Clients</li>
            </ul>
            <h2 class="h2">They Are Our Companions</h2>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 client-logo-wrap-outer-col">
          <div class="client-logo-wrap-outer">
            <div class="client-logo-wrap">
              <ul
                data-aos="fade-in"
                data-aos-delay="1300"
                class="clearfix"
                data-aos-anchor="#section4"
              >
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-dspmf-new.png"
                    alt="adhya"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-angel-new.png"
                    alt="afya"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-motilal-new.png"
                    alt="angel"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-icici-new.png"
                    alt="artofliving"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-mse-new.png"
                    alt="authntick"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-jmfinance-new.png"
                    alt="citizen"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-wns.png"
                    alt="credila"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-datamatic-new.png"
                    alt="datamatic"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-indiamart-new.png"
                    alt="dspmf"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-mahindra-new.png"
                    alt="dut"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-pharmadesk-new.png"
                    alt="empire"
                    class="lazy"
                  />
                </li>

                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-credila-new.png"
                    alt="Indiamart"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-ardent-new.png"
                    alt="humanity"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-dut-new.png"
                    alt="interview-mocha"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-humanity-new.png"
                    alt="mahindra"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-citizen-new.png"
                    alt="motilal"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-artofliving-new.png"
                    alt="mse"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-blodbnk-new.png"
                    alt="mytrux"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-authntick-new.png"
                    alt="pharmadesk"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-afya-new.png"
                    alt="samaraat"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-adhya-new.png"
                    alt="usa"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-samaraat-new.png"
                    alt="wns"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-interview-mocha-new.png"
                    alt="wns"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-kcoverseas-new.png"
                    alt="wns"
                    class="lazy"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section scroll-div home-sec6 pb0"
    id="section6"
    data-section-name="data-section6"
  >
    <div class="container overflow-hidden">
      <div
        class="row align-items-center justify-content-center overflow-hidden"
      >
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5">
          <div class="comman-card-info">
            <ul
              class="list-inline comman-card-top-ul"
              data-aos="fade-in"
              data-aos-delay="300"
              data-aos-anchor="#section6"
            >
              <li class="list-inline-item h6 hide-after">
                Awards &amp; Recognitions
              </li>
            </ul>
            <h2
              class="h2"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor-placement="top-top"
              data-aos-anchor="#section6"
            >
              We Are<br />Blessed With..
            </h2>
          </div>
        </div>
        <div
          class="col-sm-12 col-md-12 col-lg-12 col-xl-7 hide-mobile"
          data-aos="fade-in"
          data-aos-delay="1500"
          data-aos-anchor="#section6"
        >
          <div
            class="comman-scrollbar-wrap"
            malihu-scrollbar
            [scrollbarOptions]="scrollbarOptions"
          >
            <div class="award-logo-wrap clearfix">
              <ul class="clearfix mr40">
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">A' Design Award</h6>
                    <p class="p3 text-orange">2023</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/awards/A-design-award.png"
                        alt="IDA Design Award"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Wins the Prestigious A’ Design Award for designing the UX UI for the Intelehealth Mobile & Web App
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">AWWWARDS</h6>
                    <p class="p3 text-orange">2021</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/awards/awwards.png"
                        alt="AWWWARDS"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Nomination for Best Web Presence created for Prismic
                      Reflections on AWWWARDS.com
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">Design India</h6>
                    <p class="p3 text-orange">2020</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/awards/design-india.png"
                        alt="Design India"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      IBDA 2020, Special Mention for UX/UI Design of Mutual Fund
                      Investment Platform
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">IDA Design Award</h6>
                    <p class="p3 text-orange">2018</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/awards/ida-award.png"
                        alt="IDA Design Award"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Won the IDA Awards 2018 for designing the UX UI for the
                      Authentic Web Application
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">Clutch Top Designers</h6>
                    <p class="p3 text-orange">2018</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/awards/clutch.png"
                        alt="Clutch Top Designers "
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      As on 29 january 2018, Clutch as rated us as one among the
                      top design studios in india.
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">Digithon</h6>
                    <p class="p3 text-orange">2015</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/awards/angle-broking.png"
                        alt="Digithon"
                        class="img-fluid lazy"
                        style="display: inline; max-width: 120px"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Digithon Angel Broking kick-starts a 'first-ever'
                      partner-centric challenge to foster technology innovations
                      won by Prismic Reflections
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">Design Firms</h6>
                    <p class="p3 text-orange">2011</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/awards/designfirms.png"
                        alt="Design Firms"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      DesignFirms<sup>(TM)</sup> Web Awards by Design Firm
                      <br />
                      Prismic Reflections has won the February 2011
                      DESIGNFIRMS<sup>(TM)</sup> WEB AWARD
                    </p>
                  </div>
                </li>
              </ul>
              <ul class="clearfix mt40">
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">Clutch Top Designers</h6>
                    <p class="p3 text-orange">2019</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/awards/clutch.png"
                        alt="IDA Design Award"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Again in 2019, Clutch has rated us as one among the top
                      design studios in india
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">Silicon India</h6>
                    <p class="p3 text-orange">2018</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/awards/silicon.png"
                        alt="Silicon India"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Business and Technology magazine has shortlisted us for 20
                      most promising UX/UI design services providers
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">CSSDA Nominee</h6>
                    <p class="p3 text-orange">2012</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/awards/CSSDA.png"
                        alt="CSSDA Nominee"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Nomination for Web Presence created for Karda
                      Construction, on CSS Design Awards 10 Oct, 2012<br />
                      Role: Concept, Art direction &amp; Designer
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">AWWWARDS</h6>
                    <p class="p3 text-orange">2010</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/awards/awwards.png"
                        alt="AWWWARDS"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Nomination for Best Web Presence created for Prismic
                      Reflections, on AWWWARDS.com
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">CSSDA Nominee</h6>
                    <p class="p3 text-orange">2010</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/awards/CSSDA.png"
                        alt="CSSDA Nominee"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Nomination for Web Presence for Bridgewater Nigeria
                      LTD,CSS Design Awards 19 Dec, 2010 <br />
                      Role: Concept, Art direction &amp; Designer
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-12 show-mobile">
          <owl-carousel-o
            [options]="customOptionsMob"
            class="awards-carosual"
            id="awards-carosual"
          >
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">Design India</h6>
                <p class="p3 text-orange">2020</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/design-india.png"
                    alt="Design India"
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  IBDA 2020, Special Mention for UX/UI Design of Mutual Fund
                  Investment Platform
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">Clutch Top Designers</h6>
                <p class="p3 text-orange">2019</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/clutch.png"
                    alt="Clutch Top Designers"
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Again in 2019, Clutch has rated us as one among the top design
                  studios in india
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">IDA Design Award</h6>
                <p class="p3 text-orange">2018</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/ida-award.png"
                    alt="IDA Design Award"
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Won the IDA Awards 2018 for designing the UX UI for the
                  Authentic Web Application
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">Clutch Top Designers</h6>
                <p class="p3 text-orange">2018</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/clutch.png"
                    alt="Clutch Top Designers "
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  As on 29 january 2018, Clutch as rated us as one among the top
                  design studios in india.
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">Silicon India</h6>
                <p class="p3 text-orange">2018</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/silicon.png"
                    alt="Silicon India"
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Business and Technology magazine has shortlisted us for 20
                  most promising UX/UI design services providers
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">Digithon</h6>
                <p class="p3 text-orange">2015</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/angle-broking.png"
                    alt="Digithon"
                    class="img-fluid lazy"
                    style="display: inline; max-width: inherit !important"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Digithon Angel Broking kick-starts a 'first-ever'
                  partner-centric challenge to foster technology innovations won
                  by Prismic Reflections
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">CSSDA Nominee</h6>
                <p class="p3 text-orange">2012</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/CSSDA.png"
                    alt="CSSDA Nominee"
                    class="img-fluid lazy"
                    style="opacity: 1; max-width: 40px"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Nomination for Web Presence created for Karda Construction, on
                  CSS Design Awards 10 Oct, 2012<br />
                  Role: Concept, Art direction &amp; Designer
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">Design Firms</h6>
                <p class="p3 text-orange">2011</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/designfirms.png"
                    alt="Design Firms"
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  DesignFirms<sup>(TM)</sup> Web Awards by Design Firm <br />
                  Prismic Reflections has won the February 2011 DESIGNFIRMS<sup
                    >(TM)</sup
                  >
                  WEB AWARD
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">AWWWARDS</h6>
                <p class="p3 text-orange">2010</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/awwards.png"
                    alt="AWWWARDS"
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Nomination for Best Web Presence created for Prismic
                  Reflections, on AWWWARDS.com
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">CSSDA Nominee</h6>
                <p class="p3 text-orange">2010</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/CSSDA.png"
                    alt="CSSDA Nominee"
                    class="img-fluid lazy"
                    style="opacity: 1; max-width: 40px"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Nomination for Web Presence for Bridgewater Nigeria LTD,CSS
                  Design Awards 19 Dec, 2010 <br />
                  Role: Concept, Art direction &amp; Designer
                </p>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- wajahat new code end -->

  <section
    class="comman-section comman-section-100vh overflow-hidden scroll-div about-sec5 about-sec5-1"
    id="section5-1"
    data-section-name="data-section5-1"
  >
    <div
      class="ani-wrap50 bg-black"
      data-aos="new-animation4"
      data-aos-delay="100"
      data-aos-anchor="#section5-1"
    ></div>
    <div class="container h-100">
      <div
        class="row vh100-100 mt60-ratina-lg align-items-center justify-content-center"
      >
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div
            class="comman-card-info"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section5-1"
          >
            <p class="p1">
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/about/about-expressions1.svg"
                alt="Our Belief"
                class="about-expressions1 lazy"
              />
              <span>
                A strategic design backed by research with a touch of creativity
                &amp; handshaking with technology can bring a big difference to
                the people how they interact with different products &amp;
                services in different situations.
              </span>
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/about/about-expressions.svg"
                alt="Our Belief"
                class="about-expressions lazy"
              />
            </p>
            <div class="comman-card-footer">
              <h6 class="text-white">
                Vishal J.
                <a
                  href="https://www.linkedin.com/in/vishal-jadhav-29b19823/"
                  target="_blank"
                  ><img
                    src="assets/images/teams/link-in.png"
                    alt="linkin.svg"
                    class="linkin-adj1 img-fluid"
                /></a>
              </h6>
              <h6 class="text-white" style="opacity: 0.5">
                Co-founder, Head of Experience Design & Strategy
              </h6>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div
            data-aos="fade-in"
            data-aos-delay="1200"
            data-aos-anchor="#section5-1"
          >
            <div class="about-sec-img-right">
              <img
                src="assets/images/about/vishal-j.jpg"
                alt="Vishal J."
                class="img-fluid lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    class="comman-section comman-section-100vh overflow-hidden scroll-div about-sec5 about-sec5-2"
    id="section5-2"
    data-section-name="data-section5-2"
  >
    <div
      class="ani-wrap50 bg-black"
      data-aos="new-animation4"
      data-aos-delay="100"
      data-aos-anchor="#section5-2"
    ></div>
    <div class="container h-100">
      <div
        class="row vh100-100 mt60-ratina-lg align-items-center justify-content-center"
      >
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div
            class="comman-card-info"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section5-2"
          >
            <p class="p1">
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/about/about-expressions1.svg"
                alt="Our Belief"
                class="about-expressions1 lazy"
              />
              <span>
                Designing allows you to touch the subtler aspects of a human
                mind and emotions and fly high within unknown territories which
                opens up new dimensions of possibilities.
              </span>
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/about/about-expressions.svg"
                alt="Our Belief"
                class="about-expressions lazy"
              />
            </p>
            <div class="comman-card-footer">
              <h6 class=" text-white">
                Swarup B.
                <a
                  href="https://www.linkedin.com/in/swarupkbagul/"
                  target="_blank"
                  ><img
                    src="assets/images/teams/link-in.png"
                    alt="linkin.svg"
                    class="linkin-adj1 img-fluid"
                /></a>
              </h6>
              <h6 class="text-white" style="opacity: 0.5">
                Co-founder, Design Director
              </h6>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div
            data-aos="fade-in"
            data-aos-delay="1200"
            data-aos-anchor="#section5-2"
          >
            <div class="about-sec-img-right">
              <img
                src="assets/images/about/swarup-b.jpg"
                alt="Vishal J."
                class="img-fluid lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--start our leadership team new section added by wajahat -->
  <section
    class="comman-section scroll-div about-sec7"
    id="section7"
    data-section-name="data-section7"
  >
    <div class="container about-sec7-container">
      <div class="row vh100-100 align-items-center justify-content-center">
     
        <div class="col-12">
          <div
          class="comman-card-info"
          data-aos="fade-in"
          data-aos-delay="300"
          data-aos-anchor="#section7"
        >
          <h2>Our Leadership</h2>
        </div>
          <div class="card-deck" data-aos="fade-in"
          data-aos-delay="600"
          data-aos-anchor="#section7">
            <div class="card">
              <img
                class="card-img-top img-fluid"
                src="assets/images/teams/leader-team-eeshwar.png"
                alt="leader-team-rohit"
              />
              <div class="card-body">
                <h6
                  class="mt-3 font-weight-bold d-flex justify-content-between"
                >
                  Eeshwar C.
                  <a
                    href="https://www.linkedin.com/in/eeshwar-chopraa-160b866/"
                    target="_blank"
                  >
                    <img
                      src="assets/images/teams/linkin-img.png"
                      alt="linkin.svg"
                      class="img-fluid mr-3"
                  /></a>
                </h6>
                <p class="leader-managment-p-adj f-w">
                  Director of Marketing & Sales
                </p>
              </div>
            </div>

            <div class="card">
              <img
                class="card-img-top img-fluid"
                src="assets/images/teams/leader-team-akhil.png"
                alt="leader-team-rohit"
              />
              <div class="card-body">
                <h6
                  class="mt-3 font-weight-bold d-flex justify-content-between"
                >
                  Akhil P.
                  <a
                    href="https://www.linkedin.com/in/akhil-pillai-b54abb57/"
                    target="_blank"
                    ><img
                      src="assets/images/teams/linkin-img.png"
                      alt="linkin.svg"
                      class="img-fluid mr-3"
                  /></a>
                </h6>
                <p class="leader-managment-p-adj">
                  Sr. Business Development Manager
                </p>
              </div>
            </div>

            <div class="card">
              <img
                class="card-img-top img-fluid"
                src="assets/images/teams/leader-team-rohit.png"
                alt="leader-team-rohit"
              />
              <div class="card-body">
                <h6
                  class="mt-3 font-weight-bold text-start d-flex justify-content-between"
                >
                  Rohit S.
                  <a
                    href="https://www.linkedin.com/in/rohit-shimpi-a0993784/"
                    target="_blank"
                  >
                    <img
                      src="assets/images/teams/linkin-img.png"
                      alt="linkin.svg"
                      class="img-fluid mr-3"
                  /></a>
                </h6>
                <p class="leader-managment-p-adj">Sr. Lead Visual Designer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- end our leadership team section -->

  <section
    class="comman-section comman-section-100vh scroll-div about-sec6"
    id="section6"
    data-section-name="data-section6"
  >
    <div class="container">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-sm-12 comman-card-img-wrap3-outer">
          <div class="comman-card-img-wrap3">
            <div
              id="about-sec6-carousel"
              data-interval="3000"
              class="carousel slide carousel-fade"
              data-ride="carousel"
              data-aos="fade-in"
              data-aos-delay="300"
              data-aos-anchor="#section6"
              data-pause="false"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="assets/images/about/gallary-center-1.png"
                    class="d-block lazy"
                    alt="Gallery photo"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/images/about/gallary-center-2.png"
                    class="d-block lazy"
                    alt="Gallery photo"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/images/about/gallary-center-3.png"
                    class="d-block lazy"
                    alt="Gallery photo"
                  />
                </div>
              </div>
            </div>
            <div
              id="about-sec6-carousel2"
              data-interval="5000"
              class="carousel slide carousel-fade"
              data-ride="carousel"
              data-pause="false"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="assets/images/about/gallary-top-1.png"
                    class="d-block lazy"
                    alt="Gallery photo"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/images/about/gallary-top-2.png"
                    class="d-block lazy"
                    alt="Gallery photo"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/images/about/gallary-top-3.png"
                    class="d-block lazy"
                    alt="Gallery photo"
                  />
                </div>
              </div>
            </div>
            <div
              id="about-sec6-carousel3"
              data-interval="3500"
              class="carousel slide carousel-fade"
              data-ride="carousel"
              data-pause="false"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="assets/images/about/gallary-top-right-3.png"
                    class="d-block lazy"
                    alt="Gallery photo"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/images/about/gallary-top-right-2.png"
                    class="d-block lazy"
                    alt="Gallery photo"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/images/about/gallary-top-right-3.png"
                    class="d-block lazy"
                    alt="Gallery photo"
                  />
                </div>
              </div>
            </div>
            <div
              id="about-sec6-carousel4"
              data-interval="4000"
              class="carousel slide carousel-fade"
              data-ride="carousel"
              data-pause="false"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="assets/images/about/gallary-bottom-right-1.png"
                    class="d-block lazy"
                    alt="Gallery photo"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/images/about/gallary-bottom-right-2.png"
                    class="d-block lazy"
                    alt="Gallery photo"
                  />
                </div>
              </div>
            </div>
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/about/about-sec6-1.svg"
              alt="Gallery photo"
              class="about-sec8-3 lazy hide-mobile"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh scroll-div about-sec7"
    id="section7"
    data-section-name="data-section7"
  >
    <div class="container about-sec7-container">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-sm-12">
          <div
            class="comman-card-info"
            data-aos="fade-in"
            data-aos-delay="300"
            data-aos-anchor="#section7"
          >
            <h2>Our Design Force!</h2>
            <p class="p2 text-light-dark-c">
              We believe that designers can make a big difference with their
              empathy, creativity and constant enthusiasm.
            </p>
          </div>
          <div class="lg-mt-20 about-team-row">
            <div class="row">
              <div class="col-md-12">
                <owl-carousel-o
                  [options]="customOptions3"
                  class="owl-caroual-comman-fade"
                >
                  <ng-template carouselSlide>
                    <div class="teams-wrap text-center">
                      <img
                        src="assets/images/teams/priyanka.png"
                        alt="kishan-M."
                        class="img-fluid"
                      />
                      <h6 class="mt-3 font-weight-bold">Priyanka P.</h6>
                      <p>Sr. Visual Designer</p>
                    </div>
                  </ng-template>
                  <ng-template carouselSlide>
                    <div class="teams-wrap text-center">
                      <img
                        src="assets/images/teams/vishakha-agrawal.png"
                        alt="kishan-M."
                        class="img-fluid"
                      />
                      <h6 class="mt-3 font-weight-bold">Vishakha A.</h6>
                      <p>UX UI Designer</p>
                    </div>
                  </ng-template>
                  <ng-template carouselSlide>
                    <div class="teams-wrap text-center">
                      <img
                        src="assets/images/teams/shankar.png"
                        alt="kishan-M."
                        class="img-fluid"
                      />
                      <h6 class="mt-3 font-weight-bold">Sanskar M.</h6>
                      <p>UI & Graphic Designer</p>
                    </div>
                  </ng-template>
  
                  <ng-template carouselSlide>
                    <div class="teams-wrap text-center">
                      <img
                        src="assets/images/teams/meet.png"
                        alt="kishan-M."
                        class="img-fluid"
                      />
                      <h6 class="mt-3 font-weight-bold">Meet G.</h6>
                      <p>UX UI Designer</p>
                    </div>
                  </ng-template>
  
                  <ng-template carouselSlide>
                    <div class="teams-wrap text-center">
                      <img
                        src="assets/images/teams/raj.png"
                        alt="kishan-M."
                        class="img-fluid"
                      />
                      <h6 class="mt-3 font-weight-bold">Raj V.</h6>
                      <p>UI UX Designer</p>
                    </div>
                  </ng-template>
  
                  <ng-template carouselSlide>
                    <div class="teams-wrap text-center">
                      <img
                        src="assets/images/teams/vishakha.png"
                        alt="kishan-M."
                        class="img-fluid"
                      />
                      <h6 class="mt-3 font-weight-bold">Vishakha T.</h6>
                      <p>UX UI Designer</p>
                    </div>
                  </ng-template>
                  <ng-template carouselSlide>
                    <div class="teams-wrap text-center">
                      <img
                        src="assets/images/teams/dhananjay.png"
                        alt="kishan-M."
                        class="img-fluid"
                      />
                      <h6 class="mt-3 font-weight-bold">Dhananjay M.</h6>
                      <p>UX UI Designer</p>
                    </div>
                  </ng-template>
                  <ng-template carouselSlide>
                    <div class="teams-wrap text-center">
                      <img
                        src="assets/images/teams/sukriti.png"
                        alt="kishan-M."
                        class="img-fluid"
                      />
                      <h6 class="mt-3 font-weight-bold">Sukriti S.</h6>
                      <p>UX UI Designer</p>
                    </div>
                  </ng-template>
                  <ng-template carouselSlide>
                    <div class="teams-wrap text-center">
                      <img
                        src="assets/images/teams/dipti.png"
                        alt="kishan-M."
                        class="img-fluid"
                      />
                      <h6 class="mt-3 font-weight-bold">Dipti G.</h6>
                      <p>UX UI Designer</p>
                    </div>
                  </ng-template>
  
                  <ng-template carouselSlide>
                    <div class="teams-wrap text-center">
                      <img
                        src="assets/images/teams/kishan.png"
                        alt="kishan-M."
                        class="img-fluid"
                      />
                      <h6 class="mt-3 font-weight-bold">Kishan M.</h6>
                      <p>UX UI Designer</p>
                    </div>
                  </ng-template>
                  <ng-template carouselSlide>
                    <div class="teams-wrap text-center">
                      <img
                        src="assets/images/teams/jyoti.png"
                        alt="kishan-M."
                        class="img-fluid"
                      />
                      <h6 class="mt-3 font-weight-bold">Jyoti A.</h6>
                      <p>UX UI Designer</p>
                    </div>
                  </ng-template>
                  <ng-template carouselSlide>
                    <div class="teams-wrap text-center">
                      <img
                        src="assets/images/teams/wajahat.png"
                        alt="kishan-M."
                        class="img-fluid"
                      />
                      <h6 class="mt-3 font-weight-bold">Wajahat S.</h6>
                      <p>UI Developer</p>
                    </div>
                  </ng-template>
                </owl-carousel-o>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh scroll-div bg-black join-section"
    id="join-section"
    data-section-name="data-join-section"
  >
    <div
      class="ani-wrap100-top-2"
      style="background: #fff"
      data-aos="new-animation3"
      data-aos-delay="300"
      data-aos-anchor="#join-section"
    ></div>
    <div class="container h-100 position-relative">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div
          class="col-12 text-center"
          data-aos="fade-in"
          data-aos-delay="900"
          data-aos-anchor="#join-section"
        >
          <h2>Want to Join The Force?</h2>
          <a [routerLink]="['/career']" class="bttn btn-line btn-primary-custom"
            ><span>Apply Now</span></a
          >
        </div>
      </div>
    </div>
  </section>
</div>
