import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {

  status = false;

  element1 = false;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    // page loader
    $('.loader-api').delay(1800).fadeOut(2000);
    // setTimeout(function(){
    //   $(".comman-top-menu.active-class .mega-menu.mega-menu-close").click(function () {
    //     // alert(1);
    //     $(".comman-top-menu").removeClass("active-class");
    //   });
    // }, 2000);
    // setTimeout(function () {
    //   $(".comman-header .mega-menu.mega-menu-open").click(function () {
    //     $(".comman-top-menu").addClass("active-class");
    //   });
    //   $(".comman-top-menu .mega-menu.mega-menu-close").click(function () {
    //     $(".comman-top-menu").removeClass("active-class");
    //   });
    // }, 1500);

    const windowWidth = $(window).width();
    if (windowWidth <= 1198) {
      // alert();
      let didScroll;
      let lastScrollTop = 0;
      const delta = 70;
      const navbarHeight = $('#common-header').outerHeight();

      $(window).scroll(function(event): void {
        didScroll = true;
      });
      setInterval(function(): void {
        if (didScroll) {
          hasScrolled();
          didScroll = false;
        }
      }, 250);

      function hasScrolled(): void {
        const st = $(window).scrollTop();
        if (Math.abs(lastScrollTop - st) <= delta) {
          return;
        }
        if (st > lastScrollTop && st > navbarHeight) {
          $('#common-header').removeClass('nav-down nav-up').addClass('nav-up');
        } else {
          if (st + $(window).height() < $(document).height()) {
            if ($(window).scrollTop() < 70) {
              $('#common-header').removeClass('nav-up').removeClass('nav-down');
            } else {
              $('#common-header').removeClass('nav-up').addClass('nav-down');
            }
          }
        }
        lastScrollTop = st;
      }
    }
  }

  ocl(e): void {
    // console.log(e);
    this.element1 = false;
  }

  ocl2(e): void {
    // console.log(e);
    this.element1 = !this.element1;
  }

  hasRoute(route: string): boolean {
    return this.router.url.includes(route);
  }


  
  

}
