import { Component, OnInit } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import scrollify from 'jquery-scrollify';
import AOS from 'aos';
declare let $: any;

@Component({
  selector: 'app-vimson',
  templateUrl: './vimson.component.html',
  styleUrls: ['./vimson.component.scss']
})
export class VimsonComponent implements OnInit {

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  
  // new desc
  descrip = 'Check how we delivered Vimson - a technology business its strategic branding. The next brand could be yours. Let`s discuss!';

  constructor( private titleService: Title, private metaService: Meta ) {
    
     this.setTitle('Branding for Technology Business | Vimson - Casestudy');
    this.metaService.updateTag({name: 'description', content: this.descrip});
    this.metaService.updateTag({name: 'keywords', content: 'UI Design for Corporates, UX for Startup, User Experience Design (UX), User Experience Design, Top Design Studio India, Best Design Agency Mumbai, Pune, Nashik, UI UX Design for Angel Broking, User Interface Design HDFC Credila, User Experience Design (UX) for Fintech, UI/UX for eCommerce, eLearning, User Interface Design for LMS, UI/UX Design Mobile App, UX Design for Mobility, Logo Design, Logo Branding, UI for Food Tech, UI/UX for Leading Real estate Company'});
    this.metaService.updateTag({property: 'og:type', content: 'business.business'});
    this.metaService.updateTag({property: 'og:title', content: 'Branding for Technology Business | Vimson - Casestudy'});
    this.metaService.updateTag({property: 'og:description', content: this.descrip});
  }

  ngOnInit(): void {
    $(function() {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });
    $('.comman-header-wrap').show();
    $('#comman-footer').show();
    $(document).ready(function() {
      $('.logo-wrap-our-team').hide();
      $('.logo-wrap-a').show();
      $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
      $('.arrow-scroll-abs').show();
      $('.arrow-scroll-abs .next').removeClass('text-white');
      $('.comman-top-menu').removeClass('comman-top-menu-dark');
      const wido = $(window).width();
      if (wido >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          overflowscroll: true,
          before(i, panels) {
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.logo-wrap-our-team').removeClass('active-white-text').show();
            $('.pagination-wrap')
              .find('a[href="#' + ref + '"]')
              .addClass('active');
            $('.arrow-scroll-abs').fadeIn();
            $('.arrow-scroll-abs .next').removeClass('text-white');
            $('.comman-top-menu').removeClass('comman-top-menu-dark');
            $('.comman-header .logo-wrap-a').show();
            if (ref === 'data-section1') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').hide();
              $('.comman-header .logo-wrap-a').show();
            } else if (ref === 'data-section2') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').fadeIn();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section3') {
              $('.comman-header')
                .addClass('active-menu-white')
                .removeClass('active-logo-white active-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.arrow-scroll-abs').fadeIn();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section4') {
              $('.comman-header')
                .addClass('active-menu-white')
                .removeClass('active-logo-white active-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.arrow-scroll-abs').fadeIn();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section5') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section7') {
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section8') {
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section-testimonial') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section-learning') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-comman-footer') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section-next-page') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').hide();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.comman-header .logo-wrap-a').hide();
            } else {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').show();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
          },
          afterRender() {
            $('.logo-wrap-our-team').removeClass('active-white-text').hide();
            aosinit();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i) {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
              // pagination += "<li><a class=\"" + activeClass + "\" href=\"#" + $(this).attr("data-section-name") + "\"><span class=\"hover-text\">" + $(this).attr("data-section-name").charAt(0).toUpperCase() + $(this).attr("data-section-name").slice(1) + "</span></a></li>";
            });
            // pagination += "</ul>";
            // $(".home").append(pagination);
          },
        });
        $('.arrow-scroll-abs .next').click(function(e1) {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2) {
          e2.preventDefault();
          $.scrollify.prev();
        });
      }
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }

      function aosinit() {
        AOS.init({
          duration: 400,
          anchorPlacement: 'top-top',
          disable() {
            const maxWidth = 1199;
            return window.innerWidth < maxWidth;
          },
          once: true,
          offset: 20,
        });
      }
    });
  }

}
