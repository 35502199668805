<div class="portfolio-website-moneyguru-body">
  <section
    class="comman-section comman-section-100vh moneyguru-sec-website1 scroll-div overflow-hidden top-section-wrapper mobile-adj"
    id="section1"
    data-section-name="data-section1"
  >
    <div
      class="flexify-first ani-wrap50"
      style="
        background: linear-gradient(218.34deg, #ff668e 4.91%, #3f1f68 180.24%);
      "
      data-aos="new-animation7"
      data-aos-delay="100"
      data-aos-anchor="#section1"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div
          class="col-sm-12 col-md-12 col-lg-7 order-xs-last order-md-last-adj"
        >
          <div
            class="moneyguru-sec-left portfolio-info-wrap2 portfolio-info-banner-l xxs-text-center"
          >
            <ul
              class="list-inline comman-card-top-ul"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section1"
            >
              <li class="list-inline-item h6">
                <span style="color: #fff">Flexify me</span>
              </li>
              <li class="list-inline-item h6" style="color: #fff">
                Fitness App
              </li>
            </ul>
            <h1
              data-aos="fade-in"
              data-aos-delay="400"
              data-aos-anchor="#section1"
              style="white-space: normal; color: #fff"
              class="hide-mobile"
            >
            Achieve Mental, Physical,&<br>Spiritual Harmony 
            </h1>
            
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-5">



            <div>
             
              
              <h1
                data-aos="fade-in"
                data-aos-delay="900"
                data-aos-anchor="#section1"
                style="white-space: normal"
                class="show-mobile flexifyme-h1"
              >
              Achieve Mental, Physical,<br> & Spiritual Harmony 
              </h1>
            </div>
            

         
          <div class="portfolio-info-banner-r xs-mt35 xs-mb10">
            <div class="flexifyme-bnnar-group">
                <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-website-flexifyme/flexify-banner-1.png"
                alt="Moneyguru - Fintech"
                class="moneyguru-banner-img lazy flexifyme-banner-img"
                data-aos="fade-in"
                data-aos-delay="500"
              />
  
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-website-flexifyme/flexify-banner-2.png"
                alt="Moneyguru - Fintech"
                class="moneyguru-banner-img-2 lazy flexifyme-banner-img"
                data-aos="fade-in"
                data-aos-delay="900"
              />
  
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-website-flexifyme/flexify-banner-3.png"
                alt="Moneyguru - Fintech"
                class="moneyguru-banner-img-3 lazy flexifyme-banner-img"
                data-aos="fade-in"
                data-aos-delay="1300"
              />
            </div>
          
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="circle-group show-desktop hide-mobile">
            <img src="assets/images/portfolio/portfolio-website-flexifyme/left-circle.png" alt="left-circle" class="left-circle">
            <img src="assets/images/portfolio/portfolio-website-flexifyme/right-circl.png" alt="right-circle" class="right-circle">
        </div>
      </div>
    </div>
    <div class="arrow-scroll-abs">
      <a href="#" class="next"
        >Scroll
        <div class="c-scrolldown">
          <div class="c-line"></div>
        </div>
      </a>
    </div>

   

  </section>

  <section
    class="comman-section comman-section-100vh comman-solution-challenge moneyguru-sec2 overflow-hidden scroll-div"
    id="section2"
    data-section-name="data-section2"
  >
    <div
      class="ani-wrap100-top-2"
      style="background-color: #ffff"
      data-aos="new-animation3"
      data-aos-delay="300"
      data-aos-anchor="#section2"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-6 col-lg-6 order-xs-last">
          <div
            class="moneyguru-sec2-left"
            data-aos="fade-in"
            data-aos-delay="900"
            data-aos-anchor="#section2"
          >
            <img
              src="assets/images/portfolio/portfolio-website-flexifyme/flexifyme-overview.png"
              alt="flexime - Overview"
              class="moneyguru-sec2-3 img-fluid lazy flexifyme-overview-img"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div
            class="moneyguru-sec2-right portfolio-info-wrap2"
            style="margin-bottom: 50px"
          >
            <h3
              data-aos="fade-in"
              data-aos-delay="1200"
              data-aos-anchor="#section2"
            >
              Overview
            </h3>
            <p
              class="p2"
              data-aos="fade-in"
              data-aos-delay="1500"
              data-aos-anchor="#section2"
            >
              Achieve mental, physical, and spiritual harmony through live
              1-on-1 sessions with our world-class yoga, meditation, and
              nutrition coaches.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh comman-solution-challenge moneyguru-sec3 scroll-div"
    id="section3"
    style="overflow: hidden"
    data-section-name="data-section3"
  >
    <div
      class="ani-wrap50-2"
      style="background: #47347c"
      data-aos="new-animation2"
      data-aos-delay="1200"
      data-aos-anchor="#section3"
    ></div>
    <div
      class="ani-wrap50"
      style="background: #fff"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section3"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-end">
        <div class="col-sm-12 col-md-6 col-lg-6 col-sm-6">
          <div class="moneyguru-sec3-left portfolio-info-wrap2">
            <h3
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section3"
            >
              Challenges
            </h3>
            <p
              class="p2"
              data-aos="fade-in"
              data-aos-delay="900"
              data-aos-anchor="#section3"
            >
            Flexifyme, a startup focused on helping individuals with chronic illnesses or pains caused by modern lifestyle, 
            required a website that would effectively communicate with their target audience. 99999999999999999999999999999999999999999999999999999 
            </p>
            <br />
            <p
              class="p2"
              data-aos="fade-in"
              data-aos-delay="900"
              data-aos-anchor="#section3"
            >
            The team aimed to 
            position their website in a way 
            that would attract users with relevant search queries and engage them with high-quality content and a user-friendly interface.
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-sm-6 section-3-mob-adj">
          <div class="moneyguru-sec3-right portfolio-info-wrap2 ml85">
            <h3
              data-aos="fade-in"
              data-aos-delay="2000"
              data-aos-anchor="#section3"
              class="text-white"
            >
              Solution
            </h3>
            <p
              data-aos="fade-in"
              data-aos-delay="2300"
              data-aos-anchor="#section3"
              class="text-white p2"
            >
            Our objective was to provide a seamless user experience on the website, 
            including the appointment and user login areas, with clear and consistent messaging throughout.
            The dashboard design was intended to empower trainers and users to efficiently plan, check their schedules, and record their prescribed asanas and exercises on any device. Our experienced design team presented the client with multiple wireframes and design options, ensuring that the final product met their expectations and industry standards. By prioritizing user experience
            and responsive design, we aimed to improve the website's search engine visibility and user engagement metrics.
            </p>
            <br />
            <p
              data-aos="fade-in"
              data-aos-delay="2300"
              data-aos-anchor="#section3"
              class="text-white p2"
            >
          
            </p>
            <!-- <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-sec3-bg.svg"
              alt="Moneyguru - Solution"
              class="moneyguru-sec3-bg lazy"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh scroll-div personas-sec-slider bottom-col-section mobile-adj "
    id="section4"
    data-section-name="data-section4"
  >
    <div
      class="comman-bg-overlay1"
      data-aos="comman-bg-overlay-animation"
      data-aos-delay="300"
      data-aos-anchor="#section4"
      style="background-color: #f5f5f5"
    ></div>
    <div class="container">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-md-12">
          <div
            class="esop-center-wrap"
            data-aos="fade-in"
            data-aos-delay="500"
            data-aos-anchor="#section4"
          >
            <h3 class="mt-5">User Persona’s</h3>
          </div>
        </div>
        
        <div class="col-md-12">
            <img
            src="assets/images/portfolio/portfolio-website-flexifyme/user-personas.png"
            alt="Empty States : Stay Engaged!"
            class="show-desktop hide-mobile"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section4"
          />

          <img
          src="assets/images/portfolio/portfolio-website-flexifyme/user-personas1.png"
          alt="Empty States : Stay Engaged!"
          class="hide-desktop show-mobile user-persona-adj"
          data-aos="fade-in"
          data-aos-delay="600"
          data-aos-anchor="#section4"
        />


        </div>

        <div class="col-md-12 bottom-col-set2 d-none">
          <div
            class="choose_slider"
            data-aos="fade-in"
            data-aos-delay="1200"
            data-aos-anchor="#section4"
          >
            <div class="choose_slider_items" style="margin-top: -70px">
              <div
                id="ui-card-slider"
                class="ui-card-slider"
                style="pointer-events: none"
               
              >
                <div class="slide">
                  <img
                    src="assets/images/portfolio/portfolio-website-flexifyme/user-personas.png"
                    alt="Empty States : Stay Engaged!"
                    class="show-desktop hide-mobile"
                  />
                  <img
                    src="assets/images/portfolio/portfolio-website-flexifyme/user-personas1.png"
                    alt="Empty States : Stay Engaged!"
                    class="hide-desktop show-mobile"
                  />
                </div>
                <div class="slide">
                  <img
                    src="assets/images/portfolio/portfolio-website-flexifyme/user-personas.png"
                    alt="Empty States : Stay Engaged!"
                    class="show-desktop hide-mobile"
                  />
                  <img
                    src="assets/images/portfolio/portfolio-website-flexifyme/user-personas1.png"
                    alt="Empty States : Stay Engaged!"
                    class="hide-desktop show-mobile"
                  />
                </div>
                <div class="slide">
                  <img
                    src="assets/images/portfolio/portfolio-website-flexifyme/user-personas.png"
                    alt="Empty States : Stay Engaged!"
                    class="show-desktop hide-mobile"
                  />
                  <img
                    src="assets/images/portfolio/portfolio-website-flexifyme/user-personas1.png"
                    alt="Empty States : Stay Engaged!"
                    class="hide-desktop show-mobile"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh scroll-div esop-sec10 mobile-adj"
    id="data-section5"
    data-section-name="data-section5"
    style="background-color: rgba(71, 52, 124, 1)"
  >
    <div class="container">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-6">
          <div class="portfolio-info-wrap flow-diagrams-left-wrap">
            <h3
              class="font-family-sans-serif"
              data-aos="fade-in"
              data-aos-delay="1200"
              data-aos-anchor="#data-section5"
              style="color: #fff"
            >
              Flow Diagrams
            </h3>
            <p
              class="p2"
              data-aos="fade-in"
              data-aos-delay="1800"
              data-aos-anchor="#data-section5"
              style="color: #fff"
            >
              People can extend their experiences by purchasing monthly paid
              membership and using various features which the app offer.
            </p>
          </div>
          <div class="flow-diagram-left-circle">
            <img src="assets/images/portfolio/portfolio-website-flexifyme/left-circle.png" alt="left-circle" class="Flow-diagram-left-circle">
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="esop-right-wrap"
            data-aos="fade-in"
            data-aos-delay="900"
            data-aos-anchor="#data-section5"
          >
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/flexifyme-flowdiagram.png"
              class="img-fluid lazy flexifyme-flowdiagram-t-60-img flexifyme-diagram-adj"
              alt="ESOP - An Intelligent Comprehensive Help Center"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh mytrux-webapp-sect4 scroll-div mobile-adj1 overflow-hidden"
    data-section-name="section6"
    id="data-section6"
    data-aos-anchor="#section6"
    style="background-color: #ffffff; height: 653px"
  >
    <div class="container h-100">
      <div class="col-md-12">
        <div
          class="esop-center-wrap text-center"
          data-aos="fade-in"
          data-aos-delay="900"
          data-aos-anchor="#data-section6"
        >
          <h3>Wireframing</h3>
        </div>
      </div>

      <div class="row vh100-100">
        <div class="col-md-12 col-lg-6 col-sm-12 mt-adj-100">
          <div
            class="mytrux-webapp-sect4-1-wrap"
            data-aos="fade-in"
            data-aos-delay="1200"
            data-aos-anchor="#data-section6"
          >
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/flexifyme-wireframe2.png"
              alt="Wireframing"
              class="mytrux-webapp-sect4-1 img-fluid lazy"
            />
          </div>
        </div>
        <div class="col-md-12 col-lg-6 col-sm-12 text-md-center-adj">
          <div
            class="mytrux-webapp-sect4-2-wrap"
            data-aos="fade-in"
            data-aos-delay="1500"
            data-aos-anchor="#data-section6"
          >
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/flexifyme-wireframe1.png"
              alt="Wireframing"
              class="mytrux-webapp-sect4-2 img-fluid lazy wireframe-img1"
            />
          </div>

          <div
            class="mytrux-webapp-sect4-3-wrap"
            data-aos="fade-in"
            data-aos-delay="1800"
            data-aos-anchor="#data-section6"
          >
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/flexifyme-wireframe3.png"
              alt="Wireframing"
              class="mytrux-webapp-sect4-3 img-fluid lazy wireframe-img2"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh moneyguru-sec-website6 scroll-div"
    id="section7"
    data-section-name="data-section7"
  >
    <div
      class="ani-wrap100-top-2"
      style="background-color: #ffff"
      data-aos="new-animation3"
      data-aos-delay="100"
      data-aos-anchor="#section7"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-6 col-sm-12 order-xs-last-adj">
          <div
            class="moneyguru-insights-right"
            data-aos="fade-in"
            data-aos-delay="800"
            data-aos-anchor="#section7"
          >
            <p class="p4">Color scheme</p>
            <img src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/portfolio/portfolio-website-flexifyme/color-schem.png"
                    alt="Moneyguru - Platform" class="img-fluid moneyguru-platform lazy playfair-left-div-wrap show-mobile hide-desktop" />
          </div>

          <div class="playfair-group show-desktop hide-mobile">
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/playfair-1.png"
              alt="Moneyguru - Platform"
              class="img-fluid moneyguru-platform lazy playfair-left-div-wrap playfair-1"
              data-aos="fade-in"
              data-aos-delay="400"
              data-aos-anchor="#section7"
            />

            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/playfair-2.png"
              alt="Moneyguru - Platform"
              class="img-fluid moneyguru-platform lazy playfair-left-div-wrap playfair-2"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section7"
            />

            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/playfair-3.png"
              alt="Moneyguru - Platform"
              class="img-fluid moneyguru-platform lazy playfair-left-div-wrap playfair-3"
              data-aos="fade-in"
              data-aos-delay="800"
              data-aos-anchor="#section7"
            />

            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/playfair-4.png"
              alt="Moneyguru - Platform"
              class="img-fluid moneyguru-platform lazy playfair-left-div-wrap playfair-4"
              data-aos="fade-in"
              data-aos-delay="1000"
              data-aos-anchor="#section7"
            />

            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/playfair-5.png"
              alt="Moneyguru - Platform"
              class="img-fluid moneyguru-platform lazy playfair-left-div-wrap playfair-5"
              data-aos="fade-in"
              data-aos-delay="1200"
              data-aos-anchor="#section7"
            />
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div
            class="moneyguru-insights-left portfolio-info-wrap2 float-right-lg"
          >
            <p class="p4" >Typography</p>
            <h3
              data-aos="fade-in"
              data-aos-delay="1400"
              data-aos-anchor="#section7"
            >
              Playfair Display
            </h3>
            <div
              class="moneyguru-insights-right"
              data-aos="fade-in"
              data-aos-delay="1800"
              data-aos-anchor="#section7"
            >
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-website-flexifyme/typography.png"
                alt="Moneyguru - Platform"
                class="img-fluid moneyguru-platform lazy playfair-right-div-wrap"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh smart360-webapp-sect7 scroll-div bg-s360-eleme nts mobile-adj"
    data-section-name="data-section8"
    id="section8"
  >
    <div class="ani-wrap100-top-2" style="background-color: #f5f5f5">
    </div>
    <div class="container h-100">
      <div class="row h-100 align-items-center justify-content-center">

        <div class="col-md-12">
            <div
              class="esop-center-wrap"
              data-aos="fade-in"
              data-aos-delay="500"
              data-aos-anchor="#section8"
            >
              <h3 class="text-center">Visual Design</h3>
            </div>
          </div>

        <div class="col-md-12">
          <div class="smart360-webapp-sect7-1-wrap text-center">
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/visual-design-img.png"
              alt="Designing for Ease"
              class="smart360-webapp-sect7-1 img-fluid lazy flexi-blance-mind-img-adj"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#data-section8"
            />
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/visual-design-left.png"
              alt="Designing for Ease"
              class="smart360-webapp-sect7-1 img-fluid lazy visual-design-left-adj"
              data-aos="fade-in"
              data-aos-delay="500"
              data-aos-anchor="#section8"
            />
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/visual-design-right.png"
              alt="Designing for Ease"
              class="smart360-webapp-sect7-1 img-fluid lazy visual-design-right-adj"
              data-aos="fade-in"
              data-aos-delay="500"
              data-aos-anchor="#section8"
            />
          </div>
          <div class="visual-design-right-circle1 show-desktop hide-mobile ">
            <img src="assets/images/portfolio/portfolio-website-flexifyme/right-circl.png" alt="right-circle" class="visual-design-right-circle">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh scroll-div esop-sec12 flexifyme-sec-slider bottom-col-section mobile-adj overflow-hidden"
    id="section-onboard"
    data-section-name="data-section-onboard"
  >
    <div
      class="comman-bg-overlay"
      data-aos="comman-bg-overlay-animation"
      data-aos-delay="300"
      data-aos-anchor="#section-onboard"
      style="background-color: #ffffff"
    ></div>
    <div class="container container-adj">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-md-12">
          <div
            class="esop-center-wrap"
            data-aos="fade-in"
            data-aos-delay="900"
            data-aos-anchor="#section-onboard"
          >
            <h3 class="text-white mobile-mt-adj">Onboarding</h3>
          </div>
        </div>
        <div class="col-md-12 bottom-col-set2">
          <div
            class="choose_slider"
            data-aos="fade-in"
            data-aos-delay="1200"
            data-aos-anchor="#section-onboard"
          >
            <div class="choose_slider_items">
              <div
                id="ui-card-slider2"
                class="ui-card-slider"
                style="pointer-events: none"
              >
                <div class="slide">
                  <img
                    src="assets/images/portfolio/portfolio-website-flexifyme/onboard1.png"
                    alt="Empty States : Stay Engaged!"
                  />
                </div>
                <div class="slide">
                  <img
                    src="assets/images/portfolio/portfolio-website-flexifyme/onboard2.png"
                    alt="Empty States : Stay Engaged!"
                  />
                </div>
                <div class="slide">
                  <img
                    src="assets/images/portfolio/portfolio-website-flexifyme/onboard3.png"
                    alt="Empty States : Stay Engaged!"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh comman-solution-challenge moneyguru-sec3 scroll-div"
    id="section9"
    style="overflow: hidden"
    data-section-name="data-section9"
  >
    <div
      class="ani-wrap50-2"
      style="background: #47347c"
      data-aos="new-animation2"
      data-aos-delay="800"
      data-aos-anchor="#section9"
    ></div>
    <div
      class="ani-wrap50"
      style="background: #fff"
      data-aos="new-animation2"
      data-aos-delay="1000"
      data-aos-anchor="#section9"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-end">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="moneyguru-sec3-left portfolio-info-wrap2">
            <h3
              data-aos="fade-in"
              data-aos-delay="1200"
              data-aos-anchor="#section9"
            >
              Within 2 weeks of its launch our design has achived amazing
              results!!
            </h3>
          </div>

          <div class="amazing-result show-desktop hide-mobile">
            <img src="assets/images/portfolio/portfolio-website-flexifyme/left-circle.png" alt="left-circle" class="amazing-left-circle">
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 section-9-mob-adj">
            <div
            class="flexifyme-mob-adj moneyguru-sec3-right portfolio-info-wrap2 ml85 show-mobile hide-desktop"
          >


          <img
          src="assets/images/preloader/ajax-loader.png"
          data-src="assets/images/portfolio/portfolio-website-flexifyme/amazing-result.png"
          alt="Designing for Ease"
          class="smart360-webapp-sect7-1 img-fluid lazy "
          data-aos="fade-in"
          data-aos-delay="1900"
          data-aos-anchor="#section9"
        />

        </div>
          <div
            class="flexifyme-mob-adj moneyguru-sec3-right portfolio-info-wrap2 ml85 show-desktop hide-mobile"
          >
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/free-assesments1.png"
              alt="Designing for Ease"
              class="smart360-webapp-sect7-1 img-fluid lazy free-assesment"
              data-aos="fade-in"
              data-aos-delay="1900"
              data-aos-anchor="#section9"
            />

            <img
            src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-website-flexifyme/line1.png"
            alt="Designing for Ease"
            class="smart360-webapp-sect7-1 img-fluid lazy line"
            data-aos="fade-in"
            data-aos-delay="1900"
            data-aos-anchor="#section9"
          />

          <img
          src="assets/images/preloader/ajax-loader.png"
          data-src="assets/images/portfolio/portfolio-website-flexifyme/paid-session1.png"
          alt="Designing for Ease"
          class="smart360-webapp-sect7-1 img-fluid lazy paid-session"
          data-aos="fade-in"
          data-aos-delay="1900"
          data-aos-anchor="#section9"
        />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh illustration-bg comman-solution-challenge moneyguru-sec3 scroll-div"
    id="section10"
    style="overflow: hidden"
    data-section-name="data-section10"
  >
    <div
      class=""
     
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section10"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-end">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="moneyguru-sec3-left portfolio-info-wrap2 flexify-mt-150">
            <h3
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section10"
            >
              Custom Illustrations
            </h3>

            <p
              class="p2"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section10"
            >
              Custom illustrations are created specifically to set the design
              language and give it a personal touch to capture the user
              attention & simplify the messaging inline with consistent brand
              identity.
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div
            class="moneyguru-sec3-right portfolio-info-wrap2 ml85"
            data-aos="fade-in"
            data-aos-delay="1000"
            data-aos-anchor="#section10"
          >
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/custom-illustration.png"
              alt="Designing for Ease"
              class="smart360-webapp-sect7-1 img-fluid lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh comman-solution-challenge moneyguru-sec3 scroll-div"
    id="section11"
    style="overflow: hidden"
    data-section-name="data-section11"
  >
    <div
      class="ani-wrap50"
      style="background: #fff"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section11"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-end">
        <div class="col-md-7 col-lg-7 xs-order-1">
          <div
            class="moneyguru-sec3-left portfolio-info-wrap2"
            data-aos="fade-in"
            data-aos-delay="400"
            data-aos-anchor="#section11"
          >
          <div class="dashboard-group">
            <img
            src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-website-flexifyme/dashboard-design-1.png"
            alt="Designing for Ease"
            class="img-fluid smart360-webapp-sect7-1 lazy flexify-dashboard-1"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section11"
          />
          <img
            src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-website-flexifyme/dashboard-design-2.png"
            alt="Designing for Ease"
            class="img-fluid smart360-webapp-sect7-1 lazy flexify-dashboard-2"
            data-aos="fade-in"
            data-aos-delay="800"
            data-aos-anchor="#section11"
          />


          <img src="assets/images/portfolio/portfolio-website-flexifyme/left-circle.png" alt="left-circle" class="dashboard-left-circle show-desktop hide-mobile">
          </div>
            
          </div>
        </div>
        <div class="col-md-5 col-lg-5">
          <div
            class="moneyguru-sec3-right portfolio-info-wrap2 ml85 flexify-mt-150"
          >
            <h3
              data-aos="fade-in"
              data-aos-delay="1100"
              data-aos-anchor="#section11"
            >
              Dashboard Design
            </h3>
            <img src="assets/images/portfolio/portfolio-website-flexifyme/right-circl.png" alt="right-circle" class="dashboard-circle-right show-desktop hide-mobile">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh comman-solution-challenge moneyguru-sec3 scroll-div"
    id="section12"
    style="overflow: hidden"
    data-section-name="data-section12"
  >
    <div
      class="ani-wrap50"
      style="background: #fff"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section12"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-end">
        <div class="col-sm-12 col-md-5 col-lg-5">
          <h3
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section12"
          >
            Payment<br />
            Details Page
          </h3>
        </div>
        <div class="col-sm-12 col-md-7 col-lg-7">
          <div class="moneyguru-sec3-right">
            <div class="moneyguru-sec3-left portfolio-info-wrap2">
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-website-flexifyme/payment-detail-1.png"
                alt="Designing for Ease"
                class="smart360-webapp-sect7-1 lazy flexify-payment-1 img-fluid"
                data-aos="fade-in"
                data-aos-delay="700"
                data-aos-anchor="#section12"
              />
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-website-flexifyme/payment-detail-2.png"
                alt="Designing for Ease"
                class="smart360-webapp-sect7-1 lazy flexify-payment-2 img-fluid"
                data-aos="fade-in"
                data-aos-delay="900"
                data-aos-anchor="#section12"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh comman-solution-challenge moneyguru-sec3 scroll-div"
    id="section13"
    style="overflow: hidden"
    data-section-name="data-section13"
  >
    <div
      class="ani-wrap50"
      style="background: #fff"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section13"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-end">
        <div class="col-sm-12 col-md-7 col-lg-7 xs-order-1">
          <div
            class="moneyguru-sec3-left portfolio-info-wrap2 flexyfy-book-session"
            data-aos="fade-in"
            data-aos-delay="400"
            data-aos-anchor="#section13"
          >
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/book-session-1.png"
              alt="Designing for Ease"
              class="smart360-webapp-sect7-1 lazy flexify-dashboard-1 img-fluid"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section13"
            />
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/portfolio/portfolio-website-flexifyme/book-session-2.png"
              alt="Designing for Ease"
              class="smart360-webapp-sect7-1 lazy flexify-dashboard-2 img-fluid"
              data-aos="fade-in"
              data-aos-delay="900"
              data-aos-anchor="#section13"
            />
           
          </div>
        </div>
        <div class="col-sm-12 col-md-5 col-lg-5">
          <div
            class="moneyguru-sec3-right portfolio-info-wrap2 ml85 flexify-mt-150"
          >
            <h3
              data-aos="fade-in"
              data-aos-delay="1100"
              data-aos-anchor="#section13"
            >
              Book Your<br />
              Yoga Session
            </h3>
            <img src="assets/images/portfolio/portfolio-website-flexifyme/right-circl.png" alt="right-circle" class="dashboard-circle-right show-desktop hide-mobile">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh comman-solution-challenge moneyguru-sec3 scroll-div"
    id="section14"
    style="overflow: hidden"
    data-section-name="data-section14"
  >
    <div
      class="ani-wrap50"
      style="background: #fff"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section14"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-end">
        <div class="col-sm-12 col-md-5 col-lg-5">
          <h3
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section14"
            class="mb-5"
          >
            Refer a Friend <br />and Get Discounts
          </h3>
        </div>
        <div class="col-sm-12 col-md-7 col-lg-7">
          <div
            class="moneyguru-sec3-right portfolio-info-wrap2 ml85 flexify-refer-friend"
          >
            <div
              class="moneyguru-sec3-left portfolio-info-wrap2"
              data-aos="fade-in"
              data-aos-delay="300"
              data-aos-anchor="#section14"
            >
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-website-flexifyme/refer-friend-1.png"
                alt="Designing for Ease"
                class="smart360-webapp-sect7-1 lazy flexify-payment-1 img-fluid"
                data-aos="fade-in"
                data-aos-delay="500"
                data-aos-anchor="#section14"
              />
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-website-flexifyme/refer-friend-2.png"
                alt="Designing for Ease"
                class="smart360-webapp-sect7-1 lazy flexify-payment-2 img-fluid"
                data-aos="fade-in"
                data-aos-delay="900"
                data-aos-anchor="#section14"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh next-page-section normal-scroll"
    id="section-next-page"
    data-section-name="data-section-next-page"
  >
    <div class="container h-100">
      <div class="row">
        <div class="col-sm-12">
          <div
            class="join-team-wrap"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section-next-page"
          >
            <h6>Next</h6>
            <!-- <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/website/mytrux"> <span>MyTrux</span></a> -->
            <a
              class="page-foot-link h3 st btn-line"
              routerLink="/portfolio/web-app/intelehealth"
            >
              <span>InteleHealth</span></a
            >
            <p class="p2">Delivering Quality Healthcare Beyond Reach</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
