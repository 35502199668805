import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';

import {Error404Component} from './pages/error404/error404.component';
import {ProjectStatusComponent} from './components/project-status/project-status.component';

import {MytruxComponent} from './portfolio/branding/mytrux/mytrux.component';
import {NotionedgeComponent} from './portfolio/branding/notionedge/notionedge.component';
import {PhychemComponent} from './portfolio/branding/phychem/phychem.component';
import {SmartlinkComponent} from './portfolio/branding/smartlink/smartlink.component';
import {ViganComponent} from './portfolio/branding/vigan/vigan.component';
import {VimsonComponent} from './portfolio/branding/vimson/vimson.component';
import {ConcerroComponent} from './portfolio/branding/concerro/concerro.component';
import {WellnessInsigniaComponent} from './portfolio/branding/wellness-insignia/wellness-insignia.component';

import {AalochComponent} from './portfolio/mobile-app/aaloch/aaloch.component';
import {EasyplanComponent} from './portfolio/mobile-app/easyplan/easyplan.component';
import {MoneyguruComponent} from './portfolio/mobile-app/moneyguru/moneyguru.component';
import {AuthntickComponent} from './portfolio/web-app/authntick/authntick.component';
import {SourceTraceComponent} from './portfolio/web-app/source-trace/source-trace.component';
import {MytruxAppComponent} from './portfolio/web-app/mytrux-app/mytrux-app.component';
import {Smart360Component} from './portfolio/web-app/smart360/smart360.component';
import {EsopappComponent} from './portfolio/web-app/esopapp/esopapp.component';
import { FlexifymeComponent } from './portfolio/website/flexifyme/flexifyme.component';

import { FligitalComponent } from './portfolio/website/fligital/fligital.component';
import { IntelehealthComponent } from './portfolio/web-app/intelehealth/intelehealth.component';

import {BynryComponent} from './portfolio/website/bynry/bynry.component';
import {CrubbaComponent} from './portfolio/website/crubba/crubba.component';
import {InsisiveComponent} from './portfolio/website/insisive/insisive.component';
import {MoneyguruWebsiteComponent} from './portfolio/website/moneyguru-website/moneyguru-website.component';
import {MytruxWebsiteComponent} from './portfolio/website/mytrux-website/mytrux-website.component';
import {UxUiDesignProcessComponent} from './pages/ux-ui-design-process/ux-ui-design-process.component';
import {UserExperienceDesignComponent} from './pages/user-experience-design/user-experience-design.component';
import {DesignResearchConsultancyComponent} from './pages/design-research-consultancy/design-research-consultancy.component';
import {BrandStrategyIdentityComponent} from './pages/brand-strategy-identity/brand-strategy-identity.component';

import {AboutComponent} from './pages/about/about.component';
import {ContactComponent} from './pages/contact/contact.component';
import {OurworkComponent} from './pages/ourwork/ourwork.component';
import {CareerComponent} from './pages/career/career.component';
import {DesignExperimentsComponent} from './pages/design-experiments/design-experiments.component';
import {IndustriesComponent} from './pages/industries/industries.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {HomepageComponent} from './pages/homepage/homepage.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 45],
  onSameUrlNavigation: 'reload',
  initialNavigation: 'enabled',
  useHash: false
};

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'error404', component: Error404Component},
  {path: 'home', component: HomepageComponent},
  {path: 'status', component: ProjectStatusComponent},
  {path: 'home', component: HomepageComponent},

  {path: 'portfolio/branding/mytrux', component: MytruxComponent},
  {path: 'portfolio/branding/notionedge', component: NotionedgeComponent},
  {path: 'portfolio/branding/phychem', component: PhychemComponent},
  {path: 'portfolio/branding/smartlink', component: SmartlinkComponent},
  {path: 'portfolio/branding/vigan', component: ViganComponent},
  {path: 'portfolio/branding/vimson', component: VimsonComponent},
  {path: 'portfolio/branding/concerro', component: ConcerroComponent},
  {path: 'portfolio/branding/wellness-insignia', component: WellnessInsigniaComponent},

  {path: 'portfolio/mobile-app/aaloch', component: AalochComponent},
  {path: 'portfolio/mobile-app/easy-plan', component: EasyplanComponent},
  {path: 'portfolio/mobile-app/moneyguru', component: MoneyguruComponent},
  {path: 'portfolio/web-app/authntick', component: AuthntickComponent},
  {path: 'portfolio/web-app/source-trace', component: SourceTraceComponent},
  {path: 'portfolio/web-app/mytrux', component: MytruxAppComponent},
  {path: 'portfolio/web-app/smart360', component: Smart360Component},
  {path: 'portfolio/web-app/esop', component: EsopappComponent},
  {path: 'portfolio/web-app/intelehealth', component: IntelehealthComponent},

  {path: 'portfolio/website/bynry', component: BynryComponent},
  {path: 'portfolio/website/crubba', component: CrubbaComponent},
  {path: 'portfolio/website/insisive', component: InsisiveComponent},
  {path: 'portfolio/website/moneyguru', component: MoneyguruWebsiteComponent},
  {path: 'portfolio/website/mytrux', component: MytruxWebsiteComponent},
  {path: 'portfolio/website/flexifyme', component: FlexifymeComponent},
  {path: 'portfolio/website/fligital', component: FligitalComponent},

  {path: 'services/ux-ui-design-process', component: UxUiDesignProcessComponent},
  {path: 'services/user-experience-design', component: UserExperienceDesignComponent},
  {path: 'services/design-research-consultancy', component: DesignResearchConsultancyComponent},
  {path: 'services/brand-strategy-identity', component: BrandStrategyIdentityComponent},

  {path: 'about', component: AboutComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'our-work', component: OurworkComponent, runGuardsAndResolvers: 'always'},
  {path: 'career', component: CareerComponent},
  {path: 'industries', component: IndustriesComponent},
  {path: 'design-experiments', component: DesignExperimentsComponent},
  {path: 'privacy-term', component: PrivacyComponent},

  {path: '**', component: Error404Component},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
