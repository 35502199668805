import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import AOS from 'aos';

declare let $: any;
declare let value: any;

@Component({
  selector: 'app-design-experiments',
  templateUrl: './design-experiments.component.html',
  styleUrls: ['./design-experiments.component.scss'],
})
export class DesignExperimentsComponent implements OnInit {

  
  //new description
  descrip = 'Prismic Reflections is a strategic ux ui design company that specializes in  ' +
    'user experience design. Let`s discuss!';

  constructor(private titleService: Title, private metaService: Meta) {
   
    this.setTitle('User Experience Design Service | Prismic Reflections');
    this.metaService.updateTag({name: 'description', content: this.descrip});
    this.metaService.updateTag({
      name: 'keywords',
      content: 'UI Design for Corporates, UX for Startup, User Experience Design (UX), User ' +
        'Experience Design, Top Design Studio India, Best Design Agency Mumbai, Pune, Nashik, ' +
        'UI UX Design for Angel Broking, User Interface Design HDFC Credila, User Experience ' +
        'Design (UX) for Fintech, UI/UX for eCommerce, eLearning, User Interface Design for ' +
        'LMS, UI/UX Design Mobile App, UX Design for Mobility, Logo Design, Logo Branding, ' +
        'UI for Food Tech, UI/UX for Leading Real estate Company'
    });
    this.metaService.updateTag({property: 'og:type', content: 'business.business'});
    this.metaService.updateTag({
      property: 'og:title',
      
      content: 'User Experience Design Service | Prismic Reflections'
    });
    this.metaService.updateTag({property: 'og:description', content: this.descrip});
  }

  ngOnInit(): void {
    $(function(): void {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });

    function aosinit(): void {
      AOS.init({
        duration: 400,
        anchorPlacement: 'top-top',
        disable(): boolean {
          const maxWidth = 1199;
          return window.innerWidth < maxWidth;
        },
        once: true,
        offset: 20,
      });
    }

    $('.comman-header-wrap').show();
    $('#comman-footer').show();

    $(document).ready(function(): void {
      $('.logo-wrap-our-team').hide();
      $('.logo-wrap-a').show();
      const windowWidth = $(window).width();
      $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
      $('.arrow-scroll-abs').hide();
      $('.comman-top-menu').removeClass('comman-top-menu-dark');
      $('.design-expe-modal').on('hidden.bs.modal', function(e): void {
        // console.log(e, 'closed');
        $(this).find('.youtube-iframe').each(function(index): boolean {
          $(this).attr('src', $(this).attr('src'));
          return false;
        });
      });
      if (windowWidth >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          overflowscroll: true,
          touchScroll: false,
          before(i, panels): void {
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.pagination-wrap')
              .find('a[href="#' + ref + '"]')
              .addClass('active');
            $('.logo-wrap-a .company-name-mobile')
              .removeClass('active-white-text')
              .hide();
            $('.comman-top-menu').removeClass('comman-top-menu-dark');

            if (ref === 'data-section1') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.arrow-scroll-abs').show();
            } else if (ref === 'data-comman-footer') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
            } else {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.arrow-scroll-abs').show();
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            }
          },
          afterRender(): void {
            aosinit();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i): void {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
            // $('.comman-scrollbar-wrap').mCustomScrollbar({ theme: 'rounded-dots', scrollInertia: 400 });
          },
        });
        $('.arrow-scroll-abs .next').click(function(e1): void {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2): void {
          e2.preventDefault();
          $.scrollify.prev();
        });
      }
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }

    });
    const wido = $(window).width();

    $(window).scroll(function(): void {
      const sticky = $('.our-work-page .work-comman-card-info .custom-nav-wrap-outer');
      const scroll = $(window).scrollTop();

      if (scroll >= 100) {
        sticky.addClass('nav-down');
      } else {
        sticky.removeClass('nav-down');
      }
    });

    // filter menu case
    $('.portfolio-thumb-webapp-btn').click(function(p11): void {
      // alert();
      p11.preventDefault();
      $('.portfolio-thumb-all').hide();
      $('.portfolio-thumb-webapp').show();
      $('.btn-link-custom.btn-line').removeClass('active');
      $('.portfolio-thumb-webapp-btn').addClass('active');
      $('body,html').animate({
        scrollTop: 0
      }, 800);
    });
    $('.portfolio-thumb-mobileapp-btn').click(function(p12): void {
      p12.preventDefault();
      $('.portfolio-thumb-all').hide();
      $('.portfolio-thumb-mobileapp').show();
      $('.btn-link-custom.btn-line').removeClass('active');
      $('.portfolio-thumb-mobileapp-btn').addClass('active');
      $('body,html').animate({
        scrollTop: 0
      }, 800);
    });
    $('.portfolio-thumb-branding-btn').click(function(p13): void {
      p13.preventDefault();
      $('.portfolio-thumb-all').hide();
      $('.portfolio-thumb-branding').show();
      $('.btn-link-custom.btn-line').removeClass('active');
      $('.portfolio-thumb-branding-btn').addClass('active');
      $('body,html').animate({
        scrollTop: 0
      }, 800);
    });
    $('.portfolio-thumb-website-btn').click(function(p14): void {
      p14.preventDefault();
      $('.portfolio-thumb-all').hide();
      $('.portfolio-thumb-website').show();
      $('.btn-link-custom.btn-line').removeClass('active');
      $('.portfolio-thumb-website-btn').addClass('active');
      $('body,html').animate({
        scrollTop: 0
      }, 800);
    });
    $('.portfolio-thumb-all-btn').click(function(p15): void {
      p15.preventDefault();
      $('.portfolio-thumb-all').show();
      $('.btn-link-custom.btn-line').removeClass('active');
      $('.portfolio-thumb-all-btn').addClass('active');
      $('body,html').animate({
        scrollTop: 0
      }, 800);

    });

    $(document).ready(function(): void {

      const pageURL = document.location.href.match(/[^\/]+$/)[0];
      // // alert(pageURL);
      const href = $(this).find('a.nav-link').attr('href');
      setTimeout(function(): void {
        if (pageURL === 'our-work#portfolio-thumb-webapp') {
          $('.portfolio-thumb-webapp-btn').trigger('click');
          // alert(pageURL);
        } else if (pageURL === 'our-work#portfolio-thumb-mobileapp') {
          $('.portfolio-thumb-mobileapp-btn').trigger('click');
          // alert(pageURL);
        } else if (pageURL === 'our-work#portfolio-thumb-branding') {
          $('.portfolio-thumb-branding-btn').trigger('click');
          // alert(pageURL);
        } else if (pageURL === 'our-work#portfolio-thumb-website') {
          $('.portfolio-thumb-website-btn').trigger('click');
          // alert(pageURL);
        }
      }, 1000);
    });
    
    $("#design-expe-card-modal-moderAi-V1").on('hidden.bs.modal', function (e) {
      $("#design-expe-card-modal-moderAi-V1 iframe").attr("src", $("#design-expe-card-modal-moderAi-V1 iframe").attr("src"));
    });

    $("#design-expe-card-modal-moderAi-V2").on('hidden.bs.modal', function (e) {
      $("#design-expe-card-modal-moderAi-V2 iframe").attr("src", $("#design-expe-card-modal-moderAi-V2 iframe").attr("src"));
    });
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

}


