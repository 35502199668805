<div class="portfolio-webapp-esop-body">

    <section class="comman-section comman-section-100vh scroll-div esop-sec1 top-section-wrapper" id="section1"
        data-section-name="data-section1">
        <div class="ani-wrap100-top" style="background: #15269B;" ></div>
            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-esop/esop-triangle1.svg" alt="ESOP - fingerprint" class="esop-triangle1 lazy">
        <div class="esop-triangle2-outer">
            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-esop/esop-triangle2.svg" alt="ESOP - fingerprint" class="esop-triangle2 lazy">
        </div>

        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div class="esop-left-wrap">
                        <ul class="list-inline comman-card-top-ul">
                            <li class="list-inline-item text-white h6">ESOP Direct</li>
                            <li class="list-inline-item text-white h6">Enterprise App </li>
                        </ul>
                        <h1 class="xs-mt-15-adj hide-mobile text-white">
                            Employee Stock Administration
                            Made Intuitive
                            &amp; Simple
                        </h1>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div class="esop-right-wrap">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-esop/esop-header.png"
                            alt="ESOP - Employee Stock Option Administation" class="img-fluid lazy esop-header-img">
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs"> <a href="#" class="next">Scroll<div class="c-scrolldown">
                    <div class="c-line"></div>
                </div> </a></div>
    </section>
    
    <section class="comman-section comman-section-100vh scroll-div esop-sec2" id="section2"
        data-section-name="data-section2">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="overview-sec-center portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2">
                            Overview</h3>
                        <p data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2" class="p2">
                            ESOP Direct offers one of the finest comprehensive solutions for Esop management and stock
                            option administration. Having a vision to become the most <b
                                style="font-weight: 600 !important;">preferred & globally benchmarked
                                platform/solution</b> for stock option administration, client approached Prismic
                            Reflections® to reimagine this functionally rich platform into engaging, an experience rich
                            and a global standard product.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="comman-section comman-section-100vh scroll-div esop-sec3 comman-solution-challenge" id="section3"
        data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background-color: #11D092;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3">
        </div>
        <div class="ani-wrap50 bg-white" data-aos="new-animation2" data-aos-delay="100" data-aos-anchor="#section3">
        </div>
        <div class="container h-100">
            <div class="row h-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-6">
                    <div class="esop-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3"> Challenges</h3>
                        <p class="text-light-dark-c p2" data-aos="fade-in" data-aos-delay="900"
                            data-aos-anchor="#section3">
                            Having a technically complex domain, existing platform was struggling to offer
                            simplicity and self-serving mode to its wide range of different user groups, causing load on
                            customer support team.
                            One of the main objectives behind redesign was also to appeal to the international customers
                            and new generation workforce with a completely new look, intuitive &amp; engaging
                            experience.

                        </p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="esop-right-wrap ml85 portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section3">
                            Solutions</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section3">
                            Targeting to 5 key personas, findability, task completion &amp; accuracy ratio were focused
                            heavily in the redesign exercise.
                            To influence the perception of existing &amp; new customers and users, we decided to
                            introduce new visual language all together, in addition to existing brand guidelines.

                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div esop-sec4" id="section4"
        data-section-name="data-section4">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section4" style="background-color: #11D092;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6">
                    <div class="esop-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4" class="text-white">
                            Findability First</h3>
                        <p data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section4"
                            class="p2 text-white mb20">
                            Users can’t use it if they can not find it. No matter how useful it is.
                        </p>
                        <p data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section4" class="p2 text-white">
                            Revised information architecture &amp; newly designed navigation system addressed the core
                            primary issues we noticed in user research
                        </p>

                    </div>
                </div>
                <div class="col-md-6 text-center">
                    <div class="text-center esop-findability-wrap hide-mobile">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-esop/esop-findability.png"
                            class="esop-findability lazy" alt="ESOP - Findability First" />

                        <div class="list-wrap" style="display:none">
                            <img src="assets/images/portfolio/portfolio-webapp-esop/list-1.png" class="list-1"
                                alt="ESOP - list" data-aos="fade-in" data-aos-delay="1600"
                                data-aos-anchor="#section4" />
                            <img src="assets/images/portfolio/portfolio-webapp-esop/list-2.png" class="list-2"
                                alt="ESOP - list" data-aos="fade-in" data-aos-delay="1800"
                                data-aos-anchor="#section4" />
                            <img src="assets/images/portfolio/portfolio-webapp-esop/list-3.png" class="list-3"
                                alt="ESOP - list" data-aos="fade-in" data-aos-delay="2000"
                                data-aos-anchor="#section4" />
                            <img src="assets/images/portfolio/portfolio-webapp-esop/list-4.png" class="list-4"
                                alt="ESOP - list" data-aos="fade-in" data-aos-delay="2200"
                                data-aos-anchor="#section4" />
                            <img src="assets/images/portfolio/portfolio-webapp-esop/list-5.png" class="list-5"
                                alt="ESOP - list" data-aos="fade-in" data-aos-delay="2400"
                                data-aos-anchor="#section4" />
                            <img src="assets/images/portfolio/portfolio-webapp-esop/list-6.png" class="list-6"
                                alt="ESOP - list" data-aos="fade-in" data-aos-delay="2600"
                                data-aos-anchor="#section4" />
                            <img src="assets/images/portfolio/portfolio-webapp-esop/list-7.png" class="list-7"
                                alt="ESOP - list" data-aos="fade-in" data-aos-delay="2800"
                                data-aos-anchor="#section4" />
                            <img src="assets/images/portfolio/portfolio-webapp-esop/list-8.png" class="list-8"
                                alt="ESOP - list" data-aos="fade-in" data-aos-delay="3000"
                                data-aos-anchor="#section4" />
                        </div>
                    </div>
                    <div class="text-center esop-findability-wrap show-mobile ">
                        <img src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/portfolio/portfolio-webapp-esop/esop-findability-xs.png"
                        class="esop-findability lazy" alt="ESOP - Findability First" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div esop-sec5" id="section5"
        data-section-name="data-section5">
        <div class="ani-wrap50" style="background: #15269B;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section5">
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6">
                    <div class="esop-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section5" class="text-white mb40">
                            It's Time to <br />Hit Refresh!
                        </h3>
                        <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-webapp-esop/esop-time-hits.png"
                                class="img-fluid lazy esop-hits-img" alt="ESOP - It's Time to Hit Refresh!" />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div data-aos="zoom-in" data-aos-delay="1500" data-aos-anchor="#section5" class="text-center show-mobile">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-esop/esop-time-hits-map.svg" class="img-fluid lazy esop-hits-map-img" alt="ESOP - Findability First" />
                    </div>
                    <div class="hide-mobile">
                        <div class="chart-circle-wrap">
                            <img src="assets/images/portfolio/portfolio-webapp-esop/chart1.png" class="chart-img1" alt="ESOP - Findability First"/>
                            <img src="assets/images/portfolio/portfolio-webapp-esop/chart2.png" class="chart-img2" alt="ESOP - Findability First" data-aos="zoom-in" data-aos-delay="1800" data-aos-anchor="#section5" />
                            <img src="assets/images/portfolio/portfolio-webapp-esop/chart3.png" class="chart-img3" alt="ESOP - Findability First" data-aos="zoom-in" data-aos-delay="2000" data-aos-anchor="#section5" />
                            <img src="assets/images/portfolio/portfolio-webapp-esop/chart4.png" class="chart-img4" alt="ESOP - Findability First" data-aos="zoom-in" data-aos-delay="2200" data-aos-anchor="#section5" />
                            <img src="assets/images/portfolio/portfolio-webapp-esop/chart5.png" class="chart-img5" alt="ESOP - Findability First" data-aos="zoom-in" data-aos-delay="2400" data-aos-anchor="#section5" />
                            <img src="assets/images/portfolio/portfolio-webapp-esop/chart6.png" class="chart-img6" alt="ESOP - Findability First" data-aos="zoom-in" data-aos-delay="2600" data-aos-anchor="#section5" />
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div esop-sec6 xs-pb0" id="section6"
        data-section-name="data-section6">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 order-md-1 order-2">
                    <div class="esop-left-wrap">
                        <div class="esop-slider-circle"></div>
                        <div class="comman-scrollbar-wrap">
                            <div class="esop-slider-outer-wrap clearfix">
                                <ul class="clearfix esop-slider-ul mr30" data-aos="fade-in" data-aos-delay="600"
                                    data-aos-anchor="#section6">
                                    <li>
                                        <div class="esop-slider st">
                                            <img src="assets/images/portfolio/portfolio-webapp-esop/esop-slider1.svg"
                                                alt="Personal Touch" class="img-fluid lazy" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st">
                                            <img src="assets/images/portfolio/portfolio-webapp-esop/esop-slider2.svg"
                                                alt="Personal Touch" class="img-fluid lazy" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st">
                                            <img src="assets/images/portfolio/portfolio-webapp-esop/esop-slider3.svg"
                                                alt="Personal Touch" class="img-fluid lazy" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st">
                                            <img src="assets/images/portfolio/portfolio-webapp-esop/esop-slider4.svg"
                                                alt="Personal Touch" class="img-fluid lazy" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st">
                                            <img src="assets/images/portfolio/portfolio-webapp-esop/esop-slider5.svg"
                                                alt="Personal Touch" class="img-fluid lazy" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st">
                                            <img src="assets/images/portfolio/portfolio-webapp-esop/esop-slider6.svg"
                                                alt="Personal Touch" class="img-fluid lazy" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st">
                                            <img src="assets/images/portfolio/portfolio-webapp-esop/esop-slider7.svg"
                                                alt="Personal Touch" class="img-fluid lazy" />
                                        </div>
                                    </li>
                                </ul>

                                <ul class="clearfix esop-slider-ul mt140" data-aos="fade-in" data-aos-delay="600"
                                    data-aos-anchor="#section6">
                                    <li>
                                        <div class="esop-slider st">
                                            <img src="assets/images/portfolio/portfolio-webapp-esop/esop-slider8.svg"
                                                alt="Personal Touch" class="img-fluid lazy" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st">
                                            <img src="assets/images/portfolio/portfolio-webapp-esop/esop-slider9.svg"
                                                alt="Personal Touch" class="img-fluid lazy" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st">
                                            <img src="assets/images/portfolio/portfolio-webapp-esop/esop-slider10.svg"
                                                alt="Personal Touch" class="img-fluid lazy" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st">
                                            <img src="assets/images/portfolio/portfolio-webapp-esop/esop-slider11.svg"
                                                alt="Personal Touch" class="img-fluid lazy" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st">
                                            <img src="assets/images/portfolio/portfolio-webapp-esop/esop-slider12.svg"
                                                alt="Personal Touch" class="img-fluid lazy" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st">
                                            <img src="assets/images/portfolio/portfolio-webapp-esop/esop-slider13.svg"
                                                alt="Personal Touch" class="img-fluid lazy" />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 order-md-2">
                    <div class="esop-right-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6">
                            Perceptions &amp; Emotions
                        </h3>
                        <p data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section6"
                            class="p2 text-light-dark-c">
                            Created a new visual language with custom Illustrations and icons to influence the
                            perception &amp; tap into emotional need.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div esop-sec7 bottom-col-section overflow-hidden"
        id="section7" data-section-name="data-section7">
        <div class="container h-100">
            <div class="row align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="esop-center-wrap xxl-pt80">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section7">
                            Onboarding &amp; Login <br /> Seamless &amp; Simplified</h3>
                    </div>
                </div>
                <div class="col-md-12 bottom-col-set" data-aos="fade-in" data-aos-delay="1600"
                    data-aos-anchor="#section7">
                    <img src="assets/images/portfolio/portfolio-webapp-esop/esop-onboarding-login.png"
                        alt="Onboarding &amp; Login Seamless & Simplified"
                        class="img-fluid lazy esop-onboarding-login" />
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div esop-sec8" id="section8"
        data-section-name="data-section8">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section8" style="background-color: #ffffff;"></div>
        <div class="container">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6">
                    <div class="esop-left-wrap portfolio-info-wrap2">
                        <h3 class="text-white no-wrap-lg" data-aos="fade-in" data-aos-delay="900"
                            data-aos-anchor="#section8">
                            What Needs My <br />
                            Immediate Attention?
                        </h3>
                        <p data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section8" class="p2 text-white">
                            We addressed this key concern of the user while using the <b
                                style="font-weight: 600 !important;">Dashboard</b>. Brought the key insights, actionable
                            items to the surface in a simple and customizable manner.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="esop-right-wrap">
                        <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section8">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-webapp-esop/esop-attention-wrap.png"
                                class="esop-attention-wrap img-fluid lazy"
                                alt="ESOP - What needs My Immediate Attention" />
                        </div>
                        <div class="esop-right-row clearfix">
                            <div class="esop-right-row1" data-aos="fade-in" data-aos-delay="1800"
                                data-aos-anchor="#section8">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-webapp-esop/esop-attention1.png"
                                    class="img-fluid lazy" alt="ESOP - What needs My Immediate Attention" />
                            </div>
                            <div class="esop-right-row2" data-aos="fade-in" data-aos-delay="2100"
                                data-aos-anchor="#section8">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-webapp-esop/esop-attention2.png"
                                    class="img-fluid lazy" alt="ESOP - What needs My Immediate Attention" />
                            </div>
                            <div class="esop-right-row3" data-aos="fade-in" data-aos-delay="2400"
                                data-aos-anchor="#section8">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-webapp-esop/esop-attention3.png"
                                    class="img-fluid lazy" alt="ESOP - What needs My Immediate Attention" />
                            </div>
                            <div class="esop-right-row4" data-aos="fade-in" data-aos-delay="2700"
                                data-aos-anchor="#section8">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-webapp-esop/esop-attention4.png"
                                    class="img-fluid lazy" alt="ESOP - What needs My Immediate Attention" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div esop-sec9" id="section9"
        data-section-name="data-section9">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section9" style="background-color: #ffffff;"></div>
        <div class="circle-sec"></div>
        <div class="container">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-7 order-md-1 order-2">
                    <div class="esop-left-wrap" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section9">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-esop/esop-exercising-stocks.png"
                            class="img-fluid lazy" alt="ESOP - What needs My Immediate Attention" />
                    </div>
                </div>
                <div class="col-md-5 order-md-2">
                    <div class="portfolio-info-wrap esop-right-wrap">
                        <h3 class="font-family-sans-serif" data-aos="fade-in" data-aos-delay="1200"
                            data-aos-anchor="#section9">
                            Exercising Stocks
                        </h3>
                        <p class="p2 text-light-dark-c" data-aos="fade-in" data-aos-delay="1500"
                            data-aos-anchor="#section9">
                            Clear, Informed &amp; Easy like never before!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="comman-section comman-section-100vh scroll-div esop-sec10" id="section10"
        data-section-name="data-section10">
        <div class="circle-sec"></div>
        <div class="container">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6">
                    <div class="portfolio-info-wrap esop-left-wrap">
                        <h3 class="font-family-sans-serif" data-aos="fade-in" data-aos-delay="1200"
                            data-aos-anchor="#section10">
                            An Intelligent Comprehensive <br />
                            Help Center
                        </h3>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="esop-right-wrap" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section10">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-esop/esop-help-center.png"
                            class="img-fluid lazy" alt="ESOP - An Intelligent Comprehensive Help Center" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div esop-sec11" id="section11"
        data-section-name="data-section11">
        <div class="circle-sec"></div>
        <div class="container">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 order-md-1 order-2">
                    <div class="esop-left-wrap" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section11">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-esop/esop-notification.png"
                            class="img-fluid lazy" alt="ESOP - What needs My Immediate Attention" />
                    </div>
                </div>
                <div class="col-md-6 order-md-2">
                    <div class="easy-plan-track-left portfolio-info-wrap esop-right-wrap">
                        <h3 class="font-family-sans-serif" data-aos="fade-in" data-aos-delay="1200"
                            data-aos-anchor="#section11">
                            Centralized &amp; Categorised Notifications
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div esop-sec12 bottom-col-section" id="section12"
        data-section-name="data-section12">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section12" style="background-color: #ffffff;"></div>
        <div class="container">
            <div class="row align-items-center justify-content-center text-center">
                <div class="col-md-12">
                    <div class="esop-center-wrap" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section12">
                        <h3>
                            Stay Engaged on Empty States
                        </h3>
                    </div>
                </div>
                <div class="col-md-12 bottom-col-set2">
                    <div class="choose_slider" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section12">
                        <div class="choose_slider_items">
                            <div id="ui-card-slider" class="ui-card-slider" style="pointer-events: none;">
                                <div class="slide">
                                    <img src="assets/images/portfolio/portfolio-webapp-esop/esop-empty-states1.png"
                                        alt="Empty States : Stay Engaged!" />
                                </div>
                                <div class="slide">
                                    <img src="assets/images/portfolio/portfolio-webapp-esop/esop-empty-states2.png"
                                        alt="Empty States : Stay Engaged!" />
                                </div>
                                <div class="slide">
                                    <img src="assets/images/portfolio/portfolio-webapp-esop/esop-empty-states3.png"
                                        alt="Empty States : Stay Engaged!" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-section-100vh impact-section-upd scroll-div esop-sec13"
        id="section13" data-section-name="data-section-testimonial" style="background-color: #1E1E1E;">
        <div class="ani-wrap50-2" style="background-color: #1E1E1E;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section13"></div>
        <div class="ani-wrap50" style="background-color: #242424;" data-aos="new-animation2" data-aos-delay="300"
            data-aos-anchor="#section13"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <div class="esop-sec13-left portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section13">
                            Impact</h3>
                        <p class="p2 text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section13">
                            After redesign and testing, following impact, we saw on key metrics
                            (in comparison with the older version before redesign)
                        </p>

                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="comman-impact-bottom ml85">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section13">
                            Task Success Rate</h3>
                        <ul class="comman-impact-bottom-ul clearfix">
                            <li data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section13">
                                <p class="p2 text-white mb20">
                                    Finding Required Information
                                </p>
                                <h3 style="color: #ffffff;">
                                    <span class="counter1">80</span>
                                    <span>%</span>
                                </h3>
                            </li>
                            <li data-aos="fade-in" data-aos-delay="2100" data-aos-anchor="#section13">
                                <p class="p2 text-white mb20">
                                    Completing Critical Tasks
                                </p>
                                <h3 style="color: #ffffff;">
                                    <span class="counter2">80</span>
                                    <span>%</span>
                                </h3>
                            </li>
                            <li data-aos="fade-in" data-aos-delay="2700" data-aos-anchor="#section13">
                                <p class="p2 text-white mb20">
                                    Satisfaction &amp; Happiness
                                </p>
                                <h3 style="color: #ffffff;">
                                    <span class="counter3">70</span>
                                    <span>%</span>
                                </h3>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh impact-section-upd scroll-div" id="section-testimonial"
        data-section-name="data-section-testimonial">
        <div class="ani-wrap100-top" style="background-color: #242424;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="comman-impact-bottom" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section-testimonial" style="margin: auto !important;">
                        <div class="card-img-wrap"> <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/testimonial/esop-client.png" alt="placeholder"
                                class="comman-impact-img lazy" /></div>
                        <h6 class="text-white">Jitendra Kasliwal</h6>
                        <h6 class="text-white" style="opacity: 0.5;">CTO</h6>
                        <p class="p3 text-white"> <span> <i class="fas fa-quote-left"></i>
                                Processes and People play a crucial role in the success of any design project. We found
                                Prismic Reflections good at both. They followed a process-driven approach tailored to
                                our specific needs, and came up with solutions that blend creativity, rationale &amp;
                                user feedback together.
                                <i class="fas fa-quote-right"></i> </span></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6> <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/website/insisive">
                            <span>Insisive</span> </a>
                        <p class="p2">Helping Enterprises to Monitor, Optimise &amp; Reduce Their Cloud Spend</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>