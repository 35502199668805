import {BrowserModule, Meta, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {MalihuScrollbarModule} from 'ngx-malihu-scrollbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularDraggableModule} from 'angular2-draggable';
import {NgxBootstrapSliderModule} from 'ngx-bootstrap-slider';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {NgxCaptchaModule} from 'ngx-captcha';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';

import {ProjectStatusComponent} from './components/project-status/project-status.component';
import {MytruxComponent} from './portfolio/branding/mytrux/mytrux.component';
import {NotionedgeComponent} from './portfolio/branding/notionedge/notionedge.component';
import {PhychemComponent} from './portfolio/branding/phychem/phychem.component';
import {SmartlinkComponent} from './portfolio/branding/smartlink/smartlink.component';
import {ViganComponent} from './portfolio/branding/vigan/vigan.component';
import {VimsonComponent} from './portfolio/branding/vimson/vimson.component';
import {AalochComponent} from './portfolio/mobile-app/aaloch/aaloch.component';
import {EasyplanComponent} from './portfolio/mobile-app/easyplan/easyplan.component';
import {MoneyguruComponent} from './portfolio/mobile-app/moneyguru/moneyguru.component';
import {AuthntickComponent} from './portfolio/web-app/authntick/authntick.component';
import {SourceTraceComponent} from './portfolio/web-app/source-trace/source-trace.component';
import {MytruxAppComponent} from './portfolio/web-app/mytrux-app/mytrux-app.component';
import {BynryComponent} from './portfolio/website/bynry/bynry.component';
import {CrubbaComponent} from './portfolio/website/crubba/crubba.component';
import {InsisiveComponent} from './portfolio/website/insisive/insisive.component';
import {MoneyguruWebsiteComponent} from './portfolio/website/moneyguru-website/moneyguru-website.component';
import {MytruxWebsiteComponent} from './portfolio/website/mytrux-website/mytrux-website.component';
import {AboutComponent} from './pages/about/about.component';
import {OurworkComponent} from './pages/ourwork/ourwork.component';
import {ContactComponent} from './pages/contact/contact.component';
import {CareerComponent} from './pages/career/career.component';
import {IndustriesComponent} from './pages/industries/industries.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {UxUiDesignProcessComponent} from './pages/ux-ui-design-process/ux-ui-design-process.component';
import {UserExperienceDesignComponent} from './pages/user-experience-design/user-experience-design.component';
import {DesignResearchConsultancyComponent} from './pages/design-research-consultancy/design-research-consultancy.component';
import {BrandStrategyIdentityComponent} from './pages/brand-strategy-identity/brand-strategy-identity.component';
import {Error404Component} from './pages/error404/error404.component';
import {HomepageComponent} from './pages/homepage/homepage.component';
import {DesignExperimentsComponent} from './pages/design-experiments/design-experiments.component';
import {PvcypolcyComponent} from './components/pvcypolcy/pvcypolcy.component';
import {Smart360Component} from './portfolio/web-app/smart360/smart360.component';
import {EsopappComponent} from './portfolio/web-app/esopapp/esopapp.component';
import {ScrollifyAniService} from './appServices/scrollify-ani.service';
import {ConcerroComponent} from './portfolio/branding/concerro/concerro.component';
import {WellnessInsigniaComponent} from './portfolio/branding/wellness-insignia/wellness-insignia.component';
import { FlexifymeComponent } from './portfolio/website/flexifyme/flexifyme.component';
import { IntelehealthComponent } from './portfolio/web-app/intelehealth/intelehealth.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HeaderComponent,
    ProjectStatusComponent,
    MytruxComponent,
    NotionedgeComponent,
    PhychemComponent,
    SmartlinkComponent,
    ViganComponent,
    VimsonComponent,
    AalochComponent,
    EasyplanComponent,
    MoneyguruComponent,
    AuthntickComponent,
    SourceTraceComponent,
    MytruxAppComponent,
    BynryComponent,
    CrubbaComponent,
    InsisiveComponent,
    MoneyguruWebsiteComponent,
    MytruxWebsiteComponent,
    AboutComponent,
    OurworkComponent,
    ContactComponent,
    CareerComponent,
    IndustriesComponent,
    PrivacyComponent,
    UxUiDesignProcessComponent,
    UserExperienceDesignComponent,
    DesignResearchConsultancyComponent,
    BrandStrategyIdentityComponent,
    Error404Component,
    HomepageComponent,
    DesignExperimentsComponent,
    FooterComponent,
    PvcypolcyComponent,
    Smart360Component,
    EsopappComponent,
    ConcerroComponent,
    WellnessInsigniaComponent,
    FlexifymeComponent,
    IntelehealthComponent
  ],
  imports: [
    // BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    MalihuScrollbarModule.forRoot(),
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularDraggableModule,
    NgxBootstrapSliderModule,
    NgxIntlTelInputModule,
    SweetAlert2Module.forRoot(),
    NgxCaptchaModule
  ],
  exports: [],
  providers: [
    Title,
    Meta,
    ScrollifyAniService
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
