import {Component, OnInit} from '@angular/core';
import {faHeart} from '@fortawesome/free-solid-svg-icons';
import {faBehance, faDribbble, faFacebookF, faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  faDribbble = faDribbble;
  faBehance = faBehance;
  faInstagram = faInstagram;
  faLinkedinIn = faLinkedinIn;
  faFacebookF = faFacebookF;
  faHeart = faHeart;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  gotoContact(): void {
    // this.router.navigate(['contact']);
    this.router.navigate(['contact'], {fragment: 'section1'});
    // document.body.scrollTop = 0;
    window.scrollTo(0, 0);
  }

  // gotoCareer() {
  //   this.router.navigate(['career'], {fragment: 'section2'});
  // }

  hasRoute(route: string): boolean {
    return this.router.url.includes(route);
  }

}
