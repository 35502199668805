import {Component, OnInit} from '@angular/core';
import AOS from 'aos';
// import * as $ from 'jquery';
declare let $: any;

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    $(function(): void {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });

    function aosinit(): void {
      AOS.init({
        duration: 400,
        anchorPlacement: 'top-top',
        disable(): boolean {
          const maxWidth = 1199;
          return window.innerWidth < maxWidth;
        },
        once: true,
        offset: 20,
      });
    }

    const windowWidth = $(window).width();
    if (windowWidth >= 1199) {
      $.scrollify({
        section: '.scroll-div',
        interstitialSection: '.normal-scroll',
        offset: 10,
        scrollbars: true,
        updateHash: false,
        setHeights: true,
        overflowscroll: true,
        before(i, panels): void {
          const ref = panels[i].attr('data-section-name');
          $('.pagination-wrap .active').removeClass('active');
          $('.pagination-wrap').find('a[href="#' + ref + '"]').addClass('active');
          $('.logo-wrap-a .company-name-mobile').removeClass('active-white-text').hide();
          $('.comman-top-menu').removeClass('comman-top-menu-dark');
          if (ref === 'data-section1') {
            $('.comman-header').addClass('active-white').removeClass('active-logo-white active-menu-white');
            $('.arrow-scroll-abs .next').addClass('text-white');
            $('.comman-top-menu').addClass('comman-top-menu-dark');
          } else if (ref === 'data-comman-footer') {
            $('.comman-header').addClass('active-white').removeClass('active-logo-white active-menu-white');
            $('.comman-top-menu').addClass('comman-top-menu-dark');
            $('.process-section1 .comman-card-info-left').fadeOut(100);
          } else {
            $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
            $('.arrow-scroll-abs').show();
            $('.arrow-scroll-abs .next').removeClass('text-white');
            $('.comman-top-menu').removeClass('comman-top-menu-dark');
          }
        },
        afterRender(): void {
          aosinit();
          const pagination = '<ul class="pagination-wrap">';
          let activeClass = '';
          $('.scroll-div').each(function(i): void {
            activeClass = '';
            if (i === 0) {
              activeClass = 'active';
            }
          });
        }
      });
      $('.arrow-scroll-abs .next').click(function(e1): void {
        e1.preventDefault();
        $.scrollify.next();
      });
      $('.arrow-scroll-abs .prev').click(function(e2): void {
        e2.preventDefault();
        $.scrollify.prev();
      });
    }

    if ($(window).width() < 1199) {
      $('[data-aos]').addClass('aos-init aos-animate');
    }

  }

}
