<div class="contact-page-body">
  <section class="comman-section contact-section2 comman-section-100vh scroll-div xs-mt40 md-mt40 sm-mt40"
           id="section1" data-section-name="data-section1">
    <div class="container" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section1">
      <div id="contact-form-carosual" class="carousel slide h-100" data-ride="carousel" data-interval="false"
           data-wrap="false" data-touch="false" data-pause="false">
        <!-- {{ showErrors }} <br> ph: {{ phnHasError }} <br>  -->
        <form class="career-form-wrap" id="contactForm" (ngSubmit)="submitContact(frm)" #frm=ngForm novalidate>
          <div class="carousel-inner" role="listbox">
            <div id="firstSlide" class="carousel-item active">
              <div class="row">
                <div class="col-sm-8 col-md-10 col-lg-8">
                  <div>
                    <div class="contact-form-inner mb50 xs-mt20">
                      <h5> Lets work on your dream project...</h5>
                      <div class="row intchkboxmain">
                        <div *ngFor="let interest of interests; let i = index;" class="col-md-6 mb-3"
                             ngModelGroup="intrs">
                          <div class="custom-checkbox checkbox-adj intchkbox" id="intchkbox">
                            <input #enqintrst="ngModel" [class.error1]="enqintrst.invalid && enqintrst.touched"
                                   name={{interest}} class="interest-chk" id={{interest}} type="checkbox"
                                   value={{interest}} ngModel required>
                                   
                            <label for={{interest}} class="label-checkbox h6"><span
                              class="checkmark"></span>{{interest}}</label>
                          </div>
                        </div>
                      </div>
                      <label *ngIf="showIntrst" class="error interest-chk-error interest-chk-error-comman">Please check
                        at least one option</label>
                    </div>
                    <div class="contact-form-inner xs-mb20 xs-mt40">
                      <h5> For</h5>
                      <div class="row srvchkboxmain">
                        <div *ngFor="let srvc of services; let i = index;" ngModelGroup="srvcs" class="col-md-6 mb-3">
                          <div class="custom-checkbox checkbox-adj srvchkbox">
                            <input name={{srvc}} class="service-chk" id={{srvc}} type="checkbox" value={{srvc}} ngModel
                                   required>
                            <label for={{srvc}} class="label-checkbox h6"><span class="checkmark"></span>{{srvc}}
                            </label>
                          </div>
                        </div>
                      </div>
                      <label *ngIf="showSrvc" class="error service-chk-error interest-chk-error-comman">Please check at
                        least one option</label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-2 col-lg-4 my-budget-wrap-col hide-mobile">
                  <div class="contact-form-inner my-budget-wrap">
                    <h5> My budget is</h5>
                    <div class="range-slider-main">
                      <mv-slider [(value)]="valb" [min]="1" [max]="6" [enabled]="enabled" [orientation]="orientation"
                                 [tooltip]="tooltip" [ticks]="ticks" [ticksTooltip]="ticksTooltip"
                                 [ticksLabels]='["$100k+", "$50K-$100K", "$25K-$50K", "$10K-$25K", "$5K-$10K", "$5K"]'
                                 [lockToTicks]="true"
                                 (change)="changedVal($event)"></mv-slider>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 my-budget-wrap-col-xs show-mobile xs-mt30">
                  <div class="contact-form-inner">
                    <h4>My budget is : <span class="my-budget-text-xs">{{ selectBudget }}</span></h4>
                  </div>
                  <mv-slider class="budget_input_xs" dir='rtl' [(value)]="valb" [min]="1" [max]="6" [enabled]="enabled"
                             [orientation]="orientationMob" [tooltip]="tooltip"
                             [ticks]="ticks" [ticksTooltip]="ticksTooltip"
                             [ticksLabels]='["$100k+", "$50K-$100K", "$25K-$50K", "$10K-$25K", "$5K-$10K", "$5K"]'
                             [lockToTicks]="true"
                             (change)="changedVal($event)"></mv-slider>
                </div>
                <div class="col-md-12 form-control-col-btn">
                  <div>
                    <button class="bttn btn-line btn-primary-custom" name="btn_next" id="btn_next" type="button"
                            role="button" (click)=nxtClicked($event)><span>Next</span></button>
                    <span class="p3 ml40"> Step 1 of 2 </span>
                  </div>
                </div>
              </div>
            </div>
            <div id="secondSlide" class="carousel-item h-100">
              <div class="row">
                <div class="col-sm-12">
                  <div class="contact-breadcrum-outer" data-slide-to="0"
                       data-target="#contact-form-carosual" style="cursor: pointer;"><a
                    class="bttn btn-link-custom btn-back-link" href="#"><img alt="Location"
                                                                             class="left-arrow-icon"
                                                                             src="assets/images/svg/left-arrow-icon.svg"></a>
                    <ul class="list-inline contact-breadcrum2">
                      <li class="list-inline-item h6"><span id="selected_interest">interests</span></li>
                      <li class="list-inline-item h6"><span id="selected_for">looking for</span></li>
                      <li class="list-inline-item h6"><span>{{selectBudget}}</span></li>
                    </ul>
                  </div>
                  <div class="contact-form-inner mb30">
                    <h5> Tell us about you</h5>
                  </div>
                </div>
                <div class="col-md-6 form-control-col pr50">
                  <div class="pr-form-control h6 input-effect">
                    <input class="input-control-wrap h6" type="text" placeholder="" #fname="ngModel"
                           [class.error]="fname.invalid && fname.touched" id="full_name" name="full_name" ngModel
                           required>
                    <label class="form-label h6" for="full_name">My Name is</label>
                    <label *ngIf="fname.invalid && fname.touched" id="full_name-error" class="error" for="full_name">Please
                      enter your full name</label>
                    <label *ngIf="showErrors && fname.untouched && fname.pristine" class="error">This field cannot be
                      empty</label>
                    <span class="focus-border"></span>
                  </div>
                </div>
                <div class="col-md-6 form-control-col pl50">
                  <div class="pr-form-control h6 input-effect">
                    <input class="input-control-wrap h6" type="email" #enqemail="ngModel"
                           pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                           [class.error]="enqemail.invalid && enqemail.touched" placeholder="" id="email" name="email"
                           ngModel required>
                    <label class="form-label h6" for="email">My Email</label>
                    <label *ngIf="enqemail.invalid && enqemail.touched && enqemail.errors?.required" id="email-error"
                           class="error"
                           for="career_email">Please enter your valid email address</label>
                    <label *ngIf="enqemail.invalid && enqemail.touched && enqemail.errors?.pattern" id="email-error"
                           class="error"
                           for="career_email">This is not a valid email address</label>
                    <label *ngIf="showErrors && enqemail.untouched && enqemail.pristine" class="error">This field cannot
                      be empty</label>
                    <span class="focus-border"></span>
                  </div>
                </div>
                <div class="col-md-6 form-control-col pr50 form-group-wrap-tel-outer">
                  <div class="pr-form-control h6 input-effect">
                    <div class="wrapper" #f="ngForm" [formGroup]="phoneForm">
                      <ngx-intl-tel-input #enqnum [cssClass]="'input-control-wrap h6'"
                                          [preferredCountries]="preferredCountries"
                                          [enableAutoCountrySelect]="true" [enablePlaceholder]="false"
                                          [searchCountryFlag]="true"
                                          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                          [selectFirstCountry]="false"
                                          [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                                          [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                          [numberFormat]="PhoneNumberFormat.National" name="phone"
                                          formControlName="phone">
                      </ngx-intl-tel-input>
                      <span class="focus-border"></span>
                      <label *ngIf="f.form.controls.phone.invalid && f.form.controls.phone.valid.touched"
                             id="email-error" class="error">Please enter valid mobile number</label>
                      <label *ngIf="showErrors && phnHasError" class="error">Please enter valid mobile number</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-control-col pl50">
                  <div class="pr-form-control h6 input-effect">
                    <textarea name="message" #enqmsg="ngModel" [class.error]="enqmsg.invalid && enqmsg.touched"
                              id="message" rows="1" class="input-control-wrap h6" ngModel required></textarea>
                    <label class="form-label h6" for="message">My Message</label>
                    <label *ngIf="enqmsg.invalid && enqmsg.touched" id="message-error" class="error" for="message">Please
                      enter your message</label>
                    <label *ngIf="showErrors && enqmsg.untouched && enqmsg.pristine" class="error">This field cannot be
                      empty</label>
                    <span class="focus-border"></span>
                  </div>
                </div>
                <div class="col-md-6 form-control-col pr50">
                  <div class="pr-form-control h6 input-effect">
                    <select (blur)="validateFoundon(enqfoundon.value)" (change)="validateFoundon(enqfoundon.value)"
                            class="input-control-wrap has-content valid" #enqfoundon="ngModel"
                            [class.error]="enqfoundon.invalid && enqfoundon.touched" id="found_on" name="found_on"
                            style="background: transparent url('assets/images/svg/down-arrow-select.svg') no-repeat center right 15px;"
                            [(ngModel)]="property" required>
                      <option disabled ngValue="default">Select an Option</option>
                      <option *ngFor="let fon of foundOn; let i = index;">{{fon}}</option>
                    </select>
                    <label class="form-label h6" for="found_on">We found you on</label>
                    <label *ngIf="foundonHasError && enqfoundon.touched" id="message-error" class="error" for="message">Please
                      select an option</label>
                    <label *ngIf="showErrors && enqfoundon.untouched && enqfoundon.pristine" class="error">Please select
                      from given options</label>
                  </div>
                </div>
                <div class="col-md-6 form-control-col pl50">
                  <div *ngIf="specificsrc" class="pr-form-control h6 input-effect" id="specific-source-wrap">
                    <input class="input-control-wrap h6" type="text" placeholder="" id="specific_source"
                           name="specific_source" value="" ngModel>
                    <label class="form-label h6" for="specific_source">Enter specific source</label>
                    <!-- <span class="focus-border"></span> -->
                  </div>
                </div>
                <div class="col-md-6 form-control-col pr50 g-capcha-col-wrap">
                  <!-- <div class="pr-form-control h6 input-effect xs-mb20">
                      <input type="hidden" class="hiddenRecaptcha" name="hiddenRecaptcha" id="hiddenRecaptcha">
                      <div class="g-recaptcha" data-sitekey="6Lfzug4TAAAAANC_OHvvSmrIoCtnHrAmMvk3EXtm"></div>
                  </div> -->
                  <div class="pr-form-control h6 input-effect xs-mb20" [formGroup]="cFormGroup">
                    <ngx-recaptcha2 #captchaElem [siteKey]="'6Lfzug4TAAAAANC_OHvvSmrIoCtnHrAmMvk3EXtm'"
                                    (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="size"
                                    [hl]="lang" [theme]="theme" formControlName="recaptcha"
                                    [type]="type">
                    </ngx-recaptcha2>
                    <label *ngIf="showErrors && captchaHasError" class="error">Please verify the captcha</label>
                  </div>
                </div>
                <div class="col-md-6 form-control-col pl50 term-col-wrap">
                  <div class="custom-checkbox">
                    <input type="checkbox" #prvcyaccpt="ngModel"
                           [class.error]="prvcyaccpt.invalid && prvcyaccpt.touched" id="i_agree" name="i_agree"
                           value="i_agree" ngModel required>
                    <label for="i_agree" class="label-checkbox p5" tabindex="10"><span class="checkmark"></span>I
                      have read and understood all terms and conditions of the <a routerLink="/privacy-term"
                                                                                  class="a-link-black"
                                                                                  target="_blank"
                                                                                  style="font-size: inherit;">Terms of
                        Use and Privacy Policy</a> and hereby provide my specific
                      consent to control/process the information submitted by me.</label>
                    <label *ngIf="prvcyaccpt.invalid && prvcyaccpt.touched" id="i_agree-error" class="error"
                           for="i_agree">Please agree
                      Terms of use and privacy policy</label>
                    <label *ngIf="showErrors && prvcyaccpt.untouched && prvcyaccpt.pristine" class="error">Please tick
                      the box</label>
                  </div>
                </div>
                <div class="col-md-12 form-control-col-btn form-control-col-btn2" id="form-submit-btn-wrap">
                  <button class="bttn btn-line btn-primary-custom xs-mr20" type="submit" name="btn_contact"><span>Send it</span>
                  </button>
                  <button class="bttn btn-line btn-primary-custom" type="button" name="btn_test" id="btn_test" hidden>
                    <span>Send it</span></button>
                  <input (click)="frm.resetForm({})" type="reset" name="btn_reset" id="btn_reset" value="Reset"
                         data-target="#contact-form-carosual" data-slide="prev"
                         class="bttn btn-link-custom btn-line reset-btn">
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  <section class="comman-section contact-section1 top-section-wrapper comman-section-100vh scroll-div" id="section2"
           data-section-name="data-section2">
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2">
                <h2 class="text-center mb50">Our Presence</h2>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 xs-text-center">
              <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                <div class="addr-wrap">
                  <div class="addr-img-wrap"><img src="assets/images/preloader/ajax-loader.png"
                                                  data-src="assets/images/svg/location-nashik.svg" alt="Location Nashik"
                                                  class="addr-location lazy" height="80" style="margin-top: 8px;"/></div>
                  <div class="addr-indo-wrap">
                    <h5>Nashik</h5>
                    <p class="p3 text-light-dark-c">IT/21, Jyoti Telecom, Near AIMA Recreation Hall,
                      Ambad MIDC,<br/> Nashik, Maharashtra 422010</p>
                    <ul class="list-inline addr-list-inline">
                      <li class="list-inline-item"><a class="p3 text-light-dark-c"
                                                      href="tel:02532386721">0253-238 6721</a></li>
                      <li class="list-inline-item"><a class="p3 text-light-dark-c"
                                                      href="tel:+917887888729">+91 788 788 8729</a></li>
                    </ul>
                    <!-- <a class="bttn btn-line btn-link-custom"
                       href="https://goo.gl/maps/DgwRhrSisSnPooJ68" target="_blank"><img
                      alt="Location" class="location-icon lazy"
                      src="assets/images/svg/location-icon.svg"><span>View on Map</span></a> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 addr-wrap-col-adj xs-text-center">
              <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2">
                <div class="addr-wrap">
                  <div class="addr-img-wrap"><img src="assets/images/preloader/ajax-loader.png"
                                                  data-src="assets/images/svg/location-mumbai.svg" alt="Location Mumbai"
                                                  class="addr-location lazy"></div>
                  <div class="addr-indo-wrap">
                    <h5>Mumbai</h5>
                    <p class="p3 text-light-dark-c">202, 2nd floor, Ackruti Star, MIDC, Central
                      Road, Andheri (East),<br> Mumbai, Maharashtra 400093</p>
                    <ul class="list-inline addr-list-inline">
                      <li class="list-inline-item"><a class="p3 text-light-dark-c"
                                                      href="tel:+919881266721">+91 98812 66721</a></li>
                      <li class="list-inline-item"><a class="p3 text-light-dark-c"
                                                      href="tel:+919823391221">+91 98233 91221</a></li>
                    </ul>
                    <!-- <a class="bttn btn-line btn-link-custom"
                       href="https://goo.gl/maps/2dzuxzJXMiDheqb76" target="_blank"><img
                      alt="Location" class="location-icon lazy"
                      src="assets/images/svg/location-icon.svg"><span>View on Map</span></a> -->
                  </div>
                </div>
              </div>
            </div>
                 <!-- Wajahat code add for pune location  -->
                 <div class="col-sm-12 col-md-12 col-lg-4 xs-text-center">
                  <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                   <div class="addr-wrap">
                     <div class="addr-img-wrap"><img src="assets/images/preloader/ajax-loader.png"
                                                     data-src="assets/images/svg/pune.svg" alt="Location Nashik"
                                                     class="addr-location lazy img-adj"/></div>
                     <div class="addr-indo-wrap">
                       <h5>Pune</h5>
                       <p class="p3 text-light-dark-c">501/502 Fifth floor,<br/>
                         Mariegold Building,<br/> Viman Nagar, Pune-411014</p>
                         <p class="p3 text-light-dark-c">+91 98812 66721</p>
                       
                     </div>
                   </div>
                 </div>
               </div>
                   <!-- wajahat code end for pune location -->
          </div>
        </div>
      </div>
    </div>
    <div class="arrow-scroll-abs"><a href="#" class="next">Scroll
      <div class="c-scrolldown">
        <div class="c-line"></div>
      </div>
    </a></div>
  </section>

</div>
