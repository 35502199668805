<div class="portfolio-webapp-authntick-body">
    <section class="comman-section comman-section-100vh scroll-div authntick-sec1 top-section-wrapper" id="section1"
        data-section-name="data-section1">
        <div class="ani-wrap100-top" style="background: #d2143a;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section1"></div>
        <img src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-webapp-authntick/authntick-fingerprint1.svg"
            alt="Authntick - fingerprint" class="authntick-fingerprint1 lazy" data-aos="fade-in" data-aos-delay="1500"
            data-aos-anchor="#section1" />
        <img src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-webapp-authntick/authntick-fingerprint2.svg"
            alt="Authntick - fingerprint" class="authntick-fingerprint2 lazy" data-aos="fade-in" data-aos-delay="900"
            data-aos-anchor="#section1" />
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-5 col-xl-4 order-md-last-adj">
                    <div class="authntick-left-wrap">
                        <ul class="list-inline comman-card-top-ul xs-text-center" data-aos="fade-in"
                            data-aos-delay="600" data-aos-anchor="#section1">
                            <li class="list-inline-item text-white h6">Authntick</li>
                            <li class="list-inline-item text-white h6">A.I, Digital Verification</li>
                        </ul>
                        <h1 class="xs-mt-15-adj hide-mobile" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            Security Meets<br />
                            Simplicity
                        </h1>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-7 col-xl-8">
                    <h1 class="xs-mt-15-adj show-mobile xs-text-center" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section1">
                        Security Meets<br />
                        Simplicity
                    </h1>
                    <div class="authntick-right-wrap" data-aos="fade-in" data-aos-delay="1200"
                        data-aos-anchor="#section1">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-authntick/authntick-dashboard.png"
                            alt="Authntick - Security Meets Simplicity" class="img-fluid lazy" />
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">
                Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div authntick-sec3" id="section2"
        data-section-name="data-section2">
        <div class="ani-wrap100-top-2 bg-black" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section2"></div>
        <div class="authntick-sec3-bg"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="authntick-sec3-center overview-sec-center portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2" class="text-white">
                            Overview</h3>
                        <p data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2" class="text-white p2">
                            AuthNTick Identity Service Delivers enhanced security and privacy to businesses &amp;
                            individuals by providing real-time National Police check and Document Verification service
                            in completely an automated way
                            using AI &amp; Biometrics.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div authntick-sec2 comman-solution-challenge"
        id="section3" data-section-name="data-section3">
        <div class="ani-wrap50-2 bg-black" data-aos="new-animation2" data-aos-delay="1200" data-aos-anchor="#section3">
        </div>
        <div class="ani-wrap50 bg-white" data-aos="new-animation2" data-aos-delay="100" data-aos-anchor="#section3">
        </div>
        <img src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-webapp-authntick/authntick-sec2-1.svg"
            alt="Authntick - Challenges" class="authntick-sec2-1 lazy" />
        <div class="container h-100">
            <div class="row h-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-6">
                    <div class="authntick-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3">Challenges</h3>
                        <ul class="comman-card-bottom-ul" data-aos="fade-in" data-aos-delay="900"
                            data-aos-anchor="#section3">
                            <li class="p2 text-light-dark-c">Re Imagining the flow &amp; architecture of a traditional
                                offline verification process into completely digital &amp; automated process</li>
                            <li class="p2 text-light-dark-c">Create scalable platform that can accommodate further
                                verticals and functional features</li>
                            <li class="p2 text-light-dark-c">Reduce information overload &amp; simplify communication
                                language for non tech user</li>
                            <li class="p2 text-light-dark-c">Enable organisations to handle &amp; track bulk
                                verification in single flow</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="authntick-right-wrap ml85 portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3">
                            Solutions</h3>
                        <ul class="comman-card-bottom-ul z-index5 position-relative" data-aos="fade-in"
                            data-aos-delay="2300" data-aos-anchor="#section3">
                            <li class="p2 text-white">Simple &amp; Single Onboarding for businesses &amp; individuals.
                                With only necessary details captured in the beginning.</li>
                            <li class="p2 text-white">Progressive disclosure technique for long form broken down into 7
                                easy steps</li>
                            <li class="p2 text-white">Enabled multiple check application by adding all application
                                details at once or import from external file</li>
                            <li class="p2 text-white">Dashboard surfacing necessary metrics to track checks and take
                                actions upon</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div authntick-sec4" id="section4"
        data-section-name="data-section4">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section4" style="background-color: #242424;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6">
                    <div class="authntick-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4">Flow Diagrams</h3>
                        <p data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section4"
                            class="p2 text-light-dark-c">Reimagining offline process to completely digital online
                            process.</p>
                        <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section4">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-webapp-authntick/authntick-sec4-3.svg"
                                class="authntick-sec4-3 img-fluid lazy" alt="Authntick - User Journey" />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div data-aos="fade-in" data-aos-delay="1900" data-aos-anchor="#section4"
                        class="authntick-sec4-2-outer">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-authntick/authntick-sec4-2.svg"
                            class="authntick-sec4-2 img-fluid lazy" alt="Authntick - User Journey" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div authntick-sect5" id="section5"
        data-section-name="data-section5">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section5" style="background-color: #fff;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="authntick-sec5-outer">
                        <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5"
                            class="position-relative z-index5 authntick-sect5-laptop-wrap">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-webapp-authntick/authntick-sect5-laptop.png"
                                class="authntick-sect5-laptop img-fluid lazy" alt="Authntick - Dashboard" />
                        </div>
                        <div class="authntick-sect5-overlay-wrap" data-aos="fade-in" data-aos-delay="1500"
                            data-aos-anchor="#section5">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-webapp-authntick/authntick-sect5-overlay.png"
                                class="authntick-sect5-overlay img-fluid lazy" alt="Authntick - Dashboard" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div authntick-sec6 xs-pb0" id="section6"
        data-section-name="data-section6">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section6" style="background-color: #d71538;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6">
                    <div class="authntick-left-wrap portfolio-info-wrap2 xs-text-center">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6">Onboarding for
                            Businesses &amp; Individuals</h3>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="authntick-sec6-2-outer">
                        <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section6"
                            class="position-relative z-index5">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-webapp-authntick/authntick-sec6-2.png"
                                class="authntick-sec6-2 img-fluid lazy" alt="Authntick - Select an Account" />
                        </div>
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-authntick/authntick-sec6-1.svg"
                            class="authntick-sec6-1 img-fluid lazy" alt="Authntick - Select an Account" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div authntick-sec7 xs-pb0" id="section7"
        data-section-name="data-section7">
        <div class="ani-wrap50-3 bg-red" data-aos="new-animation5" data-aos-delay="100" data-aos-anchor="#section7">
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 order-xs-last">
                    <div data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section7"
                        class="authntick-sec7-2-outer">
                        <div id="ui-card-slider" class="ui-card-slider">
                            <div class="slide"><img src="assets/images/portfolio/portfolio-webapp-authntick/slider-3d-1.svg" alt="Authntick - Choose an Order Type" /></div>
                            <div class="slide"><img src="assets/images/portfolio/portfolio-webapp-authntick/slider-3d-1.svg" alt="Authntick - Choose an Order Type" /></div>
                            <div class="slide"><img src="assets/images/portfolio/portfolio-webapp-authntick/slider-3d-1.svg" alt="Authntick - Choose an Order Type" /></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="authntick-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section7" class="xs-text-center">
                            Select Your <br class="show-mobile" /> Order Type
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div authntick-sec9 bottom-col-section" id="section9"
        data-section-name="data-section9">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="authntick-center-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="300" data-aos-anchor="#section9">
                            Complex &amp; Long <br class="show-mobile" />
                            Process Simplified
                        </h3>
                        <p data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section9"
                            class="p2 text-light-dark-c">
                            Progressing wizard introduced by grouping the relevant information in small steps. Enabled
                            user with flexibility to stop and exit from any step and return on the same.
                        </p>
                    </div>
                </div>
                <div class="col-md-12 bottom-col-set" data-aos="fade-in" data-aos-delay="900"
                    data-aos-anchor="#section9">
                    <div class="authntick-sec9-2-outer">
                        <owl-carousel-o [options]="customOptions" class="authntick-result-carosual" id="authntick-result-carosual">
                            <ng-template carouselSlide>
                                <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-webapp-authntick/authntick-result1.png" alt="Authntick - Photo Gallary" />
                            </ng-template>
                            <ng-template carouselSlide>
                                <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-webapp-authntick/authntick-result1.png" alt="Authntick - Photo Gallary" />
                            </ng-template>
                            <ng-template carouselSlide>
                                <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-webapp-authntick/authntick-result1.png" alt="Authntick - Photo Gallary" />
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh impact-section-upd scroll-div" id="section-testimonial"
        data-section-name="data-section-testimonial">
        <div class="ani-wrap100-top" style="background-color: #242424;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="comman-impact-bottom" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section-testimonial" style="margin: auto !important;">
                        <div class="card-img-wrap"><img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/testimonial/dev-tripathi.jpeg" alt="Dev Tripathi"
                                class="comman-impact-img lazy" /></div>
                        <h6 class="text-white">Dev Tripathi</h6>
                        <h6 class="text-white" style="opacity: 0.5;">Founder</h6>
                        <p class="p3 text-white">
                            <span>
                                <i class="fas fa-quote-left"></i> Prismic Reflections used User-Centered Design analysis
                                &amp; design process to solve challenging problems. They found innovative solutions like
                                identity tests that verify
                                customers who visit companies' websites. Asking the right questions provided the
                                information we need to do more processing. <i class="fas fa-quote-right"></i>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/website/insisive" > <span>Insisive</span>
                        </a>
                        <p class="p2">Helping Enterprises to Monitor, Optimise &amp; Reduce Their Cloud Spend</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>