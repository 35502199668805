<div class="portfolio-branding-phycheme-body">
    <section class="comman-section comman-section-100vh scroll-div phycheme-sec1" id="section1"
        data-section-name="data-section1">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section1" style="background-color: #111af5;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center text-center">
                <div class="col-sm-12">
                    <div class="phycheme-log-center-wrap portfolio-info-banner-c xs-mt15" data-aos="fade-in"
                        data-aos-delay="600" data-aos-anchor="#section1">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-logo.svg"
                            alt="Phycheme - logo" class="img-fluid phycheme-logo-img logo-img lazy" />
                    </div>
                    <h1 class="text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                        Reimagining Face of<br />
                        Manufacturing Brand
                    </h1>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">
                Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div phycheme-sec2" id="section2"
        data-section-name="data-section2">
        <div class="ani-wrap50" data-aos="new-animation2" data-aos-delay="100" data-aos-anchor="#section2"
            style="background-color: #0a0333;"></div>
        <div class="ani-wrap50-2 bg-black hide-mobile" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section2"
            style="background-image: url('assets/images/portfolio/portfolio-branding-phycheme/phycheme-about.jpg'); background-repeat: no-repeat; background-size: cover;">
        </div>
        <div class="card-img-inner-wrap show-mobile" data-aos="fade-in" data-aos-delay="900"
            data-aos-anchor="#section2">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-about.jpg"
                alt="Phycheme - Overview" class="img-fluid about-sub-banner-img lazy" />
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2" class="text-white">
                            Overview</h3>
                        <p class="p2 text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                            Phychem Technologies Pvt. Ltd. is a manufacturer &amp; supplier of highly customised &amp;
                            special grade rotational moulding material/ powder compound solutions. Since the past 10
                            years having a major focus
                            on the Indian market, the brand is now looking for expansion mainly in the US &amp; European
                            Market. <br />
                            Mr Umakant, Founder &amp; CEO of the company, realised the need of a revised brand strategy
                            &amp; plan with new identity system &amp; communication assets to support their business
                            expansion plans.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12"></div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge scroll-div phycheme-sec3"
        id="section3" data-section-name="data-section3">
        <div class="ani-wrap50" data-aos="new-animation2" data-aos-delay="100" data-aos-anchor="#section3"
            style="background-color: #0a0333;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3" class="text-white">
                            Challenges</h3>
                        <p class="p2 text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">
                            The challenge was to understand this specific rotational moulding industry, which is mainly
                            operating in the traditional way and create a fresh identity that will look more modern,
                            innovative &amp; compelling
                            to its targeted audience. <br />
                            Phychem approached Prismic Reflections&reg;, to shape up the new brand strategy that will
                            guide internal stakeholders to position the brand to externals in a unique way.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-right portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3">Creative Brief</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3">
                            The company wanted to focus mainly on the Internal Market, majorly new edge product
                            companies to maximise their profit. Phychem wanted to position themselves as a modern edge
                            environment-friendly
                            manufacturing company that produces high quality, customised &amp; innovative rational
                            moulding powder compounds. The Brand to hold guiding, assuring &amp; inspirational tone of
                            voice in messaging.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge scroll-div phycheme-sec4 pb0"
        id="section4" data-section-name="data-section4">
        <div class="ani-wrap50-2" style="background-color: #0a0333;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section4"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="phycheme-sec4-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section4">Brainstorming</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4">
                            Keeping the brand attributes in mind, we have produced the logo mark concepts that use
                            different types of symbols, marks &amp; shapes to convey a certain message. We discovered
                            the following brand attributes
                            that are unique to them.
                        </p>
                        <div class="concept-wrap" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section4">
                            <h5>Key Attributes</h5>
                            <p class="p2" style="color: #0f27ff !important;">Innovative + Collaborative + Expert &amp;
                                Knowledgeable</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="phycheme-sec4-right">
                        <div class="phycheme4-left" data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section4">
                            <owl-carousel-o [options]="customOptions" class="owl-caroual-comman-fade">
                                <ng-template carouselSlide>
                                    <div class="phycheme4-4-inner">
                                        <div class="phycheme4-4-img">
                                            <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-branding-phycheme/owl-caroual-phycheme2.png"
                                                alt="Phycheme - Molecule formation" />
                                        </div>
                                        <p class="p2">Molecule formation</p>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="phycheme4-4-inner">
                                        <div class="phycheme4-4-img">
                                            <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-branding-phycheme/owl-caroual-phycheme3.png"
                                                alt="PhychemeLetter P formation" />
                                        </div>
                                        <p class="p2">Letter P formation</p>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="phycheme4-4-inner">
                                        <div class="phycheme4-4-img">
                                            <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-branding-phycheme/owl-caroual-phycheme4.png" alt="Phycheme - Equidistant elements for consistency" />
                                        </div>
                                        <p class="p2">Equidistant elements for consistency</p>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="phycheme4-4-inner">
                                        <div class="phycheme4-4-img">
                                            <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-branding-phycheme/owl-caroual-phycheme4.png" alt="Phycheme - The final Logo Mark" />
                                        </div>
                                        <p class="p2">The final Logo Mark</p>
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div phycheme-sec5 no-padding overflow-hidden"
        id="section5" data-section-name="data-section5" style="background-color: #0a0333;">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section5"></div>
        <div class="container-fluid h-100 xs-no-padding">
            <div class="row">
                <div class="col-md-12 no-padding">
                    <owl-carousel-o [options]="customOptions2" class="owl-caroual-comman-fade">
                        <ng-template carouselSlide>
                            <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-logo-concept-1.jpg" alt="Phycheme - Logo concept" />
                        </ng-template>
                        <ng-template carouselSlide>
                            <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-logo-concept-2.jpg" alt="Phycheme - Logo concept" />
                        </ng-template>
                        <ng-template carouselSlide>
                            <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-logo-concept-3.jpg" alt="Phycheme - Logo concept" />
                        </ng-template>
                    </owl-carousel-o>
                </div>                
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div phycheme-sec6" id="section6"
        data-section-name="data-section6">
        <div class="ani-wrap100-top-2" style="background-color: #0a0333;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section6"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="phycheme-sec6-left text-center mb30">
                        <h3 class="text-white xl-mb100" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section6">Logo Construction</h3>
                    </div>
                    <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section6"
                        class="phycheme-sec6-inner text-center">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec6-1.svg"
                            class="phycheme-sec6-1 img-fluid lazy" alt="Phycheme - Logo Construction" />
                    </div>
                </div>
            </div>
        </div>
        <div class="phycheme-sec6-3-outer">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec6-3.svg"
                class="phycheme-sec6-2 img-fluid lazy" alt="Phycheme - Logo Construction" />
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div phycheme-sec7" id="section7"
        data-section-name="data-section7">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section7" style="background-color: #0a0333;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6">
                    <div class="phycheme-sec6-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section7">Typography</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section7">The brand
                            speaks for trust and assurance, hence we choose lato that creates a sense of assurance and
                            trust.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section7"
                        class="phycheme-sec7-1-outer">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec7-1.svg"
                            class="phycheme-sec7-1 img-fluid lazy" alt="Phycheme - Typography" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div phycheme-sec8" id="section8"
        data-section-name="data-section8">
        <div class="ani-wrap50" data-aos="new-animation2" data-aos-delay="100" data-aos-anchor="#section8"
            style="background-color: #0a0333;"></div>
        <div class="ani-wrap50-2 bg-black hide-mobile" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section8"
            style="background-image: url('assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec8.jpg'); background-repeat: no-repeat; background-size: cover;">
        </div>
        <div class="card-img-inner-wrap show-mobile" data-aos="fade-in" data-aos-delay="900"
            data-aos-anchor="#section8">
            <div class="phycheme-sect3-right-adj portfolio-info-wrap2 show-mobile">
                <h3 data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section8" class="text-white no-wrap-md">
                    Factory Visit</h3>
            </div>
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec8.jpg"
                alt="Phycheme - Activities Covered" class="img-fluid about-sub-banner-img lazy" />
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section8"
                            class="text-white no-wrap-md">Activities Covered</h3>
                        <ul class="crubba-sec3-ul" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section8">
                            <li class="p2 text-white">Audience Research</li>
                            <li class="p2 text-white">Competitive Analysis</li>
                            <li class="p2 text-white">Brand Strategy</li>
                            <li class="p2 text-white">Mission &amp; Vision Statements</li>
                            <li class="p2 text-white">Logo Identity Design</li>
                            <li class="p2 text-white">Stationary &amp; Print Material</li>
                            <li class="p2 text-white">Social Media Assets</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="phycheme-sect3-right-adj portfolio-info-wrap2 hide-mobile">
                        <h3 data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section8"
                            class="text-white no-wrap-md">Factory Visit</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div phycheme-sec9" id="section9"
        data-section-name="data-section9">
        <div class="ani-wrap100-top" style="background-color: #0c25fa;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section9"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="phycheme-sec9-1-outer" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section9">
                        <ul class="phycheme-sec9-ul clearfix">
                            <li><img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec9-1.svg"
                                    alt="Phycheme - Branding" class="phycheme-sec9-1 lazy" /></li>
                            <li><img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec9-2.svg"
                                    alt="Phycheme - Branding" class="phycheme-sec9-2 lazy" /></li>
                            <li><img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec9-3.svg"
                                    alt="Phycheme - Branding" class="phycheme-sec9-3 lazy" /></li>
                            <li><img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec9-4.svg"
                                    alt="Phycheme - Branding" class="phycheme-sec9-4 lazy" /></li>
                            <li><img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec9-5.svg"
                                    alt="Phycheme - Branding" class="phycheme-sec9-5 lazy" /></li>
                            <li><img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec9-6.svg"
                                    alt="Phycheme - Branding" class="phycheme-sec9-6 lazy" /></li>
                            <li><img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec9-7.svg"
                                    alt="Phycheme - Branding" class="phycheme-sec9-7 lazy" /></li>
                            <li><img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec9-8.svg"
                                    alt="Phycheme - Branding" class="phycheme-sec9-8 lazy" /></li>
                            <li><img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec9-9.svg"
                                    alt="Phycheme - Branding" class="phycheme-sec9-9 lazy" /></li>
                            <li><img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec9-10.svg"
                                    alt="Phycheme - Branding" class="phycheme-sec9-10 lazy" /></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div phycheme-sec10 comman-bg-section no-padding comman-bg-section" id="section10" data-section-name="data-section10">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section10" style="background-color: #134dd2;"></div>
        <div class="container h-100"></div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div phycheme-sec11" id="section11"
        data-section-name="data-section11">
        <div class="ani-wrap50-2" style="background: #f2f2f2;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section11"></div>
        <div class="ani-wrap50" style="background: #111bde;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section11"></div>
        <div class="phycheme-center-wrap xl-text-center xl-mt100" data-aos="fade-in" data-aos-delay="1500"
            data-aos-anchor="#section11">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec11-3.png"
                alt="Phycheme - Branding" class="phycheme-sec11-3 lazy img-fluid" />
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12"></div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div phycheme-sec12" id="section12"
        data-section-name="data-section12">
        <div class="ani-wrap50-2" style="background: #ff5b82;" data-aos="new-animation2" data-aos-delay="900"
            data-aos-anchor="#section12"></div>
        <div class="ani-wrap50" style="background: #ffffff;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section12"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="phycheme-left-wrap4" data-aos="fade-in" data-aos-delay="1200"
                        data-aos-anchor="#section12">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec12-4.png"
                            alt="Phycheme - Branding" class="phycheme-sec12-4 lazy img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div phycheme-sec13" id="section13"
        data-section-name="data-section13">
        <div class="ani-wrap50-2" style="background: #f2f2f2;" data-aos="new-animation2" data-aos-delay="900"
            data-aos-anchor="#section13"></div>
        <div class="ani-wrap50" style="background: #00d1a3;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section13"></div>
        <div class="phycheme-left-wrap xl-w-100 xl-text-center" data-aos="fade-in" data-aos-delay="1200"
            data-aos-anchor="#section13">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec13-1.png"
                alt="Phycheme - Branding" class="phycheme-sec13-1 lazy img-fluid" />
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12"></div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div phycheme-sec14" id="section14"
        data-section-name="data-section14">
        <div class="ani-wrap50-2" style="background: #111bde;" data-aos="new-animation2" data-aos-delay="900"
            data-aos-anchor="#section14"></div>
        <div class="ani-wrap50" style="background: #ffffff;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section14"></div>
        <div class="phycheme-left-wrap" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section14">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec14-1.png"
                alt="Phycheme - Branding" class="phycheme-sec14-1 lazy img-fluid" />
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12"></div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh impact-section-upd scroll-div" id="section-testimonial"
        data-section-name="data-section-testimonial">
        <div class="ani-wrap100-top" style="background-color: #242424;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="comman-impact-bottom" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section-testimonial" style="margin: auto !important;">
                        <div class="card-img-wrap"><img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/testimonial/phycheme-testimonial.png" alt="Umakant Savadekar"
                                class="comman-impact-img lazy" /></div>
                        <h6 class="text-white">Umakant Savadekar</h6>
                        <h6 class="text-white" style="opacity: 0.5;">Director</h6>
                        <p class="p3 text-white">
                            <span>
                                <i class="fas fa-quote-left"></i> Branding exercise done with Prismic Reflections was
                                like reinventing ourself as a company or brand. They have helped us put our best foot
                                forward for future journey.
                                Thanks Prismic Reflections, you guys are the best ! <i class="fas fa-quote-right"></i>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/branding/smartlink"> <span>Smartlink</span> </a>
                        <p class="p2">Giving a New Face to Logistics Experts</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>