<!-- http://localhost:4200/portfolio/web-app/source-trace -->
<div class="portfolio-webapp-source-trace-body">
    <section class="comman-section comman-section-100vh scroll-div source-trace-sec1 top-section-wrapper" id="section1" data-section-name="data-section1">
        <div class="ani-wrap50-3 hide-mobile" style="background-color: #009456;" data-aos="new-animation5" data-aos-delay="400" data-aos-anchor="#section1"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center xs-text-center">
                <div class="col-md-8">
                    <div class="source-trace-left-wrap">
                        <ul class="list-inline comman-card-top-ul xs-text-center hide-mobile" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section1">
                            <li class="list-inline-item h6">Source Trace</li>
                            <li class="list-inline-item h6">Agriculture</li>
                        </ul>
                        <h1 data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section1">Architects of <br class="hide-mobile" /> New-Age Sustainable <br class="hide-mobile" />Agriculture</h1>
                        <p data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section1" class="p2 text-light-dark-c hide-mobile">UX UI Design for India based online platform providing comprehensive solutions to manage all aspects of the agricultural value chain.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="source-trace-right-wrap" data-aos="fade-in" data-aos-delay="2400" data-aos-anchor="#section1">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/hero-img.png" alt="Architects of New-Age Sustainable Agriculture" class="img-fluid lazy" />
                        <p data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section1" class="p2 text-light-dark-c show-mobile hide-desktop">UX UI Design for India based online platform providing comprehensive solutions to manage all aspects of the agricultural value chain.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">
                Scroll
                <div class="c-scrolldown"><div class="c-line"></div></div>
            </a>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div source-trace-sec2" id="section2" data-section-name="data-section2">
        <div class="ani-wrap50-3" style="background-color: #009456;" data-aos="new-animation5" data-aos-delay="100" data-aos-anchor="#section2"></div>
        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/farmers.png" class="farmers-img hide-mobile img-fluid lazy" alt="Source Trace - Farmers" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2" />
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-10">
                    <div class="source-trace-center-wrap text-center" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/dashboard.png" class="dashboard-img img-fluid lazy" alt="Source Trace - Dashboard" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div source-trace-sec3" id="section3" data-section-name="data-section3">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="overview-sec-center portfolio-info-wrap2">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/leave1.png" class="leave1-img img-fluid lazy hide-mobile" alt="Source Trace - Overview" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3" />
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/leave2.png" class="leave2-img img-fluid lazy hide-mobile" alt="Source Trace - Overview" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3"/>
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">Overview</h3>
                        <p data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section3" class="p2 text-light-dark-c">
                            SourceTrace offers one of the largest and comprehensive digitized Solutions in the Farm Management and agritech domain. Presently having a feature & function-rich application which is flexible and can be tailored
                            according to the customer.
                        </p>
                        <p data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section3" class="p2 text-light-dark-c">
                            They Want to become a Global Player in the agriculture food chain and empower farmers. Aiming to reach 10 million farmers in coming years currently, they are 1 million active farmers. Recently entered the food
                            markets sector providing traceability solutions.
                        </p>
                        <div data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section3">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/small-tracker.png" class="small-tracker-img img-fluid lazy hide-mobile" alt="Source Trace - Overview" />
                    </div>
                    </div>                    
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div source-trace-sec4 comman-solution-challenge" id="section4" data-section-name="data-section4">
        <div class="ani-wrap50-2" style="background-color: #F7F7F8;" data-aos="new-animation2" data-aos-delay="1200" data-aos-anchor="#section4"></div>
        <div class="ani-wrap50" style="background-color: #009456;" data-aos="new-animation2" data-aos-delay="100" data-aos-anchor="#section4"></div>
        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/leave3.png" alt="Source trace - Challenges" class="leave3-img lazy" data-aos="fade-in" data-aos-delay="2800" data-aos-anchor="#section4" />
        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/corn.png" alt="Source trace - Challenges" class="corn-img lazy" data-aos="fade-in" data-aos-delay="2800" data-aos-anchor="#section4" />
        <div class="container h-100">
            <div class="row h-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-6">
                    <div class="source-trace-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4" class="text-white">Challenges</h3>
                        <p data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section4" class="text-white p2">
                            Dashboard Very long vertical scroll without any Hierarchy <br />
                            Key Metrics and Data Points have no Contrast and inefficient use of real estate <br />
                            Absense of Guided Flow and Nudge for the user Farmer Profile has unnecessary overloaded of irrelevent information. It doesn't give quick overview of the farmer or his farm and crops <br />
                            Prioritize activity, Users donʼt know what to do in the first 10 seconds. ( Show tutorial, walkthrough, Guide through the next step) <br />
                            The navigation and Flow is a confusing specially for the farmer dashboard and profile creation
                        </p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="source-trace-right-wrap ml85 portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section4">Solutions</h3>
                        <p data-aos="fade-in" data-aos-delay="2600" data-aos-anchor="#section4" class="text-light-dark-c p2">
                            The stakeholders want their design revamp to be more intuitive and user friendly in terms of specific modules. Overall ease of use visual design enhancement and consistancy is expected.<br />
                            More Visual Representation than Notes, data table and grids Quick Information overview eliminating long scrolls Simplified User flow , Navigation and layout. <br />
                            The design should be self explainatory and less customer cupport should be reuqired for executing tasks. <br />
                            Ease of use. Appealing Layout with consistant and engaging colour contrast Design decisons based from user's point of view Clear alert Indications, Prominant and consistant CTAs. <br />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div source-trace-sec5" id="section5" data-section-name="data-section5">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300" data-aos-anchor="#section5" style="background-color: #F7F7F8;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="source-trace-center-wrap">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section5">Revised Information Architecture</h3>
                        <p data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5" class="p2 text-light-dark-c">
                            Overall journey, relevant information & functional features brought in the right place to make the information discovery & task completion quick & easy.
                        </p>
                        <div class="text-center">
                            <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-webapp-source-trace/info-architecture1.svg"
                                class="info-architecture1 img-fluid lazy"
                                alt="Source trace - Revised Information Architecture"
                                data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section5"
                            />
                            <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-webapp-source-trace/info-architecture2.svg"
                                class="info-architecture1 img-fluid lazy"
                                alt="Source trace - Revised Information Architecture"
                                data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section5"
                            />
                            <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-webapp-source-trace/info-architecture3.svg"
                                class="info-architecture1 img-fluid lazy"
                                alt="Source trace - Revised Information Architecture"
                                data-aos="fade-in" data-aos-delay="1700" data-aos-anchor="#section5"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div source-trace-sec6 pb0" id="section6" data-section-name="data-section6">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300" data-aos-anchor="#section6" style="background-color: #fff;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="source-trace-center-wrap text-center">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section6">Wireframes</h3>
                        <p data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6" class="p2 text-light-dark-c">
                            A travel bag company with more than x years of experience in the sector. <br />
                            Bagshah provides premium, durable quality of travel bags for rent or to buy along with it they provide a hassle
                        </p>
                    </div>
                        <div class="row text-center source-trace-sec6-row">
                            <div class="col-sm-6 col-md-4" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section6">
                                <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/wireframe1.png" class="wireframe-img img-fluid lazy" alt="Source trace - Wireframe" />
                            </div>                       
                            <div class="col-sm-6 col-md-4" data-aos="fade-in" data-aos-delay="1400" data-aos-anchor="#section6">
                                <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/wireframe2.png" class="wireframe-img img-fluid lazy" alt="Source trace - Wireframe" />
                            </div>
                            <div class="col-sm-6 col-md-4" data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section6">
                                <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/wireframe3.png" class="wireframe-img img-fluid lazy" alt="Source trace - Wireframe" />
                            </div>
                            <div class="col-sm-6 col-md-4" data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section6">
                                <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/wireframe4.png" class="wireframe-img img-fluid lazy" alt="Source trace - Wireframe" />
                            </div>                     
                            <div class="col-sm-6 col-md-4" data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section6">
                                <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/wireframe5.png" class="wireframe-img img-fluid lazy" alt="Source trace - Wireframe" />
                            </div>
                            <div class="col-sm-6 col-md-4" data-aos="fade-in" data-aos-delay="2200" data-aos-anchor="#section6">
                                <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/wireframe6.png" class="wireframe-img img-fluid lazy" alt="Source trace - Wireframe" />
                            </div>                     
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div source-trace-sec7" id="section7" data-section-name="data-section7">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300" data-aos-anchor="#section6" style="background-color: #F7F7F9;"></div>        
        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/leave1.png" class="leave1-img img-fluid lazy" alt="Source Trace - Overview" data-aos="fade-in" data-aos-delay="2200" data-aos-anchor="#section7" />
        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/big-tracter.svg" class="big-tracter img-fluid lazy" alt="Farmer Flow" data-aos="fade-in" data-aos-delay="2400" data-aos-anchor="#section7" />
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="source-trace-center-wrap text-center">                        
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/leave2.png" class="leave2-img img-fluid lazy hide-mobile" alt="Source Trace - Overview" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section7" />
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section7">
                            <span class="position-relative">
                                <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/farmer-icon.svg" class="farmer-icon-img img-fluid lazy" alt="Source Trace - Overview" />
                                Farmer Flow
                            </span>
                        </h3>
                        <p data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section7" class="p2 text-light-dark-c">
                            A travel bag company with more than x years of experience in the sector. <br />
                            Bagshah provides premium, durable quality of travel bags for rent or to buy along with it they provide a hassle
                        </p>
                    </div>
                        <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section7" class="position-relative text-center z-index5">
                            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/farmer-flow.png" class="farmer-flow img-fluid lazy" alt="Farmer Flow" />
                        </div>                        
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div source-trace-sec8" id="section8" data-section-name="data-section8">
        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/plant-bg.png" class="plant-bg img-fluid lazy" alt="Farmer Flow" data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section8" />        
        <div data-aos="new-animation3" data-aos-delay="100" data-aos-anchor="#section8" class="ani-wrap100-top" style="background: #009456;"></div>
        <div class="container h-100">
            <img src="assets/images/portfolio/portfolio-webapp-source-trace/leave4.png" alt="Source Trace" class="leave2-img img-fluid lazy" data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section8"/>
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6">
                    <div class="source-trace-left-wrap">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section8">
                            Multiple <br />
                            Dashboards
                        </h3>
                        <p data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section8" class="p2 text-white">
                            5 different dashboards created aiming to surface critical data speed up the decision making which has high impact on overall operation cost & timeline.
                        </p>
                        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section8">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/leave-bg.svg" class="leave-bg img-fluid lazy" alt="Farmer Flow" />
                    </div>
                    </div>
                </div>
                <div class="col-md-6" data-aos="fade-in" data-aos-delay="1400" data-aos-anchor="#section8">
                    <div class="source-trace-right-wrap">
                    <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/multi-dashboard.png" class="multi-dashboard img-fluid lazy" alt="Farmer Flow" />                    
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div source-trace-sec9 text-center" id="section9" data-section-name="data-section9">
        <div class="container-fluid h-100 no-padding">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="source-trace-center-wrap" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section9">
                        <owl-carousel-o [options]="customOptions" class="source-trace-carosual" id="source-trace-carosual">
                            <ng-template carouselSlide> <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-webapp-source-trace/screen1.png" alt="Source-trace - Photo Gallary" /> </ng-template>
                            <ng-template carouselSlide> <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-webapp-source-trace/screen2.png" alt="Source-trace - Photo Gallary" /> </ng-template>
                            <ng-template carouselSlide> <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-webapp-source-trace/screen3.png" alt="Source-trace - Photo Gallary" /> </ng-template>
                            <ng-template carouselSlide> <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-webapp-source-trace/screen4.png" alt="Source-trace - Photo Gallary" /> </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div source-trace-sec10 text-center" id="section10" data-section-name="data-section10">        
        <div class="container position-relative z-index9">
            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/db1.png" class="db1 img-fluid lazy" alt="Farmer Flow" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section10" />
        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/db2.png" class="db2 img-fluid lazy" alt="Farmer Flow" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section10" />
            <div class="row">
                <div class="col-md-12 position-relative z-index9">
                    <div class="source-trace-center-wrap">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section10">Data Tables</h3>
                        <p data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section10" class="p2 text-light-dark-c">
                            A travel bag company with more than x years of experience in the sector. <br />
                            Bagshah provides premium, durable quality of travel bags for rent or to buy along with it they provide a hassle
                        </p>                        
                    <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/datatable.png" class="dt-img img-fluid lazy" alt="Datatable" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section10" />
                </div>      
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div esop-sec6 xs-pb0 source-trace-sec11" id="section11" data-section-name="data-section11">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 order-md-1 order-2">
                    <div class="esop-left-wrap">
                        <div class="esop-slider-circle"></div>
                        <div class="comman-scrollbar-wrap">
                            <div class="esop-slider-outer-wrap clearfix">
                                <ul class="clearfix esop-slider-ul mr30" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section11">
                                    <li>
                                        <div class="esop-slider st"><img src="assets/images/portfolio/portfolio-webapp-source-trace/icon1.svg" alt="Personal Touch" class="img-fluid lazy" /></div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st"><img src="assets/images/portfolio/portfolio-webapp-source-trace/icon2.svg" alt="Personal Touch" class="img-fluid lazy" /></div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st"><img src="assets/images/portfolio/portfolio-webapp-source-trace/icon3.svg" alt="Personal Touch" class="img-fluid lazy" /></div>
                                    </li>
                                </ul>
                                <ul class="clearfix esop-slider-ul mt140" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section11">
                                    <li>
                                        <div class="esop-slider st"><img src="assets/images/portfolio/portfolio-webapp-source-trace/icon4.svg" alt="Personal Touch" class="img-fluid lazy" /></div>
                                    </li>
                                    <li>
                                        <div class="esop-slider st"><img src="assets/images/portfolio/portfolio-webapp-source-trace/icon5.svg" alt="Personal Touch" class="img-fluid lazy" /></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 order-md-2">
                    <div class="esop-right-wrap">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section11">Personal Touch with Custom Illustrations</h3>
                        <p data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section11" class="p2 text-light-dark-c">Redefining a New Visual Language, colors, fonts etc change the perception of user about product and company.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div source-trace-sec12 text-center xs-pt0" id="section12" data-section-name="data-section12">        
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="mb40" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section12">GUI Components</h3>
                    <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section12">
                    <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-source-trace/gui-components.png" class="gui-components img-fluid lazy" alt="GUI Components" />
                </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page" data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/website/insisive"> <span>Insisive</span> </a>
                        <p class="p2">Helping Enterprises to Monitor, Optimise &amp; Reduce Their Cloud Spend</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
