import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollifyAniService {

  constructor() {
  }

  msgalert(): void {
    alert('service');
  }
}
