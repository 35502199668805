import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import AOS from 'aos';

declare let $: any;

@Component({
  selector: 'app-design-research-consultancy',
  templateUrl: './design-research-consultancy.component.html',
  styleUrls: ['./design-research-consultancy.component.scss'],
})
export class DesignResearchConsultancyComponent implements OnInit {


 
 // new desc
   descrip = 'Prismic Reflections is a strategic ux ui design company that specializes in design research consultation. Let`s discuss!';


  
  constructor(private titleService: Title, private metaService: Meta) {
  
    this.setTitle('Design Research Consultation Service | Prismic Reflections');

    this.metaService.updateTag({name: 'description', content: this.descrip});
    this.metaService.updateTag({
      name: 'keywords',
      content: 'ui ux design, user interface design, logo branding, brand identities, application interface design, ecommerce sites design, website design services, web development services, quality web designs, website development solutions, creative web design, web application development, multimedia cd presentation, mumbai, india'
    });
    this.metaService.updateTag({property: 'og:type', content: 'business.business'});
    this.metaService.updateTag({property: 'og:title', content: 'Design Research Consultation Service | Prismic Reflections'});
    this.metaService.updateTag({property: 'og:description', content: this.descrip});
  }

  ngOnInit(): void {
    $(function(): void {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });
    $('.comman-header-wrap').show();
    $('#comman-footer').show();

    $(document).ready(function(): void {
      $('.logo-wrap-our-team').hide();
      $('.logo-wrap-a').show();
      $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
      $('.arrow-scroll-abs').show();
      $('.arrow-scroll-abs .next').removeClass('text-white');
      $('.comman-top-menu').removeClass('comman-top-menu-dark');
      const windowWidth = $(window).width();
      if (windowWidth >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          overflowscroll: true,
          before(i, panels): void {
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.pagination-wrap')
              .find('a[href="#' + ref + '"]')
              .addClass('active');
            $('.logo-wrap-a .company-name-mobile')
              .removeClass('active-white-text')
              .hide();
            $('.comman-top-menu').removeClass('comman-top-menu-dark');

            if (ref === 'data-comman-footer') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
            } else if (ref === 'data-section5') {
              $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.arrow-scroll-abs').show();
              $('.comman-scrollbar-wrap').mCustomScrollbar({theme: 'rounded-dots', scrollInertia: 400});
            } else if (ref === 'data-section-next-page') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').hide();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            } else {
              $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.arrow-scroll-abs').show();
            }
          },
          afterRender(): void {
            aosinit();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i): void {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
            // $('.comman-scrollbar-wrap').mCustomScrollbar({ theme: 'rounded-dots', scrollInertia: 400 });
          },
        });
        $('.arrow-scroll-abs .next').click(function(e1): void {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2): void {
          e2.preventDefault();
          $.scrollify.prev();
        });
      }
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }

      function aosinit(): void {
        AOS.init({
          duration: 400,
          anchorPlacement: 'top-top',
          disable(): boolean {
            const maxWidth = 1199;
            return window.innerWidth < maxWidth;
          },
          once: true,
          offset: 20,
        });
      }
    });
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

}
