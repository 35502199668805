<div class="services-page-body">
    <section class="comman-section comman-section-100vh scroll-div expertise-sect1 top-section-wrapper" id="section1"
        data-section-name="data-section1">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-7 order-md-last-adj">
                    <div class="comman-card-info xs-mt-30">
                        <h1 class="h1 sec-heading hide-mobile" data-aos="fade-in" data-aos-delay="700"
                            data-aos-anchor="#section1"> User Experience<br /> Design</h1>

                           
                        <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                            <table class="expertise-tbl">
                                <tr>
                                    <td>
                                        <ul class="industries-list">
                                            <li class="p2-lora"> <a class="text-black cursor-pointer" (click)="secondSec()"> Digital Product Design </a></li>
                                            <li class="p2-lora"> <a class="text-black cursor-pointer" (click)="fourthSec()"> Conversational UX </a></li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul class="industries-list">
                                            <li class="p2-lora"> <a class="text-black cursor-pointer" (click)="thirdSec()"> Enterprise &amp; SaaS Applications </a></li>
                                            <li class="p2-lora"> <a class="text-black cursor-pointer" (click)="fifthSec()"> Strategic Corporate &amp; Marketing Websites </a></li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-5">
                    <div>
                        <h1 class="h1 sec-heading show-mobile xs-text-center" data-aos="fade-in" data-aos-delay="700"
                            data-aos-anchor="#section1"> User Experience<br /> Design</h1>
                           
                        <div class="comman-card-img-wrap services-user-experience-design-wrap hide-mobile"> 
                            <!-- <img
                                src="assets/images/expertise/services-user-experience-design1.png"
                                alt="User Experience Design" class="services-user-experience-design1 lazy"
                                data-aos="fade-in" data-aos-delay="2200" data-aos-anchor="#section1" /> -->
                                 <!-- <img
                                src="assets/images/expertise/services-user-experience-design2.png"
                                alt="User Experience Design" class="services-user-experience-design2 lazy"
                                data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section1" /> -->
                                 <img
                                src="assets/images/expertise/services-user-experience-design3.png"
                                alt="User Experience Design" class="services-user-experience-design3 lazy"
                                data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section1" /> <img
                                src="assets/images/expertise/services-user-experience-design4.png"
                                alt="User Experience Design" class="services-user-experience-design4 lazy"
                                data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section1" /> <img
                                src="assets/images/expertise/services-user-experience-design5.svg"
                                alt="User Experience Design"
                                class="services-user-experience-design5 lazy floatingX-r" /> <img
                                src="assets/images/expertise/services-user-experience-design6.svg"
                                alt="User Experience Design"
                                class="services-user-experience-design6 lazy floatingX-l" /> 
                                <!-- <img
                                src="assets/images/expertise/services-user-experience-design7.svg"
                                alt="User Experience Design" class="services-user-experience-design7 lazy"
                                data-aos="fade-in" data-aos-delay="2700" data-aos-anchor="#section1" /> -->
                            </div>
                        <div class="comman-card-img-wrap-upd2 show-mobile text-center"> <img
                                src="assets/images/expertise/services-user-experience-design-xs.png"
                                alt="User Experience Design"
                                class="services-user-experience-design-xs lazy mb30 mt20" /></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs"> <a href="#" class="next">Scroll<div class="c-scrolldown">
                    <div class="c-line"></div>
                </div> </a></div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div expertise-sect2 xs-pt0" id="section2"
        data-section-name="data-section2">
        <span class="secondsec"></span>
        <div class="container expertise-container">
            <div class="row expertise-sect-row expertise-sect-row-1-wrap">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="comman-card-info expertise-sect-left">
                        <div class="expertise-sect-left-head">
                            <h3 class="sec-heading txt-adj"> Digital Product Design</h3>
                        </div>
                        <p class="p2 sec-para txt-adj"> A great product is always focused on the core problem/s of its
                            users, empowers them, delivers great experience and meets the desired business objective. If
                            you you have an idea, we are here to translate that into reality & take the next leap. Or if
                            you have an existing product and facing some challenge , we can help you to tap into it's
                            full potential.</p>
                        <div class="expertise-sect-left-body">
                            <ul class="list-inline no-wrap">
                                <li class="list-inline-item">
                                    <a [routerLink]="['/our-work']" class="bttn btn-line btn-primary-custom text-center"><span>View Case Studies</span></a>
                                </li>
                                <li class="list-inline-item">
                                    <a [routerLink]="['/contact']" class="bttn btn-line btn-primary-custom text-center"><span>Let's Talk</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="expertise-sect-right">
                        <h6 class="font-weight-medium"> Bring your product ideas into the reality</h6>
                        <ul class="expertise-ul-list ul-purple-adj p3">
                            <li> Research &amp; analysis to arrive at the strategic solution</li>
                            <li> Using science and art to craft an experience</li>
                            <li> Data to back up the design decisions</li>
                            <li> Creating a story and not just pile of features</li>
                            <li> Forming habits through engagement strategies</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div expertise-sect2 xs-pt0" id="section3"
        data-section-name="data-section3">
        <span class="thirdsec"></span>
        <div class="container expertise-container">
            <div class="row expertise-sect-row expertise-sect-row-2-wrap">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="comman-card-info expertise-sect-left">
                        <div class="expertise-sect-left-head">
                            <h3 class="sec-heading txt-adj"> Enterprise Apps</h3>
                        </div>
                        <p class="p2 sec-para txt-adj mb10"> Reimagining the way how your workforce will perform in the coming future. Empower
                            your employees with design first approach & by putting them at the center. Investing in designing & humanizing your
                            current enterprise tools and legacy platforms are worth & well deserved.
                        </p>
                        <p class="p2 sec-para txt-adj">
                            UX for Sales intelligence applications, CRM, HRMS, ERP, Help Desk Management, Stock Admin - we are equipped for all
                            these.
                        </p>
                        <div class="expertise-sect-left-body">
                            <ul class="list-inline no-wrap">
                                <li class="list-inline-item">
                                    <a [routerLink]="['/our-work']" class="bttn btn-line btn-primary-custom text-center"><span>View Case Studies</span></a>
                                </li>
                                <li class="list-inline-item">
                                    <a [routerLink]="['/contact']" class="bttn btn-line btn-primary-custom text-center"><span>Let's Talk</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="expertise-sect-right">
                        <h6 class="font-weight-medium"> Benefits &amp; Value</h6>
                        <ul class="expertise-ul-list ul-purple-adj p3">
                            <li> Increase in efficiency</li>
                            <li> Productivity improvement</li>
                            <li> Sense of satisfaction in teams</li>
                            <li> Reducing cost of training &amp; support</li>
                            <li> Avoid critical &amp; high risk errors</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div expertise-sect2 xs-pt0" id="section4"
        data-section-name="data-section4">
        <span class="fourthsec"></span>
        <div class="container expertise-container">
            <div class="row expertise-sect-row expertise-sect-row-3-wrap">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="comman-card-info expertise-sect-left">
                        <div class="expertise-sect-left-head">
                            <h3 class="sec-heading txt-adj"> Conversational UX</h3>
                        </div>
                        <p class="p2 sec-para txt-adj"> How can we connect with users in a more humanised way? How can
                            we build satisfying relationships &amp; engage with them more? Conversational UX helps
                            companies to connect these dots of personal and human like conversation with your products
                            and brand. If you are thinking about your next Conversation UX , Chatbot or Voice assistant
                            or both, we would be excited to jump in!</p>
                        <div class="expertise-sect-left-body">
                            <ul class="list-inline no-wrap">
                                <li class="list-inline-item">
                                    <a [routerLink]="['/portfolio/mobile-app/easy-plan']"  class="bttn btn-line btn-primary-custom text-center"><span>View Case Studies</span></a>
                                </li>
                                <li class="list-inline-item">
                                    <a [routerLink]="['/contact']" class="bttn btn-line btn-primary-custom text-center"><span>Let's Talk</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="expertise-sect-right">
                        <h6 class="font-weight-medium"> How we do it?</h6>
                        <ul class="expertise-ul-list ul-purple-adj p3">
                            <li> User &amp; Stakeholder Research</li>
                            <li> Defining Strategy inline with business &amp; user goals</li>
                            <li> Identifying use cases and scenarios</li>
                            <li> Creating Chatbot &amp; Voice assistant persona &amp; guidelines</li>
                            <li> Creating dialog flows &amp; writing dialog scripts with professionals</li>
                            <li> Testing, validation and fine tuning</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div expertise-sect2 xs-pt0" id="section5"
        data-section-name="data-section5">
        <span class="fifthsec"></span>
        <div class="container expertise-container">
            <div class="row expertise-sect-row expertise-sect-row-2-wrap">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="comman-card-info expertise-sect-left">
                        <div class="expertise-sect-left-head">
                            <h2 class="sec-heading txt-adj"> Strategic Website Design</h2>
                        </div>
                        <p class="p2 sec-para txt-adj"> In the world of mobile apps, websites still hold their
                            importance. A strategically crafted website connects your audience with your brand, people
                            &amp; overall culture. A well designed website positions your organisation appropriately
                            &amp; communicates to the relevant audience with the relevant message.</p>
                        <div class="expertise-sect-left-body">
                            <ul class="list-inline no-wrap">
                                <li class="list-inline-item">
                                    <a [routerLink]="['/our-work']"  class="bttn btn-line btn-primary-custom text-center"><span>View Case Studies</span></a>
                                </li>
                                <li class="list-inline-item">
                                    <a [routerLink]="['/contact']" class="bttn btn-line btn-primary-custom text-center"><span>Let's Talk</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="expertise-sect-right">
                        <h6 class="font-weight-medium"> What can you achieve with a website</h6>
                        <ul class="expertise-ul-list ul-purple-adj p3">
                            <li> Brand positioning</li>
                            <li> Effective messaging &amp; communication with relevant audience</li>
                            <li> Important tool for your marketing team</li>
                            <li> Maximise reach, increase visibility</li>
                            <li> Showcase your capabilities, strengths &amp; build the customer trust factor.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div expertise-sect-9 xs-pt0 xs-pb0 pb0" id="section6"
        data-section-name="data-section3">
        <div class="container">
            <div class="row vh100-100 align-items-center justify-content-center overflow-hidden">
                <div class="col-sm-12 text-center">
                    <div class="row">
                        <div class="col-sm-12">
                            <h3> Engagement Models</h3>
                        </div>
                        <div class="col-sm-12">
                            <div class="comman-scrollbar-wrap">
                                <table class="table-expertise">
                                    <tr>
                                        <td>
                                            <div class="comman-card-info text-center">
                                                <div class="process-img-wrapper2"> <img
                                                        src="assets/images/preloader/ajax-loader.png"
                                                        data-src="assets/images/process/evolving-design.svg" alt="Discover"
                                                        class="process-image lazy mb25" /></div>
                                                <h5>Evolving<br class="hide-mobile"> Design</h5>
                                                <p class="p3 sec-para text-light-dark-c"> When you are on the idea
                                                    level, there is an absence of clear scope documentation and ongoing
                                                    innovation is required; this model is a right fit.</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="comman-card-info text-center">
                                                <div class="process-img-wrapper2"> <img
                                                        src="assets/images/preloader/ajax-loader.png"
                                                        data-src="assets/images/process/fixed-scope-projects.svg"
                                                        alt="Discover" class="process-image lazy mb25" /></div>
                                                <h5>Fixed Scope <br class="hide-mobile">Projects</h5>
                                                <p class="p3 sec-para text-light-dark-c"> This is well suited for the
                                                    project and those are well documented, boundary lines defined &amp;
                                                    easy for assessment. A lump sum fee can be given for such projects.
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="comman-card-info text-center">
                                                <div class="process-img-wrapper2"> <img
                                                        src="assets/images/preloader/ajax-loader.png"
                                                        data-src="assets/images/process/strategic-consultancy.svg"
                                                        alt="Discover" class="process-image lazy mb25" /></div>
                                                <h5>Strategic <br class="hide-mobile"> Consultancy</h5>
                                                <p class="p3 sec-para text-light-dark-c"> Where there is already
                                                    something built but needs a third person/expert overview or you have
                                                    an in-house team that needs external expert hand holding you can opt
                                                    for this. Fee Structure: Per expert, per hour with minimum
                                                    engagement criteria.</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="comman-card-info text-center">
                                                <div class="process-img-wrapper2"> <img
                                                        src="assets/images/preloader/ajax-loader.png"
                                                        data-src="assets/images/process/dedicated-resource.svg"
                                                        alt="Discover" class="process-image lazy mb25" /></div>
                                                <h5>Dedicated <br class="hide-mobile"> Resource</h5>
                                                <p class="p3 sec-para text-light-dark-c"> You hire a dedicated person
                                                    with specific skill sets (UX Researcher, Visual designer, UI
                                                    engineer etc), assign any task and manage them by your own. Fee
                                                    Structure: Monthly Per resource with minimum engagement criteria.
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6> <a class="page-foot-link h3 st btn-line" [routerLink]="['/services/brand-strategy-identity']">
                            <span>Brand Strategy &amp; Identity Design</span> </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>