import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {OwlOptions} from 'ngx-owl-carousel-o';
import AOS from 'aos';
// import * as $ from 'jquery';
// declare var $:JQueryStatic;
declare let $: any;


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  descrip = 'Prismic Reflections - a strategic ux ui design studio specializing in user experience, ui engineering, brand strategy & identity design. Let`s discuss!';

  customOptions: OwlOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1,
    dots: false,
    autoWidth: false,
    autoHeight: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {},
  };

  customOptionsMob: OwlOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1,
    dots: false,
    autoWidth: true,
    autoplay: true,
    autoplayTimeout: 4000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    center: true,
    mergeFit: true,
    responsive: {
      0: {
        items: 1,
      },
      520: {
        items: 2,
      }
    },
  };

  //   $(".comman-scrollbar-wrap").mCustomScrollbar({theme:"rounded-dots",scrollInertia:400});
  public scrollbarOptions = {theme: 'light-thick', scrollInertia: 400};

  constructor(private titleService: Title, private metaService: Meta) {
    this.setTitle('Strategic UI UX Design Studio in India | Prismic Reflections');
    this.metaService.updateTag({name: 'description', content: this.descrip});
    this.metaService.updateTag({property: 'og:type', content: 'business.business'});
    this.metaService.updateTag({
      property: 'og:title',
      content: 'Strategic UI UX Design Studio in India | Prismic Reflectionsy'
    });
    this.metaService.updateTag({property: 'og:description', content: this.descrip});
  }

  ngOnInit(): void {
    // $(".loader-api").delay(1000).fadeOut(1600);
    $('.carousel').carousel({
      interval: 4000
    })
    
    $(function(): void {
     
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });

    function aosinit(): void {
      AOS.init({
        duration: 400,
        anchorPlacement: 'top-top',
        disable(): boolean {
          const maxWidth = 1199;
          return window.innerWidth < maxWidth;
        },
        once: true,
        offset: -20,
      });
    }

    $('.comman-header-wrap').show();
    $('#comman-footer').show();
    $('.logo-wrap-our-team').hide();
    $('.logo-wrap-a').show();
    $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
    $('.arrow-scroll-abs').show(); // shows next
    $('.arrow-scroll-abs .next').removeClass('text-white'); // .text-white (makes next white)
    $('.comman-top-menu').removeClass('comman-top-menu-dark'); // .comman-top-menu-dark (makes menu bg white)
    $(document).ready(function(): void {
      const wido = $(window).width();
      if (wido >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          overflowscroll: true,
          before(i, panels): void {
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.pagination-wrap')
              .find('a[href="#' + ref + '"]')
              .addClass('active');
            $('.logo-wrap-a .company-name-mobile')
              .removeClass('active-white-text')
              .hide();
            $('.arrow-scroll-abs').fadeIn();
            $('#home-carousel-indicators-ul').hide();
            $('.arrow-scroll-abs .next').removeClass('text-white');
            $('.comman-top-menu').removeClass('comman-top-menu-dark');
            if (ref === 'data-section2') {
              //   $('#home-carosual2').carousel();
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
            } else if (ref === 'data-section3') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
            } else if (ref === 'data-section4') {
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-white active-menu-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            } else if (ref === 'data-section5') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
            } else if (ref === 'data-section6') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            } else 
            if (ref === 'data-section3-home') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
            } else 
            if (ref === 'data-section7') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.process-iazy-img').lazy({
                effect: 'fadeIn',
                placeholder: 'assets/images/lazy-preloader.png',
                delay: 1200,
              });
            } else if (ref === 'data-comman-footer') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
            } else {
              $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
              $('.arrow-scroll-abs').show();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            }
          },
          afterResize(): void {
            $.scrollify.update();
          },
          afterRender(): void {
            aosinit();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i): void {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
          },
        });
        $('.arrow-scroll-abs .next').click(function(e1): void {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2): void {
          e2.preventDefault();
          $.scrollify.prev();
        });
      }
      $('#home-carosual2').on('slide.bs.carousel', function(e): void {
        if (e.relatedTarget.id === 'firstSlide') {
          $('#home-carosual2 .carousel-indicators-ul li').removeClass('active');
          $('#home-carosual2 .carousel-indicators-ul li:nth-child(1)').addClass(
            'active'
          );
        } else if (e.relatedTarget.id === 'secondSlide') {
          $('#home-carosual2 .carousel-indicators-ul li').removeClass('active');
          $('#home-carosual2 .carousel-indicators-ul li:nth-child(2)').addClass(
            'active'
          );
        } else if (e.relatedTarget.id === 'thirdSlide') {
          $('#home-carosual2 .carousel-indicators-ul li').removeClass('active');
          $('#home-carosual2 .carousel-indicators-ul li:nth-child(3)').addClass(
            'active'
          );
        } else if (e.relatedTarget.id === 'fourthSlide') {
          $('#home-carosual2 .carousel-indicators-ul li').removeClass('active');
          $('#home-carosual2 .carousel-indicators-ul li:nth-child(4)').addClass(
            'active'
          );
          setTimeout(function(e8): boolean {
            return false;
            e8.stopPropagation();
          }, 2000);
        }
      });

       $('#home-carosual22').on('slide.bs.carousel', function(e): void {
        if (e.relatedTarget.id === 'firstSlide') {
          $('#home-carosual22 .carousel-indicators-ul li').removeClass('active');
          $('#home-carosual22 .carousel-indicators-ul li:nth-child(1)').addClass(
            'active'
          );
        } else if (e.relatedTarget.id === 'secondSlide') {
          $('#home-carosual22 .carousel-indicators-ul li').removeClass('active');
          $('#home-carosual22 .carousel-indicators-ul li:nth-child(2)').addClass(
            'active'
          );
          }
      });
      $('#home-carosual2').carousel({
        interval: 4000
      });
      $('#culture-carousel').carousel({
        interval: 4000
      });
      $('#culture-carousel2').carousel({
        interval: 4000
      });
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }
     
      $("#pr-video-modal").on('hidden.bs.modal', function (e) {
  $("#pr-video-modal iframe").attr("src", $("#pr-video-modal iframe").attr("src"));
});
    });

    $("#pr-ceo-video-modal").on('hidden.bs.modal', function (e) {
      $("#pr-ceo-video-modal iframe").attr("src", $("#pr-ceo-video-modal iframe").attr("src"));
    });
        

  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }



}
