<div class="portfolio-branding-smartlink-body">
    <section class="comman-section comman-section-100vh scroll-div smartlinks-sec1" id="section1"
        data-section-name="data-section1">
        <div class="ani-wrap100-top bg-blue9" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section1"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 text-center">
                    <div class="smartlinks-left-wrap xs-mt60">
                        <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section1">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-branding-smartlink/smartlinks-logo-1.svg"
                                alt="Smartlink - Logo" class="smartlinks-logo lazy img-fluid" />
                            <h1 class="text-white position-relative z-index99" data-aos="fade-in" data-aos-delay="900"
                                data-aos-anchor="#section1">
                                Giving a New Face <br />
                                to Logistics Experts
                            </h1>
                        </div>
                        <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-branding-smartlink/smartlinks-logo-after.svg"
                                alt="Smartlink - Logo" class="smartlinks-logo-after lazy img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">
                Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div smartlinks-sect2" id="section2"
        data-section-name="data-section2">
        <div class="comman-bg-overlay-2 bg-blue9" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section2"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="smartlinks-sec2-center overview-sec-center portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2">Overview</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                            SL4 Technology Pvt. Ltd. - is a Bangalore based startup, holding constructive experience in
                            the Logistics industry. They provide consulting and technology solutions to global clients
                            with a team of 15+
                            people. Company's goal is to bring hygiene and best practices in the Logistics and
                            Transportation sector in India, currently which happens to be a highly unorganised sector in
                            the country.
                        </p>
                    </div>
                    <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-smartlink/smartlinks-logo-after2.svg"
                            alt="Smartlink - Overview" class="smartlinks-logo-after2 lazy img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge scroll-div smartlinks-sect3"
        id="section3" data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #1161df;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3">Challenges</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">
                            It was necessary for us to analyze, understand and build the context for Smartlink - being a
                            logistics player. Gathering business and consumer insights, USP's and differentiating
                            factors was the key at the
                            initial level analysis. Translating our understanding into tangible logo designs was a
                            crucial task. I think our team has done that successfully.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-right portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3" class="text-white">Logo
                            Brief</h3>
                        <p data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3" class="text-white p2">
                            Client had an existing multicoloured and complex logo identity which was not appealing to
                            their audience. We got an opportunity to re-design the logo for Smartlink, with a goal to
                            create a cohesive brand
                            identity to translate their endeavours and represent their knowledge, experience and overall
                            capability.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div smartlinks-sect4 pb0" id="section4"
        data-section-name="data-section4">
        <div class="ani-wrap50-2" style="background-color: #37bbfe;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section4"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="smartlinks-sec4-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section4">Brainstorming and Ideas
                        </h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4">
                            This is where the brain starts expressing its ideas with the help of pencil and paper.
                            Smartlink offers that missing “link” within supply chain &amp; transportation by providing
                            active consultation and
                            products to simplify client's problems and addressing their needs. We tried to design our
                            ideas to highlight the same.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12"></div>
            </div>
        </div>
        <div class="smartlinks-sec4-right bg-blue-xs" data-aos="fade-in" data-aos-delay="2500"
            data-aos-anchor="#section4">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-branding-smartlink/smartlinks-sect4-1.png"
                alt="Smartlink - Brainstorming" class="img-fluid smartlinks-sect4-1 smartlinks-sect4-1-img lazy" />
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div smartlinks-sec7 no-padding comman-comparison"
        id="section6" data-section-name="data-section6">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section6" style="background-color: #37bbfe;"></div>
        <div id="comparison" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section6">
            <figure>
                <div id="handle"></div>
                <div id="divisor"></div>
            </figure>
            <input type="range" min="0" max="100" value="50" id="slider" (input)="divisorChanged($event.target.value)"/>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div comman-bg-section smartlinks-sec9" id="section7"
        data-section-name="data-section7">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section7" style="background-color: #ffffff;"></div>
        <div class="container h-100 hide-mobile">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6"></div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div smartlinks-sec10" id="section8"
        data-section-name="data-section8">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section8" style="background-color: #27273c;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div data-aos="fade-right" data-aos-delay="900" data-aos-anchor="#section8">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-smartlink/smartlinks-sec10-1.png"
                            alt="Smartlink - Branding" class="smartlinks-sec9-1 img-fluid lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/branding/vigan"> <span>Vigan
                                Technologies</span> </a>
                        <p class="p2">People-Focused Technology</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>