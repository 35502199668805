<div class="portfolio-webapp-smart360-body">
    <section class="comman-section comman-section-100vh smart360-sec1 scroll-div top-section-wrapper bg-s360-elements overflow-hidden" id="section1" data-section-name="data-section1">
        <div class="ani-wrap50-2" style="background-color: #EAEBF4;" data-aos="new-animation3" data-aos-delay="300" data-aos-anchor="#section1">
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-lg-7">
                    <div class="smart360-sec1-left portfolio-info-wrap2 portfolio-info-banner-l xxs-text-center xs-mb30">
                        <ul class="list-inline comman-card-top-ul hide-mobile-xxs" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <li class="list-inline-item h6">Smart<em>360</em></li>
                            <li class="list-inline-item h6">Utility Management Solution</li>
                        </ul>
                        <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1" style="white-space: normal;" class="xs-mt30 no-wrap-lg">
                            360 Degree View<br>
                            of Complex<br>
                            Utility Business
                        </h1>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="portfolio-info-wrap2" data-aos="fade-in" data-aos-delay="1400" data-aos-anchor="#section1">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-smart360/smart360-banner.png" alt="Moneyguru - Empowering Smart Savers with The Best Mutual Fund Investing Experience" class="smart360-banner-img lazy">
                    </div>
                    <ul class="list-inline comman-card-top-ul show-mobile-xxs text-center">
                        <li class="list-inline-item h6">Smart360</li>
                        <li class="list-inline-item h6">Utility Management Solution</li>
                    </ul>
    
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>

    <section class="comman-section comman-section-100vh comman-solution-challenge smart360-sec2 scroll-div bg-s360-elements" id="section2" data-section-name="data-section2">
        <div class="ani-wrap100-top-2" style="background-color: #EAEBF4;" data-aos="new-animation3" data-aos-delay="300" data-aos-anchor="#section2">
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-lg-6 order-xs-last">
                    <div class="smart360-sec2-left" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-smart360/smart-360-overview.png" alt="Smart360 - Overview" class="smart360-sec2-3 img-fluid lazy">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="smart360-sec2-right portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2">Overview </h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section2">
                            We got an opportunity to work on one more design project for utility domain. The utilities which serves in power, gas (fuel), water sectors within their cities, states or province for domestic & industrial consumers/municipalities. The product is a SaaS based application designed and developed by software engineers with multiple missing links in between. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="comman-section comman-section-100vh comman-solution-challenge scroll-div bg-s360-elements" id="section3" data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background-color: #24264C;" data-aos="new-animation2" data-aos-delay="1200" data-aos-anchor="#section3"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3">Challenges</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">As our client
                            reported during stakeholder interview - the product never evolved with any conscious plan
                            and rather developed based on random requests they encountered with from their anchoring
                            customers.</p>
                        <br />
                        <p class="p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section3">The mission
                            was to get the product ready to launch in US market targeting small Utility segments with
                            consumer base between 10k to 50k to compete with the well known giant industry leaders.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3" class="text-white">
                            Solution</h3>
                        <p data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3" class="text-white p2">
                            Insights from stake holder & user interviews, product analysis and competitive analysis
                            helped us to identify the gaps across the product.</p>
                        <br />
                        <p data-aos="fade-in" data-aos-delay="2600" data-aos-anchor="#section3" class="text-white p2">
                            User centered design (UCD) process helped us on different levels and phases of project and
                            helped us to list down 9 crucial modules with its detailed tasks, work flows for different
                            user roles and consumers across mobile and web platforms.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="comman-section smart360-webapp-sect4 scroll-div comman-section-100vh viewer-section bg-s360-elements" id="section4"
        data-section-name="data-section4">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section4" style="background-color: #1E1E1E;"></div>
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-md-12 bottom-col-set" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section4">
                    <div class="smart360-architecture-bottom">
                        <div class="viewer no-select" id="viewer">
                            <img src="assets/images/portfolio/portfolio-webapp-smart360/smart360-architecture.png" alt="Smart360 - Information Architecture" class="smart360-architecture-img" draggable="false" id="photo">
                        </div>
                        <!-- <div ngDraggable lockAxis="y" [bounds]="myBounds" [inBounds]="true" [outOfBounds]="outOfBounds">
                            <img src="assets/images/portfolio/portfolio-webapp-smart360/smart360-architecture.png" alt="Smart360 - Information Architecture" class="smart360-architecture-img" draggable="false" id="photo">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="comman-section comman-section-100vh smart360-webapp-sect5 scroll-div bg-s360-elements" data-section-name="data-section5"
        id="section5" data-aos-anchor="#section5">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section5" style="background-color: #0F2541;"></div>
        <div class="container h-100">
            <div class="row vh100-100">
                <div class="col-md-12 col-lg-6 col-sm-12">
                    <div class="portfolio-info-wrap2 text-md-center-adj">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section5">Wireframing</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5">Blue prints to test, validate - take feedback from users and stake holders and to refine for further perfection.</p>
                    </div>
                    <div class="smart360-webapp-sect5-1-wrap" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section5">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-smart360/smart360-webapp-sect5-1.svg" alt="Wireframing" class="smart360-webapp-sect5-1 img-fluid lazy" />
                    </div>
                    <div class="smart360-webapp-sect5-2-wrap" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section5">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-smart360/smart360-webapp-sect5-2.svg" alt="Wireframing" class="smart360-webapp-sect5-2 img-fluid lazy" />
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-sm-12 text-md-center-adj">
                    <div class="smart360-webapp-sect5-3-wrap" data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section5">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-smart360/smart360-webapp-sect5-3.svg" alt="Wireframing" class="smart360-webapp-sect5-3 img-fluid lazy" />
                    </div>
                    <div class="smart360-webapp-sect5-4-wrap" data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section5">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-smart360/smart360-webapp-sect5-4.svg" alt="Wireframing" class="smart360-webapp-sect5-4 img-fluid lazy" />
                    </div>
                    <div class="smart360-webapp-sect5-5-wrap" data-aos="fade-in" data-aos-delay="2200" data-aos-anchor="#section5">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-smart360/smart360-webapp-sect5-5.svg" alt="Wireframing" class="smart360-webapp-sect5-5 img-fluid lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="comman-section comman-section-100vh scroll-div smart360-webapp-sect6 pb0 bg-s360-elements overflow-hidden" id="section6" data-section-name="data-section6">
        <div class="ani-wrap50-2" style="background-color: #FFFFFF;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section6"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="smart360-webapp-sect6-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section6">Style Guide
                        </h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6">To set the overall persona and visual consistency across the web and mobile app.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12"></div>
            </div>
        </div>
        <div class="smart360-webapp-sect6-right text-right" data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section6">
            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-smart360/smart360-styleguide.png" alt="Vimson - Brainstorming" class="img-fluid lazy" />
        </div>
    </section>

    <section class="comman-section comman-section-100vh smart360-webapp-sect7 scroll-div bg-s360-elements" data-section-name="data-section7" id="section7" style="background-color: #24264C;">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-md-12">
                    <div class="portfolio-info-wrap">
                        <h3 data-aos="fade-in" data-aos-delay="300" data-aos-anchor="#section7" class="text-center text-white">UI Designs</h3>
                        <p data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section7" class="p2 text-center text-white">The final drafts after couple of rounds of feedback and iterations.</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="smart360-webapp-sect7-1-wrap text-center" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section7">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-smart360/smart360-uidesign1.png" alt="Designing for Ease" class="smart360-webapp-sect7-1 img-fluid lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="comman-section comman-section-100vh smart360-webapp-sect8 scroll-div xs-pt0 bg-s360-elements"
        data-section-name="data-section8" id="section8" style="background-color: #24264C;">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-md-12 col-lg-6 col-sm-12">
                    <div class="smart360-webapp-sect8-1-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section8">
                        <p>O&amp;M</p> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-smart360/smart360-uidesign3.png"
                            alt="Designing for Ease" class="smart360-webapp-sect8-1 img-fluid lazy" />
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-sm-12">
                    <div class="smart360-webapp-sect8-2-wrap" data-aos="fade-in" data-aos-delay="1200"
                        data-aos-anchor="#section8">
                        <p>Consumer Web App</p> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-smart360/smart360-uidesign2.png"
                            alt="Designing for Ease" class="smart360-webapp-sect8-2 img-fluid lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="comman-section comman-section-100vh smart360-webapp-sect9 scroll-div bg-s360-elements" id="section9" data-section-name="data-section9" data-aos-anchor="#section9" style="background-color: #EAEBF4;">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-start">
                <div class="col-md-6 col-sm-12">
                    <div class="moneyguru-gateway-left portfolio-info-wrap2" data-aos="fade-in" data-aos-delay="300"
                        data-aos-anchor="#section9">
                        <h3 class="xs-text-center">Field Force App</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section9">Intuitive app for FOS team to manage ground level activities efficiently.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 smart360-webapp-sect9-wrap">
                    <div class="smart360-webapp-sect9-wrap1" data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section9">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-smart360/smart360-webapp-sect9-1.png" alt="Field Force App" class="smart360-webapp-sect9-1 img-fluid lazy" />
                    </div>
                    <div class="smart360-webapp-sect9-wrap2" data-aos="fade-in" data-aos-delay="2100" data-aos-anchor="#section9">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-smart360/smart360-webapp-sect9-2.png" alt="Field Force App" class="smart360-webapp-sect9-2 img-fluid lazy" />
                    </div>
                    <div class="smart360-webapp-sect9-wrap3" data-aos="fade-in" data-aos-delay="2400" data-aos-anchor="#section9">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-smart360/smart360-webapp-sect9-3.png" alt="Field Force App" class="smart360-webapp-sect9-3 img-fluid lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="comman-section comman-section-100vh insisive-website-sec11 failure-section scroll-div" id="section-learning" data-section-name="data-section-learning">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300" data-aos-anchor="#section-learning" style="background-color: #1E1E1E;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-7 col-lg-6 col-sm-12">
                    <div class="insisive-sec-left portfolio-info-wrap2 failure-sec-left">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section-learning">Failure &amp;
                            Learnings</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section-learning">
                            While designing large enterprise applications, we noted that maintaining consistency is crucial. One has to keep an eye for it.
                        </p>
                        <ul class="failure-ul p2" data-aos="fade-in" data-aos-delay="1200"
                            data-aos-anchor="#section-learning">
                            <li>Bring standradisation in Design to scale</li>
                            <li>Do not hesitate to take the lead</li>
                            <li>Design is never done</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-5 col-lg-6">    
                </div>
            </div>
        </div>
        <div class="failure-sec-right" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section-learning">
            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-sec14-bg.png" alt="Failure &amp; Learnings" class="img-fluid lazy failure-sec-right-img" />
        </div>
    </section>

    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6> <a class="page-foot-link h3 st btn-line" [routerLink]="['/portfolio/website/insisive']">
                            <span>Insisive</span> </a>
                        <p class="p2">Helping Enterprises to Monitor, Optimise &amp; Reduce Their Cloud Spend</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>