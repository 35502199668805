<div class="our-work-page">
  <section
    class="comman-section work-sec1 top-section-wrapper normal-scroll pb0 clearfix"
    data-section-name="data-section1"
    id="section1"
  >
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div
            class="comman-card-info work-comman-card-info"
            style="max-width: inherit"
          >
            <h1 class="h6 mb35 xs-text-center">
              UX UI Design &amp; Branding Portfolio
            </h1>
            <h6 class="h1 sec-heading xs-text-center">Our Work</h6>
            <div class="custom-nav-wrap-outer">
              <ul class="list-inline custom-nav-wrap mt20 st">
                <li class="list-inline-item">
                  <a
                    class="bttn btn-link-custom btn-line portfolio-thumb-webapp-btn"
                    href="#"
                  >
                    <img
                      src="assets/images/work/icon-web-app.svg"
                      alt="Web App"
                      class="icon-app"
                    />
                    <span>Web App</span></a
                  >
                </li>
                <li class="list-inline-item">
                  <a
                    class="bttn btn-link-custom btn-line portfolio-thumb-mobileapp-btn"
                    href="#"
                    ><img
                      src="assets/images/work/icon-mobile-app.svg"
                      alt="Mobile App"
                      class="icon-app"
                    /><span>Mobile App</span></a
                  >
                </li>
                <li class="list-inline-item">
                  <a
                    class="bttn btn-link-custom btn-line portfolio-thumb-branding-btn"
                    href="#"
                    ><img
                      src="assets/images/work/icon-branding.svg"
                      alt="Branding"
                      class="icon-app"
                    /><span>Branding</span></a
                  >
                </li>
                <li class="list-inline-item">
                  <a
                    class="bttn btn-link-custom btn-line portfolio-thumb-website-btn"
                    href="#"
                    ><img
                      src="assets/images/work/icon-website.svg"
                      alt="Website"
                      class="icon-app"
                    /><span>Website</span></a
                  >
                </li>
                <li class="list-inline-item">
                  <a
                    class="bttn btn-link-custom active btn-line portfolio-thumb-all-btn"
                    href="#"
                    ><img
                      src="assets/images/work/icon-all.svg"
                      alt="All"
                      class="icon-app"
                    /><span>All</span></a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="arrow-scroll-abs"></div>
    <div class="on-scroll-filter-menu-wrap" style="display: none">
      <ul class="list-unstyled custom-nav-wrap">
        <li>
          <a
            class="bttn btn-link-custom btn-line portfolio-thumb-webapp-btn"
            href="#"
          >
            <img
              src="assets/images/work/icon-web-app.svg"
              alt="Web App"
              class="icon-app"
            />
            <span>Web App</span></a
          >
        </li>
        <li>
          <a
            class="bttn btn-link-custom btn-line portfolio-thumb-mobileapp-btn"
            href="#"
            ><img
              src="assets/images/work/icon-mobile-app.svg"
              alt="Mobile App"
              class="icon-app"
            /><span>Mobile App</span></a
          >
        </li>
        <li>
          <a
            class="bttn btn-link-custom btn-line portfolio-thumb-branding-btn"
            href="#"
            ><img
              src="assets/images/work/icon-branding.svg"
              alt="Branding"
              class="icon-app"
            /><span>Branding</span></a
          >
        </li>
        <li>
          <a
            class="bttn btn-link-custom btn-line portfolio-thumb-website-btn"
            href="#"
            ><img
              src="assets/images/work/icon-website.svg"
              alt="Website"
              class="icon-app"
            /><span>Website</span></a
          >
        </li>
        <li>
          <a
            class="bttn btn-link-custom active btn-line portfolio-thumb-all-btn"
            href="#"
            ><img
              src="assets/images/work/icon-all.svg"
              alt="All"
              class="icon-app"
            /><span>All</span></a
          >
        </li>
      </ul>
    </div>
  </section>
  <section
    class="comman-section scroll-div clearfix"
    data-section-name="data-section2"
    id="section2"
  >
    <div class="container h-100">
      <div class="row our-work-row">

        <div
        class="col-md-6 col-sm-12 portfolio-thumb-mobileapp portfolio-thumb-webapp portfolio-thumb-all"
      >
        <a [routerLink]="['/portfolio/web-app/intelehealth']">
        <div
          class="work-card card-portfolio-branding-vimson"
        >
          <div class="work-card-img intelhealth-work-card-img1">
            <div class="work-card-img-bg" style="overflow: inherit;top: -20px;">
              <div class="work-card-img-bg-inner overflow-hidden">
                <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/thumbnail/intelhealth/Group-2.png"
                alt="Fligital"
                class="img-fluid intelhealth-overlay-img1 lazy"
                style="margin-top: 20px"
              />
              </div>
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/thumbnail/intelhealth/Group-1.png"
                alt="fligital-thumb1"
                class="intelhealth-thumb1 lazy" style="right: -17px;top: 200px; z-index: 4;"
              />
           
              
            </div>
          </div>
          <div class="work-card-body">
            <ul>
              <li>InteleHealth</li>
              <li>Telemedicine</li>
            </ul>
            <h5>
              Delivering Quality Healthcare Beyond Reach
            </h5>
          </div>
        </div>
      </a>
      </div>

        <div
          class="col-md-6 col-sm-12 portfolio-thumb-webapp portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/web-app/smart360']">
            <div class="work-card card-portfolio-webapp-smart360">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #051941">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-webapp-smart360-1.png"
                    alt="portfolio-webapp-smart360-1"
                    class="portfolio-webapp-smart360-1 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-webapp-smart360-2.svg"
                    alt="portfolio-webapp-smart360-2"
                    class="portfolio-webapp-smart360-2 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-webapp-smart360-3.svg"
                    alt="portfolio-webapp-smart360-3 "
                    class="portfolio-webapp-smart360-3 floatingX-r st lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Smart360</li>
                  <li>Cloud based ERP</li>
                </ul>
                <h5>360 Degree View of Complex Utility Business</h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-webapp portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/web-app/authntick']">
            <div class="work-card card-portfolio-webapp-authntick">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #d2143a">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-webapp-authntick-1.png"
                    alt="portfolio-webapp-authntick-1"
                    class="portfolio-webapp-authntick-1 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-webapp-authntick-2.svg"
                    alt="portfolio-webapp-authntick-2"
                    class="portfolio-webapp-authntick-2 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-webapp-authntick-3.svg"
                    alt="portfolio-webapp-authntick-3"
                    class="portfolio-webapp-authntick-3 floatingX-l st lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Authntick</li>
                  <li>A.I, Digital Verification</li>
                </ul>
                <h5>Security Meets Simplicity</h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-mobileapp portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/mobile-app/moneyguru']">
            <div class="work-card card-portfolio-mobile-app-moneyguru">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #fe822c">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-mobile-app-moneyguru-1.png"
                    alt="portfolio-mobile-app-moneyguru-1"
                    class="portfolio-mobile-app-moneyguru-1 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-mobile-app-moneyguru-2.svg"
                    alt="portfolio-mobile-app-moneyguru-2"
                    class="portfolio-mobile-app-moneyguru-2 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-mobile-app-moneyguru-3.svg"
                    alt="portfolio-mobile-app-moneyguru-3"
                    class="portfolio-mobile-app-moneyguru-3 floatingX-r st lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Moneyguru</li>
                  <li>Fintech, Mutual Fund Investments</li>
                </ul>
                <h5>Empowering Smart Savers with Tech &amp; Insights</h5>
              </div>
            </div>
          </a>
        </div>
      


        <div class="col-md-6 col-sm-12 portfolio-thumb-website portfolio-thumb-all"> <a
          [routerLink]="['/portfolio/website/flexifyme']">
          <div class="work-card card-portfolio-website-crubba">
              <div class="work-card-img">
                  <div class="work-card-img-bg" style="background: #D25685;"> 
                    <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/flexifyme-thumbnail.png"
                    alt="portfolio-branding-smartlink-1"
                    class="flexifyme-thumbnail lazy"
                  />
                  </div>
              </div>
              <div class="work-card-body">
                  <ul>
                      <li>Flexifyme</li>
                      <li>Yoga, Meditation, &  Nutrition Coaches</li>
                  </ul>
                  <h5>Achieve Mental, Physical, & Spiritual Harmony Through Live 1-on-1 Sessions</h5>
              </div>
          </div>
      </a></div>
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-webapp portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/web-app/esop']">
            <div class="work-card card-portfolio-webapp-esop">
              <!-- <div class="work-card-img">
                                <div class="work-card-img-bg" style="background: #3246D3;">
                                    <img src="assets/images/thumbnail/portfolio-webapp-esop-monitors.png"
                                        alt="portfolio-webapp-esop-1" class="portfolio-webapp-esop-1 lazy" />
                                    <img src="assets/images/thumbnail/portfolio-webapp-esop-patterns.png"
                                        alt="portfolio-webapp-esop-2" class="portfolio-webapp-esop-2 lazy" />
                                    <img src="assets/images/thumbnail/portfolio-webapp-esop-triangles.png"
                                        alt="portfolio-webapp-esop-3" class="portfolio-webapp-esop-3 lazy" />
                                </div>
                            </div> -->
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #3246d3">
                  <img
                    src="assets/images/thumbnail/portfolio-webapp-esop-monitors.png"
                    alt="portfolio-webapp-esop-monitors"
                    class="portfolio-webapp-esop-monitors lazy"
                  />
                  <img
                    src="assets/images/thumbnail/portfolio-webapp-esop-patterns.png"
                    alt="portfolio-webapp-esop-patterns"
                    class="portfolio-webapp-esop-patterns floatingX-r st lazy"
                  />
                  <img
                    src="assets/images/thumbnail/portfolio-webapp-esop-triangles.png"
                    alt="portfolio-webapp-esop-triangles"
                    class="portfolio-webapp-esop-triangles lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>ESOP</li>
                  <li>Employee Stock Option Administration</li>
                </ul>
                <h5>ESOP Direct Web App</h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-webapp portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/web-app/mytrux']">
            <div class="work-card card-portfolio-website-mytrux">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #0f2441">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-webapp-mytrux-1.png"
                    alt="portfolio-webapp-mytrux-1"
                    class="portfolio-webapp-mytrux-1 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-webapp-mytrux-2.svg"
                    alt="portfolio-webapp-mytrux-2"
                    class="portfolio-webapp-mytrux-2 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-webapp-mytrux-3.svg"
                    alt="portfolio-webapp-mytrux-3"
                    class="portfolio-webapp-mytrux-3 floatingX-l st lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>MyTrux</li>
                  <li>Logistics</li>
                </ul>
                <h5>Redefining UX for Digital Transportation</h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-branding portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/branding/vigan']">
            <div class="work-card card-portfolio-branding-vigan">
              <div class="work-card-img">
                <div
                  class="work-card-img-bg"
                  style="
                    background: linear-gradient(
                      289.45deg,
                      #6f54fa 16.5%,
                      #35baef 70.63%
                    );
                  "
                >
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/vigan-thumbnail.jpg"
                    alt="portfolio-branding-vigan-1"
                    class="vigan-thumbnail lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Vigan <span class="hide-mobile">Technologies</span></li>
                  <li>AI, Tech Support</li>
                </ul>
                <h5>People-Focused Technology</h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-mobileapp portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/mobile-app/easy-plan']">
            <div class="work-card card-portfolio-mobile-app-easy">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #ebedf3">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/easy-plan-thumbnail1.png"
                    alt="easy-plan-thumbnail1"
                    class="easy-plan-thumbnail1 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/easy-plan-thumbnail2.svg"
                    alt="easy-plan-thumbnail2"
                    class="easy-plan-thumbnail2 floatingX-l st img-fluid lazy"
                  />
                  <img
                    src="assets/images/thumbnail/easy-plan-thumbnail3.svg"
                    alt="easy-plan-thumbnail3"
                    class="easy-plan-thumbnail3 img-fluid floatingX-r st lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Easyplan</li>
                  <li>Fintech, Money Management</li>
                </ul>
                <h5>Enhancing India's #1 Goal-Based Saving App</h5>
              </div>
            </div>
          </a>
        </div>
        <!-- wajahat code start for casestudy -->
        <!-- <div class="col-md-6 col-sm-12 portfolio-thumb-website portfolio-thumb-all"> <a
                    [routerLink]="['/portfolio/website/bynry']">
                    <div class="work-card card-portfolio-website-bynry">
                        <div class="work-card-img">
                            <div class="work-card-img-bg" style="background: #051941;"> <img
                                    src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/thumbnail/portfolio-website-bynry1.svg"
                                    alt="portfolio-website-bynry1" class="portfolio-website-bynry1 lazy" /> <img
                                    src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/thumbnail/portfolio-website-bynry2.svg"
                                    alt="portfolio-website-bynry2" class="portfolio-website-bynry2 lazy" /></div>
                        </div>
                        <div class="work-card-body">
                            <ul>
                                <li> Bynry</li>
                                <li> Energy, Utility &amp; IoT</li>
                            </ul>
                            <h5> Enhancing the Web Presence for Utility Management Company</h5>
                        </div>
                    </div>
                </a></div> -->

        <!-- wajahat code added for flexifyme -->

        <div
        class="col-md-6 col-sm-12 portfolio-thumb-mobileapp portfolio-thumb-all"
      >
        <a [routerLink]="['/portfolio/mobile-app/aaloch']">
          <div class="work-card card-portfolio-mobile-app-alloch">
            <div class="work-card-img">
              <div class="work-card-img-bg" style="background: #5c212f">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/thumbnail/portfolio-mobile-app-alloch-1.png"
                  alt="portfolio-mobile-app-alloch-1"
                  class="portfolio-mobile-app-alloch-1 lazy"
                />
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/thumbnail/portfolio-mobile-app-alloch-2.svg"
                  alt="portfolio-mobile-app-alloch-2"
                  class="portfolio-mobile-app-alloch-2 lazy"
                />
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/thumbnail/portfolio-mobile-app-alloch-3.svg"
                  alt="portfolio-mobile-app-alloch-3"
                  class="portfolio-mobile-app-alloch-3 floatingX-l st lazy"
                />
              </div>
            </div>
            <div class="work-card-body">
              <ul>
                <li>Aaloch</li>
                <li>Social Connectivity</li>
              </ul>
              <h5>Let's Track Our Nasty Friends</h5>
            </div>
          </div>
        </a>
      </div>
        <!-- wajahat code end for flexifyme -->

        <div
          class="col-md-6 col-sm-12 portfolio-thumb-website portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/website/crubba']">
            <div class="work-card card-portfolio-website-crubba">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #7ce2bb">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-website-crubba-1.png"
                    alt="portfolio-website-crubba-1"
                    class="portfolio-website-crubba-1 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-website-crubba-2.svg"
                    alt="portfolio-website-crubba-2"
                    class="portfolio-website-crubba-2 floatingX-l st lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-website-crubba-3.svg"
                    alt="portfolio-website-crubba-3"
                    class="portfolio-website-crubba-3 floatingX-r st lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Crubba</li>
                  <li>Foodtech</li>
                </ul>
                <h5>A Personalised &amp; Engaging Take on Home Cooking</h5>
              </div>
            </div>
          </a>
        </div>
        <!-- wajahat code end for casestudy -->
        <div
        class="col-md-6 col-sm-12 portfolio-thumb-website portfolio-thumb-branding portfolio-thumb-all"
      >
        <a href="#" class="pr-video-modal-btn" data-toggle="modal" data-target="#overseas-edu-video-modal">
        <div
          class="work-card card-portfolio-branding-vimson"
        >
          <div class="work-card-img fligital-work-card-img">
            <div class="work-card-img-bg" style="overflow: inherit">
              <div class="work-card-img-bg-inner overflow-hidden">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/thumbnail/overseas-bg-img.png"
                  alt="Fligital"
                  class="img-fluid fligital-overlay-img lazy"
                  style="margin-top: 60px"
                />
              </div>
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/thumbnail/overseas-mob-img.png"
                alt="fligital-thumb1"
                class="fligital-thumb1 lazy" style="position: absolute;
                top: 22px;"
              />
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/thumbnail/overseas-bg-circle.png"
                alt="fligital-thumb2"
                class="fligital-thumb2 lazy" style="position: absolute;
                top: 45px;
                left: 280px;"
              />
              <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/thumbnail/overseas-dot-img.png"
                alt="fligital-thumb3"
                class="fligital-thumb3 lazy" style="position: absolute;
                top: 84px;
                right: 66px;z-index: 9;"
              />
              
            </div>
          </div>
          <div class="work-card-body">
            <ul>
              <li>KC Overseas Education</li>
              <li>EdTech Website</li>
            </ul>
            <h5>
              Bringing global education within everyone's reach
            </h5>
          </div>
        </div>
      </a>
      </div>
       

        <div
          class="col-md-6 col-sm-12 portfolio-thumb-branding portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/branding/smartlink']">
            <div class="work-card card-portfolio-branding-smartlink">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #3dbdff">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-branding-smartlink-1.png"
                    alt="portfolio-branding-smartlink-1"
                    class="portfolio-branding-smartlink-1 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-branding-smartlink-2.png"
                    alt="portfolio-branding-smartlink-2"
                    class="portfolio-branding-smartlink-2 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-branding-smartlink-3.svg"
                    alt="portfolio-branding-smartlink-3"
                    class="portfolio-branding-smartlink-3 floatingX-l st lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Smartlink</li>
                  <li>Logistics Management</li>
                </ul>
                <h5>Giving a New Face to Logistics Experts</h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-website portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/website/mytrux']">
            <div class="work-card card-portfolio-website-mytrux">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #0f2441">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-website-mytrux-1.svg"
                    alt="portfolio-website-mytrux-1"
                    class="portfolio-website-mytrux-1 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-website-mytrux-2.png"
                    alt="portfolio-website-mytrux-2"
                    class="portfolio-website-mytrux-2 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-website-mytrux-3.svg"
                    alt="portfolio-website-mytrux-3 "
                    class="portfolio-website-mytrux-3 floatingX-r st lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>MyTrux</li>
                  <li>Logistics</li>
                </ul>
                <h5>Website for Connected Digital Transport</h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-website portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/website/bynry']">
            <div class="work-card card-portfolio-website-bynry">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #051941">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-website-bynry1.svg"
                    alt="portfolio-website-bynry1"
                    class="portfolio-website-bynry1 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-website-bynry2.svg"
                    alt="portfolio-website-bynry2"
                    class="portfolio-website-bynry2 lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Bynry</li>
                  <li>Energy, Utility &amp; IoT</li>
                </ul>
                <h5>
                  Enhancing the Web Presence for Utility Management Company
                </h5>
              </div>
            </div>
          </a>
        </div>
        <!-- <div class="col-md-6 col-sm-12 portfolio-thumb-website portfolio-thumb-all"> <a
                        [routerLink]="['/portfolio/website/crubba']">
                        <div class="work-card card-portfolio-website-crubba">
                            <div class="work-card-img">
                                <div class="work-card-img-bg" style="background: #7ce2bb;"> <img
                                        src="assets/images/preloader/ajax-loader.png"
                                        data-src="assets/images/thumbnail/portfolio-website-crubba-1.png"
                                        alt="portfolio-website-crubba-1" class="portfolio-website-crubba-1 lazy" /> <img
                                        src="assets/images/preloader/ajax-loader.png"
                                        data-src="assets/images/thumbnail/portfolio-website-crubba-2.svg"
                                        alt="portfolio-website-crubba-2"
                                        class="portfolio-website-crubba-2 floatingX-l st lazy" /> <img
                                        src="assets/images/preloader/ajax-loader.png"
                                        data-src="assets/images/thumbnail/portfolio-website-crubba-3.svg"
                                        alt="portfolio-website-crubba-3"
                                        class="portfolio-website-crubba-3 floatingX-r st lazy" />
                                </div>
                            </div>
                            <div class="work-card-body">
                                <ul>
                                    <li> Crubba</li>
                                    <li> Foodtech</li>
                                </ul>
                                <h5> A Personalised &amp; Engaging Take on Home Cooking</h5>
                            </div>
                        </div>
                    </a></div> -->
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-website portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/website/moneyguru']">
            <div class="work-card card-portfolio-website-moneyguru">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #fe822c">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-website-moneyguru1.png"
                    alt="portfolio-website-moneyguru1"
                    class="portfolio-website-moneyguru1 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/portfolio-website-moneyguru2.svg"
                    alt="portfolio-website-moneyguru2"
                    class="portfolio-website-moneyguru2 floatingX-l st lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Moneyguru</li>
                  <li>Fintech, Mutual Fund Investments</li>
                </ul>
                <h5>
                  Establishing Trust &amp; Delivering the Best Online Investment
                  Experience
                </h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-branding portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/branding/phychem']">
            <div class="work-card card-portfolio-branding-phycheme">
              <div class="work-card-img">
                <div
                  class="work-card-img-bg"
                  style="
                    background: linear-gradient(
                      144.25deg,
                      #022165 9.73%,
                      #022674 96.74%
                    );
                  "
                >
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/phycheme-thumbnail.jpg"
                    alt="portfolio-branding-phycheme1"
                    class="phycheme-thumbnail lazy img-fluid"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Phychem <span class="hide-mobile">Technologies</span></li>
                  <li>Manufacturing</li>
                </ul>
                <h5>Reimagining the Face of Manufacturing Brand</h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-branding portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/branding/notionedge']">
            <div class="work-card card-portfolio-branding-notion-edge">
              <div class="work-card-img">
                <div class="work-card-img-bg">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/notion-edge-thumbnail.jpg"
                    alt="portfolio-branding-notion-edge1"
                    class="lazy img-fluid"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Notion Edge</li>
                  <li>IT &amp; Services</li>
                </ul>
                <h5>Brand Identity for SAP Consulting Company</h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-branding portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/branding/mytrux']">
            <div class="work-card card-portfolio-branding-mytrux">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #0f2541">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/mytrux-branding-thumbnail.jpg"
                    alt="portfolio-branding-mytrux-1"
                    class="mytrux-branding-thumbnail lazy img-fluid"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Mytrux</li>
                  <li>Logistics</li>
                </ul>
                <h5>Identity Redesign for Connected Digital Transport</h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-branding portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/branding/vimson']">
            <div class="work-card card-portfolio-branding-vimson">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #303134">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/vimson-thumbnail.png"
                    alt="Vimson"
                    class="img-fluid lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Vimson</li>
                  <li>Manufacturing</li>
                </ul>
                <h5>Helping Manufacturing Companies Go Global</h5>
              </div>
            </div>
          </a>
        </div>

        <div
          class="col-md-6 col-sm-12 portfolio-thumb-branding portfolio-thumb-all"
        >
          <a [routerLink]="['/portfolio/branding/concerro']">
            <div class="work-card card-portfolio-website-concerro-health">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #36415b">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/thumb-concerro-health1.png"
                    alt="Concerro Health"
                    class="thumb-concerro-health1 st lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/thumb-concerro-health2.svg"
                    alt="Concerro Health"
                    class="thumb-concerro-health2 st lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Concerro Health</li>
                  <li>Pharmaceutical, Healthcare</li>
                </ul>
                <h5>A Brand Promise of Companionship Assurance</h5>
              </div>
            </div>
          </a>
        </div>

        <div
          class="col-md-6 col-sm-12 portfolio-thumb-branding portfolio-thumb-all"
        >
          <div
            class="work-card card-portfolio-website-wellness-insignia coming-soon-hover"
          >
            <div class="work-card-img">
              <div class="work-card-img-bg" style="background: #643476">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/thumbnail/thumb-wellness-insignia1.png"
                  alt="Wellness Insignia"
                  class="thumb-wellness-insignia1 st lazy"
                />
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/thumbnail/thumb-wellness-insignia2.svg"
                  alt="Wellness Insignia"
                  class="thumb-wellness-insignia2 st lazy"
                />
                <h5 class="coming-soon-h5 st">Coming Soon...</h5>
              </div>
            </div>
            <div class="work-card-body">
              <ul>
                <li>Wellness Insignia</li>
                <li>Health & Wellness</li>
              </ul>
              <h5>Fall in Love with Wellness</h5>
            </div>
          </div>
        </div>

        <div
          class="col-md-6 col-sm-12 portfolio-thumb-website portfolio-thumb-all"
        >
          <a href="https://www.engati.com/" target="_blank">
            <div class="work-card card-portfolio-website-engati">
              <div class="work-card-img">
                <div class="work-card-img-bg" style="background: #b81e43">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/thumb-engati1.png"
                    alt="thumb-engati"
                    class="thumb-engati1 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/thumb-engati2.png"
                    alt="thumb-engati"
                    class="thumb-engati2 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/thumb-engati3.png"
                    alt="thumb-engati"
                    class="thumb-engati3 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/thumb-engati4.png"
                    alt="thumb-engati"
                    class="thumb-engati4 lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/thumb-engati5.svg"
                    alt="thumb-engati"
                    class="thumb-engati5 floatingX-r st lazy"
                  />
                </div>
              </div>
              <div class="work-card-body">
                <ul>
                  <li>Engati</li>
                  <li>Conversational Automation & AI</li>
                </ul>
                <h5>
                  Repositioning one of the most advanced and intelligent
                  live-chat platform company.
                </h5>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-md-6 col-sm-12 portfolio-thumb-website portfolio-thumb-branding portfolio-thumb-all"
        >
          <div
            class="work-card card-portfolio-branding-vimson coming-soon-hover"
          >
            <div class="work-card-img">
              <div class="work-card-img-bg" style="background: transparent">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/thumbnail/turtle-shield-thumbnail.png"
                  alt="Turtle Shield"
                  class="img-fluid lazy"
                />
                <h5 class="coming-soon-h5 st">Coming Soon...</h5>
              </div>
            </div>
            <div class="work-card-body">
              <ul>
                <li>Turtle Shield</li>
                <li>Data Privacy & Security, AI</li>
              </ul>
              <h5>Brand Identity & Website Design for Data Privacy Startup</h5>
            </div>
          </div>
        </div>


        <div
          class="col-md-6 col-sm-12 portfolio-thumb-website portfolio-thumb-branding portfolio-thumb-all"
        >
          <!-- <a [routerLink]="['/portfolio/website/fligital']"> -->
            <a [routerLink]="">
          <div
            class="work-card card-portfolio-branding-vimson"
          >
            <div class="work-card-img fligital-work-card-img">
              <div class="work-card-img-bg" style="overflow: inherit">
                <div class="work-card-img-bg-inner overflow-hidden">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/thumbnail/fligital-overlay-img.png"
                    alt="Fligital"
                    class="img-fluid fligital-overlay-img lazy"
                    style="margin-top: 60px"
                  />
                </div>
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/thumbnail/fligital-thumb1.svg"
                  alt="fligital-thumb1"
                  class="fligital-thumb1 lazy"
                />
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/thumbnail/fligital-thumb2.svg"
                  alt="fligital-thumb2"
                  class="fligital-thumb2 lazy"
                />
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/thumbnail/fligital-thumb3.svg"
                  alt="fligital-thumb3"
                  class="fligital-thumb3 lazy"
                />
                
              </div>
            </div>
            <div class="work-card-body">
              <ul>
                <li>Fligital</li>
                <li>Transport & Logistics</li>
              </ul>
              <h5>
                Brand Identity & Website Design for Digitized Fleet Platform
              </h5>
            </div>
          </div>
        </a>
        </div>


        <div
        class="col-md-6 col-sm-12 portfolio-thumb-website portfolio-thumb-webapp portfolio-thumb-all"
      >
        <a [routerLink]="['/portfolio/website/insisive']">
          <div class="work-card card-portfolio-website-insisive">
            <div class="work-card-img">
              <div class="work-card-img-bg" style="background: #282d51">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/thumbnail/insisive-cloud-thumbnail.jpg"
                  alt="portfolio-website-insisive1"
                  class="insisive-cloud-thumbnail lazy img-fluid"
                />
              </div>
            </div>
            <div class="work-card-body">
              <ul>
                <li>Insisive</li>
                <li>Enterprise Cloud, AI</li>
              </ul>
              <h5>
                Helping Enterprises to Monitor, Optimise &amp; Reduce Their
                Cloud Spend
              </h5>
            </div>
          </div>
        </a>
      </div>

    

   

 <!-- start popup modal for overseas education -->
 <div class="modal fade overseas-edu-video-modal" id="overseas-edu-video-modal" tabindex="-1" aria-labelledby="overseas-edu-video-modalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
   
  <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
          <div class="modal-body">
              <button type="button" class="close close-adj-1" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
      
              <div class="embed-responsive embed-responsive-16by9">
                  <iframe width="200" height="200" src="https://www.youtube.com/embed/KSYU45ThqNc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
          </div>
      </div>
      
  </div>
</div>
<!-- end popup modal for  overseas education-->
    

      </div>
    </div>
  </section>
</div>
