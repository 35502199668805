import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import AOS from 'aos';

declare let $: any;

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit {


  
  //new desc
  descrip = 'Prismic Reflections is a Strategic UX UI Design Company that speacializes in User Experience, Brand Strategy & Identity Design and UI Engineering. Lets discuss!';

  constructor(private titleService: Title, private metaService: Meta) {
   
    this.setTitle('Privacy Policy | Prismic Reflections');
    this.metaService.updateTag({name: 'description', content: this.descrip});
    this.metaService.updateTag({
      name: 'keywords',
      content: 'ui ux design, web design firms, web development firm, website development services, website design services, brand creation, mumbai, india'
    });
    this.metaService.updateTag({property: 'og:type', content: 'business.business'});
    this.metaService.updateTag({property: 'og:title', content: 'Privacy Policy | Prismic Reflections'});
    this.metaService.updateTag({property: 'og:description', content: this.descrip});
  }

  ngOnInit(): void {
    $(function(): void {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });
    $('.comman-header-wrap').show();
    $('#comman-footer').show();
    $(document).ready(function(): void {
      $('.logo-wrap-our-team').hide();
      $('.logo-wrap-a').show();
      $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
      $('.arrow-scroll-abs').hide();
      $('.comman-top-menu').removeClass('comman-top-menu-dark');
      const windowWidth = $(window).width();
      if (windowWidth >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          overflowscroll: true,
          before(i, panels): void {
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.pagination-wrap')
              .find('a[href="#' + ref + '"]')
              .addClass('active');
            $('.logo-wrap-a .company-name-mobile')
              .removeClass('active-white-text')
              .hide();
            $('.comman-top-menu').removeClass('comman-top-menu-dark');

            if (ref === 'data-section1') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.process-section1 .comman-card-info-left').fadeIn(800);
              $('.process-section1 .comman-card-info-left').addClass(
                'fixed-left'
              );
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            } else if (ref === 'data-comman-footer') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.process-section1 .comman-card-info-left').fadeOut(100);
            } else {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            }
          },
          afterRender(): void {
            aosinit();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i): void {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
          },
        });
        $('.arrow-scroll-abs .next').click(function(e1): void {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2): void {
          e2.preventDefault();
          $.scrollify.prev();
        });
      }
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }

      function aosinit(): void {
        AOS.init({
          duration: 400,
          anchorPlacement: 'top-top',
          disable(): boolean {
            const maxWidth = 1199;
            return window.innerWidth < maxWidth;
          },
          once: true,
          offset: 20,
        });
      }
    });
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

}
