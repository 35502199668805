<div class="portfolio-website-crubba-body">
    <section class="comman-section comman-section-100vh crubba-sec1 scroll-div top-section-wrapper"
        data-section-name="data-section1" id="section1">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section1" style="background-color: #ffffff;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-6 order-xs-last">
                    <div class="crubba-sec1-right portfolio-info-wrap2 portfolio-info-banner-l xxs-text-center">
                        <ul class="list-inline comman-card-top-ul" data-aos="fade-in" data-aos-delay="1200"
                            data-aos-anchor="#section1">
                            <li class="list-inline-item h6 text-white">Crubba</li>
                            <li class="list-inline-item h6 text-white">Foodtech</li>
                        </ul>
                        <h1 data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section1"
                            class="text-white no-wrap-md hide-mobile"> A Personalised &amp; <br />Engaging Take <br />
                            on Home Cooking</h1>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <h1 data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section1"
                        class="text-white no-wrap-md show-mobile xs-text-center"> A Personalised &amp; <br />Engaging
                        Take <br /> on Home Cooking</h1>
                    <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                        <div class="portfolio-img-wrap"> <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec9-inner2.png"
                                alt="Crubba - Cooking" class="img-fluid crubba-sec9-inner2 lazy" /> <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec9-inner3.png"
                                alt="Crubba - Cooking" class="img-fluid crubba-sec9-inner3 lazy" /> <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec9-inner4.svg"
                                alt="Crubba - Cooking" class="img-fluid crubba-sec9-inner4 lazy" /> <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec9-inner5.svg"
                                alt="Crubba - Cooking" class="img-fluid crubba-sec9-inner5 lazy" /></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs"> <a href="#" class="next">Scroll<div class="c-scrolldown">
                    <div class="c-line"></div>
                </div> </a></div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge bottom-col-section crubba-sec2 scroll-div"
        data-section-name="data-section2" id="section2">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section2" style="background-color: #252525;"></div>
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-sm-12 crubba-sec2-center-outer">
                    <div class="crubba-sec2-center overview-sec-center portfolio-info-wrap2">
                        <h3 class="mt20-lg" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">Overview
                        </h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2"> Mixator AB
                            is a Swedish startup. They started a recipe portal with its unique differentiators - to
                            enable users by providing easy yet tasty recipes matching with their dietary preferences as
                            well as the available time and material. Platform enables users by helping them to plan
                            their weekly menu and recipe plan upfront.</p>
                    </div>
                    <div class="col-sm-12 bottom-col-set" data-aos="fade-in" data-aos-delay="1500"
                        data-aos-anchor="#section2">
                        <div> <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec2-img1.png"
                                alt="Crubba - Overview" class="img-fluid crubba-sec2-img1 lazy" /></div> <img
                            src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec2-img2.png"
                            alt="Crubba - Overview" class="img-fluid crubba-sec2-img2 lazy" /> <img
                            src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec2-img3.png"
                            alt="Crubba - Overview" class="img-fluid crubba-sec2-img3 lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge crubba-sec3-upd scroll-div"
        id="section3" style="overflow: hidden;" data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #1E1E1E;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3"></div>
        <div class="container h-100">
            <div class="row h-100 align-items-center justify-content-end">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3">Challenges</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3"> The existing
                            Crubba website was too basic to appeal to its users and other business stakeholders (like
                            advertising partners and investors). Thus it was failing to hold user attention and make
                            them curious enough to explore and understand the core goal of Crubba's existence.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-right portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3" class="text-white">
                            Solution</h3>
                        <p data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3" class="text-white p2">
                            As part of our UX design strategy, we decided to change content &amp; information
                            architecture (IA) to increase the curiosity of users after analysing their cultural, social
                            and environmental differences &amp; to empower Crubba's business objective. More users will
                            help Crubba to gain new paid advertisements and earn more revenue through successful
                            partnerships.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh crubba-sec4 viewer-section scroll-div"
        data-section-name="data-section4" id="section4" data-aos-anchor="#section4">
        <div class="ani-wrap100-top-2 bg-yellow2" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section4"></div> <img src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec4-bottom1.png"
            alt="Crubba - Simplified Navigation" class="img-fluid crubba-sec4-bottom1 lazy" />
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-sm-12">
                    <div class="crubba-sec4-center portfolio-info-wrap2" data-aos="fade-in" data-aos-delay="500"
                        data-aos-anchor="#section4">
                        <h3 class="text-black2 font-family-sans-serif top-60-lg"> Simplified Navigation</h3>
                    </div>
                </div>
                <div class="col-sm-12 bottom-col-set">
                    <div class="crubba-sec4-bottom clearfix" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section4">
                        <div class="viewer no-select" id="viewer">
                            <img src="assets/images/portfolio/portfolio-website-crubba/crubba-sec4-bottom2.png" alt="Crubba - Simplified Navigation" class="crubba-sec4-bottom-img" draggable="false" id="photo">
                        </div>
                        <!-- <div ngDraggable [bounds]="myBounds" [inBounds]="true" [outOfBounds]="outOfBounds">
                            <img src="assets/images/portfolio/portfolio-website-crubba/crubba-sec4-bottom2.png" alt="Crubba - Simplified Navigation" class="crubba-sec4-bottom-img" draggable="false" id="photo" style="max-height: unset;position: static;">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh crubba-sec6 scroll-div" data-section-name="data-section5"
        id="section5">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section5" style="background-color: #ffd177;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="crubba-sec6-center portfolio-info-wrap2 mt40-lg" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section5">
                        <h3 class="text-white"> User Onboarding</h3>
                        <p class="text-white p2"> Progressive onboarding, to help users specify their dietary
                            preferences and get relevant content suggestions each time when they search on a portal.</p>
                    </div>
                    <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5">
                        <div class="crubba-sec6-bottom">
                            <owl-carousel-o [options]="customOptions" class="owl-caroual-comman">
                                <ng-template carouselSlide>
                                    <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-website-crubba/crubba-user-onboarding1.png" alt="Crubba - User Onboarding" />
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-website-crubba/crubba-user-onboarding2.png" alt="Crubba - User Onboarding" />
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-website-crubba/crubba-user-onboarding3.png" alt="Crubba - User Onboarding" />
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-website-crubba/crubba-user-onboarding4.png" alt="Crubba - User Onboarding" />
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div> <img src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec6-1.png"
                        alt="Crubba - User Onboarding" class="crubba-sec6-1 lazy" />
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh crubba-sec7 scroll-div" data-section-name="data-section6"
        id="section7">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="crubba-sec7-center portfolio-info-wrap2" data-aos="fade-in" data-aos-delay="300"
                        data-aos-anchor="#section7">
                        <h3 class="text-white"> Visual Design</h3>
                        <p class="text-white p2"> To match the design to the taste of European audiences, A/B Testing
                            helped us to decide fresh &amp; clean styling, flow of content and overall visual assets to
                            create a contextual emotional connect.</p>
                    </div>
                    <div data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section7">
                        <div class="crubba-sec7-bottom"> <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec7-bottom1.png"
                                alt="Crubba - Visual Design" class="crubba-sec7-bottom1 lazy" /> <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec7-bottom2.png"
                                alt="Crubba - Visual Design" class="crubba-sec7-bottom2 lazy" />
                            <div class="crubba-sec7-bottom3-wrap"> <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec7-bottom3.png"
                                    alt="Crubba - Visual Design" class="crubba-sec7-bottom3 lazy" />
                                <div class="crubba-sec7-bottom4-hover"></div>
                            </div>
                        </div>
                    </div> <img src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec9-inner3.png"
                        alt="Crubba - Visual Design" class="img-fluid crubba-sec7-img1 lazy" />
                </div>
            </div> <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec6-bottom-before.png"
                alt="Crubba - Visual Design" class="img-fluid crubba-sec6-bottom-before lazy" />
        </div>
    </section>
    <section class="comman-section comman-section-100vh crubba-sec8 scroll-div" data-section-name="data-section7"
        id="section8">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12 col-lg-6 col-sm-6">
                    <div class="crubba-sec3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section8">Inner Pages</h3>
                        <ul class="list-inline comman-card-bottom-ul mt30 no-wrap-md" data-aos="fade-in"
                            data-aos-delay="900" data-aos-anchor="#section8">
                            <li class="list-inline-item h6 text-danger">Search Recipies</li>
                            <li class="list-inline-item h6 text-danger">Recipe Author's Profile</li>
                            <li class="list-inline-item h6 text-danger">Recipe Timeline</li>
                        </ul>
                    </div> <img src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec4-bottom1.png"
                        alt="Crubba - Inner Pages" class="img-fluid crubba-sec4-bottom1 lazy" />
                </div>
                <div class="col-md-12 col-lg-6 col-sm-6"></div>
            </div>
        </div>
        <div class="crubba-sec3-right">
            <div class="crubba-sec3-right4" data-aos="fade-in" data-aos-delay="2500" data-aos-anchor="#section8"> <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec3-right4.png"
                    alt="Crubba - Inner Pages" class="img-fluid lazy" /></div>
            <div class="crubba-sec3-right2" data-aos="fade-in" data-aos-delay="2100" data-aos-anchor="#section8"> <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec3-right2.png"
                    alt="Crubba - Inner Pages" class="img-fluid lazy" /></div>
            <div class="crubba-sec3-right3" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section8"> <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec3-right3.png"
                    alt="Crubba - Inner Pages" class="img-fluid lazy" /></div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh crubba-sec9 scroll-div" data-section-name="data-section9"
        id="section9">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12 col-lg-6 col-sm-6">
                    <div class="crubba-sec3-left portfolio-info-wrap2">
                        <h3 class="no-wrap-md" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section9">
                            Responsive Design</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section9"> For seamless
                            and mobile friendly experience across the new age devices.</p>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-sm-6"></div>
            </div>
        </div>
        <div class="crubba-sec9-img-wrap" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section9"> <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec9-img.png"
                alt="Crubba - Responsive Design" class="img-fluid lazy crubba-sec9-img" /> <span
                class="crubba-slider-mobile"></span></div> <img src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-website-crubba/crubba-sec9-img2.png"
            alt="Crubba - Responsive Design" class="img-fluid lazy crubba-sec9-img2" />
    </section>
    <section class="comman-section comman-section-100vh impact-section-upd scroll-div" id="section-testimonial"
        data-section-name="data-section-testimonial">
        <div class="ani-wrap50-2" style="background-color: #1E1E1E;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section-testimonial"></div>
        <div class="ani-wrap50" style="background-color: #242424;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <div class="moneyguru-sec13-left portfolio-info-wrap2">
                        <ul class="list-inline comman-card-top-ul">
                            <li class="list-inline-item h6 text-white hide-after">Success Metrics</li>
                        </ul>
                        <h3 class="text-white">Impact</h3>
                        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section-testimonial">
                            <ul class="list-inline comman-impact-list-inline">
                                <li class="list-inline-item mr0"> <img src="assets/images/preloader/ajax-loader.png"
                                        data-src="assets/images/svg/impact-star.png" alt="Downloads" class="lazy">
                                    <h3 class="text-white"><span class="counter1">57</span>%</h3>
                                    <p class="text-white">Initial bounce rate reduced</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="comman-impact-bottom crubba-sec13-right ml85" data-aos="fade-in" data-aos-delay="1500"
                        data-aos-anchor="#section-testimonial">
                        <div class="card-img-wrap"> <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/testimonial/placeholder.png" alt="Michael Thysell"
                                class="comman-impact-img lazy" /></div>
                        <h6 class="text-white">Michael Thysell</h6>
                        <h6 class="text-white" style="opacity: 0.5;">CEO, Stockholm, Sweden</h6>
                        <p class="p3 text-white"> <span> <i class="fas fa-quote-left"></i> Prismic Reflections has
                                delivered a new UI design that is stable and intuitive. They're proactive and
                                collaborative, ensuring every one of their designs meets all requirements and
                                constraints. Customers can expect impressive quality with all of their deliverables. <i
                                    class="fas fa-quote-right"></i> </span></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6> <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/website/moneyguru">
                            <span>Moneyguru</span> </a>
                        <p class="p2">Establishing Trust &amp; Delivering the Best Online Investment Experience</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>