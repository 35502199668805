<div class="career-page-body">
    <section class="comman-section comman-section-100vh scroll-div career-sec1 top-section-wrapper" id="section1"
        data-section-name="data-section1">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-7 order-md-last-adj order-xs-last-adj">
                    <div class="comman-card-info">
                        <h1 class="h1 sec-heading hide-mobile" data-aos="fade-in" data-aos-delay="300"
                            data-aos-anchor="#section1"> Enabling &amp; <br /> Unveiling You!</h1>
                        <p class="p1 sec-para" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section1"> We,
                            at Prismic Reflections&reg;, enable you to do your BEST and WIN by giving you an exposure to
                            various challenging opportunities.</p>
                        <div class="hide-mobile" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                            <button class="bttn btn-line btn-primary-custom apply-btn" (click)="first()"><span>Apply Now</span></button>

                            <!-- <a
                            href="#" target="_blank"
                            class="bttn btn-link-custom btn-line reset-btn podcast-all-btn"
                            (click)="second()" ><span>Read More</span></a
                          > -->
                        </div>
                        <div class="show-mobile xs-text-center">
                            <button class="bttn btn-line btn-primary-custom apply-btn" (click)="first()"><span>Apply Now</span></button>
                        </div>

                      
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-5">
                    <h1 class="h1 sec-heading show-mobile xs-text-center" data-aos="fade-in" data-aos-delay="300"
                        data-aos-anchor="#section1"> Enabling &amp; <br class="d-block" /> Unveiling You!</h1>
                    <div class="comman-card-img-wrap-upd hide-mobile"> <img src="assets/images/banner/career-sect-1.png"
                            alt="Enabling &amp; Unveiling You!" class="career-sect-1 lazy" data-aos="fade-down"
                            data-aos-duration="1000" data-aos-delay="1600" data-aos-anchor="#section1"> <img
                            src="assets/images/banner/career-sect-2.svg" alt="Enabling &amp; Unveiling You!"
                            class="career-sect-2 lazy floatingX-r"> <img src="assets/images/banner/career-sect-3.svg"
                            alt="Enabling &amp; Unveiling You!" class="career-sect-3 lazy floatingX-l"> <img
                            src="assets/images/banner/career-sect-4.svg" alt="Enabling &amp; Unveiling You!"
                            class="career-sect-4 lazy" data-aos="fade-in" data-aos-delay="1200"
                            data-aos-anchor="#section1"></div>
                    <div class="comman-card-img-wrap-upd2 show-mobile text-center">
                        <div class="z-index8" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section1"> <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/banner/career-sect-xs-1.png" alt="Enabling &amp; Unveiling You!"
                                class="career-sect-1 lazy img-fluid"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div about-sect2 comman-bg-section" id="section1-2"
        data-section-name="data-section1-2">
        
        <div class="comman-bg-overlay-2" style="background-color: #fff !important;"
            data-aos="comman-bg-overlay-animation" data-aos-delay="300" data-aos-anchor="#section1-2"></div>
            <span class="scrollhere1"></span>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 text-center">
                    <div class="card-img-inner-wrap xs-pt100" data-aos="fade-in" data-aos-delay="100"
                        data-section-name="data-section2" style="max-width: 730px;">
                        <h2 class="text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1-2">
                            It's All About Mindset.
                        </h2>
                        <p class="p2 text-white" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section1-2">
                            Our evolving creative culture that enables everyone in the company to solve the problems &
                            innovate for businesses & people in the society. <br>
                            We believe, Leaders with a Design Mindset can bring a big difference to society.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="comman-section scroll-div about-sec4" id="section4" data-section-name="data-section4">
        <div class="ani-wrap50-3 bg-white" data-aos="new-animation2" data-aos-delay="100" data-aos-anchor="#section4">
        </div>
        <div class="container">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="comman-card-info ml-20-adj">
                        <ul class="list-inline comman-card-top-ul" data-aos="fade-in" data-aos-delay="300"
                            data-aos-anchor="#section4">
                            <li class="list-inline-item h6 text-white hide-after">Our Values</li>
                        </ul>
                        <h2 class="h2" data-aos="fade-in" data-aos-delay="600" data-aos-anchor-placement="top-top"
                            data-aos-anchor="#section4"> Our Sacred <br class="d-block" /> Guiding Principle</h2>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 our-values-wrap-outer about-page-body">
                    <div class="comman-scrollbar-wrap">
                        <div class="our-values-wrap">
                            <ul>
                                <li data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section4">
                                    <div class="process-img-wrapper2"> <img src="assets/images/about/master-fundamental.png" alt="master-fundamental"
                                        class="process-image lazy about-img-adj" /></div>
                                    <div class="our-values-card">
                                        <h5>Master the Fundamentals</h5>
                                        <p class="p2"> Master the fundamentals &amp; principles before going beyond.</p>
                                    </div>
                                </li>
                                <li data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section4">
                                    <div class="process-img-wrapper2"> <img src="assets/images/about/be-valuable.png" alt="be-valuable"
                                        class="process-image lazy about-img-adj" /></div>
                                    <div class="our-values-card">
                                        <h5>Be Valuable</h5>
                                        <p class="p2">Be a valuable resource to humanity &amp; the world.</p>
                                    </div>
                                </li>
                                <li data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section4">
                                    <div class="process-img-wrapper2"> <img src="assets/images/about/respect-trust.png" alt="respect-trust"
                                        class="process-image lazy about-img-adj" /></div>
                                    <div class="our-values-card">
                                        <h5>Respect &amp; Trust</h5>
                                        <p class="p2">We all are ONE, disciplined family.</p>
                                    </div>
                                </li>
                                <li data-aos="fade-in" data-aos-delay="2200" data-aos-anchor="#section4">
                                    <div class="process-img-wrapper2"> <img src="assets/images/about/benifit-people.png" alt="Benefit People"
                                        class="process-image lazy about-img-adj" /></div>
                                    <div class="our-values-card">
                                        <h5>Benefit People</h5>
                                        <p class="p2">Work for the benefit of the masses with a broader perspective.</p>
                                    </div>
                                </li>
                                <li data-aos="fade-in" data-aos-delay="2400" data-aos-anchor="#section4">
                                    <div class="process-img-wrapper2"> <img src="assets/images/about/result-impact.png" alt="result-impact"
                                        class="process-image lazy about-img-adj" /></div>
                                    <div class="our-values-card">
                                        <h5>Results &amp; Impact Matters</h5>
                                        <p class="p2"> Just knowing is not enough, results matter.</p>
                                    </div>
                                </li>
                                <li data-aos="fade-in" data-aos-delay="2600" data-aos-anchor="#section4">
                                    <div class="process-img-wrapper2"> <img src="assets/images/about/ownership.png" alt="ownership"
                                        class="process-image lazy about-img-adj" /></div>
                                    <div class="our-values-card">
                                        <h5>Ownership</h5>
                                        <p class="p2">Accountability &amp; Ownership is the only way.</p>
                                    </div>
                                </li>
                                <li data-aos="fade-in" data-aos-delay="2800" data-aos-anchor="#section4">
                                    <div class="process-img-wrapper2"> <img src="assets/images/about/creative-collaboration.png" alt="Creativity to Collaboration"
                                        class="process-image lazy about-img-adj" /></div>
                                    <div class="our-values-card">
                                        <h5>Creativity to Collaboration</h5>
                                        <p class="p2">Creativity, Innovation, Excellence &amp; Collaboration is our
                                            daily doing.</p>
                                    </div>
                                </li>
                                <li data-aos="fade-in" data-aos-delay="3000" data-aos-anchor="#section4">
                                    <div class="process-img-wrapper2"> <img src="assets/images/about/start-with-you.png" alt="It starts with you"
                                        class="process-image lazy about-img-adj" /></div>
                                    <div class="our-values-card">
                                        <h5>It starts with you!</h5>
                                        <p class="p2">Let the transformation happen, start with yourself first.</p>
                                    </div>
                                </li>
                                <li data-aos="fade-in" data-aos-delay="3200" data-aos-anchor="#section4">
                                    <div class="process-img-wrapper2"> <img src="assets/images/about/share-share.png" alt="Share"
                                        class="process-image lazy about-img-adj" /></div>
                                    <div class="our-values-card">
                                        <h5>Share, Share &amp; Share</h5>
                                        <p class="p2"> Share to start receiving.</p>
                                    </div>
                                </li>
                                <li data-aos="fade-in" data-aos-delay="3400" data-aos-anchor="#section4">
                                    <div class="process-img-wrapper2"> <img src="assets/images/about/ego-to-push-up.png" alt="Ego to Push You Up"
                                        class="process-image lazy about-img-adj" /></div>
                                    <div class="our-values-card">
                                        <h5>Ego to Push You Up!</h5>
                                        <p class="p2">Use your ego to push you &amp; your work, upward</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- wajahat new code added -->
    <section
    class="comman-section comman-section-100vh scroll-div home-sec4"
    id="section4"
    data-section-name="data-section4"
  >
    <div
      class="ani-wrap50-3 bg-white"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section4"
    ></div>
    <div class="container h-100">
      <div
        class="row vh100-100 align-items-center justify-content-center overflow-hidden"
      >
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div
            class="comman-card-info"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section4"
          >
            <ul class="list-inline comman-card-top-ul">
              <li class="list-inline-item h6 text-white hide-after">Clients</li>
            </ul>
            <h2 class="h2">They Are Our Companions</h2>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 client-logo-wrap-outer-col">
          <div class="client-logo-wrap-outer">
            <div class="client-logo-wrap">
              <ul
                data-aos="fade-in"
                data-aos-delay="1300"
                class="clearfix"
                data-aos-anchor="#section4"
              >
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-dspmf-new.png"
                    alt="adhya"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-angel-new.png"
                    alt="afya"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-motilal-new.png"
                    alt="angel"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-icici-new.png"
                    alt="artofliving"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-mse-new.png"
                    alt="authntick"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-jmfinance-new.png"
                    alt="citizen"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-wns.png"
                    alt="credila"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-datamatic-new.png"
                    alt="datamatic"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-indiamart-new.png"
                    alt="dspmf"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-mahindra-new.png"
                    alt="dut"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-pharmadesk-new.png"
                    alt="empire"
                    class="lazy"
                  />
                </li>

                          <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-credila-new.png"
                    alt="Indiamart"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-ardent-new.png"
                    alt="humanity"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-dut-new.png"
                    alt="interview-mocha"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-humanity-new.png"
                    alt="mahindra"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-citizen-new.png"
                    alt="motilal"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-artofliving-new.png"
                    alt="mse"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-blodbnk-new.png"
                    alt="mytrux"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-authntick-new.png"
                    alt="pharmadesk"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-afya-new.png"
                    alt="samaraat"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-adhya-new.png"
                    alt="usa"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-samaraat-new.png"
                    alt="wns"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-interview-mocha-new.png"
                    alt="wns"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-kcoverseas-new.png"
                    alt="wns"
                    class="lazy"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section
  class="comman-section scroll-div home-sec6 pb0"
  id="section6"
  data-section-name="data-section6"
>
  <div class="container overflow-hidden">
    <div
      class="row align-items-center justify-content-center overflow-hidden"
    >
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5">
        <div class="comman-card-info">
          <ul
            class="list-inline comman-card-top-ul"
            data-aos="fade-in"
            data-aos-delay="300"
            data-aos-anchor="#section6"
          >
            <li class="list-inline-item h6 hide-after">
              Awards &amp; Recognitions
            </li>
          </ul>
          <h2
            class="h2"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor-placement="top-top"
            data-aos-anchor="#section6"
          >
            We Are<br />Blessed With..
          </h2>
        </div>
      </div>
      <div
        class="col-sm-12 col-md-12 col-lg-12 col-xl-7 hide-mobile"
        data-aos="fade-in"
        data-aos-delay="1500"
        data-aos-anchor="#section6"
      >
        <div
          class="comman-scrollbar-wrap"
          malihu-scrollbar
          [scrollbarOptions]="scrollbarOptions"
        >
          <div class="award-logo-wrap clearfix">
            <ul class="clearfix mr40">
              <li>
                <div class="award-card st">
                  <h6 class="text-black">AWWWARDS</h6>
                  <p class="p3 text-orange">2021</p>
                  <div class="card-img-wrap">
                    <img
                      src="assets/images/preloader/ajax-loader.png"
                      data-src="assets/images/awards/awwards.png"
                      alt="AWWWARDS"
                      class="img-fluid lazy"
                    />
                  </div>
                  <p class="p4 text-light-dark-c">
                    Nomination for Best Web Presence created for Prismic
                    Reflections on AWWWARDS.com
                  </p>
                </div>
              </li>
              <li>
                <div class="award-card st">
                  <h6 class="text-black">Design India</h6>
                  <p class="p3 text-orange">2020</p>
                  <div class="card-img-wrap">
                    <img
                      src="assets/images/awards/design-india.png"
                      alt="Design India"
                      class="img-fluid lazy"
                    />
                  </div>
                  <p class="p4 text-light-dark-c">
                    IBDA 2020, Special Mention for UX/UI Design of Mutual Fund
                    Investment Platform
                  </p>
                </div>
              </li>
              <li>
                <div class="award-card st">
                  <h6 class="text-black">IDA Design Award</h6>
                  <p class="p3 text-orange">2018</p>
                  <div class="card-img-wrap">
                    <img
                      src="assets/images/awards/ida-award.png"
                      alt="IDA Design Award"
                      class="img-fluid lazy"
                    />
                  </div>
                  <p class="p4 text-light-dark-c">
                    Won the IDA Awards 2018 for designing the UX UI for the
                    Authentic Web Application
                  </p>
                </div>
              </li>
              <li>
                <div class="award-card st">
                  <h6 class="text-black">Clutch Top Designers</h6>
                  <p class="p3 text-orange">2018</p>
                  <div class="card-img-wrap">
                    <img
                      src="assets/images/awards/clutch.png"
                      alt="Clutch Top Designers "
                      class="img-fluid lazy"
                    />
                  </div>
                  <p class="p4 text-light-dark-c">
                    As on 29 january 2018, Clutch as rated us as one among the
                    top design studios in india.
                  </p>
                </div>
              </li>
              <li>
                <div class="award-card st">
                  <h6 class="text-black">Digithon</h6>
                  <p class="p3 text-orange">2015</p>
                  <div class="card-img-wrap">
                    <img
                      src="assets/images/awards/angle-broking.png"
                      alt="Digithon"
                      class="img-fluid lazy"
                      style="display: inline; max-width: 120px"
                    />
                  </div>
                  <p class="p4 text-light-dark-c">
                    Digithon Angel Broking kick-starts a 'first-ever'
                    partner-centric challenge to foster technology innovations
                    won by Prismic Reflections
                  </p>
                </div>
              </li>
              <li>
                <div class="award-card st">
                  <h6 class="text-black">Design Firms</h6>
                  <p class="p3 text-orange">2011</p>
                  <div class="card-img-wrap">
                    <img
                      src="assets/images/awards/designfirms.png"
                      alt="Design Firms"
                      class="img-fluid lazy"
                    />
                  </div>
                  <p class="p4 text-light-dark-c">
                    DesignFirms<sup>(TM)</sup> Web Awards by Design Firm
                    <br />
                    Prismic Reflections has won the February 2011
                    DESIGNFIRMS<sup>(TM)</sup> WEB AWARD
                  </p>
                </div>
              </li>
            </ul>
            <ul class="clearfix mt40">
              <li>
                <div class="award-card st">
                  <h6 class="text-black">Clutch Top Designers</h6>
                  <p class="p3 text-orange">2019</p>
                  <div class="card-img-wrap">
                    <img
                      src="assets/images/preloader/ajax-loader.png"
                      data-src="assets/images/awards/clutch.png"
                      alt="IDA Design Award"
                      class="img-fluid lazy"
                    />
                  </div>
                  <p class="p4 text-light-dark-c">
                    Again in 2019, Clutch has rated us as one among the top
                    design studios in india
                  </p>
                </div>
              </li>
              <li>
                <div class="award-card st">
                  <h6 class="text-black">Silicon India</h6>
                  <p class="p3 text-orange">2018</p>
                  <div class="card-img-wrap">
                    <img
                      src="assets/images/preloader/ajax-loader.png"
                      data-src="assets/images/awards/silicon.png"
                      alt="Silicon India"
                      class="img-fluid lazy"
                    />
                  </div>
                  <p class="p4 text-light-dark-c">
                    Business and Technology magazine has shortlisted us for 20
                    most promising UX/UI design services providers
                  </p>
                </div>
              </li>
              <li>
                <div class="award-card st">
                  <h6 class="text-black">CSSDA Nominee</h6>
                  <p class="p3 text-orange">2012</p>
                  <div class="card-img-wrap">
                    <img
                      src="assets/images/preloader/ajax-loader.png"
                      data-src="assets/images/awards/CSSDA.png"
                      alt="CSSDA Nominee"
                      class="img-fluid lazy"
                    />
                  </div>
                  <p class="p4 text-light-dark-c">
                    Nomination for Web Presence created for Karda
                    Construction, on CSS Design Awards 10 Oct, 2012<br />
                    Role: Concept, Art direction &amp; Designer
                  </p>
                </div>
              </li>
              <li>
                <div class="award-card st">
                  <h6 class="text-black">AWWWARDS</h6>
                  <p class="p3 text-orange">2010</p>
                  <div class="card-img-wrap">
                    <img
                      src="assets/images/preloader/ajax-loader.png"
                      data-src="assets/images/awards/awwards.png"
                      alt="AWWWARDS"
                      class="img-fluid lazy"
                    />
                  </div>
                  <p class="p4 text-light-dark-c">
                    Nomination for Best Web Presence created for Prismic
                    Reflections, on AWWWARDS.com
                  </p>
                </div>
              </li>
              <li>
                <div class="award-card st">
                  <h6 class="text-black">CSSDA Nominee</h6>
                  <p class="p3 text-orange">2010</p>
                  <div class="card-img-wrap">
                    <img
                      src="assets/images/awards/CSSDA.png"
                      alt="CSSDA Nominee"
                      class="img-fluid lazy"
                    />
                  </div>
                  <p class="p4 text-light-dark-c">
                    Nomination for Web Presence for Bridgewater Nigeria
                    LTD,CSS Design Awards 19 Dec, 2010 <br />
                    Role: Concept, Art direction &amp; Designer
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-sm-12 show-mobile">
        <owl-carousel-o
          [options]="customOptionsMob"
          class="awards-carosual"
          id="awards-carosual"
        >
          <ng-template carouselSlide>
            <div class="award-card st">
              <h6 class="text-black">Design India</h6>
              <p class="p3 text-orange">2020</p>
              <div class="card-img-wrap">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/awards/design-india.png"
                  alt="Design India"
                  class="img-fluid lazy"
                />
              </div>
              <p class="p4 text-light-dark-c">
                IBDA 2020, Special Mention for UX/UI Design of Mutual Fund
                Investment Platform
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="award-card st">
              <h6 class="text-black">Clutch Top Designers</h6>
              <p class="p3 text-orange">2019</p>
              <div class="card-img-wrap">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/awards/clutch.png"
                  alt="Clutch Top Designers"
                  class="img-fluid lazy"
                />
              </div>
              <p class="p4 text-light-dark-c">
                Again in 2019, Clutch has rated us as one among the top design
                studios in india
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="award-card st">
              <h6 class="text-black">IDA Design Award</h6>
              <p class="p3 text-orange">2018</p>
              <div class="card-img-wrap">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/awards/ida-award.png"
                  alt="IDA Design Award"
                  class="img-fluid lazy"
                />
              </div>
              <p class="p4 text-light-dark-c">
                Won the IDA Awards 2018 for designing the UX UI for the
                Authentic Web Application
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="award-card st">
              <h6 class="text-black">Clutch Top Designers</h6>
              <p class="p3 text-orange">2018</p>
              <div class="card-img-wrap">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/awards/clutch.png"
                  alt="Clutch Top Designers "
                  class="img-fluid lazy"
                />
              </div>
              <p class="p4 text-light-dark-c">
                As on 29 january 2018, Clutch as rated us as one among the top
                design studios in india.
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="award-card st">
              <h6 class="text-black">Silicon India</h6>
              <p class="p3 text-orange">2018</p>
              <div class="card-img-wrap">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/awards/silicon.png"
                  alt="Silicon India"
                  class="img-fluid lazy"
                />
              </div>
              <p class="p4 text-light-dark-c">
                Business and Technology magazine has shortlisted us for 20
                most promising UX/UI design services providers
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="award-card st">
              <h6 class="text-black">Digithon</h6>
              <p class="p3 text-orange">2015</p>
              <div class="card-img-wrap">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/awards/angle-broking.png"
                  alt="Digithon"
                  class="img-fluid lazy"
                  style="display: inline; max-width: inherit !important"
                />
              </div>
              <p class="p4 text-light-dark-c">
                Digithon Angel Broking kick-starts a 'first-ever'
                partner-centric challenge to foster technology innovations won
                by Prismic Reflections
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="award-card st">
              <h6 class="text-black">CSSDA Nominee</h6>
              <p class="p3 text-orange">2012</p>
              <div class="card-img-wrap">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/awards/CSSDA.png"
                  alt="CSSDA Nominee"
                  class="img-fluid lazy"
                  style="opacity: 1; max-width: 40px"
                />
              </div>
              <p class="p4 text-light-dark-c">
                Nomination for Web Presence created for Karda Construction, on
                CSS Design Awards 10 Oct, 2012<br />
                Role: Concept, Art direction &amp; Designer
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="award-card st">
              <h6 class="text-black">Design Firms</h6>
              <p class="p3 text-orange">2011</p>
              <div class="card-img-wrap">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/awards/designfirms.png"
                  alt="Design Firms"
                  class="img-fluid lazy"
                />
              </div>
              <p class="p4 text-light-dark-c">
                DesignFirms<sup>(TM)</sup> Web Awards by Design Firm <br />
                Prismic Reflections has won the February 2011 DESIGNFIRMS<sup
                  >(TM)</sup
                >
                WEB AWARD
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="award-card st">
              <h6 class="text-black">AWWWARDS</h6>
              <p class="p3 text-orange">2010</p>
              <div class="card-img-wrap">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/awards/awwards.png"
                  alt="AWWWARDS"
                  class="img-fluid lazy"
                />
              </div>
              <p class="p4 text-light-dark-c">
                Nomination for Best Web Presence created for Prismic
                Reflections, on AWWWARDS.com
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="award-card st">
              <h6 class="text-black">CSSDA Nominee</h6>
              <p class="p3 text-orange">2010</p>
              <div class="card-img-wrap">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/awards/CSSDA.png"
                  alt="CSSDA Nominee"
                  class="img-fluid lazy"
                  style="opacity: 1; max-width: 40px"
                />
              </div>
              <p class="p4 text-light-dark-c">
                Nomination for Web Presence for Bridgewater Nigeria LTD,CSS
                Design Awards 19 Dec, 2010 <br />
                Role: Concept, Art direction &amp; Designer
              </p>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
   <!-- wajahat new code end -->

   
   <section class="comman-section scroll-div about-sec6" id="section6" data-section-name="data-section6">
    <div class="container">
        <div class="row vh100-100 align-items-center justify-content-center">
            <div class="col-sm-12 comman-card-img-wrap3-outer">
                <div class="comman-card-img-wrap3">
                    <div id="about-sec6-carousel" data-interval="3000" class="carousel slide carousel-fade"
                        data-ride="carousel" data-aos="fade-in" data-aos-delay="300" data-aos-anchor="#section6"
                        data-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active"> <img src="assets/images/about/gallary-center-1.png"
                                    class="d-block lazy" alt="Gallery photo"></div>
                            <div class="carousel-item"> <img src="assets/images/about/gallary-center-2.png"
                                    class="d-block lazy" alt="Gallery photo"></div>
                            <div class="carousel-item"> <img src="assets/images/about/gallary-center-3.png"
                                    class="d-block lazy" alt="Gallery photo"></div>
                        </div>
                    </div>
                    <div id="about-sec6-carousel2" data-interval="5000" class="carousel slide carousel-fade"
                        data-ride="carousel" data-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active"> <img src="assets/images/about/gallary-top-1.png"
                                    class="d-block lazy" alt="Gallery photo"></div>
                            <div class="carousel-item"> <img src="assets/images/about/gallary-top-2.png"
                                    class="d-block lazy" alt="Gallery photo"></div>
                            <div class="carousel-item"> <img src="assets/images/about/gallary-top-3.png"
                                    class="d-block lazy" alt="Gallery photo"></div>
                        </div>
                    </div>
                    <div id="about-sec6-carousel3" data-interval="3500" class="carousel slide carousel-fade"
                        data-ride="carousel" data-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active"> <img src="assets/images/about/gallary-top-right-1.png"
                                    class="d-block lazy" alt="Gallery photo"></div>
                            <div class="carousel-item"> <img src="assets/images/about/gallary-top-right-2.png"
                                    class="d-block lazy" alt="Gallery photo"></div>
                            <div class="carousel-item"> <img src="assets/images/about/gallary-top-right-3.png"
                                    class="d-block lazy" alt="Gallery photo"></div>
                        </div>
                    </div>
                    <div id="about-sec6-carousel4" data-interval="4000" class="carousel slide carousel-fade"
                        data-ride="carousel" data-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active"> <img src="assets/images/about/gallary-bottom-right-1.png"
                                    class="d-block lazy" alt="Gallery photo"></div>
                            <div class="carousel-item"> <img src="assets/images/about/gallary-bottom-right-2.png"
                                    class="d-block lazy" alt="Gallery photo"></div>
                        </div>
                    </div> <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/about/about-sec6-1.svg"
                        alt="Gallery photo" class="about-sec8-3 lazy hide-mobile">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="comman-section scroll-div about-sec7" id="section7" data-section-name="data-section7">
    <div class="container about-sec7-container">
        <div class="row vh100-100 align-items-center justify-content-center">
            <div class="col-sm-12">
                <div class="comman-card-info" data-aos="fade-in" data-aos-delay="300" data-aos-anchor="#section7">
                    <h2>Our Design Force!</h2>
                  
                </div>
           
        <div>
            <div class="lg-mt-20 about-team-row">
                <div class="row">
                    <div class="col-md-12">
                        
                 
                        <owl-carousel-o [options]="customOptions3" class="owl-caroual-comman-fade">
                          
                          <ng-template carouselSlide>
                            <div class="teams-wrap text-center">
                           <img src="assets/images/teams/leader-team-rohit.png" alt="Rohit S."
                            class="img-fluid" />
                            <h6 class="mt-3 font-weight-bold">Rohit S.</h6>
                            <p>Sr. Lead Visual Designer</p>
                            </div>
                    </ng-template>

                    <ng-template carouselSlide>
                      <div class="teams-wrap text-center">
                     <img src="assets/images/teams/leader-team-akhil.png" alt="Akhil P."
                      class="img-fluid" />
                      <h6 class="mt-3 font-weight-bold">Akhil P.</h6>
                      <p>Sr. Business Development Manager</p>
                      </div>
              </ng-template>


                        <ng-template carouselSlide>
                            <div class="teams-wrap text-center">
                           <img src="assets/images/teams/priyanka.png" alt="Priyanka P."
                            class="img-fluid" />
                            <h6 class="mt-3 font-weight-bold">Priyanka P.</h6>
                            <p>Sr. Visual Designer</p>
                            </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="teams-wrap text-center">
                       <img src="assets/images/teams/vishakha-agrawal.png" alt="Vishakha A."
                        class="img-fluid" />
                        <h6 class="mt-3 font-weight-bold">Vishakha A.</h6>
                        <p>UX UI Designer</p>
                        </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="teams-wrap text-center">
                   <img src="assets/images/teams/shankar.png" alt="Sanskar M."
                    class="img-fluid" />
                    <h6 class="mt-3 font-weight-bold">Sanskar M.</h6>
                    <p>UI & Graphic Designer</p>
                    </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="teams-wrap text-center">
               <img src="assets/images/teams/meet.png" alt="Meet G."
                class="img-fluid" />
                <h6 class="mt-3 font-weight-bold">Meet G.</h6>
                <p>UX UI Designer</p>
                </div>
        </ng-template>

        <ng-template carouselSlide>
            <div class="teams-wrap text-center">
           <img src="assets/images/teams/raj.png" alt="Raj V."
            class="img-fluid" />
            <h6 class="mt-3 font-weight-bold">Raj V.</h6>
            <p>UX UI Designer</p>
            </div>
    </ng-template>

    <ng-template carouselSlide>
        <div class="teams-wrap text-center">
       <img src="assets/images/teams/vishakha.png" alt="Vishakha T."
        class="img-fluid" />
        <h6 class="mt-3 font-weight-bold">Vishakha T.</h6>
        <p>UX UI Designer</p>
        </div>
</ng-template>
<ng-template carouselSlide>
    <div class="teams-wrap text-center">
   <img src="assets/images/teams/dhananjay.png" alt="Dhananjay M."
    class="img-fluid" />
    <h6 class="mt-3 font-weight-bold">Dhananjay M.</h6>
    <p>UX UI Designer</p>
    </div>
</ng-template>
<ng-template carouselSlide>
<div class="teams-wrap text-center">
<img src="assets/images/teams/sukriti.png" alt="Sukriti S."
class="img-fluid" />
<h6 class="mt-3 font-weight-bold">Sukriti S.</h6>
<p>UX UI Designer</p>
</div>
</ng-template>
<ng-template carouselSlide>
<div class="teams-wrap text-center">
<img src="assets/images/teams/dipti.png" alt="Dipti G."
class="img-fluid" />
<h6 class="mt-3 font-weight-bold">Dipti G.</h6>
<p>UX UI Designer</p>
</div>
</ng-template>

<ng-template carouselSlide>
<div class="teams-wrap text-center">
<img src="assets/images/teams/kishan.png" alt="kishan-M."
class="img-fluid" />
<h6 class="mt-3 font-weight-bold">Kishan M.</h6>
<p>UX UI Designer</p>
</div>
</ng-template>
<ng-template carouselSlide>
  <div class="teams-wrap text-center">
 <img src="assets/images/teams/jyoti.png" alt="Jyoti A."
  class="img-fluid" />
  <h6 class="mt-3 font-weight-bold">Jyoti A.</h6>
  <p>UX Designer</p>
  </div>
</ng-template>
<ng-template carouselSlide>
  <div class="teams-wrap text-center">
 <img src="assets/images/teams/wajahat.png" alt="Wajahat S."
  class="img-fluid" />
  <h6 class="mt-3 font-weight-bold">Wajahat S.</h6>
  <p>UI Develoer</p>
  </div>
</ng-template>
                        
                        </owl-carousel-o> 
                 
                    </div>
                </div>
            </div>
            <div class="comman-card-info" data-aos="fade-in" data-aos-delay="300" data-aos-anchor="#section7">
              <div class="hide-mobile" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                <button class="bttn btn-line btn-primary-custom apply-btn" (click)="first()"><span>Apply Now</span></button>
            </div>
            <div class="show-mobile xs-text-center">
                <button class="bttn btn-line btn-primary-custom apply-btn" (click)="first()"><span>Apply Now</span></button>
            </div>
            
            
            </div>
        </div>
      </div>
    </div>
    </div>

</section>
    
    <section class="comman-section career-details-sec2 career-details-sec1 scroll-div" id="section2" data-section-name="data-section2">
        <div class="container" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2">
            <span class="scrollhere"></span>
            <div class="row vh100-100 align-items-center justify-content-center">
              <div class="col-12">
            <form action="post" class="career-form-wrap" id="careerForm" (ngSubmit)="submitContact(frm)" name="careerForm" #frm=ngForm novalidate>
                <div class="row">
                    <div class="col-md-6 form-control-col pr50">
                        <div class="show-mobile mb35">
                            <h4>Apply</h4>
                        </div>
                        <div class="pr-form-control h6 input-effect select2-wrap">
                            <select (blur)="validateApplyFor(applyfor.value)" (change)="validateApplyFor(applyfor.value)" class="input-control-wrap has-content select2" #applyfor="ngModel" [class.error]="validateApplyHasError && applyfor.touched" id="applyingfor" name="applyingfor" style="background: transparent url('assets/images/svg/down-arrow-select.svg') no-repeat center right 15px;" [(ngModel)]="property" required>
                                <option disabled ngValue="default">Select an Option</option>
                                <option *ngFor="let fon of foundOn; let i = index;">{{fon}}</option>
                            </select>
                            <label *ngIf="validateApplyHasError && applyfor.touched" id="message-error" class="error" for="message">Please select an option</label>
                            <label *ngIf="showErrors && applyfor.untouched && applyfor.pristine" class="error">Please select from given options</label>
                            <label class="form-label h6" for="applyingfor">Applying for</label>
                        </div>                        
                    </div>
                    <div class="col-md-6 form-control-col pl50">
                        <div class="pr-form-control h6 input-effect">
                            <input #fname="ngModel" class="input-control-wrap h6" type="text" placeholder="" id="career_name" name="career_name" [(ngModel)]="careName" required>
                            <label class="form-label h6" for="career_name">My Name is</label>
                            <label *ngIf="fname.invalid && fname.touched" id="full_name-error" class="error" for="full_name">Please enter your full name</label>
                            <label *ngIf="showErrors && fname.untouched && fname.pristine" class="error">This field cannot be empty</label>
                            <span class="focus-border"></span>
                        </div>
                    </div>
                    <div class="col-md-6 form-control-col pr50">
                        <div class="pr-form-control h6 input-effect">
                            <input #caremail="ngModel" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" class="input-control-wrap h6" type="email" placeholder="" id="career_email" name="career_email" [(ngModel)]="careEmail" required>
                            <label class="form-label h6" for="career_email">My Email</label>
                            <label *ngIf="caremail.invalid && caremail.touched && caremail.errors?.required" id="email-error" class="error" for="career_email">Please enter your valid email address</label>
                            <label *ngIf="caremail.invalid && caremail.touched && caremail.errors?.pattern" id="email-error" class="error" for="career_email">This is not a valid email address</label>
                            <label *ngIf="showErrors && caremail.untouched && caremail.pristine" class="error">This field cannot be empty</label>
                            <span class="focus-border"></span>
                        </div>
                    </div>
                    <div class="col-md-6 form-control-col pl50">
                        <div class="pr-form-control h6 input-effect">
                            <input #carmob="ngModel" class="input-control-wrap h6" type="number" maxlength="10" minlength="10" placeholder="" id="career_mobile_no" name="career_mobile_no" [(ngModel)]="carePhone" required>
                            <label class="form-label h6" for="career_mobile_no">My Number is</label>
                            <label *ngIf="carmob.invalid && carmob.touched" id="mobile_no-error" class="error" for="career_mobile_no">Please enter your mobile number without dashes</label>
                            <label *ngIf="showErrors && carmob.untouched && carmob.pristine" class="error">This field cannot be empty</label>
                            <span class="focus-border"></span>
                        </div>
                    </div>
                    <div class="col-md-6 form-control-col pr50">
                        <div class="pr-form-control h6 input-effect">
                            <input #carexp="ngModel" class="input-control-wrap h6" type="text" placeholder="" id="career_experience" name="career_experience" [(ngModel)]="careExp" required>
                            <label class="form-label h6" for="career_experience">My Experience is (Year &amp; Month)</label>
                            <label *ngIf="carexp.invalid && carexp.touched" id="experience-error" class="error" for="career_experience">Please enter your work experience</label>
                            <label *ngIf="showErrors && carexp.untouched && carexp.pristine" class="error">This field cannot be empty</label>
                            <span class="focus-border"></span>
                        </div>
                    </div>
                    <div class="col-md-6 form-control-col pl50">
                        <div class="pr-form-control h6 input-effect">
                            <input #carsts="ngModel" class="input-control-wrap h6" type="text" placeholder="" id="current_status" name="career_current_status" [(ngModel)]="careCurrSts" required>
                            <label class="form-label h6" for="current_status">Current Status</label>
                            <label *ngIf="carsts.invalid && carsts.touched" id="current_status-error" class="error" for="current_status">Please enter your current status</label>
                            <label *ngIf="showErrors && carsts.untouched && carsts.pristine" class="error">This field cannot be empty</label>
                            <span class="focus-border"></span>
                        </div>
                    </div>
                    <div class="col-md-6 form-control-col pr50">
                        <div class="pr-form-control h6 input-effect">
                            <textarea #carabt="ngModel" name="career_journey" id="career_journey" rows="2" class="input-control-wrap h6" [(ngModel)]="careAbt" required></textarea>
                            <label class="form-label h6" for="career_journey">About Me &amp; My Journey so far</label>
                            <label *ngIf="carabt.invalid && carabt.touched" id="career_journey-error" class="error" for="career_journey">Please enter your career journey</label>
                            <label *ngIf="showErrors && carabt.untouched && carabt.pristine" class="error">This field cannot be empty</label>
                            <span class="focus-border"></span>
                        </div>
                    </div>
                    <div class="col-md-6 form-control-col pl50">
                        <div class="pr-form-control h6 input-effect">
                            <textarea #carportf="ngModel" name="career_portfolio" id="career_portfolio" rows="2" class="input-control-wrap h6" [(ngModel)]="carePort" required></textarea>
                            <label class="form-label h6" for="career_portfolio">Portfolio Links</label>
                            <label *ngIf="carportf.invalid && carportf.touched" id="career_portfolio-error" class="error" for="career_portfolio">Please enter your career portfolio</label>
                            <label *ngIf="showErrors && carportf.untouched && carportf.pristine" class="error">This field cannot be empty</label>
                            <span class="focus-border"></span>
                        </div>
                    </div>
                    <div class="col-md-6 form-control-col pr50 g-capcha-col-wrap">
                        <!-- <div >
                            <input type="hidden" class="hiddenRecaptcha" name="hiddenRecaptcha" id="hiddenRecaptcha">
                            <div class="g-recaptcha" data-sitekey="6Lfzug4TAAAAANC_OHvvSmrIoCtnHrAmMvk3EXtm"></div>
                        </div> -->
                        <div class="pr-form-control h6 input-effect xs-mb20" [formGroup]="aFormGroup">
                            <ngx-recaptcha2 #captchaElem [siteKey]="'6Lfzug4TAAAAANC_OHvvSmrIoCtnHrAmMvk3EXtm'"
                                (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="size" [hl]="lang" [theme]="theme" formControlName="recaptcha" 
                                [type]="type">
                            </ngx-recaptcha2>
                            <label *ngIf="showErrors && captchaHasError" class="error">Please verify the captcha</label>
                        </div>
                    </div>
                    <div class="col-md-6 form-control-col pl50 term-col-wrap">
                        <div class="custom-checkbox">
                            <input #prvcyaccpt2="ngModel" type="checkbox" id="i_agree" name="i_agree" value="i_agree" required="" aria-required="true" [(ngModel)]="carePolicy" required>
                            <label for="i_agree" class="label-checkbox p5" tabindex="10"><span class="checkmark"></span>I have read and understood all terms and conditions of the <a routerLink="/privacy-term" class="a-link-black" target="_blank" style="font-size: inherit;">Terms of Use and Privacy Policy</a> and hereby provide my specific consent to control/process the information submitted by me.</label>
                            <label *ngIf="prvcyaccpt2.invalid && prvcyaccpt2.touched" id="i_agree-error" class="error" for="i_agree">Please agree Terms of use and privacy policy</label>
                            <label *ngIf="showErrors && prvcyaccpt2.untouched && prvcyaccpt2.pristine" class="error">Please tick the box</label>
                        </div>
                    </div>
                    <div class="col-md-12 form-control-col-btn form-control-col-btn2" id="form-submit-btn-wrap">
                        <button class="bttn btn-line btn-primary-custom xs-mr20" type="submit" id="btn_career"><span>Submit</span></button>
                        <button class="bttn btn-line btn-primary-custom" type="submit" id="btn_test" hidden><span>Submit</span></button>
                        <button class="bttn btn-link-custom btn-line reset-btn" id="btn_reset" type="reset" (click)="frm.resetForm({})"><span>Reset</span></button>
                    </div>
                </div>
            </form>
          </div>
        </div>
        </div>
    </section>
    <section class="comman-section contact-section1 top-section-wrapper comman-section-100vh scroll-div" id="section3"
        data-section-name="data-section3">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <div data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3">
                                <h2 class="text-center mb35">Our Presence</h2>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 xs-text-center">
                            <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">
                                <div class="addr-wrap">
                                    <div class="addr-img-wrap"> <img src="assets/images/preloader/ajax-loader.png"
                                            data-src="assets/images/svg/location-nashik.svg" alt="Location Nashik"
                                            class="addr-location lazy" /></div>
                                    <div class="addr-indo-wrap">
                                        <h5>Nashik</h5>
                                        <p class="p3 text-light-dark-c">IT/21, Jyoti Telecom, Near AIMA Recreation Hall,
                                            Ambad MIDC,<br /> Nashik, Maharashtra 422010</p>
                                        <ul class="list-inline addr-list-inline">
                                            <li class="list-inline-item"> <a class="p3 text-light-dark-c"
                                                    href="tel:02532386721">0253-238 6721</a></li>
                                            <li class="list-inline-item"> <a class="p3 text-light-dark-c"
                                                    href="tel:+917887888729">+91 788 788 8729</a></li>
                                        </ul>
                                         <!-- <a class="bttn btn-line btn-link-custom"
                                            href="https://goo.gl/maps/DgwRhrSisSnPooJ68" target="_blank"><img
                                                alt="Location" class="location-icon lazy"
                                                src="assets/images/svg/location-icon.svg"><span>View on Map</span></a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 addr-wrap-col-adj xs-text-center">
                            <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section3">
                                <div class="addr-wrap">
                                    <div class="addr-img-wrap"> <img src="assets/images/preloader/ajax-loader.png"
                                            data-src="assets/images/svg/location-mumbai.svg" alt="Location Mumbai"
                                            class="addr-location lazy"></div>
                                    <div class="addr-indo-wrap">
                                        <h5>Mumbai</h5>
                                        <p class="p3 text-light-dark-c">202, 2nd floor, Ackruti Star, MIDC, Central
                                            Road, Andheri (East),<br> Mumbai, Maharashtra 400093</p>
                                        <ul class="list-inline addr-list-inline">
                                            <li class="list-inline-item"> <a class="p3 text-light-dark-c"
                                                    href="tel:+919881266721">+91 98812 66721</a></li>
                                            <li class="list-inline-item"> <a class="p3 text-light-dark-c"
                                                    href="tel:+919823391221">+91 98233 91221</a></li>
                                        </ul>
                                         <!-- <a class="bttn btn-line btn-link-custom"
                                            href="https://goo.gl/maps/2dzuxzJXMiDheqb76" target="_blank"><img
                                                alt="Location" class="location-icon lazy"
                                                src="assets/images/svg/location-icon.svg"><span>View on Map</span></a> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                          <!-- Wajahat code add for pune location  -->
                          <div class="col-sm-12 col-md-12 col-lg-4 xs-text-center">
               <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                <div class="addr-wrap">
                  <div class="addr-img-wrap"><img src="assets/images/preloader/ajax-loader.png"
                                                  data-src="assets/images/svg/pune.svg" alt="Location Nashik"
                                                  class="addr-location lazy"/></div>
                  <div class="addr-indo-wrap">
                    <h5>Pune</h5>
                    <p class="p3 text-light-dark-c">501/502 Fifth floor,<br/>
                      Mariegold Building,<br/> Viman Nagar, Pune-411014</p>
                      <p class="p3 text-light-dark-c">+91 98812 66721</p>
                    
                  </div>
                </div>
              </div>
            </div>
                <!-- wajahat code end for pune location -->
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs"> <a href="#" class="next">Scroll<div class="c-scrolldown">
                    <div class="c-line"></div>
                </div> </a></div>
    </section>
</div>