<div class="portfolio-branding-notion-edge-body">
    <section class="comman-section comman-section-100vh scroll-div notion-edge-sec1" id="section1"
        data-section-name="data-section1">
        <div class="ani-wrap100-top bg-blue12" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section1"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 text-center">
                    <div class="notion-edge-left-wrap xs-mt55">
                        <div class="notion-edge-left-inner-wrap">
                            <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-notion-edge/notion-edge-logo-1.svg"
                                    alt="Notion Edge - Logo" class="smartlinks-logo lazy img-fluid mb30" />
                            </div>
                            <h1 class="text-white position-relative z-index5" data-aos="fade-in" data-aos-delay="1200"
                                data-aos-anchor="#section1">
                                Brand Identity for<br class="d-block" />
                                SAP Consulting Company
                            </h1>
                        </div>
                        <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section1">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-branding-notion-edge/notion-edge-logo-bg.svg"
                                alt="Notion Edge - Logo" class="notion-edge-logo-bg lazy" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">
                Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div smartlinks-sect2" id="section2"
        data-section-name="data-section2">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="smartlinks-sec2-center overview-sec-center portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2">Overview</h3>
                        <p class="p2 mb10" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                            Specialising in SAP implementation &amp; products, France based software consultancy Notion
                            Edge, wanted to reimagine their visual identity design that clearly reflects the company's
                            SAP expertise &amp;
                            experience led approach in solutioning.
                        </p>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                            In the strategic discovery &amp; discussions we had with the co-founders and other
                            stakeholders, the design team decided to focus on brand key attributes - Simplicity, Clever,
                            Competitive edge through unique
                            ideas/ approach.
                        </p>
                    </div>
                    <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-notion-edge/smartlinks-logo-after2.svg"
                            alt="Notion Edge - Logo" class="smartlinks-logo-after2 lazy img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge scroll-div smartlinks-sect3 xs-pt0"
        id="section3" data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #181e24;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3">Challenges</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">
                            The challenge was to enter into an extremely crowded software consultancy market with unique
                            positioning that focuses on SAP products &amp; their implementations. Client approached
                            Prismic Reflections® to
                            redesign their brand identity aiming towards the international SAP market in Europe &amp;
                            America.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-right portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3" class="text-white">
                            Creative Brief</h3>
                        <p class="p2 text-white" data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3">
                            Focusing mainly on European market primarily, a new identity design has to appeal to the mid
                            &amp; large scale size companies, specific audience in between the age group of 35-40,
                            people from Sales, Service
                            or IT companies, who used to deal with big vendors. Having a mission to be recognised as a
                            leading SAP consulting partner, an identity is expected to convey simplicity, easy to
                            approach &amp; a nimble team
                            with a modern &amp; fresh approach.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div smartlinks-sect4 pb0" id="section4"
        data-section-name="data-section4">
        <div class="ani-wrap50-2" style="background-color: #37bbfe;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section4"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="smartlinks-sec4-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section4">Brainstorming</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4">
                            During the stakeholder research and internal brainstorming session, we decided to focus on
                            the concept of - unique idea (notion) and first letter of name i.e. N. Three (3) simple dots
                            represents the thinking
                            process/ideation (also a hidden E) &amp; smooth curvy letter N represents the ease in
                            dealing and simplicity.
                        </p>
                        <div class="concept-wrap" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section4">
                            <h5>Key Attributes</h5>
                            <p class="p2" style="color: #117bed !important;">Ideation/Thinking + Ease + Simplicity +
                                Fresh</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12"></div>
            </div>
        </div>
        <div class="smartlinks-sec4-right" data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section4">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-branding-notion-edge/notion-edge-idea.svg"
                alt="Notion Edge - Overview" class="img-fluid smartlinks-sect4-1 lazy" />
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div smartlinks-sec4" id="section5"
        data-section-name="data-section5">
        <div class="ani-wrap100-top" style="background-color: #2895ff;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section5"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6">
                    <div class="bagshaha-sec6-left portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section5">Logo
                            Mark Construction</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5">
                            A unique logo mark, built with golden proportions circle scale with highest legibility &amp;
                            memorability in smallest size.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section5"
                        class="bagshaha-sec9-2-outer">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-notion-edge/notion-edge-logo-mark.svg"
                            alt="Notion Edge - Logo Mark" class="smartlinks-sec4-1 img-fluid lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div notion-edge-sec8 no-padding comman-comparison"
        id="section6" data-section-name="data-section6">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section6" style="background-color: #2895ff;"></div>
        <div id="comparison" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section6">
            <figure>
                <div id="handle"></div>
                <div id="divisor"></div>
            </figure>
            <input type="range" min="0" max="100" value="50" id="slider" (input)="nedivisorChanged($event.target.value)"/>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div comman-bg-section notion-edge-sec9" id="section7"
        data-section-name="data-section7">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section7" style="background-color: #14181c;"></div>
        <div class="container h-100"></div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div comman-bg-section notion-edge-sec10" id="section8"
        data-section-name="data-section8">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section8" style="background-color: #2c353e;"></div>
        <div class="container h-100"></div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/branding/phychem"> <span>Phychem Technologies</span>
                        </a>
                        <p class="p2">Reimagining the Face of Manufacturing Brand</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
