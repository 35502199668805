       <!--start popup modal for video -->
       <div class="modal fade pr-video-modal" id="pr-video-modal" tabindex="-1" aria-labelledby="pr-video-modalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
   
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
            
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="w-100 h-100" src="https://www.youtube.com/embed/1mxLRlyhsn0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                </div>
            </div>
            
        </div>
      </div>
      <!-- end popup modal for video -->

 <!--start popup modal for CEO Video -->
 <div class="modal fade pr-ceo-video-modal" id="pr-ceo-video-modal" tabindex="-1" aria-labelledby="pr-ceo-video-modalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
   
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
        
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/8yHpqNek0NM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
            </div>
        </div>
        
    </div>
  </div>
  <!-- end popup modal for for CEO Video -->


 

<div class="comman-header-wrap">
    <div class="loader-api">
        <div class="loading-api"><img src="assets/images/svg/page-loader.gif" alt="Prismic Reflections" class="fa-pulse-ani" />
        </div>
    </div>
    <header class="comman-header" id="common-header" [ngClass]="hasRoute('our-work') ? 'work-header' : 'no-work-header'">
        <div class="container">
            <div class="row align-items-center header-navigation">
                <div class="col-md-3 col-6">                    
                    <ng-template [ngIf]="hasRoute('portfolio')">
                        <a class="logo-wrap-our-team"  [routerLink]="['/our-work']">
                            <p class="company-name-mobile bttn btn-link-custom btn-line st no-wrap no-margin">
                                <img src="assets/images/svg/left-arrow-icon.svg" alt="Location" class="left-arrow-icon"
                                    style="width: 20px;"><span>Back to Work</span>
                            </p>
                        </a>
                    </ng-template>
                    <ng-template [ngIf]="!hasRoute('portfolio')">
                        <a class="logo-wrap-our-team"  [routerLink]="['/']">
                            <p class="company-name-mobile bttn btn-link-custom btn-line st no-wrap no-margin">
                                <span>Prismic Reflections&reg;</span>
                            </p>
                        </a>
                    </ng-template>
                    <ng-template [ngIf]="hasRoute('our-work')">
                        <a class="logo-wrap-our-team"  [routerLink]="['/']">
                            <p class="company-name-mobile bttn btn-link-custom btn-line st no-wrap no-margin">
                                <span>Prismic Reflections&reg;</span>
                            </p>
                        </a>
                    </ng-template>
                    <a class="logo-wrap-a"  [routerLink]="['/']">
                        <svg width="43" height="43" class="logo-img" viewBox="0 0 46 45" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <!-- <path class="st"
                                d="M44.6594 31.0602C42.6943 27.4825 38.7775 23.4733 36.1785 18.7568C35.4661 17.4566 34.8465 16.0976 34.2927 14.7395C24.371 16.4226 9.07558 20.923 0.418609 33.06C-0.358963 35.4236 0.0408645 37.2947 0.849143 39.1424C2.0217 41.8296 4.25893 44.2308 8.52222 44.2308C12.485 44.2308 16.4614 41.7358 22.9448 41.7358C28.9612 41.7358 33.4518 44.2308 37.4314 44.2308C41.3556 44.2308 43.4675 42.0926 44.8003 39.7623C46.1649 37.3613 46.672 34.7374 44.6594 31.0602ZM30.2031 4.73134C28.1903 1.06852 25.5375 -0.044238 22.8813 0.0013343C20.2981 0.0398274 17.7159 1.15148 15.749 4.73134C13.7143 8.43751 12.3497 14.0557 9.66323 18.941C8.66356 20.7728 7.45924 22.4789 6.25281 24.1066C10.7872 19.2901 18.5867 12.8215 31.2331 6.87412C30.9012 6.1078 30.5676 5.38616 30.2031 4.73134Z"
                                fill="#242424" />  original -->
                                <path d="M41.9521 31.1393C40.2329 27.8848 36.8059 24.2364 34.5317 19.944C33.9079 18.7595 33.3645 17.5233 32.8815 16.287C24.199 17.8194 10.8159 21.9163 3.24025 32.9592C2.56175 35.1097 2.90962 36.8145 3.61687 38.4935C4.64325 40.9402 6.60112 43.1252 10.3329 43.1252C13.8001 43.1252 17.2789 40.8539 22.9512 40.8539C28.2154 40.8539 32.1455 43.1252 35.6271 43.1252C39.0599 43.1252 40.9085 41.1788 42.0757 39.0599C43.2689 36.8749 43.7145 34.4858 41.9521 31.1393ZM29.3021 7.18192C27.5426 3.84692 25.2196 2.83492 22.8966 2.87517C20.6369 2.91254 18.3771 3.92167 16.655 7.18192C14.8754 10.5543 13.6822 15.666 11.3305 20.1108C10.4565 21.7783 9.40137 23.3308 8.34625 24.8114C12.3137 20.4299 19.139 14.5419 30.2049 9.13117C29.9145 8.43255 29.6212 7.77704 29.3021 7.18192Z" fill="#FF5500"/>

                        </svg>
                   
                    </a>
                </div>
                <div class="col-md-9 col-6 text-right">
                    <ul class="list-inline comman-header-menu-ul comman-header-menu-right">
                        <li class="list-inline-item">
                            <a class="bttn btn-link-custom btn-line st hide-mobile bttn1" routerLink="/our-work"><span>Our Work</span></a>
                        </li>
                        <li class="list-inline-item">
                            <a class="bttn btn-link-custom btn-line st hide-mobile" routerLink="/contact"><span>Contact Us</span></a>
                        </li>
                        <li class="list-inline-item" (click)="ocl2($event)">
                            <a class="mega-menu mega-menu-open" [ngClass]="element1 ? 'active-class' : ''">
                                <svg width="25" height="10" viewBox="0 0 25 10" fill="none" class="mega-menu-img1 st"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H25V2H0V0Z" fill="#242424" class="st" />
                                    <path d="M0 8H19V10H0V8Z" fill="#242424" class="st" />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
    <section class="comman-top-menu" [ngClass]="element1 ? 'active-class' : ''">
        <div class="comman-header-inner">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <div class="container-fluid mw-1470">
                        <div class="row align-items-center">
                            <div class="col-3">
                                <a class="logo-wrap-a" [routerLink]="['/']" (click)="ocl($event)">
                                    
                                    <div class="logo-img">
                                        <svg width="46" height="45" viewBox="0 0 46 45" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M44.6594 31.0602C42.6943 27.4825 38.7775 23.4733 36.1785 18.7568C35.4661 17.4566 34.8465 16.0976 34.2927 14.7395C24.371 16.4226 9.07558 20.923 0.418609 33.06C-0.358963 35.4236 0.0408645 37.2947 0.849143 39.1424C2.0217 41.8296 4.25893 44.2308 8.52222 44.2308C12.485 44.2308 16.4614 41.7358 22.9448 41.7358C28.9612 41.7358 33.4518 44.2308 37.4314 44.2308C41.3556 44.2308 43.4675 42.0926 44.8003 39.7623C46.1649 37.3613 46.672 34.7374 44.6594 31.0602ZM30.2031 4.73134C28.1903 1.06852 25.5375 -0.044238 22.8813 0.0013343C20.2981 0.0398274 17.7159 1.15148 15.749 4.73134C13.7143 8.43751 12.3497 14.0557 9.66323 18.941C8.66356 20.7728 7.45924 22.4789 6.25281 24.1066C10.7872 19.2901 18.5867 12.8215 31.2331 6.87412C30.9012 6.1078 30.5676 5.38616 30.2031 4.73134Z"
                                                fill="#242424" />
                                        </svg>
                                    </div>
                                </a>
                            </div>
                            <div class="col-9 text-right">
                                <ul class="list-inline comman-header-menu-ul comman-header-menu-right">
                                    <li class="list-inline-item">
                                        <a class="mega-menu mega-menu-close">
                                            <!-- <img src="images/svg/mega-menu-close.svg" alt="Mega Menu" class="mega-menu-img lazy" /> -->
                                            <svg class="mega-menu-img2 st" width="16" height="16" viewBox="0 0 16 16"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path class="st"
                                                    d="M1.00024 13.4351L14.4353 2.99811e-05L15.8495 1.41424L2.41446 14.8493L1.00024 13.4351Z"
                                                    fill="#242424" />
                                                <path class="st"
                                                    d="M2.00024 0.435059L15.4353 13.8701L14.0211 15.2843L0.586031 1.84927L2.00024 0.435059Z"
                                                    fill="#242424" />
                                            </svg>

                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="container mw-1000">
                        <div class="row header-row-mobile">
                            <div class="col-md-6">
                                <ul class="top-menu-left">
                                    <li (click)="ocl($event)"><a [routerLink]="['/our-work']"
                                            class="btn-line h2"><span>Work</span></a></li>
                                    <li (click)="ocl($event)"><a [routerLink]="['/services/ux-ui-design-process']"
                                            class="btn-line h2"><span>Process</span></a></li>
                                    <li (click)="ocl($event)"><a [routerLink]="['/home']" fragment="data-section3-home"
                                            id="btn-service-nav" class="btn-line h2"><span>Services</span></a>
                                    </li>
                                    <li (click)="ocl($event)"><a [routerLink]="['/about']" 
                                        class="btn-line h2"><span>About</span></a></li>
                                </ul>
                                <ul class="top-menu-right show-mobile mt20">
                                    <li (click)="ocl($event)"><a [routerLink]="['/industries']" 
                                            class="btn-line h6"><span>Industries</span></a></li>
                                    <li (click)="ocl($event)"><a [routerLink]="['/design-experiments']" 
                                            class="btn-line h6"><span>Design Experiments</span></a></li>
                                    <li (click)="ocl($event)"><a [routerLink]="['/career']" 
                                            class="btn-line h6"><span>Career</span></a></li>
                                    <li (click)="ocl($event)"><a href="https://www.prismicreflections.com/blog/" 
                                            class="btn-line h6"><span>Blog</span></a></li>
                                    <li (click)="ocl($event)"><a [routerLink]="['/contact']" 
                                            class="btn-line h6"><span>Contact</span></a></li>
                                </ul>
                            </div>
                            <div class="col-md-6 hide-mobile">
                                <ul class="top-menu-right">
                                    <li (click)="ocl($event)"><a [routerLink]="['/industries']"  
                                            class="btn-line h6"><span>Industries</span></a></li>
                                    <li (click)="ocl($event)"><a [routerLink]="['/design-experiments']"  
                                            class="btn-line h6"><span>Design Experiments</span></a></li>
                                    <li (click)="ocl($event)"><a [routerLink]="['/career']"  
                                            class="btn-line h6"><span>Career</span></a></li>
                                    <li (click)="ocl($event)"><a href="https://www.prismicreflections.com/blog/" 
                                            class="btn-line h6"><span>Blog</span></a></li>
                                    <li (click)="ocl($event)"><a [routerLink]="['/contact']"  
                                            class="btn-line h6"><span>Contact</span></a></li>
                                </ul>
                            </div>
                            <div class="col-md-12">
                                <ul class="top-menu-bottom list-inline">
                                    <li class="list-inline-item h6"><a href="https://www.behance.net/prismicreflections"
                                            target="_blank" class="btn-line-b btn-behance"><i
                                                class="fab fa-behance"></i><span>Behance</span></a>
                                    </li>
                                    <li class="list-inline-item h6"><a href="https://dribbble.com/PrismicReflections"
                                            target="_blank" class="btn-line-b btn-dribbble"><i
                                                class="fab fa-dribbble"></i><span>Dribbble</span></a>
                                    </li>
                                    <li class="list-inline-item h6"><a
                                            href="https://www.instagram.com/prismicreflections/?hl=en" target="_blank"
                                            class="btn-line-b btn-instagram"><i
                                                class="fab fa-instagram"></i><span>Instagram</span></a></li>
                                    <li class="list-inline-item h6"><a
                                            href="https://in.linkedin.com/company/prismicreflections" target="_blank"
                                            class="btn-line-b btn-linkedin"><i
                                                class="fab fa-linkedin-in"></i><span>Linkedin</span></a></li>
                                    <li class="list-inline-item h6"><a
                                            href="https://www.facebook.com/prismicreflections" target="_blank"
                                            class="btn-line-b btn-facebook"><i
                                                class="fab fa-facebook-f"></i><span>Facebook</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>