import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import scrollify from 'jquery-scrollify';
import AOS from 'aos';
declare let TouchScroll: any;
declare let $: any;

@Component({
  selector: 'app-smart360',
  templateUrl: './smart360.component.html',
  styleUrls: ['./smart360.component.scss']
})
export class Smart360Component implements OnInit, OnDestroy {

  outOfBounds = {
    top: false,
    bottom: false,
    right: false,
    left: true
  };

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  
  //new desc
  descrip = 'Check how we delivered Smart360 - a technology enterprise its strategic web application ux ui design. The next web app could be yours. Let`s discuss!';
  
  constructor(private titleService: Title, private metaService: Meta) {
   
    this.setTitle('Web App UX UI Design for Technology Enterprise | Smart360 - Casestudy');
    this.metaService.updateTag({ name: 'description', content: this.descrip });
    this.metaService.updateTag({ name: 'keywords', content: 'UI Design for Corporates, UX for Startup, User Experience Design (UX), User Experience Design, Top Design Studio India, Best Design Agency Mumbai, Pune, Nashik, UI UX Design for Angel Broking, User Interface Design HDFC Credila, User Experience Design (UX) for Fintech, UI/UX for eCommerce, eLearning, User Interface Design for LMS, UI/UX Design Mobile App, UX Design for Mobility, Logo Design, Logo Branding, UI for Food Tech, UI/UX for Leading Real estate Company' });
    this.metaService.updateTag({ property: 'og:type', content: 'business.business' });
    this.metaService.updateTag({ property: 'og:title', content: 'Web App UX UI Design for Technology Enterprise | Smart360 - Casestudy' });
    this.metaService.updateTag({ property: 'og:description', content: this.descrip });
  }

  ngOnInit(): void {
    $(function() {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });
    $('.comman-header-wrap').show();
    $('#comman-footer').show();
    // $('.comman-header .header-navigation p.btn-link-custom').html(
    //   '<img src="assets/images/svg/left-arrow-icon.svg" alt="Location" class="left-arrow-icon" style="width: 20px;" /> <span>Back to Work</span>'
    // );
    // $(".comman-header .header-navigation .logo-wrap-our-team").attr("href", "/our-work");
    $(document).ready(function() {
      $('.logo-wrap-our-team').hide();
      $('.logo-wrap-a').show();
      $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
      $('.arrow-scroll-abs').show();
      $('.arrow-scroll-abs .next').removeClass('text-white');
      $('.comman-top-menu').removeClass('comman-top-menu-dark');
      const wido = $(window).width();
      if (wido >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          standardScrollElements: true,
          overflowscroll: true,
          before(i, panels) {
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.logo-wrap-our-team').removeClass('active-white-text').show();
            $('.pagination-wrap').find('a[href="#' + ref + '"]').addClass('active');
            $('.arrow-scroll-abs').fadeIn();
            $('.comman-top-menu').removeClass('comman-top-menu-dark');
            $('.comman-header .logo-wrap-a').show();
            if (ref === 'data-section1') {
              $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').hide();
              $('.comman-header .logo-wrap-a').show();
            }
            else if (ref === 'data-section2') {
              $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
            else if (ref === 'data-section3') {
              $('.comman-header').addClass('active-menu-white').removeClass('active-white active-logo-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
            else if (ref === 'data-section4') {
              $('.comman-header').removeClass('active-logo-white active-menu-white active-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
              const viewer = new TouchScroll();
              viewer.init({ id: 'viewer', draggable: true, wait: false });
            }
            else if (ref === 'data-section5') {
              $('.comman-header').removeClass('active-logo-white active-menu-white active-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
            else if (ref === 'data-section6') {
              $('.comman-header').removeClass('active-logo-white active-menu-white active-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
            else if (ref === 'data-section7') {
              $('.comman-header').addClass('active-white').removeClass('active-menu-white active-logo-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
            else if (ref === 'data-section8') {
              $('.comman-header').addClass('active-white').removeClass('active-menu-white active-logo-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
            else if (ref === 'data-section9') {
              $('.comman-header').removeClass('active-logo-white active-menu-white active-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
            else if (ref === 'data-section-testimonial') {
              $('.comman-header').addClass('active-white').removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
            else if (ref === 'data-comman-footer') {
              $('.comman-header').addClass('active-white').removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
            else if (ref === 'data-section-next-page') {
              $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.comman-header .logo-wrap-a').hide();
            }
            else {
              $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
              $('.arrow-scroll-abs').show();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
          },
          afterRender() {
            $('.logo-wrap-our-team').removeClass('active-white-text').hide();
            aosinit();
            // counterNum();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i) {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
            // var viewer = new TouchScroll();
            // viewer.init({
            //     id: 'viewer',
            //     draggable: true,
            //     wait: false
            // });
          }
        });
        $('.arrow-scroll-abs .next').click(function(e1) {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2) {
          e2.preventDefault();
          $.scrollify.prev();
        });
      }
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }

      function aosinit() {
        AOS.init({
          duration: 400,
          anchorPlacement: 'top-top',
          disable() {
            const maxWidth = 1199;
            return window.innerWidth < maxWidth;
          },
          once: true,
          offset: 20,
        });
      }
    });
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    AOS.init({
      disable: true
    });
  }

}
