import {Component, OnDestroy, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {NavigationEnd, Router} from '@angular/router';
import AOS from 'aos';

declare let $: any;

@Component({
  selector: 'app-ourwork',
  templateUrl: './ourwork.component.html',
  styleUrls: ['./ourwork.component.scss'],
})
export class OurworkComponent implements OnInit, OnDestroy {

  // ... your class variables here
  navigationSubscription;

  
  descrip = 'Prismic Reflections is a strategic design company that specializes in user experience, brand strategy & identity design, and ui engineering. Let`s discuss!';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
  ) {
    
    this.setTitle('Strategic Design Company in India | Prismic Reflections');
    
    this.metaService.updateTag({name: 'description', content: this.descrip});
    this.metaService.updateTag({
      name: 'keywords',
      content: 'UI Design for Corporates, UX for Startup, User Experience Design (UX), User Experience Design, Top Design Studio India, Best Design Agency Mumbai, Pune, Nashik, UI UX Design for Angel Broking, User Interface Design HDFC Credila, User Experience Design (UX) for Fintech, UI/UX for eCommerce, eLearning, User Interface Design for LMS, UI/UX Design Mobile App, UX Design for Mobility, Logo Design, Logo Branding, UI for Food Tech, UI/UX for Leading Real estate Company'
    });
    this.metaService.updateTag({property: 'og:type', content: 'business.business'});
    this.metaService.updateTag({
      property: 'og:title',
      content: 'Strategic Design Company in India | Prismic Reflections'
     
    });
    this.metaService.updateTag({property: 'og:description', content: this.descrip});

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  ngOnInit(): void {
    // alert('our-works init reloaded!!!');
    $(function(): void {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });

    function aosinit(): void {
      AOS.init({
        duration: 400,
        anchorPlacement: 'top-top',
        disable(): boolean {
          const maxWidth = 1199;
          return window.innerWidth < maxWidth;
        },
        once: true,
        offset: 20,
      });
    }

    $('.comman-header-wrap').show();
    $('#comman-footer').show();
    $('.logo-wrap-our-team').hide();
    $('.logo-wrap-a').show();
    $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
    $('.arrow-scroll-abs').hide();
    $('.comman-top-menu').removeClass('comman-top-menu-dark');
    const wido = $(window).width();
    if (wido >= 1199) {
      // $.scrollify.destroy();
      $.scrollify.disable();
    }
    $(window).scroll(function(): void {
      const sticky = $('.our-work-page .work-comman-card-info .custom-nav-wrap-outer');
      const scroll = $(window).scrollTop();
      if (scroll >= 100) {
        sticky.addClass('nav-down');
      } else {
        sticky.removeClass('nav-down');
      }
    });
    $('.portfolio-thumb-webapp-btn').click(function(p11): void {
      p11.preventDefault();
      $('.portfolio-thumb-all').hide();
      $('.portfolio-thumb-webapp').show();
      $('.btn-link-custom.btn-line').removeClass('active');
      $('.portfolio-thumb-webapp-btn').addClass('active');
      $('body,html').animate(
        {
          scrollTop: 0,
        },
        800
      );
    });
    $('.portfolio-thumb-mobileapp-btn').click(function(p12): void {
      p12.preventDefault();
      $('.portfolio-thumb-all').hide();
      $('.portfolio-thumb-mobileapp').show();
      $('.btn-link-custom.btn-line').removeClass('active');
      $('.portfolio-thumb-mobileapp-btn').addClass('active');
      $('body,html').animate(
        {
          scrollTop: 0,
        },
        800
      );
    });
    $('.portfolio-thumb-branding-btn').click(function(p13): void {
      p13.preventDefault();
      $('.portfolio-thumb-all').hide();
      $('.portfolio-thumb-branding').show();
      $('.btn-link-custom.btn-line').removeClass('active');
      $('.portfolio-thumb-branding-btn').addClass('active');
      $('body,html').animate(
        {
          scrollTop: 0,
        },
        800
      );
    });
    $('.portfolio-thumb-website-btn').click(function(p14): void {
      p14.preventDefault();
      $('.portfolio-thumb-all').hide();
      $('.portfolio-thumb-website').show();
      $('.btn-link-custom.btn-line').removeClass('active');
      $('.portfolio-thumb-website-btn').addClass('active');
      $('body,html').animate(
        {
          scrollTop: 0,
        },
        800
      );
    });
    $('.portfolio-thumb-all-btn').click(function(p15): void {
      p15.preventDefault();
      $('.portfolio-thumb-all').show();
      $('.btn-link-custom.btn-line').removeClass('active');
      $('.portfolio-thumb-all-btn').addClass('active');
      $('body,html').animate(
        {
          scrollTop: 0,
        },
        800
      );
    });

    const pageURL = document.location.href.match(/[^\/]+$/)[0];
    const href = $(this).find('a.nav-link').attr('href');
    setTimeout(function(): void {
      if (pageURL === 'our-work#portfolio-thumb-webapp') {
        $('.portfolio-thumb-webapp-btn').trigger('click');
      } else if (pageURL === 'our-work#portfolio-thumb-mobileapp') {
        $('.portfolio-thumb-mobileapp-btn').trigger('click');
      } else if (pageURL === 'our-work#portfolio-thumb-branding') {
        $('.portfolio-thumb-branding-btn').trigger('click');
      } else if (pageURL === 'our-work#portfolio-thumb-website') {
        $('.portfolio-thumb-website-btn').trigger('click');
      }
    }, 1000);

    if ($(window).width() < 1199) {
      $('[data-aos]').addClass('aos-init aos-animate');
    }
  }

  ngOnDestroy(): void {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    $.scrollify.enable();
    AOS.init({
      duration: 400,
      anchorPlacement: 'top-top',
      disable(): boolean {
        const maxWidth = 1199;
        return window.innerWidth < maxWidth;
      },
      once: true,
      offset: 20,
    });
  }

  initialiseInvites(): void {
    this.ngOnInit();
    // window.location.reload();
    // $.scrollify.destroy();

    $("#overseas-edu-video-modal").on('hidden.bs.modal', function (e) {
      $("#overseas-edu-video-modal iframe").attr("src", $("#overseas-edu-video-modal iframe").attr("src"));
    });
        
  }


  

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

}
