<div class="portfolio-website-mytrux-body">
    <section class="comman-section comman-section-100vh mytrux-webapp-sec1 scroll-div top-section-wrapper"
        data-section-name="data-section1" id="section1">
        <div class="ani-wrap100-top" style="background-color: #0F2541;" data-aos="new-animation3" data-aos-delay="300"
            data-aos-anchor="#section1"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-7 order-xxs-last">
                    <div class="mytrux-webapp-sec1-right portfolio-info-wrap2 portfolio-info-banner-l xxs-text-center">
                        <ul class="list-inline comman-card-top-ul" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <li class="list-inline-item h6 text-white">MyTrux</li>
                            <li class="list-inline-item h6 text-white">Logistics</li>
                        </ul>
                        <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1"
                            class="text-white hide-mobile-xxs xl-no-wrap"> Website for <br> Connected <br> Digital
                            Transport</h1>
                    </div>
                </div>
                <div class="col-sm-5">
                    <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1"
                        class="text-white show-mobile-xxs xs-text-center"> Website for Connected <br> Digital Transport
                    </h1>
                    <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section1"> <img
                            src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-webapp-sec1-1.png"
                            alt="MyTrux - Marketing Website" class="img-fluid mytrux-webapp-sec1-1 lazy" /></div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs"> <a href="#" class="next">Scroll<div class="c-scrolldown">
                    <div class="c-line"></div>
                </div> </a></div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div mytrux-logo-sec2" id="section2"
        data-section-name="data-section2">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section2" style="background-color: #0F2541;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="mytrux-logo-sec2-center overview-sec-center portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2">Overview</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2"> Mytrux is a
                            digital platform which takes care of all logistics related processes right from the first
                            mile to the last mile, through the SaaS based product and mobile app. Product suite is
                            designed to empower the unorganised sector of indian transportation industry including large
                            enterprises, container transport, road transport, couriers and 3 PL Warehouses.</p>
                    </div>
                    <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2"> <img
                            src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-website-sec9-4.svg"
                            alt="MyTrux - Overview" class="img-fluid mytrux-website-sec9-4 lazy" /></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh mytrux-webapp-sec3 scroll-div comman-solution-challenge"
        id="section3" style="overflow: hidden;" data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #0F2541;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-end">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="mytrux-webapp-sec3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3">Challenges</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3"> Mytrux
                            product offerings consist of two main platforms - ERP and Hub (One stop destination which
                            allows you to source vehicles on demand). To promote these 2 dimensions distinctively and
                            help Mytrux to gain more leads and strategic partnerships was the key objective.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="mytrux-webapp-sec8-b portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3" class="text-white">
                            Solution</h3>
                        <p data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3" class="text-white p2">
                            After analysing user types, business model, goals and product offerings, we revised the
                            sitemap and navigation structure of the website. Information architecture has been designed
                            to emphasise clearer features, differentiators and to stand tall among competition.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mytrux-banner-line-outer" data-aos="fade-in" data-aos-delay="2500" data-aos-anchor="#section3"> <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-banner-line.svg"
                alt="MyTrux - Challenges" class="img-fluid mytrux-banner-line lazy" /></div>
    </section>
    <section class="comman-section comman-section-100vh mytrux-webapp-sec4 viewer-section pb0 scroll-div"
        data-section-name="data-section4" id="section4" data-aos-anchor="#section4">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section4" style="background-color: #0F2541;"></div>
        <div class="container-fluid h-100">
            <div class="row h-100000">
                <div class="col-sm-12">
                    <div class="mytrux-webapp-sec4-center portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section4"> Userflow</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4"> To simplify,
                            we proposed Mytrux not just to list and showcase product features but engage users by
                            letting them experience the glimpses of core features of the application, understand its
                            value and motivate them for onboarding.</p>
                    </div>
                </div>
                <div class="col-sm-12 bottom-col-set" data-aos="fade-in" data-aos-delay="1200"
                    data-aos-anchor="#section4">
                    <div class="mytrux-webapp-sec4-bottom clearfix">
                        <div class="viewer no-select" id="viewer">
                            <img src="assets/images/portfolio/portfolio-website-mytrux/mytrux-webapp-sec4-bottom2.png" alt="Navigation Simplified" class="mytrux-webapp-sec4-bottom-img" draggable="false" id="photo">
                        </div>
                        <!-- <div ngDraggable lockAxis="y" [bounds]="myBounds" [inBounds]="true" [outOfBounds]="outOfBounds">
                            <img src="assets/images/portfolio/portfolio-website-mytrux/mytrux-webapp-sec4-bottom2.png" alt="Navigation Simplified" class="mytrux-webapp-sec4-bottom-img" draggable="false" id="photo">
                        </div> -->
                        <!-- <div id="ele">
                            <img src="assets/images/portfolio/portfolio-website-mytrux/mytrux-webapp-sec4-bottom2.png" alt="Navigation Simplified" class="mytrux-webapp-sec4-bottom-img" draggable="false" id="photo">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh mytrux-webapp-sec6 scroll-div xs-pb30"
        data-section-name="data-section5" id="section5">
        <div class="ani-wrap100-top" style="background-color: #D3E8FF;" data-aos="new-animation3" data-aos-delay="300"
            data-aos-anchor="#section5"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="mytrux-webapp-sec6-center portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section5"> Wireframing</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5"> Iterating the
                            concept to bring ideas in reality and to validate it within stakeholders.</p>
                    </div>
                </div>
                <div class="col-sm-12"></div>
            </div>
        </div>
        <div class="mytrux-webapp-sec6-right">
            <div class="mytrux-web-sec6-1 hide-mobile" data-aos="fade-in" data-aos-delay="1500"
                data-aos-anchor="#section5"> <img src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-webapp-sec6-1.png"
                    alt="MyTrux - Wireframing" class="img-fluid lazy" /></div>
            <div class="mytrux-web-sec6-2 hide-mobile" data-aos="fade-in" data-aos-delay="1200"
                data-aos-anchor="#section5"> <img src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-webapp-sec6-2.png"
                    alt="MyTrux - Wireframing" class="img-fluid lazy" /></div>
            <div class="mytrux-web-sec6-3-xs show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1500"
                data-aos-anchor="#section5"> <img src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-web-sec6-3-xs.png"
                    alt="MyTrux - Wireframing" class="img-fluid lazy xxs-mt30" /></div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh mytrux-webapp-sec7 bottom-col-section scroll-div"
        data-section-name="data-section6" id="section6">
        <div class="ani-wrap100-top-2" style="background-color: #AE294C;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section6"></div>
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-sm-12">
                    <div class="mytrux-webapp-sec7-center portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section6">
                            Landing Page</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6"> To
                            set the right context for business and targeted audience. It's more of story telling for us.
                        </p>
                    </div>
                </div>
                <div class="col-sm-12 bottom-col-set" data-aos="fade-in" data-aos-delay="1200"
                    data-aos-anchor="#section6">
                    <div class="mytrux-webapp-sec7-1-outer"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-webapp-sec7-1.png"
                            alt="MyTrux - Landing Page" class="img-fluid mytrux-webapp-sec7-1 lazy" /></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh mytrux-webapp-sec8 bottom-col-section comman-solution-challenge scroll-div"
        data-section-name="data-section7" id="section7">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section7" style="background-color: #AE294C;"></div>
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-md-12">
                    <div class="mytrux-webapp-sec8-center portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section7">Driver's Registration
                        </h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section7"> Why keep the
                            users waiting? Allow them to explore the freemium features and onboard them right away.</p>
                    </div>
                </div>
                <div class="col-md-12 bottom-col-set hide-mobile" data-aos="fade-in" data-aos-delay="1500"
                    data-aos-anchor="#section7">
                    <div class="mytrux-webapp-sec8-b row clearfix">
                        <div class="mytrux-webapp-sec8-1 col-md-4 col-sm-4"> <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-webapp-sec8-1.png"
                                alt="MyTrux - Driver's Registration" class="img-fluid lazy" /></div>
                        <div class="mytrux-webapp-sec8-2 col-md-4 col-sm-4"> <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-webapp-sec8-2.png"
                                alt="MyTrux - Driver's Registration" class="img-fluid lazy" /></div>
                        <div class="mytrux-webapp-sec8-3 col-md-4 col-sm-4"> <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-webapp-sec8-3.png"
                                alt="MyTrux - Driver's Registration" class="img-fluid lazy" /></div>
                    </div>
                </div>
                <div class="col-md-12 show-mobile hide-desktop">
                    <div class="mytrux-webapp-sec8-3-xs"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-webapp-sec8-3-xs.png"
                            alt="MyTrux - Driver's Registration" class="img-fluid lazy" /></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div mytrux-website-sec10" data-section-name="data-section8" id="section8">
        <div class="ani-wrap100-top-2 bg-blue7" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section8"></div>
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div data-aos="fade-in" data-aos-delay="700" data-aos-anchor="#section8">
                        <div class="mytrux-website-top-wrap hide-mobile show-desktop"> <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-website-sec10-1.png"
                                alt="MyTrux - Design Fall &amp; Rise" class="img-fluid mytrux-website-sec10-1 lazy"
                                data-aos="fade-down" data-aos-delay="900" data-aos-anchor="#section8"> <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-website-sec10-1-1.png"
                                alt="MyTrux - Design Fall &amp; Rise" class="img-fluid mytrux-website-sec10-1-1 lazy"
                                data-aos="fade-down" data-aos-delay="1200" data-aos-anchor="#section8"> <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-website-sec10-2.svg"
                                alt="MyTrux - Design Fall &amp; Rise" class="img-fluid mytrux-website-sec10-2 lazy">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-website-sec10-3.svg"
                                alt="MyTrux - Design Fall &amp; Rise" class="img-fluid mytrux-website-sec10-3 lazy">
                        </div>
                        <div class="mytrux-website-top-wrap-xs show-mobile"> <img
                                src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-website-mytrux/mytrux-website-top-wrap-xs.png"
                                alt="MyTrux - Design Fall &amp; Rise" class="img-fluid mytrux-website-top-wrap-xs lazy">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh impact-section-upd scroll-div" id="section-testimonial"
        data-section-name="data-section-testimonial">
        <div class="ani-wrap100-top" style="background-color: #1E1E1E;" data-aos="new-animation3" data-aos-delay="900"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="moneyguru-sec13-left portfolio-info-wrap2 text-center" data-aos="fade-in"
                        data-aos-delay="1500" data-aos-anchor="#section-testimonial" style="max-width: inherit;">
                        <ul class="list-inline comman-card-top-ul">
                            <li class="list-inline-item h6 text-white hide-after">Success Metrics</li>
                        </ul>
                        <h3 class="text-white">Anchoring customers</h3>
                        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section-testimonial">
                            <ul class="list-inline comman-impact-list-inline">
                                <li class="list-inline-item" style="margin: auto !important;"> <img
                                        src="assets/images/preloader/ajax-loader.png"
                                        data-src="assets/images/svg/impact-dowload.svg" alt="Downloads" class="lazy">
                                    <h3 class="text-white">0<span class="counter1">3</span></h3>
                                    <p class="text-white p2">Acquired</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh insisive-website-sec11 failure-section scroll-div"
        id="section-learning" data-section-name="data-section-learning">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section-learning" style="background-color: #1E1E1E;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-7 col-lg-6 col-sm-12">
                    <div class="insisive-sec-left portfolio-info-wrap2 failure-sec-left">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section-learning">Failure &amp;
                            Learnings</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section-learning"> Give
                            users their identity within your product or website. Users need identity to understand and
                            perform their tasks smoothly.</p>
                    </div>
                </div>
                <div class="col-md-5 col-lg-6"></div>
            </div>
        </div>
        <div class="failure-sec-right" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section-learning">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-sec14-bg.png"
                alt="Failure &amp; Learnings" class="img-fluid lazy failure-sec-right-img" /></div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6> <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/website/bynry"> <span>Bynry</span>
                        </a>
                        <p class="p2">Enhancing the Web Presence for Utility Management Company</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>