<!-- privacy-policy-sticky -->
<div class="privacy-policy-sticky">
    <div class="container">
        <div class="row">
            <div class="col-sm-8 col-md-9 col-lg-10">
                <p class="text-white">
                    This website uses analytic solutions, cookies and softwares which are essential and help us to
                    improve the user experience. By using the website, you consent to the use of these analytic
                    solutions. Read our <a routerLink="/privacy-term" class="a-link-orange text-orange" target="_blank"
                        style="font-size: inherit;">Terms of Use and Privacy Policy</a> to learn more.
                </p>
            </div>
            <div class="col-sm-4 col-md-3 col-lg-2 text-center xs-mt10">
                <button type="button" class="btn btn-success btn-sm st mr-1" id="agree-btn">OK</button>
                <button type="button" class="btn btn-default btn-sm st" routerLink="/privacy-term">Read More</button>
            </div>
        </div>
    </div>
</div>