import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import AOS from 'aos';
import counterUp from 'counterup2';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {ScrollifyAniService} from 'src/app/appServices/scrollify-ani.service';

declare const $: any;
declare const value: any;
declare const Waypoint: any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {

  // old description
  // descrip: string = 'Prismic Reflections is on a mission to be the world\'s best and most-respected Design Studio by creating highly ' +
  //   'impactful experiences for anything that humans interact with.';

  // new description added
   
  descrip: string = 'Prismic Reflections - a strategic ux ui design company specializing in user experience, ui engineering, ' +
    'brand strategy & identity design. Let`s discuss!';

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  constructor(private titleService: Title, private metaService: Meta, private scrollifyAniService: ScrollifyAniService) {
  
    this.setTitle('Strategic UI UX Design Company in India | Prismic Reflections');
    this.metaService.updateTag({name: 'description', content: this.descrip});
    this.metaService.updateTag({property: 'og:type', content: 'business.business'});
    this.metaService.updateTag({
      property: 'og:title',
     
      content: 'Strategic UI UX Design Company in India | Prismic Reflections'
    });
    this.metaService.updateTag({property: 'og:description', content: this.descrip});
  }

  customOptionsMob: OwlOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1,
    dots: false,
    autoWidth: true,
    autoplay: true,
    autoplayTimeout: 4000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    center: true,
    mergeFit: true,
    responsive: {
      0: {
        items: 1,
      },
      520: {
        items: 2,
      }
    },
  };

  public scrollbarOptions = {theme: 'light-thick', scrollInertia: 400};

  

  customOptions3: OwlOptions = {
    loop: true,
    items: 3,
    autoWidth: false,
    pullDrag: false,
    navSpeed: 700,
    dots: false,
    nav: false,
    margin: 30,
    autoplay: true,
    autoHeight: true,
    mouseDrag: true,
    touchDrag: true,
    lazyLoad: true,
    autoplayTimeout : 2000,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
        dots: false
      },
      767: {
        items: 2,
        dots: false
      },
      1050: {
        items: 3,
      },
    },
  };

  ngOnInit(): void {
    const numcounter1 = document.querySelector('.counter1');
    const numcounter2 = document.querySelector('.counter2');
    const numcounter3 = document.querySelector('.counter3');
    counterUp(numcounter1, {duration: 2400, delay: 20});
    counterUp(numcounter2, {duration: 1500, delay: 20});
    counterUp(numcounter3, {duration: 1500, delay: 20});

    // this._scolifyService.msgalert();
    // console.log('serveri c');

    $(function() {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });

    function aosinit() {
      AOS.init({
        duration: 400,
        anchorPlacement: 'top-top',
        disable: function() {
          const maxWidth = 1199;
          return window.innerWidth < maxWidth;
        },
        once: true,
        offset: 20,
      });
    }

    $('.comman-header-wrap').show();
    $('#comman-footer').show();
    $('#about-sec6-carousel').carousel({
      interval: 3000
    });
    $('#about-sec6-carousel2').carousel({
      interval: 5000
    });
    $('#about-sec6-carousel3').carousel({
      interval: 3500
    });
    $('#about-sec6-carousel4').carousel({
      interval: 4000
    });
    $(document).ready(function() {
      $('.logo-wrap-our-team').hide();
      $('.logo-wrap-a').show();
      $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
      $('.arrow-scroll-abs').show();
      $('.arrow-scroll-abs .next').removeClass('text-white');
      $('.comman-top-menu').removeClass('comman-top-menu-dark');
      const wido = $(window).width();
      if (wido >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          scrollSpeed: 1100,
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          overflowscroll: true,
          before: function(i, panels) {
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.pagination-wrap')
              .find('a[href="#' + ref + '"]')
              .addClass('active');
            $('.logo-wrap-a .company-name-mobile')
              .removeClass('active-white-text')
              .hide();
            $('.arrow-scroll-abs').fadeIn();
            $('.arrow-scroll-abs .next').removeClass('text-white');
            $('.comman-top-menu').removeClass('comman-top-menu-dark');
            $('.logo-wrap-a .company-name-mobile').removeClass('active-white-text').hide();
            if (ref === 'data-section1') {
              $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
              $('.arrow-scroll-abs').show();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              // counterNum();
              const waypoint1 = new Waypoint({
                element: numcounter1,
                handler: function() {
                  counterUp(numcounter1, {duration: 2400, delay: 20});
                  this.destroy();
                }, offset: 'bottom-in-view',
              });
              const waypoint2 = new Waypoint({
                element: numcounter2,
                handler: function() {
                  counterUp(numcounter2, {duration: 1500, delay: 20});
                  this.destroy();
                }, offset: 'bottom-in-view',
              });
              const waypoint3 = new Waypoint({
                element: numcounter3,
                handler: function() {
                  counterUp(numcounter3, {duration: 1500, delay: 20});
                  this.destroy();
                }, offset: 'bottom-in-view',
              });
            }
            if (ref === 'data-section2') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
            } else if (ref === 'data-section3') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
            } else if (ref === 'data-section4') {
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-white active-menu-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.comman-scrollbar-wrap, .comman-scrollbar-wrap2').mCustomScrollbar({theme: 'rounded-dots', scrollInertia: 400});
            } else if (ref === 'data-section5-1') {
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-white active-menu-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').removeClass('text-white');
            } else if (ref === 'data-section5-2') {
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-white active-menu-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').removeClass('text-white');
            } else if (ref === 'data-comman-footer') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
            } else {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').show();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            }
          },
          afterRender: function() {
            aosinit();
            var pagination = '<ul class="pagination-wrap">';
            var activeClass = '';
            $('.scroll-div').each(function(i) {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
          },
        });
        $('.arrow-scroll-abs .next').click(function(e1) {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2) {
          e2.preventDefault();
          $.scrollify.prev();
        });
      }

      $('#about-carosual').on('slide.bs.carousel', function(e) {
        if (e.relatedTarget.id === 'firstSlide') {
          $('#about-carosual .carousel-indicators-ul li').removeClass('active');
          $('#about-carosual .carousel-indicators-ul li:nth-child(1)').addClass(
            'active'
          );
        } else if (e.relatedTarget.id === 'secondSlide') {
          $('#about-carosual .carousel-indicators-ul li').removeClass('active');
          $('#about-carosual .carousel-indicators-ul li:nth-child(2)').addClass(
            'active'
          );
        }
      });
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }
      

    });
  }
}
