<div class="page-not-found-body">
    <section class="comman-section error-page-section1 scroll-div top-section-wrapper" data-section-name="data-section1" id="section1">
        <div class="ani-wrap100-top bg-black new-animation3-class" data-aos="new-animation3" data-aos-delay="100" data-aos-anchor="#section1"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-7 col-lg-7 order-xs-last">
                    <div class="portfolio-info-wrap2 xs-text-center">
                        <ul class="list-inline comman-card-top-ul" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section1">
                            <li class="list-inline-item h6 hide-after text-white">Error 404</li>
                        </ul>
                        <h1 class="text-white no-wrap-md" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                            Something went <br />wrong
                        </h1>
                        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section1">
                            <a routerLink="/home" class="bttn btn-line btn-secondary-custom"><span>Go to Homepage</span></a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-5 col-lg-5">
                    <div class="error-img-wrap" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section1">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/svg/error-icon.svg" alt="Error 404" class="lazy error-icon" />
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/svg/error-page.svg" alt="Error 404" class="error-page lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>