<div class="process-page-body">
    <section class="comman-section process-section1 scroll-div top-section-wrapper pb0 xs-pb60"
        data-section-name="data-section1" id="section1">
        <div class="container">
            <div class="row process-section1-row justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="comman-card-info-left fixed-left xs-text-center xl-no-wrap">
                        <h1 class="h1 sec-heading" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section1">
                            Creativity With<br /> Disciplined <span class="restlessRegularText">Actions</span></h1>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 position-relative process-left-sec process-left-sec-height">
                    <div class="comman-scrollbar-wrap-process">
                        <div class="comman-card-img-wrap-upd hide-mobile lg-mt160"> <img
                                src="assets/images/banner/process-sect-1.png" alt="Creativity With Disciplined Actions"
                                class="process-sect-1 lazy img-adj" data-aos="fade-in" data-aos-delay="1200"
                                data-aos-anchor="#section1"> <img src="assets/images/banner/process-sect-2.svg"
                                alt="Creativity With Disciplined Actions" class="process-sect-2 lazy"> <img
                                src="assets/images/banner/process-sect-3.svg" alt="Creativity With Disciplined Actions"
                                class="process-sect-3 lazy floatingX-l"> <img src="assets/images/banner/process-sect-4.svg"
                                alt="Creativity With Disciplined Actions" class="process-sect-4 lazy floatingX-l"> <img
                                src="assets/images/banner/process-sect-5.svg" alt="Creativity With Disciplined Actions"
                                class="process-sect-5 lazy" data-aos="fade-in" data-aos-delay="2000"
                                data-aos-anchor="#section1"></div>
                        <div class="comman-card-img-wrap-upd2 show-mobile text-center">
                            <div class="z-index8 mt50" data-aos="fade-in" data-aos-delay="600"
                                data-aos-anchor="#section1"> <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="./images/banner/process-sect-xs-1.png"
                                    alt="Creativity With Disciplined Actions" class="process-sect-1 lazy img-fluid"
                                    style="padding-top: 30px;"></div>
                        </div>
                        <div class="mt130-lg xs-mt60">
                            <div class="comman-card-info" data-aos="fade-in" data-aos-delay="900"
                                data-aos-anchor="#section1">
                                <div class="process-img-wrapper2"> <img src="assets/images/process/icon-discover.svg"
                                        alt="Discover" class="process-image" /></div>
                                <h3>Discover</h3>
                                <p class="p2 sec-para text-light-dark-c"> The design process begins with understanding
                                    the business
                                    &amp; brand. Understanding product/service along with USPs and importantly,
                                    empathising with the users. <br /><br /> Observe &amp; analyse competition. Curate
                                    rich insights from reports which can be used in the next stage.</p>
                            </div>
                            <div class="comman-card-info" data-aos="fade-in" data-aos-delay="900"
                                data-aos-anchor="#section1">
                                <div class="process-img-wrapper2"> <img src="assets/images/process/icon-define.svg"
                                        alt="Define" class="process-image" /></div>
                                <h3>Define</h3>
                                <p class="p2 sec-para text-light-dark-c"> Defining problems precisely. Map the user
                                    journey and identify opportunity areas.</p>
                            </div>
                            <div class="comman-card-info" data-aos="fade-in" data-aos-delay="900"
                                data-aos-anchor="#section1">
                                <div class="process-img-wrapper2"> <img src="assets/images/process/icon-strategize.svg"
                                        alt="Strategize" class="process-image" /></div>
                                <h3>Strategise</h3>
                                <p class="p2 sec-para text-light-dark-c"> Strategise &amp; plan for solving problems in
                                    the
                                    most effective the constraints in mind. Strategies to tap into
                                    opportunities we discovered.</p>
                            </div>
                            <div class="comman-card-info" data-aos="fade-in" data-aos-delay="900"
                                data-aos-anchor="#section1">
                                <div class="process-img-wrapper2"> <img src="assets/images/process/icon-design.svg"
                                        alt="Design" class="process-image" /></div>
                                <h3>Design</h3>
                                <p class="p2 sec-para text-light-dark-c"> Arrive at information architecture, low
                                    fidelity &amp; high fidelity wireframes made ready for early testing. Harnessing the
                                    power of emotional design to establish a strong emotional connect with users through
                                    visual design.</p>
                            </div>
                            <div class="comman-card-info" data-aos="fade-in" data-aos-delay="900"
                                data-aos-anchor="#section1">
                                <div class="process-img-wrapper2"> <img src="assets/images/process/icon-refine.svg"
                                        alt="Evaluate &amp; Refine" class="process-image" /></div>
                                <h3>Evaluate &amp; Refine</h3>
                                <p class="p2 sec-para text-light-dark-c"> Based on feedback to early stage evaluation,
                                    refine the wireframes to avoid later stage effort. Test the visual design mockups to
                                    validate for the last time before jumping into development.</p>
                            </div>
                            <div class="comman-card-info" data-aos="fade-in" data-aos-delay="900"
                                data-aos-anchor="#section1">
                                <div class="process-img-wrapper2"> <img src="assets/images/process/icon-code.svg" alt="Code"
                                        class="process-image" />
                                </div>
                                <h3>Code</h3>
                                <p class="p2 sec-para text-light-dark-c"> Engineer visual designs into browser-friendly,
                                    fast renderable pixel-perfect codes,
                                    and apply engaging interactive animations to
                                    deliver intuitive experience.</p>
                            </div>
                            <div class="comman-card-info" data-aos="fade-in" data-aos-delay="900"
                                data-aos-anchor="#section1">
                                <div class="process-img-wrapper2"> <img src="assets/images/process/icon-launch.svg"
                                        alt="Launch" class="process-image" /></div>
                                <h3>Launch</h3>
                                <p class="p2 sec-para text-light-dark-c"> When everything is ready, all set to go live,
                                    our design team ensures that the final experience meets the defined standards,
                                    original vision and main goal. They are on their toes, just in case of any urgent
                                    support needed during the launch.</p>
                            </div>
                            <div class="comman-card-info" data-aos="fade-in" data-aos-delay="900"
                                data-aos-anchor="#section1">
                                <div class="process-img-wrapper2"> <img src="assets/images/process/icon-evolve.svg"
                                        alt="Evolve" class="process-image" /></div>
                                <h3>Evolve</h3>
                                <p class="p2 sec-para text-light-dark-c"> The story doesn't end after the launch, in
                                    fact it actually begins from here. Monitoring &amp; analysing what's working and
                                    what can be improved. By being closely associated with stakeholders, we help our
                                    partners to evolve to the next stage.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>