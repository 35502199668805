<div class="portfolio-branding-mytrux-body">
    <section class="comman-section comman-section-100vh scroll-div mytrux-logo-sec1" id="section1"
        data-section-name="data-section1">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center text-center">
                <div class="col-sm-12">
                    <div class="mytrux-log-center-wrap portfolio-info-banner-c" data-aos="fade-in" data-aos-delay="300"
                        data-aos-anchor="#section1">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-update.svg"
                            alt="Mytrux - logo" class="img-fluid mytrux-logo-img logo-img lazy" />
                        <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                            Identity Redesign for <br />
                            Connected Digital Transport
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">
                Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div mytrux-logo-sec2" id="section2" data-section-name="data-section2">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="mytrux-logo-sec2-center overview-sec-center portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2">Overview</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                            We got an opportunity to contribute our design skills to shape up a logo and identity design for Mytrux. It is a digital platform which takes care of all logistics related processes right from the first mile
                            to the last mile, through the SaaS based product and mobile app. Product suite is designed to empower the unorganised sector of Indian transportation industry including large enterprises, container transport,
                            road transport, couriers and 3PL Warehouses.
                        </p>
                    </div>
                    <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-mytrux/mytrux-website-sec9-4.svg" alt="Mytrux - Overview" class="img-fluid mytrux-website-sec9-4 lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge scroll-div mytrux-logo-sec3" id="section3" data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #0f2541;" data-aos="new-animation2" data-aos-delay="1200" data-aos-anchor="#section3"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3">Challenges</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">
                            Mytrux founders are design enthusiasts with a vision to take their brand across the boundaries, wide and global. Their requirement was to have an identity which can stand for the product differentiators and
                            its core value proposition in a contemporary style. Understanding complex products and its features was important and scary at the very beginning as some of the terms were very new to deal with. But together
                            we did it!
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-right portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3" class="text-white">Creative Brief</h3>
                        <p data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3" class="text-white p2">
                            Our research helped us to understand “Logistics” as a domain along with key offerings of Mytrux product suite. We decided to scribble something in-line with the product's key features and business objective.
                            Intention was to give some clues to their consumers and users.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div mytrux-logo-sec4 pb0" id="section4" data-section-name="data-section4">
        <div class="ani-wrap50-2" style="background-color: #b43258;" data-aos="new-animation2" data-aos-delay="1200" data-aos-anchor="#section4"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="mytrux-logo-sec4-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section4">Brainstorming and Ideas</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4">
                            Check out the glimpses of sketches our team imagined and drafted on paper. Research, ideations and sketching rough ideas to build something new age, global and yet meaningful.
                        </p>
                        <div class="concept-wrap" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section4">
                            <h5>Key Attributes</h5>
                            <p class="p2" style="color: #b43258 !important;">Transportation + Exchange + Trade</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="mytrux-logo-sec4-right" data-aos="fade-in" data-aos-delay="1700" data-aos-anchor="#section4">
                        <img
                            src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec4-right.png"
                            alt="Mytrux - Brainstorming"
                            class="img-fluid mytrux-logo-sec4-right-img lazy"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div mytrux-logo-sec5" id="section5" data-section-name="data-section5">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="mytrux-logo-sec5-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section5">Logo Mark</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5">
                            The logo, iterative formation of location pin symbol. It's chunky, dynamic and blended with modern and contemporary colors for the logistics industry. It stands for new age technology and can be easily used
                            for various touch points to create app icons, print and stationery to offer under the extended branding.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="mytrux-logo-sec5-right" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section5">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-mark.svg" alt="Mytrux - Logo Mark" class="img-fluid mytrux-logo-mark lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div mytrux-logo-sec6" id="section6" data-section-name="data-section6">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="portfolio-info-wrap2" style="margin: auto;"><h3 data-aos="fade-in" data-aos-delay="300" data-aos-anchor="#section6" class="xl-mb100">Color Palette</h3></div>
                    <div class="row">
                        <div class="col-md-6">
                            <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6" class="mytrux-logo-sec6-inner">
                                <img
                                    src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec6-1.svg"
                                    class="mytrux-logo-sec6-1 img-fluid lazy"
                                    alt="Mytrux - Color Palette"
                                />
                                <h5 class="p2 font-family-sans-serif">#DD0B51</h5>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section6" class="mytrux-logo-sec6-inner">
                                <img
                                    src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec6-2.svg"
                                    class="mytrux-logo-sec6-2 img-fluid lazy"
                                    alt="Mytrux - Color Palette"
                                />
                                <h5 class="p2 font-family-sans-serif">#0F2541</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div mytrux-logo-sec7 no-padding comman-comparison" id="section7" data-section-name="data-section7">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300" data-aos-anchor="#section7" style="background-color: #0f2541;"></div>
        <div id="comparison" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section7">
            <figure>
                <div id="handle"></div>
                <div id="divisor"></div>
            </figure>
            <input type="range" min="0" max="100" value="50" id="slider" (input)="divisorChanged($event.target.value)"/>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div mytrux-logo-sec9" id="section8" data-section-name="data-section8">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="portfolio-info-wrap2"><h3 data-aos="fade-in" data-aos-delay="500" data-aos-anchor="#section8">Logo Before &amp; After</h3></div>
                        </div>
                        <div class="col-md-6">
                            <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section8" class="mytrux-logo-sec9-1-outer">
                                <img
                                    src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec9-1.png"
                                    class="mytrux-logo-sec9-1 img-fluid lazy"
                                    alt="Mytrux - Logo Before &amp; After"
                                />
                            </div>
                            <div data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section8"><p class="p2">Old Logo</p></div>
                        </div>
                        <div class="col-md-6">
                            <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section8" class="mytrux-logo-sec9-2-outer">
                                <img
                                    src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec9-2.png"
                                    class="mytrux-logo-sec9-2 img-fluid lazy"
                                    alt="Mytrux - Logo Before &amp; After"
                                />
                            </div>
                            <div data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section8"><p class="p2">New Finalised Logo</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div mytrux-logo-sec10" id="section9" data-section-name="data-section9">
        <div class="ani-wrap100-top bg-blue5" data-aos="new-animation3" data-aos-delay="100" data-aos-anchor="#section9"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="portfolio-info-wrap"><h3 class="text-white font-family-sans-serif" data-aos="fade-in" data-aos-delay="500" data-aos-anchor="#section9">App Icon</h3></div>
                    <ul class="app-icon-ul">
                        <li data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section9">
                            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec10-1.svg" class="mytrux-logo-sec10-1 img-fluid lazy" alt="Mytrux - App Icon" />
                        </li>
                        <li data-aos="fade-in" data-aos-delay="1100" data-aos-anchor="#section9">
                            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec10-2.svg" class="mytrux-logo-sec10-2 img-fluid lazy" alt="Mytrux - App Icon" />
                        </li>
                        <li data-aos="fade-in" data-aos-delay="1300" data-aos-anchor="#section9">
                            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec10-3.svg" class="mytrux-logo-sec10-3 img-fluid lazy" alt="Mytrux - App Icon" />
                        </li>
                        <li data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section9">
                            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec10-4.svg" class="mytrux-logo-sec10-4 img-fluid lazy" alt="Mytrux - App Icon" />
                        </li>
                        <li data-aos="fade-in" data-aos-delay="1700" data-aos-anchor="#section9">
                            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec10-5.svg" class="mytrux-logo-sec10-5 img-fluid lazy" alt="Mytrux - App Icon" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div mytrux-logo-sec11" id="section10" data-section-name="data-section10">
        <div class="ani-wrap100-top-2 bg-red4" data-aos="new-animation3" data-aos-delay="100" data-aos-anchor="#section10"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6">
                    <div class="mytrux-logo-left-wrap portfolio-info-wrap">
                        <h3 class="text-white font-family-sans-serif" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section10">App in Action</h3>
                        <p class="p2 text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section10">Next level of identity for the new age product suite. The splash screen of Mytrux android app with the logo mark.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mytrux-logo-right-wrap portfolio-info-wrap">
                        <div data-aos="fade-in" data-aos-delay="1400" data-aos-anchor="#section10" class="z-index5 position-relative">
                            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec11-1.png" class="mytrux-logo-sec11-1 img-fluid lazy" alt="Mytrux - App in Action" />
                        </div>
                        <div data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section10" class="mytrux-logo-sec11-2-outer">
                            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec11-2.svg" class="mytrux-logo-sec11-2 img-fluid lazy" alt="Mytrux - App in Action" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div mytrux-logo-sec12 comman-bg-section" id="section11" data-section-name="data-section11">
        <div class="ani-wrap100-top" style="background-color: #102035 !important;" data-aos="new-animation3" data-aos-delay="100" data-aos-anchor="#section11"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12" data-aos="fade-in" data-aos-delay="700" data-aos-anchor="#section11">
                    <div class="mytrux-logo-sec12-1-outer">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec12-1.png" class="mytrux-logo-sec12-1 img-fluid lazy" alt="Mytrux - Branding" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section style="background-color: #102035 !important;" class="comman-section comman-section-100vh scroll-div mytrux-logo-sec13 comman-bg-section" id="section12" data-section-name="data-section12">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12" data-aos="fade-in" data-aos-delay="700" data-aos-anchor="#section12">
                    <div class="mytrux-logo-sec13-1-wrap">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec13-1.png" class="mytrux-logo-sec13-1 img-fluid lazy" alt="Mytrux - Branding" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div mytrux-logo-sec14 comman-bg-section" id="section13" data-section-name="data-section13">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div data-aos="fade-right" data-aos-delay="700" data-aos-anchor="#section13" class="z-index5 mytrux-logo-sec14-inner-wrap position-relative">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec14-1.png" class="mytrux-logo-sec14-1 img-fluid lazy" alt="Mytrux - Branding" />
                    </div>
                    <div data-aos="fade-in" data-aos-delay="100" data-aos-anchor="#section13">
                        <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec14-2.svg" class="mytrux-logo-sec14-2 img-fluid lazy" alt="Mytrux - Branding" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page" data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/branding/notionedge"> <span>Notion Edge</span> </a>
                        <p class="p2">Brand Identity for SAP Consulting Company</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>