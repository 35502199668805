import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
// import 'src/assets/widgets/touch-scroll/js/TouchScroll.js';
import AOS from 'aos';

declare let TouchScroll: any;
declare let $: any;
declare let value: any;

@Component({
  selector: 'app-moneyguru-website',
  templateUrl: './moneyguru-website.component.html',
  styleUrls: ['./moneyguru-website.component.scss']
})
export class MoneyguruWebsiteComponent implements OnInit {

  outOfBounds = {
    top: true,
    bottom: false,
    right: false,
    left: true
  };

  
  //new desc
  descrip = 'Check how we delivered Money guru - a fintech enterprise its strategic website ux ui design. The next ux ui design could be yours. Let`s discuss!';

  
  constructor(private titleService: Title, private metaService: Meta) {

    this.setTitle('Website UX UI Design for Fintech Enterprise | Money Guru - Casestudy');
    this.metaService.updateTag({name: 'description', content: this.descrip});
    this.metaService.updateTag({property: 'og:type', content: 'business.business'});
    this.metaService.updateTag({property: 'og:title', content: 'Website UX UI Design for Fintech Enterprise | Money Guru - Casestudy'});
    this.metaService.updateTag({property: 'og:description', content: this.descrip});
  }

  ngOnInit(): void {
    $(function(): void {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });
    $('.comman-header-wrap').show();
    $('#comman-footer').show();
    $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
    $('.arrow-scroll-abs').show(); // shows next
    $('.arrow-scroll-abs .next').removeClass('text-white'); // .text-white (makes next white)
    $('.comman-top-menu').removeClass('comman-top-menu-dark'); // .comman-top-menu-dark (makes menu bg white)
    // all video play
    const vid121 = document.getElementById('vidoe12-1');
    const vid122 = document.getElementById('vidoe12-2');
    const vid123 = document.getElementById('vidoe12-3');
    const vid124 = document.getElementById('vidoe12-4');
    // function vidplay12_1() {vid121.play();}
    // function vidplay12_2() {vid122.play();}
    // function vidplay12_3() {vid123.play();}
    // function vidplay12_4() {vid124.play();}
    // function vidpause12_1() {vid121.pause();}
    // function vidpause12_2() {vid122.pause();}
    // function vidpause12_3() {vid123.pause();}
    // function vidpause12_4() {vid124.pause();}
    $(document).ready(function(): void {
      const wido = $(window).width();
      if (wido >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          overflowscroll: true,
          before(i, panels): void {
            // alert('scrolled');
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.pagination-wrap')
              .find('a[href="#' + ref + '"]')
              .addClass('active');
            $('.comman-top-menu').removeClass('comman-top-menu-dark');
            $('.logo-wrap-our-team').removeClass('active-white-text').show();
            $('.comman-header .logo-wrap-a').show();
            if (ref === 'data-section1') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').hide();
              $('.comman-header .logo-wrap-a').show();
            } else if (ref === 'data-section2') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team')
                .removeClass('active-white-text')
                .fadeIn();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section3') {
              $('.comman-header')
                .addClass('active-menu-white')
                .removeClass('active-logo-white active-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.arrow-scroll-abs').fadeIn();
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section4') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team')
                .removeClass('active-white-text')
                .fadeIn();
              $('.comman-header .logo-wrap-a').hide();
              const viewer = new TouchScroll();
              viewer.init({id: 'viewer', draggable: true, wait: false});
            } else if (ref === 'data-section7') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section8') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section12') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              // vidplay121();
              // vidplay122();
              // vidplay123();
              // vidplay124();
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section-learning') {
              $('.comman-header')
                .addClass('active-menu-white')
                .removeClass('active-logo-white active-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.arrow-scroll-abs').fadeIn();
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section-testimonial') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-comman-footer') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section-next-page') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').hide();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.comman-header .logo-wrap-a').hide();
            } else {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').show();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
          },
          afterRender(): void {
            $('.logo-wrap-our-team').removeClass('active-white-text').hide();
            aosinit();
            // counterNum();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i): void {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
            // var viewer = new TouchScroll();
            // viewer.init({
            //   id: 'viewer',
            //   draggable: true,
            //   wait: false,
            // });
          },
        });
        $('.arrow-scroll-abs .next').click(function(e1): void {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2): void {
          e2.preventDefault();
          $.scrollify.prev();
        });
      }
      // if (wido <= 1025) {
      //   vidplay12_1();
      //   vidplay12_2();
      //   vidplay12_3();
      //   vidplay12_4();
      // }
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }

      function aosinit(): void {
        AOS.init({
          duration: 400,
          anchorPlacement: 'top-top',
          disable(): boolean {
            const maxWidth = 1199;
            return window.innerWidth < maxWidth;
          },
          once: true,
          offset: 20,
        });
      }

    });
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

}
