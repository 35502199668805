import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input';
import AOS from 'aos';
import Swal from 'sweetalert2';

declare let $: any;
declare let grecaptcha: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  
 // new description
  descrip = 'Looking for ui ux design projects partner specializing in ux, ui engineering, brand strategy & identity design for your projects? Your search ends here!';

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  // TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  public phoneForm: FormGroup;

  public showIntrst = false;
  public showSrvc = false;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  public cFormGroup: FormGroup;

  public deleteSwal;
  public valb = 4;
  public selectBudget = '10K-25K';
  public exactvalue: string;
  enabled = true;
  orientation = 'vertical';
  orientationMob = 'horizontal';
  direction = 'ltr';
  tooltip = true;
  ticksTooltip = true;
  ticks: [1, 2, 3, 4, 5, 6];
  ticksLabels = '["+$100K", "$50K-$100K", "$25K-$50K", "$10K-$25K", "$5K-$10K", "$5K"]';

  // public buttonNm = false;

  interests = [
    'Design Audit & Consultancy',
    'UX UI Design (Apps & Websites)',
    'User Research & Testing',
    'Brand Identity Design',
    'Front End Development',
    'Dedicated Resources (UX UI Designer/Developer)'
  ];

  services = [
    'Mobile Application',
    'Web Application',
    'Desktop Application',
    'Website',
    'Other'
  ];

  foundOn = [
    'Google', 'Facebook', 'Linkedin', 'Twitter', 'Other'
  ];

  showErrors = false;
  specificsrc = false;

  foundonHasError = false;
  property = 'default';

  public captchaHasError = false;
  public phnHasError = false;
  public intNumber = 0;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private http: HttpClient,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    
    this.setTitle('UI UX Design Projects India | Prismic Reflections - contact-us');
    this.metaService.updateTag({name: 'description', content: this.descrip});
    this.metaService.updateTag({
      name: 'keywords',
      content: 'web site design, conceptual designs, website logo design, logo branding, ' +
        'corporate identity design, concept design, mumbai, india'
    });
    this.metaService.updateTag({property: 'og:type', content: 'business.business'});
    this.metaService.updateTag({
       property: 'og:title', content: 'UI UX Design Projects' +
      ' India | Prismic Reflections'
    });
    this.metaService.updateTag({property: 'og:description', content: this.descrip});
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

  changePreferredCountries(): void {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }

  nxtClicked(e): void {
    this.checkValidate();
  }

  checkValidate(): void {
    const parentInt = (document.querySelector('.intchkboxmain') as HTMLInputElement);
    const chkint = parentInt.querySelectorAll('.intchkbox input');
    const chkint1 = (chkint[0] as HTMLInputElement).checked;
    const chkint2 = (chkint[1] as HTMLInputElement).checked;
    const chkint3 = (chkint[2] as HTMLInputElement).checked;
    const chkint4 = (chkint[3] as HTMLInputElement).checked;
    const chkint5 = (chkint[4] as HTMLInputElement).checked;
    // console.log(chkint, chkint1, chkint2, chkint3, chkint4, chkint5);
    if (chkint1 || chkint2 || chkint3 || chkint4 || chkint5) {
      this.showIntrst = false;
    } else {
      this.showIntrst = true;
    }
    const parentSrvc = (document.querySelector('.srvchkboxmain') as HTMLInputElement);
    const chksrvc = parentSrvc.querySelectorAll('.srvchkbox input');
    const chksrvc1 = (chksrvc[0] as HTMLInputElement).checked;
    const chksrvc2 = (chksrvc[1] as HTMLInputElement).checked;
    const chksrvc3 = (chksrvc[2] as HTMLInputElement).checked;
    const chksrvc4 = (chksrvc[3] as HTMLInputElement).checked;
    const chksrvc5 = (chksrvc[4] as HTMLInputElement).checked;
    // console.log(chksrvc, chksrvc1, chksrvc2, chksrvc3, chksrvc4, chksrvc5);
    if (chksrvc1 || chksrvc2 || chksrvc3 || chksrvc4 || chksrvc5) {
      this.showSrvc = false;
    } else {
      this.showSrvc = true;
    }
  }

  handleSuccess(e): void {
    console.log('ReCaptcha', e);
  }

  ngOnInit(): void {
    this.cFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });

    this.phoneForm = this.formBuilder.group({
      phone: ['', Validators.required]
    });

    $(function(): void {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });

    function aosinit(): void {
      AOS.init({
        duration: 400,
        anchorPlacement: 'top-top',
        disable(): boolean {
          const maxWidth = 1199;
          return window.innerWidth < maxWidth;
        },
        once: true,
        offset: 20,
      });
    }

    $('.comman-header-wrap').show();
    $('#comman-footer').show();

    $(document).ready(function(): void {
      // var contryCode = ($('.iti__selected-flag').attr('title'));
    
      $('#btn_next').click(function(): void {
      
        if (($('.intchkbox input').filter(':checked').length > 0) && ($('.srvchkbox input').filter(':checked').length > 0)) {
       
          const selectedInterests = $('.intchkbox input:checkbox:checked').map(function(): void {
            return $(this).val();
          }).get();
          $('#selected_interest').text(selectedInterests);
          const selectedFor = $('.srvchkbox input:checkbox:checked').map(function(): void {
            return $(this).val();
          }).get();
          $('#selected_for').text(selectedFor);
          console.log(selectedInterests.toString());
          $('#contact-form-carosual').carousel(1);
          setTimeout(function(): void {
            $('#full_name').focus();
          }, 700);

        }
      });

      //   var wido = $(window).width();
      // contact form start
      //   var interest = 'Branding & Graphics';
      //   var looking_for = 'Mobile Application';
      //   var budget = 1;
      //   $('#btn_next').click(function () {
      //     var interest = [];
      //     var looking_for = [];
      //     $.each($("input[name='interest']:checked"), function () {
      //       interest.push($(this).val());
      //     });
      //     $.each($("input[name='service']:checked"), function () {
      //       looking_for.push($(this).val());
      //     });
      //   });

      $('.logo-wrap-our-team').hide();
      $('.logo-wrap-a').show();
      $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
      $('.arrow-scroll-abs').show();
      $('.arrow-scroll-abs .next').removeClass('text-white');
      $('.comman-top-menu').removeClass('comman-top-menu-dark');
      const wido = $(window).width();
      if (wido >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          overflowscroll: true,
          before(i, panels): void {
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.pagination-wrap')
              .find('a[href="#' + ref + '"]')
              .addClass('active');
            $('.logo-wrap-our-team').removeClass('active-white-text').hide();
            $('.carousel-indicators-ul').hide();
            $('.arrow-scroll-abs .next').removeClass('text-white');
            $('.comman-top-menu').removeClass('comman-top-menu-dark');
            if (ref === 'data-comman-footer') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
            } else {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').show();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            }
          },
          afterRender(): void {
            aosinit();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i): void {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
          },
        });
        $('.arrow-scroll-abs .next').click(function(e1): void {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2): void {
          e2.preventDefault();
          $.scrollify.prev();
        });
        $('#apply-btn').on('click', function(): void {
          $.scrollify.move($(this).attr('data-target'));
        });
      }

      // for carousel class add dynamically
      $('#contact-form-carosual').on('slide.bs.carousel', function(e): void {
        const $el = $(e.relatedTarget);
        if ($el.id === 'firstSlide') {
          $('.carousel-indicators-ul li').removeClass('active');
          $('.carousel-indicators-ul li:first-child').addClass('active');
        } else if ($el.id === 'secondSlide') {
          $('.carousel-indicators-ul li').removeClass('active');
          $('.carousel-indicators-ul li:nth-child(2)').addClass('active');
        }
      });

      // google map init
      $('.nashik-map-open-btn').click(function(e1): void {
        e1.preventDefault();
        $('#nashik-map').addClass('activeMap');
      });

      $('.nashik-map-close-btn').click(function(e2): void {
        e2.preventDefault();
        $('#nashik-map').removeClass('activeMap');
      });

      $('.mumbai-map-open-btn').click(function(e3): void {
        e3.preventDefault();
        $('#mumbai-map').addClass('activeMap');
      });

      $('.mumbai-map-close-btn').click(function(e4): void {
        e4.preventDefault();
        $('#mumbai-map').removeClass('activeMap');
      });
      // mumbai-map-open-btn
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }


    });
  }

  public changedVal(e): void {
    const vala = e.newValue;
    // console.log(e);
    console.log(vala);
    if (vala === 6) {
      this.selectBudget = 'under 5K';
    } else if (vala === 5) {
      this.selectBudget = '5K-10K';
    } else if (vala === 4) {
      this.selectBudget = '10K-25K';
    } else if (vala === 3) {
      this.selectBudget = '25K-50K';
    } else if (vala === 2) {
      this.selectBudget = '50K-100K';
    } else if (vala === 1) {
      this.selectBudget = 'Above 100K';
    } else {
      this.selectBudget = 'no value';
    }

  }

  validateFoundon(value): void {
    if (value === 'default') {
      this.specificsrc = false;
      this.foundonHasError = true;
      // console.log('default');
    } else if (value === 'Other') {
      this.specificsrc = true;
      this.foundonHasError = false;
      // console.log(this.property);
    } else {
      this.specificsrc = false;
      this.foundonHasError = false;
      // console.log('not default');
    }
  }


  submitContact(frm): void {
    console.log('Contact submit clicked!');

    this.captchaHasError = !((!this.cFormGroup.controls.recaptcha.pristine) && (this.cFormGroup.controls.recaptcha.status === 'VALID'));

    if (this.phoneForm.controls.phone.valid) {
      this.phnHasError = false;
      this.intNumber = this.phoneForm.controls.phone.value.internationalNumber;
    } else {
      this.phnHasError = true;
      this.intNumber = 0;
    }


    const selectedInterests = Object.keys(frm.value.intrs).filter(function(key): any {
      return frm.value.intrs[key];
    });
    const allSelectedInterests = selectedInterests.join(', ');
    const selectedServices = Object.keys(frm.value.srvcs).filter(function(key): any {
      return frm.value.srvcs[key];
    });
    const allSelectedServices = selectedServices.join(', ');
    if (this.valb === 6) {
      this.exactvalue = 'under 5K';
    } else if (this.valb === 5) {
      this.exactvalue = '5K-10K';
    } else if (this.valb === 4) {
      this.exactvalue = '10K-25K';
    } else if (this.valb === 3) {
      this.exactvalue = '25K-50K';
    } else if (this.valb === 2) {
      this.exactvalue = '50K-100K';
    } else if (this.valb === 1) {
      this.exactvalue = 'Above 100K';
    } else {
      $('#set_budget').text('null');
      this.exactvalue = 'no value';
    }

    const contact = {
      flag: 'contact',
      full_name: frm.value.full_name,
      email: frm.value.email,
      mobile_no: this.intNumber,
      message: frm.value.message,
      set_interest: allSelectedInterests,
      set_looking_for: allSelectedServices,
      set_budget: this.exactvalue,
      found_on: frm.value.found_on,
      specific_source: frm.value.specific_source
    };

    if (this.foundonHasError || (frm.value.full_name === '') || (frm.value.email === '') || (frm.value.mobile_no === '') ||
      (frm.value.message === '') || (frm.value.i_agree === false) || (frm.value.i_agree === '') || (frm.value.found_on === 'default')
      || this.captchaHasError || this.phnHasError) {
      this.showErrors = true;
    } else {
      this.showErrors = false;
      console.log('Contact form Submitted!');
      frm.reset();
      this.phoneForm.reset();
      console.log(contact);
      this.http.post('https://prismicreflections.com/prenquiry.php', contact).subscribe(
        (response) => {
          console.log('Got Response', response);
        }
      );
      Swal.fire({
        icon: 'success',
        title: 'Thanks for submitting your request. <br class="d-block"/> We will get back to you soon!',
        showConfirmButton: false,
        timer: 1800
      });
      $('#contact-form-carosual').carousel(0);
    }


  }

  gotohome(): void {
    this.router.navigateByUrl('/home');
  }

}
