<div class="portfolio-branding-wellness-body">
    <section class="comman-section comman-section-100vh scroll-div wellness-logo-sec1" id="section1"
        data-section-name="data-section1">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center text-center">
                <div class="col-sm-12 xs-mt70">
                    <div class="mytrux-log-center-wrap portfolio-info-banner-c" data-aos="fade-in" data-aos-delay="300"
                        data-aos-anchor="#section1">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-insignia/wellness-logo-update.svg"
                            alt="wellness - logo" class="img-fluid wellness-logo-img logo-img lazy" />
                        <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                            Holistic Wellbeing with <br>
                            Ancient Yogic & Ayurvedic Treasure
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">
                Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>

    <section class="comman-section comman-section-100vh scroll-div wellness-sec2" id="section2"
        data-section-name="data-section2">
        <div class="ani-wrap100-top" data-aos="new-animation3" data-aos-delay="300" data-aos-anchor="#section2"
            style="background-color: #333333;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="easy-plans2-center overview-sec-center portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                            Overview</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2">
                            WellnessInsignia is an Indian startup, founded amidst pandemic by a young corporate professional in pursuit of good health for himself. With the purpose to help humankind to achieve the perfect balance of mental and physical health, WellnessInsignia offers high-quality training content & programs for yoga-practicing professionals as well as beginners.
                            We partnered with the founder to translate his vision of bringing happiness and freedom for everyone, into a fresh new visual identity. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="comman-section comman-section-100vh comman-solution-challenge scroll-div wellness-sec3" id="section3"
        data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #fff;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3"></div>
        <div class="ani-wrap50" style="background: #561E5E;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section3"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <div class="vigan-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3" class="text-white">
                            Challenges</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">
                            Yoga & Ayurveda have their roots in India, however rarely do we see any Indian brand grow really big in this sector. Also, adoption of digital technology is not done to its fullest potential in these two domains. WellnessInsginia wanted to project themselves as a technolodically advanced organisation.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="vigan-right-wrap portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section3">Creative Brief</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section3">
                            Focusing mainly in European customers primarily, the brand aims to trigger the feeling of happiness and freedom from its new visual identity. The brand also represents the genuine source of ancient Vedic wisdom and yogic practices designed for a modern-day lifestyle. It promises holistic wellbeing by delivering high-quality content & training programs using the advanced technological platform. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="comman-section comman-section-100vh scroll-div wellness-sect5 pb0" id="section5"
        data-section-name="data-section5">
        <div class="ani-wrap50" style="background-color: #561E5E;" data-aos="new-animation2" data-aos-delay="600"
            data-aos-anchor="#section5"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12"></div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="vigan-right-wrap portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section5" class="no-wrap">Ideas & <br>
                            Concept Sketches</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="wellness-sec5-left" data-aos="fade-in" data-aos-delay="900"
            data-aos-anchor="#section5">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-branding-insignia/wellness-sect4.png"
                alt="Smartlink - Brainstorming" class="img-fluid smartlinks-sect4-1 smartlinks-sect4-1-img lazy" />
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div smartlinks-sec4 wellness-sect4 pb0" id="section4"
        data-section-name="data-section4">
        <div class="ani-wrap50-2" style="background-color: #e12166;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section4"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="smartlinks-sec4-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section4">Logo Mark <br>
                            Concept</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4">
                            Newly created logo mark focuses on key brand attributes - Freedom, Happiness, Authoritative & Perfectionist. 
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12"></div>
            </div>
        </div>
        <div class="smartlinks-sec4-right wellness-sec4-right" data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section4">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-branding-insignia/wellness-idea.png"
                alt="Notion Edge - Overview" class="img-fluid smartlinks-sect4-1 lazy" />
        </div>
    </section>


    <section class="comman-section comman-section-100vh scroll-div phycheme-sec6" id="section6"
        data-section-name="data-section6">
        <div class="ani-wrap100-top-2" style="background-color: #EE434E;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section6"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="phycheme-sec6-left text-center mb30">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section6">
                            Logo Construction</h3>
                    </div>
                    <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section6"
                        class="phycheme-sec6-inner text-center">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-insignia/wellness-sec6.svg"
                            class="phycheme-sec6-1 img-fluid lazy" alt="Phycheme - Logo Construction" />
                    </div>
                </div>
            </div>
        </div>
        <div class="phycheme-sec6-3-outer">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-branding-phycheme/phycheme-sec6-3.svg"
                class="phycheme-sec6-2 img-fluid lazy" alt="Phycheme - Logo Construction" />
        </div>
    </section>

    <!-- <section class="comman-section comman-section-100vh scroll-div phycheme-sec6" id="section7" data-section-name="data-section7">
        <div class="ani-wrap100-top-2" style="background-color: #FFFFFF;" data-aos="new-animation3" data-aos-delay="100" data-aos-anchor="#section7"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section7"
                        class="phycheme-sec6-inner text-center">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-insignia/wellness-sec7.svg"
                            class="phycheme-sec6-1 img-fluid lazy" alt="Phycheme - Logo Construction" />
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <section class="comman-section comman-section-100vh scroll-div phycheme-sec5 no-padding overflow-hidden" id="section7"
        data-section-name="data-section7" style="background-color: #0a0333;">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section7"></div>
        <div class="container-fluid h-100 xs-no-padding">
            <div class="row">
                <div class="col-md-12 no-padding">
                    <owl-carousel-o [options]="customOptionsInsig" class="owl-caroual-comman-fade wellness-logo-caroual">
                        <ng-template carouselSlide>
                            <div class="wellness-caroual-item1 wellness-caroual-item">
                            <img class="owl-lazy img-fluid"
                                src="assets/images/portfolio/portfolio-branding-insignia/wellness-logo-concept-1.svg"
                                alt="Phycheme - Logo concept" />
                                </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="wellness-caroual-item2 wellness-caroual-item">
                            <img class="owl-lazy img-fluid"
                                src="assets/images/portfolio/portfolio-branding-insignia/wellness-logo-concept-2.svg"
                                alt="Phycheme - Logo concept" />
                                </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="wellness-caroual-item3 wellness-caroual-item">
                            <img class="owl-lazy img-fluid"
                                src="assets/images/portfolio/portfolio-branding-insignia/wellness-logo-concept-3.svg"
                                alt="Phycheme - Logo concept" />
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </section>

    <section class="comman-section comman-section-100vh scroll-div wellness-sec8 no-padding comman-bg-section" id="section8"
        data-section-name="data-section8">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section8" style="background-color: #561E5E;"></div>
        <div class="container h-100"></div>
    </section>

    <section class="comman-section comman-section-100vh scroll-div wellness-sec9 no-padding comman-bg-section" id="section9"
        data-section-name="data-section9">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section9" style="background-color: #561E5E;"></div>
        <div class="container h-100"></div>
    </section>

    <section class="comman-section comman-section-100vh impact-section-upd scroll-div" id="section-testimonial"
        data-section-name="data-section-testimonial">
        <div class="ani-wrap100-top-2" style="background-color: #242424;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="comman-impact-bottom" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section-testimonial" style="margin: auto !important;">
                        <div class="card-img-wrap"><img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/testimonial/placeholder.png" alt="Umakant Savadekar"
                                class="comman-impact-img lazy" /></div>
                        <h6 class="text-white">Mr. XXXXX</h6>
                        <h6 class="text-white" style="opacity: 0.5;">XXX, Pharmadesk</h6>
                        <p class="p3 text-white">
                            <span>
                                <i class="fas fa-quote-left"></i>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non pretium interdum imperdiet nisl posuere lacinia ullamcorper.
                                <i class="fas fa-quote-right"></i>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page" data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/branding/notionedge"> <span>Notion Edge</span> </a>
                        <p class="p2">Brand Identity for SAP Consulting Company</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>