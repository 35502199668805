<div class="container-fluid container-fluid-custom" >
    <div class="row">
        <div class="col-lg-6">
            <div class="table-responsive">
                <table class="table table-bordered" style="width: 100% !important;margin-bottom: 30px;">
                    <thead>
                        <tr class="header">
                            <th colspan="4" style="text-align: center; background: #f4f4f4; border-bottom: 0;">Common pages</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="4" style="padding: 0; border: 0;">
                                <div class="table-scroll">
                                    <table class="table2">
                                        <tbody>
                                            <tr>
                                                <td style="width: 260px;"><a target="_blank" href="styleguide">styleguide</a></td>
                                                <td colspan="3"><b class="text-secondary">for testing purpose</b></td>
                                            </tr>
                                            <tr>
                                                <td><a target="_blank" href="typography">typography</a></td>
                                                <td colspan="3"><b class="text-secondary">for testing purpose</b></td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/status">Project Status</a></td>
                                                <td colspan="3"><b class="text-secondary">for tracking purpose</b></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>Web view</td>
                                                <td>Responsive</td>
                                                <td>Status</td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/home">Homepage</a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk1" type="checkbox" />
                                                        <label for="serviceChk1" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/about">about</a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk2" type="checkbox" />
                                                        <label for="serviceChk2" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/our-work">our-work</a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk3" type="checkbox" />
                                                        <label for="serviceChk3" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/contact">contact</a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk4" type="checkbox" />
                                                        <label for="serviceChk4" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/services/ux-ui-design-process">ux-ui-design-process</a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk5" type="checkbox" />
                                                        <label for="serviceChk5" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/services/user-experience-design">user-experience-design</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk6" type="checkbox" />
                                                        <label for="serviceChk6" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/services/design-research-consultancy">
                                                        design-research-consultancy</a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk7" type="checkbox" />
                                                        <label for="serviceChk7" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/design-experiments">
                                                        design-experiments</a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk7" type="checkbox" />
                                                        <label for="serviceChk7" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/services/brand-strategy-identity">brand-strategy-identity</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk8" type="checkbox" />
                                                        <label for="serviceChk8" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/career">career</a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk9" type="checkbox" />
                                                        <label for="serviceChk9" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/industries">industries</a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk10" type="checkbox" />
                                                        <label for="serviceChk10" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/privacy-term">privacy-term</a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk11" type="checkbox" />
                                                        <label for="serviceChk11" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a target="_blank" href="https://www.prismicreflections.com/blog/">Blog</a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk12" type="checkbox" />
                                                        <label for="serviceChk12" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/error404">404error</a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk13" type="checkbox" />
                                                        <label for="serviceChk13" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="table-responsive">
                <table class="table table-bordered" style="width: 100% !important;">
                    <thead>
                        <tr class="header">
                            <th colspan="4" style="text-align: center; background: #f4f4f4; border-bottom: 0;">Portfolio Pages</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="4" style="padding: 0; border: 0;">
                                <div class="table-scroll">
                                    <table class="table2">
                                        <tbody>
                                            <tr>
                                                <td colspan="2"></td>
                                                <td>Web view</td>
                                                <td>Responsive</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 260px;"><a routerLink="/portfolio/branding/mytrux">portfolio/branding/mytrux</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk14" type="checkbox" />
                                                        <label for="serviceChk14" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/branding/notionedge">portfolio/branding/notion-edge
                                                    </a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk15" type="checkbox" />
                                                        <label for="serviceChk15" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/branding/phychem">portfolio/branding/phychem</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk16" type="checkbox" />
                                                        <label for="serviceChk16" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/branding/smartlink">portfolio/branding/smartlink </a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk17" type="checkbox" />
                                                        <label for="serviceChk17" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/branding/vigan">portfolio/branding/vigan-tecnologies
                                                    </a></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk18" type="checkbox" />
                                                        <label for="serviceChk18" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/branding/vimson">portfolio/branding/vimson</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk19" type="checkbox" />
                                                        <label for="serviceChk19" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/mobile-app/aaloch">portfolio/mobile-app/aaloch</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk20" type="checkbox" />
                                                        <label for="serviceChk20" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/mobile-app/easy-plan">portfolio/mobile-app/easy-plan</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk21" type="checkbox" />
                                                        <label for="serviceChk21" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/mobile-app/moneyguru">portfolio/mobile-app/moneyguru</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk22" type="checkbox" />
                                                        <label for="serviceChk22" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/web-app/authntick">portfolio/web-app/authntick</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk23" type="checkbox" />
                                                        <label for="serviceChk23" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/web-app/mytrux">portfolio/web-app/mytrux</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk24" type="checkbox" />
                                                        <label for="serviceChk24" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/web-app/smart360">portfolio/web-app/smart360</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk24" type="checkbox" />
                                                        <label for="serviceChk24" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/web-app/esop">portfolio/web-app/esop</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk24" type="checkbox" />
                                                        <label for="serviceChk24" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/website/bynry">portfolio/website/bynry</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk25" type="checkbox" />
                                                        <label for="serviceChk25" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/website/crubba">portfolio/website/crubba</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk26" type="checkbox" />
                                                        <label for="serviceChk26" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/website/insisive">portfolio/website/insisive</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk27" type="checkbox" />
                                                        <label for="serviceChk27" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/website/moneyguru">portfolio/website/moneyguru</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk28" type="checkbox" />
                                                        <label for="serviceChk28" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><a routerLink="/portfolio/website/mytrux">portfolio/website/mytrux</a>
                                                </td>
                                                <td><span class="text-warning">working</span></td>
                                                <td><span class="text-warning">working</span></td>
                                                <td>
                                                    <div class="custom-checkbox checkbox-adj">
                                                        <input name="service" class="service-chk" id="serviceChk29" type="checkbox" />
                                                        <label for="serviceChk29" class="label-checkbox h6"><span class="checkmark"></span>
                                                            <span class="p4 text-warning">Approved</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>