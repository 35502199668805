<div class="services-page-body">
    <section class="comman-section comman-section-100vh scroll-div expertise-sect1 top-section-wrapper" id="section1"
        data-section-name="data-section1">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-7 order-md-last-adj">
                    <div class="comman-card-info xs-mt-30">
                        <h1 class="h1 sec-heading hide-mobile" data-aos="fade-in" data-aos-delay="700"
                            data-aos-anchor="#section1"> Design Research <br> &amp; Consultancy</h1>
                        <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                            <table class="expertise-tbl">
                                <tr>
                                    <td>
                                        <ul class="industries-list">
                                            <li class="p2-lora"> User Research</li>
                                            <li class="p2-lora"> Design Evaluation/Audit</li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul class="industries-list">
                                            <li class="p2-lora"> Design Strategy Workshops</li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-5">
                    <div>
                        <h1 class="h1 sec-heading show-mobile xs-text-center" data-aos="fade-in" data-aos-delay="700"
                            data-aos-anchor="#section1"> Design Research <br> &amp; Consultancy</h1>
                        <div class="comman-card-img-wrap services-user-exp-card">
                            <div data-aos="fade-in" data-aos-delay="2800" data-aos-anchor="#section1"
                                class="position-relative z-index9"> <img
                                    src="assets/images/banner/services-user-exp/services-design-1.svg"
                                    alt="User Experience Design" class="services-design-1 lazy" /></div> 
                            <div data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section1"
                                class="position-relative z-index6"> <img
                                    src="assets/images/banner/services-user-exp/services-design-3.png"
                                    alt="User Experience Design" class="services-design-3 lazy" /></div> <img
                                src="assets/images/banner/services-user-exp/services-design-4.svg"
                                alt="User Experience Design" class="services-design-4 lazy floatingX-l"
                                data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section1" /> <img
                                src="assets/images/banner/services-user-exp/services-design-5.svg"
                                alt="User Experience Design" class="services-design-5 lazy floatingX-r"
                                data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section1" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs"> <a href="#" class="next">Scroll<div class="c-scrolldown">
                    <div class="c-line"></div>
                </div> </a></div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div expertise-sect2 xs-pt0" id="section2"
        data-section-name="data-section2">
        <div class="container expertise-container">
            <div class="row expertise-sect-row expertise-sect-row-1-wrap">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="comman-card-info expertise-sect-left">
                        <div class="expertise-sect-left-head">
                            <h3 class="sec-heading txt-adj"> User Research</h3>
                        </div>
                        <p class="p2 sec-para txt-adj"> Eliminating guessing &amp; assumption games, we initiate
                            purposeful communication with real users to understand not only WHAT but the deeper WHY
                            behind it. Using various UX Design research methods, at various stages, our scientific user
                            research process helps you to uncover the facts &amp; identify hidden opportunities to tap
                            into.</p>
                        <div class="expertise-sect-left-body">
                            <ul class="list-inline no-wrap">
                                <li class="list-inline-item"></li>
                                <li class="list-inline-item">
                                    <a [routerLink]="['/contact']" class="bttn btn-line btn-primary-custom text-center"><span>Let's Talk</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="expertise-sect-right">
                        <h6 class="font-weight-medium"> Business value of User Research</h6>
                        <ul class="expertise-ul-list ul-purple-adj p3">
                            <li> Creates deeper empathy for user to help business teams across all departments</li>
                            <li> Saves time &amp; money in building wrong products/concepts</li>
                            <li> Backs up your business and design decision based on research outcomes.</li>
                            <li> Builds something that someone will actually use</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div expertise-sect2 xs-pt0" id="section3"
        data-section-name="data-section3">
        <div class="container expertise-container">
            <div class="row expertise-sect-row expertise-sect-row-2-wrap">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="comman-card-info expertise-sect-left">
                        <div class="expertise-sect-left-head">
                            <h3 class="sec-heading txt-adj"> Design Audit</h3>
                        </div>
                        <p class="p2 sec-para txt-adj"> Want to understand the real problems and potential in existing
                            product/websites? Design Audit can help you provide qualitative insights &amp;
                            recommendations from UX experts on how your platform can be improved in terms of usability
                            and overall experience.</p>
                        <div class="expertise-sect-left-body">
                            <ul class="list-inline no-wrap">
                                <li class="list-inline-item">
                                    <a [routerLink]="['/contact']" class="bttn btn-line btn-primary-custom text-center"><span> Enquire Now</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="expertise-sect-right">
                        <h6 class="font-weight-medium"> Benefits of Design Audit</h6>
                        <ul class="expertise-ul-list ul-purple-adj p3">
                            <li> Identifies problem and opportunity areas in the existing product.</li>
                            <li> Provides clarity upfront before you for complete redesign.</li>
                            <li> Your internal team gets solid takeaways and recommendations from experts for immediate
                                implementation.</li>
                            <li> Takes lesser time &amp; uncovers majority of usability and experience issues.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div expertise-sect2 xs-pt0" id="section4"
        data-section-name="data-section4">
        <div class="container expertise-container">
            <div class="row expertise-sect-row expertise-sect-row-3-wrap">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="comman-card-info expertise-sect-left">
                        <div class="expertise-sect-left-head">
                            <h3 class="sec-heading txt-adj"> Design Strategy Workshops</h3>
                        </div>
                        <p class="p2 sec-para txt-adj"> Need help in putting a Strategy in place before designing with
                            an internal team? Our experienced strategists, having more than 18 years of experience in
                            designing 100+ consumer and enterprise products can help you take strategic decisions that
                            will support your business goals.</p>
                        <div class="expertise-sect-left-body">
                            <ul class="list-inline no-wrap">
                                <li class="list-inline-item">
                                    <a [routerLink]="['/contact']" class="bttn btn-line btn-primary-custom text-center"><span>Enquire Now</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="expertise-sect-right">
                        <h6 class="font-weight-medium"> How do we do it?</h6>
                        <ul class="expertise-ul-list ul-purple-adj p3">
                            <li> Closely working with C Level executives to understand the business challenges and
                                positioning</li>
                            <li> Understanding platform specific challenges</li>
                            <li> User centered analysis</li>
                            <li> Competition overview</li>
                            <li> Provide clear UX strategy roadmap document</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div expertise-sect-9 xs-pt0 xs-pb0 pb0" id="section5"
        data-section-name="data-section5">
        <div class="container">
            <div class="row vh100-100 align-items-center justify-content-center overflow-hidden">
                <div class="col-sm-12 text-center">
                    <div class="row">
                        <div class="col-sm-12">
                            <h3> Engagement Models</h3>
                        </div>
                        <div class="col-sm-12">
                            <div class="comman-scrollbar-wrap">
                                <table class="table-expertise">
                                    <tr>
                                        <td>
                                            <div class="comman-card-info text-center">
                                                <div class="process-img-wrapper2"> <img
                                                        src="assets/images/preloader/ajax-loader.png"
                                                        data-src="assets/images/process/evolving-design.svg" alt="Discover"
                                                        class="process-image lazy mb25" /></div>
                                                <h5>Evolving<br class="hide-mobile"> Design</h5>
                                                <p class="p3 sec-para text-light-dark-c"> When you are on idea level,
                                                    there is absence of clear scope documentation and ongoing innovation
                                                    is required; this model is a right fit.</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="comman-card-info text-center">
                                                <div class="process-img-wrapper2"> <img
                                                        src="assets/images/preloader/ajax-loader.png"
                                                        data-src="assets/images/process/fixed-scope-projects.svg"
                                                        alt="Discover" class="process-image lazy mb25" /></div>
                                                <h5>Fixed Scope <br class="hide-mobile">Projects</h5>
                                                <p class="p3 sec-para text-light-dark-c"> This is well suited for the
                                                    project and those are well documented, boundary lines defined &amp;
                                                    easy for assessment. A Lump Sum fee can be given for such projects.
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="comman-card-info text-center">
                                                <div class="process-img-wrapper2"> <img
                                                        src="assets/images/preloader/ajax-loader.png"
                                                        data-src="assets/images/process/strategic-consultancy.svg"
                                                        alt="Discover" class="process-image lazy mb25" /></div>
                                                <h5>Strategic <br class="hide-mobile"> Consultancy</h5>
                                                <p class="p3 sec-para text-light-dark-c"> Where there is already
                                                    something built but needs 3rd person/expert overview or you have an
                                                    in-house team that needs external expert hand holding you can opt
                                                    for this. Fee Structure: Per expert, per hour with minimum
                                                    engagement criteria.</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="comman-card-info text-center">
                                                <div class="process-img-wrapper2"> <img
                                                        src="assets/images/preloader/ajax-loader.png"
                                                        data-src="assets/images/process/dedicated-resource.svg"
                                                        alt="Discover" class="process-image lazy mb25" /></div>
                                                <h5>Dedicated <br class="hide-mobile"> Resource</h5>
                                                <p class="p3 sec-para text-light-dark-c"> You hire a dedicated person
                                                    with specific skill sets (UX Researcher, Visual designer, UI
                                                    engineer etc), assign any task and manage them by your own. Fee
                                                    Structure: Monthly Per resource with minimum engagement criteria.
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" [routerLink]="['/services//user-experience-design']"><span>User Experience Design</span> </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>