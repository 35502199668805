<div class="home-page-body">
  <section
    class="comman-section comman-section-100vh scroll-div home-sec1 top-section-wrapper"
    data-section-name="data-section1"
    id="section1"
  >
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-7 order-md-last-adj">
          <div class="comman-card-info">
            <h1 class="mb35 h6 text-white" style="height: 0">
              Research, Strategy, UX UI Design
            </h1>
            <h6
              class="h1 sec-heading hide-mobile"
              data-aos="fade-in"
              data-aos-delay="300"
            >
              Success By<br class="d-block" />
              Strategic Design
            </h6>
            <!-- <p class="p1 sec-para" data-aos="fade-in" data-aos-delay="600">
              Designing human-centric experiences for <br />
              digital products &amp; platforms for 18 years
            </p> -->
             <p class="p1 sec-para" data-aos="fade-in" data-aos-delay="600">
              We build human-centric experiences that<br />
              win customer hearts & drive business growth.
            </p>

             

            <div data-aos="fade-in" data-aos-delay="900">
              <a
                [routerLink]="['/our-work']"
                class="bttn btn-line btn-primary-custom"
                ><span>Our Work</span></a
              >

              <a class="bttn btn-link-custom btn-line reset-btn podcast-all-btn hide-mobile show-desktop"
              [routerLink]="['/contact']" ><span>Contact us</span></a>
            </div>

         
           
            

          </div>
        </div>
        
        <a class="bttn btn-link-custom btn-line reset-btn mobile-contact-btn hide-desktop show-mobile"
        [routerLink]="['/contact']" ><span>Contact us</span></a>
    
        <div class="col-sm-12 col-md-12 col-lg-5">
          <h6
            class="h1 sec-heading show-mobile"
            style="margin-top: 55px"
            data-aos="fade-in"
            data-aos-delay="300"
          >
            Success By<br class="d-block" />
            Strategic Design
          </h6>
          <div class="comman-card-img-wrap-upd hide-mobile">
            <img
              src="assets/images/banner/home-sect-1.png"
              alt="Success by Strategic Design"
              class="home-sect-1"
              data-aos="fade-in"
              data-aos-delay="1200"
              data-aos-anchor="#section1"
            />
            <img
              src="assets/images/banner/home-sect-2.png"
              alt="Success by Strategic Design"
              class="home-sect-2"
              data-aos="fade-in"
              data-aos-delay="1600"
              data-aos-anchor="#section1"
            />
            <img
              src="assets/images/banner/home-sect-3.svg"
              alt="Success by Strategic Design"
              class="home-sect-3"
            />
            <img
              src="assets/images/banner/home-sect-4.svg"
              alt="Success by Strategic Design"
              class="home-sect-4 floatingX-r"
            />
            <img
              src="assets/images/banner/home-sect-5.svg"
              alt="Success by Strategic Design"
              class="home-sect-5 floatingX-r hide-desktop hide-mobile"
              data-aos="fade-in"
              data-aos-delay="2100"
              data-aos-anchor="#section1"
            />
            <div
              class="home-sect-6"
              data-aos="width-plus"
              data-aos-duration="1500"
              data-aos-delay="2700"
              data-aos-anchor="#section1"
            >
              <img
                src="assets/images/banner/home-sect-6.svg"
                alt="Success by Strategic Design"
                class="home-sect-6-img"
              />
            </div>
          </div>
          <div class="comman-card-img-wrap-upd2 show-mobile">
            <div
              class="z-index8"
              data-aos="fade-in"
              data-aos-delay="1200"
              data-aos-anchor="#section1"
            >
              <img
                src="assets/images/banner/home-sect-xs-1.png"
                alt="Success by Strategic Design"
                class="home-sect-1 lazy img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="arrow-scroll-abs">
      <a href="#" class="next">
        Scroll
        <div class="c-scrolldown">
          <div class="c-line"></div>
        </div>
      </a>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh scroll-div home-sec2"
    id="section2"
    data-section-name="data-section2"
  >
    <div class="container h-100">
      <div data-aos="fade-in" data-aos-delay="700" data-aos-anchor="#section2">
        <div
          id="home-carosual2"
          class="carousel slide carousel-fade carousel-comman-wrap h-100"
          data-ride="carousel"
          data-interval="4000"
        >
          <ol class="carousel-indicators hide-mobile">
            <li
              data-target="#home-carosual2"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#home-carosual2" data-slide-to="1"></li>
            <li data-target="#home-carosual2" data-slide-to="2"></li>
            <li data-target="#home-carosual2" data-slide-to="3"></li>
          </ol>
          <ul
            class="carousel-indicators-ul hide-mobile"
            style="top: 380px; transform: translateY(0)"
          >
            <li data-target="#home-carosual2" data-slide-to="0" class="active">
              <a href="#home-carosual2" data-slide-to="0">
                <svg height="25" width="25">
                  <circle
                    class="circle-svg-wrap"
                    cx="11"
                    cy="11"
                    r="10"
                    stroke="#242424"
                    stroke-width="2"
                    fill-opacity="0"
                  />
                </svg>
              </a>
            </li>
            <li data-target="#home-carosual2" data-slide-to="1">
              <a href="#home-carosual2" data-slide-to="1">
                <svg height="25" width="25">
                  <circle
                    class="circle-svg-wrap"
                    cx="11"
                    cy="11"
                    r="10"
                    stroke="#242424"
                    stroke-width="2"
                    fill-opacity="0"
                  />
                </svg>
              </a>
            </li>
            <li data-target="#home-carosual2" data-slide-to="2">
              <a href="#home-carosual2" data-slide-to="2">
                <svg height="25" width="25">
                  <circle
                    class="circle-svg-wrap"
                    cx="11"
                    cy="11"
                    r="10"
                    stroke="#242424"
                    stroke-width="2"
                    fill-opacity="0"
                  />
                </svg>
              </a>
            </li>
            <li data-target="#home-carosual2" data-slide-to="3">
              <a href="#home-carosual2" data-slide-to="3">
                <svg height="25" width="25">
                  <circle
                    class="circle-svg-wrap"
                    cx="11"
                    cy="11"
                    r="10"
                    stroke="#242424"
                    stroke-width="2"
                    fill-opacity="0"
                  />
                </svg>
              </a>
            </li>
          </ul>
          <div class="carousel-inner h-100">
            <div id="firstSlide" class="carousel-item h-100 active">
              <div
                class="row vh100-100 align-items-center justify-content-center bg-white"
              >
                <div class="col-sm-12 col-md-6 col-lg-7 order-xs-last">
                  <div class="comman-card-info">
                    <ul
                      class="list-inline comman-card-top-ul"
                      data-animation="animated fadeIn delay-03s"
                    >
                      <li class="list-inline-item h6">Intelehealth</li>
                      <li class="list-inline-item h6">Health tech, Telehealth</li>
                    </ul>
                    <h2
                      class="h2 sec-heading"
                      data-animation="animated fadeIn delay-06s"
                    >
                    
                      Delivering 
Quality Healthcare<br> Beyond Reach
                    </h2>
                    <p
                      class="p2-lora sec-para"
                      data-animation="animated fadeIn delay-09s"
                    >
                      Mobile & Web Application redesign to improve user engagement, usability &amp; reduce training cost.
                    </p>
                    <!-- <ul
                      class="list-inline comman-card-bottom-ul"
                      data-animation="animated fadeIn delay-1-2s"
                    >
                      <li class="list-inline-item h6 text-red2">
                        20% Increased CRO
                      </li>
                    </ul> -->
                    <div data-animation="animated fadeIn delay-1-5s">
                      <a
                        [routerLink]="['/portfolio/web-app/intelehealth']"
                        class="bttn btn-line btn-primary-custom"
                        ><span>View Case Study</span></a
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-5">
                  <div class="comman-card-img-wrap hide-mobile">
                   


                    <img
                    src="assets/images/home/slider/home-sec3-1.png"
                    alt="Moneyguru"
                    class="home-sec3-1-new lazy delay-1-8s"
                    data-animation="animated fadeInDown30" style="z-index: 9;top:-50px;left: 30px;"
                  />
                 
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/home/slider/home-sec3-3.svg"
                    alt="Moneyguru"
                    class="home-sec3-3 floatingX-r lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/home/slider/home-sec3-4.svg"
                    alt="Moneyguru"
                    class="home-sec3-4 floatingX-l lazy"
                  />




                  </div>
                  <div
                    class="comman-card-img-wrap-xs show-mobile text-center mb10"
                  >
                    <img
                      src="assets/images/preloader/ajax-loader.png"
                      data-src="assets/images/home/slider/thumb-xs-deliQuality.png"
                      alt="Gadgetshieldz"
                      class="lazy img-fluid" style="transform: scale(0.7);"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="secondSlide" class="carousel-item h-100">
              <div
                class="row vh100-100 align-items-center justify-content-center bg-white"
              >
                <div class="col-sm-12 col-md-6 col-lg-7 order-xs-last">
                  <div class="comman-card-info">
                    <ul
                      class="list-inline comman-card-top-ul"
                      data-animation="animated fadeIn delay-03s"
                    >
                      <li class="list-inline-item h6">Moneyguru</li>
                      <li class="list-inline-item h6">
                        Fintech
                      </li>
                    </ul>
                    <h2
                      class="h2 sec-heading no-wrap-lg position-relative z-index6"
                      data-animation="animated fadeIn delay-06s"
                    >
                  
                    Empowering Smart<br>
                    Savers With Tech <br> &
                    Insights
                    </h2>
                    <p
                      class="p2-lora sec-para"
                      data-animation="animated fadeIn delay-09s"
                    >
                    Extremely simple & seamless mutual funds investment user experience for mobile app & website
                    </p>
                    <ul
                      class="list-inline comman-card-bottom-ul"
                      data-animation="animated fadeIn delay-1-2s"
                    >
                      <li class="list-inline-item h6 text-orange" style="color: #FF6600 !important; ">
                        10k+ Downloads 
                      </li>
                      <li class="list-inline-item h6 text-orange" style="color: #FF6600 !important;">
                        4.4 <i class="fas fa-star star-icon"></i> Play store
                        Ratings
                      </li>
                    
                    </ul>
                    <div data-animation="animated fadeIn delay-1-5s">
                      <a
                        [routerLink]="['/portfolio/mobile-app/moneyguru']"
                        class="bttn btn-line btn-primary-custom"
                        ><span>View Case Study</span></a
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-5">
                  <div class="comman-card-img-wrap hide-mobile">
                    <img
                      src="assets/images/home/home-sec3-1.png"
                      alt="Moneyguru"
                      class="home-sec3-1 lazy delay-1-8s"
                      data-animation="animated fadeInDown30"
                    />
                    <img
                      src="assets/images/home/home-sec3-2.png"
                      alt="Moneyguru"
                      class="home-sec3-2 lazy delay-2-4s"
                      data-animation="animated fadeInRight30"
                    />
                    <img
                      src="assets/images/preloader/ajax-loader.png"
                      data-src="assets/images/home/home-sec3-3.svg"
                      alt="Moneyguru"
                      class="home-sec3-3 floatingX-r lazy"
                    />
                    <img
                      src="assets/images/preloader/ajax-loader.png"
                      data-src="assets/images/home/home-sec3-4.svg"
                      alt="Moneyguru"
                      class="home-sec3-4 floatingX-l lazy"
                    />
                  </div>
                  <div
                    class="comman-card-img-wrap-xs show-mobile text-center mb10"
                  >
                    <img
                      src="assets/images/preloader/ajax-loader.png"
                      data-src="assets/images/home/thumb-xs-moneyguru.png"
                      alt="Moneyguru"
                      class="lazy img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="thirdSlide" class="carousel-item h-100">
              <div
              class="row vh100-100 align-items-center justify-content-center bg-white"
            >
              <div class="col-sm-12 col-md-6 col-lg-7 order-xs-last">
                <div class="comman-card-info">
                  <ul
                    class="list-inline comman-card-top-ul"
                    data-animation="animated fadeIn delay-03s"
                  >
                    <li class="list-inline-item h6">ESOP Direct</li>
                    <li class="list-inline-item h6">Employee Stock Option Administration</li>
                  </ul>
                  <h2
                    class="h2 sec-heading"
                    data-animation="animated fadeIn delay-06s"
                  >
                    Employee Stock Administration <br />
                    Made Intuitive &amp; Simple
                  </h2>
                  <p
                    class="p2-lora sec-para"
                    data-animation="animated fadeIn delay-09s"
                  >
                    Reimagining the employee experience for stock option exercise enterprise platform
                  </p>
                  <ul
                    class="list-inline comman-card-bottom-ul bullet-color"
                    data-animation="animated fadeIn delay-1-2s"
                  >
                    <li class="list-inline-item h6 text-red2" style="color: #3346d3 !important;font-size: 15px;">Improved Task Success Ratio</li>
                    <li class="list-inline-item h6 text-red2" style="color: #3346d3 !important;font-size: 15px;">
                      Increase Customer Satisfaction 
                    </li>
                  </ul>
                  <div data-animation="animated fadeIn delay-1-5s">
                    <a
                      [routerLink]="['/portfolio/web-app/esop']"
                      class="bttn btn-line btn-primary-custom"
                      ><span>View Case Study</span></a
                    >
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-5">
                <div class="comman-card-img-wrap ani-delay1 hide-mobile">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/home/slider/home-sec5-1.png"
                    alt="MyTrux"
                    class="home-sec5-1 lazy delay-1-8s"
                    data-animation="animated fadeInDown30"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/home/slider/home-sec5-2.png"
                    alt="MyTrux"
                    class="home-sec5-2 lazy delay-2-1s"
                    data-animation="animated fadeInUp30"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/home/slider/home-sec5-3.svg"
                    alt="MyTrux"
                    class="home-sec5-3 floatingX-l lazy"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/home/slider/home-sec5-4.svg"
                    alt="MyTrux"
                    class="home-sec5-4 floatingX-r lazy"
                  />
                </div>
                <div
                  class="comman-card-img-wrap-xs show-mobile text-center mb10"
                >
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/home/slider/thumb-xs-empStok.png"
                    alt="MyTrux"
                    class="lazy img-fluid"
                  />
                </div>
              </div>
            </div>
            </div>
            <div id="fourthSlide" class="carousel-item h-100">            
              <div
              class="row vh100-100 align-items-center justify-content-center bg-white"
            >
              <div class="col-sm-12 col-md-6 col-lg-7 order-xs-last">
                <div class="comman-card-info">
                  <ul
                    class="list-inline comman-card-top-ul"
                    data-animation="animated fadeIn delay-03s"
                  >
                    <li class="list-inline-item h6">Recent</li>
                    <li class="list-inline-item h6">A.I. Digital Verification</li>
                  </ul>
                  <h2
                    class="h2 sec-heading"
                    data-animation="animated fadeIn delay-06s" 
                  >
                    Simplicity<br />
                    Meets Security
                  </h2>
                  <p
                    class="p2-lora sec-para"
                    data-animation="animated fadeIn delay-09s"
                  >
                    UX/UI Design for and Australian-based online national
                    police check &amp; document verification web application
                  </p>
                  <ul
                    class="list-inline comman-card-bottom-ul"
                    data-animation="animated fadeIn delay-1-2s"
                  >
                    <li class="list-inline-item h6 " style="color: #d2143a;">
                      IDA Winner
                    </li>
                  </ul>
                  <div data-animation="animated fadeIn delay-1-5s">
                    <a
                      [routerLink]="['/portfolio/web-app/authntick']"
                      class="bttn btn-line btn-primary-custom"
                      ><span>View Case Study</span></a
                    >
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-5">
                <div class="comman-card-img-wrap hide-mobile">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/home/home-sec2-1.png"
                    alt="Authntick"
                    class="home-sec2-1 lazy delay-1-8s"
                    data-animation="animated fadeInLeft30"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/home/home-sec2-2.png"
                    alt="Authntick"
                    class="home-sec2-2 lazy delay-2-1s"
                    data-animation="animated fadeInRight30"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/home/home-sec2-3.svg"
                    alt="Authntick"
                    class="home-sec2-3 floatingX-r lazy delay-2-4s"
                  />
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/home/home-sec2-4.svg"
                    alt="Authntick"
                    class="home-sec2-4 floatingX-l lazy"
                  />
                </div>
                <div
                  class="comman-card-img-wrap-xs show-mobile text-center mb10"
                >
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/home/thumb-xs-authntick.png"
                    alt="Authntick"
                    class="lazy img-fluid"
                  />
                </div>
              </div>
            </div>
            </div>
          </div>
          <ul class="carousel-indicators-ul show-mobile">
            <li data-target="#home-carosual2" data-slide-to="0" class="active">
              <a href="#">
                <svg height="25" width="25">
                  <circle
                    class="circle-svg-wrap"
                    cx="11"
                    cy="11"
                    r="10"
                    stroke="#242424"
                    stroke-width="2"
                    fill-opacity="0"
                  />
                </svg>
              </a>
            </li>
            <li data-target="#home-carosual2" data-slide-to="1">
              <a href="#">
                <svg height="25" width="25">
                  <circle
                    class="circle-svg-wrap"
                    cx="11"
                    cy="11"
                    r="10"
                    stroke="#242424"
                    stroke-width="2"
                    fill-opacity="0"
                  />
                </svg>
              </a>
            </li>
            <li data-target="#home-carosual2" data-slide-to="2">
              <a href="#">
                <svg height="25" width="25">
                  <circle
                    class="circle-svg-wrap"
                    cx="11"
                    cy="11"
                    r="10"
                    stroke="#242424"
                    stroke-width="2"
                    fill-opacity="0"
                  />
                </svg>
              </a>
            </li>
            <li data-target="#home-carosual2" data-slide-to="3">
              <a href="#">
                <svg height="25" width="25">
                  <circle
                    class="circle-svg-wrap"
                    cx="11"
                    cy="11"
                    r="10"
                    stroke="#242424"
                    stroke-width="2"
                    fill-opacity="0"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  

<!-- wajahat second test code added -->

<section
class="comman-section comman-section-100vh scroll-div home-sec2 pt-adj"
id="section22"
data-section-name="data-section22"
>
<div class="container h-100">
  <div data-aos="fade-in" data-aos-delay="700" data-aos-anchor="#section22">
    
      <ol class="carousel-indicators hide-mobile">
        <li
          data-target="#home-carosual22"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#home-carosual22" data-slide-to="1"></li>
      </ol>
     
      <div class="carousel-inner h-100">
        <div id="firstSlide" class="carousel-item h-100 active">
          <div
            class="row vh100-100 align-items-center justify-content-center bg-white"
          >
            <div class="col-sm-12 col-md-6 col-lg-7 order-xs-last">
              <div class="comman-card-info">
                <ul
                  class="list-inline comman-card-top-ul"
                  data-animation="animated fadeIn delay-03s"
                >
                  <li class="list-inline-item h6">New & Events</li>
             
                </ul>
                <h2
                  class="h2 sec-heading "
                  data-animation="animated fadeIn delay-06s"
                >
                Appreciation &<br />
                Award Function
                </h2>
                <p
                  class="p2-lora sec-para"
                  data-animation="animated fadeIn delay-09s"
                >
                The entire team has shown commendable efforts last year  & we received support from their family members.  To recognize this we celebrated Team Appreciation Day 2022.
                </p>
                <ul
                  class="list-inline comman-card-bottom-ul"
                  data-animation="animated fadeIn delay-1-2s"
                >
                  <li class="list-inline-item h6 text-orange">Less than a minute</li>
                </ul>
                <div data-animation="animated fadeIn delay-1-5s">
                  <a
                    href="https://prismicreflections.com/blog/" target="_blank"
                    class="bttn btn-line btn-primary-custom" data-toggle="modal" data-target="#pr-video-modal"
                    ><span>Watch Video</span></a>

                  
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-5">
              <div class="comman-card-img-wrap comman-card-img-wrap-adj2 hide-mobile">
                <a href="#" class="pr-video-modal-btn" data-toggle="modal" data-target="#pr-video-modal">
                <img
                  src="assets/images/home/pr-video-modal.png"
                  data-src="assets/images/home/pr-video-modal.png"
                  alt="pr-video-modal"
                  class="home-sec2-1-new lazy delay-1-8s img-fluid"
                  data-animation="animated fadeInLeft30"
                />
              <img class="pr-vidoe-play-icon img-fluid" src="assets/images/home/pr-vidoe-play-icon.png" alt="pr-video-play-icon"/>
                </a>
                

             
              </div>
              <div
                class="comman-card-img-wrap-xs show-mobile text-center mb10"
              >
              <a href="#" class="pr-video-modal-btn" data-toggle="modal" data-target="#pr-video-modal">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/home/pr-video-modal.png"
                  alt="pr-vidoe-play-icon"
                  class="lazy img-fluid"
                />
                <img class="pr-vidoe-play-icon" src="assets/images/home/pr-vidoe-play-icon.png" alt="pr-video-play-icon"/>
              </a>
              </div>
            </div>
          </div>
        </div>

 

        <div id="secondSlide" class="carousel-item h-100">
          <div
            class="row vh100-100 align-items-center justify-content-center bg-white"
          >
            <div class="col-sm-12 col-md-6 col-lg-7 order-xs-last">
              <div class="comman-card-info">
                <ul
                  class="list-inline comman-card-top-ul"
                  data-animation="animated fadeIn delay-03s"
                >
                  <li class="list-inline-item h6">
                    Design Insights & Thoughts
                  </li>
                </ul>
                <h2
                  class="h2 sec-heading no-wrap-lg position-relative z-index6"
                  data-animation="animated fadeIn delay-06s" 
                >
                  Telehealth & <br />
                  Telemedicine
                </h2>
                <p
                  class="p2-lora sec-para"
                  data-animation="animated fadeIn delay-09s"
                >
                This part of the article focuses on what are the opportunities in Telehealth & as a designer what we can contribute to solve and maximise the impact.
                </p>
                <ul
                  class="list-inline comman-card-bottom-ul"
                  data-animation="animated fadeIn delay-1-2s" 
                >
                  <li class="list-inline-item h6" style="color:#ff5500">
                    6 min read
                  </li>
                </ul>
                <div data-animation="animated fadeIn delay-1-5s">
                  <a
                    href="https://prismicreflections.com/blog/telehealth-series-designing-for-new-age-healthcare-systems-part-ii/"
                    target="_blank" class="bttn btn-line btn-primary-custom" 
                    ><span>Read Article</span></a
                  >

                  <a
                    href="https://prismicreflections.com/blog/" target="_blank"
                    class="bttn btn-link-custom btn-line reset-btn podcast-all-btn"
                    ><span>All Articles</span></a
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-5">
              <div class="comman-card-img-wrap hide-mobile">
                <img
                  src="assets/images/home/telehealth1.png"
                  alt="Telehealth & Telemedicine"
                  class="home-sec3-1 lazy delay-1-8s"
                  data-animation="animated fadeInDown30"
                />
              </div>
              <div
                class="comman-card-img-wrap-xs show-mobile text-center mb10"
              >
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/home/telehealth1.png"
                  alt="Telehealth & Telemedicine"
                  class="lazy img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    
    </div>
  
</div>
</section>


<section
class="comman-section comman-section-100vh scroll-div home-sec2"
id="section22"
data-section-name="data-section22"
>
<div class="container h-100">
  <div data-aos="fade-in" data-aos-delay="700" data-aos-anchor="#section22">
    
      <ol class="carousel-indicators hide-mobile">
        <li
          data-target="#home-carosual22"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#home-carosual22" data-slide-to="1"></li>
      </ol>
     
      <div class="carousel-inner h-100">
        

 

       
          <div
            class="row vh100-100 align-items-center justify-content-center bg-white"
          >
            <div class="col-sm-12 col-md-6 col-lg-7 order-xs-last">
              <div class="comman-card-info">
                <ul
                  class="list-inline comman-card-top-ul"
                  data-animation="animated fadeIn delay-03s"
                >
                  <li class="list-inline-item h6">
                    Design Insights & Thoughts
                  </li>
                </ul>
                <h2
                  class="h2 sec-heading no-wrap-lg position-relative z-index6"
                  data-animation="animated fadeIn delay-06s"
                >
                  Telehealth & <br />
                  Telemedicine
                </h2>
                <p
                  class="p2-lora sec-para"
                  data-animation="animated fadeIn delay-09s"
                >
                This part of the article focuses on what are the opportunities in Telehealth & as a designer what we can contribute to solve and maximise the impact.
                </p>
                <ul
                  class="list-inline comman-card-bottom-ul"
                  data-animation="animated fadeIn delay-1-2s"
                >
                  <li class="list-inline-item h6" style="color: #ff5500;">
                    6 min read
                  </li>
                </ul>
                <div data-animation="animated fadeIn delay-1-5s">
                  <a
                    href="https://prismicreflections.com/blog/telehealth-series-designing-for-new-age-healthcare-systems-part-ii/"
                    target="_blank" class="bttn btn-line btn-primary-custom" 
                    ><span>Read Article</span></a
                  >

                  <a
                    href="https://prismicreflections.com/blog/" target="_blank"
                    class="bttn btn-link-custom btn-line reset-btn podcast-all-btn"
                    ><span>All Articles</span></a
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-5">
              <div class="comman-card-img-wrap hide-mobile">
                <img
                  src="assets/images/home/telehealth1.png"
                  alt="Telehealth & Telemedicine"
                  class="home-sec3-1 lazy delay-1-8s"
                  data-animation="animated fadeInDown30"
                />
              </div>
              <div
                class="comman-card-img-wrap-xs show-mobile text-center mb10"
              >
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/home/telehealth1.png"
                  alt="Telehealth & Telemedicine"
                  class="lazy img-fluid"
                />
              </div>
            </div>
          </div>
       
      </div>

    
    </div>
  
</div>
</section>

<!-- wajahat second test code end -->

  <section
    class="comman-section comman-section-100vh scroll-div home-sec3 home-sec-tab"
    id="data-section3-home"
    data-section-name="data-section3-home"
  >
    <div
      class="ani-wrap50-2 bg-black"
      data-aos="new-animation2"
      data-aos-delay="1200"
      data-aos-anchor="#data-section3-home"
    ></div>
    <div
      class="ani-wrap50 bg-black2"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#data-section3-home"
    ></div>
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div
            class="comman-card-info hide-mobile"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#data-section3-home"
          >
            <ul class="list-inline comman-card-top-ul">
              <li
                class="list-inline-item h6 text-white hide-after"
                style="font-weight: 300 !important"
              >
                Our Expertise
              </li>
            </ul>
            <div class="nav flex-column hide-mobile-xs">
              <a
                class="nav-link active h4 st btn-line"
                data-toggle="pill"
                href="#v-pills-1"
              >
                <span>Design Research &amp; Consultancy</span>
              </a>
              <a
                class="nav-link h4 st btn-line"
                data-toggle="pill"
                href="#v-pills-2"
              >
                <span>User Experience Design</span>
              </a>

              <!-- wajahat code start -->
              <a
                class="nav-link h4 st btn-line"
                data-toggle="pill"
                href="#v-pills-5"
              >
                <span>Extended Design Force</span>
              </a>
              <!-- wajahat code end -->

              <a
                class="nav-link h4 st btn-line"
                data-toggle="pill"
                href="#v-pills-3"
              >
                <span>Brand Strategy &amp; Identity Design</span>
              </a>
              <a
                class="nav-link h4 st btn-line"
                data-toggle="pill"
                href="#v-pills-4"
              >
                <span>UI Engineering</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div
            class="tab-content hide-mobile"
            data-aos="fade-in"
            data-aos-delay="2000"
            data-aos-anchor="#data-section3-home"
          >
            <div class="tab-pane fade show active" id="v-pills-1">
              <div class="comman-card-info">
                <h3 class="sec-heading">Design Research &amp; Consultancy</h3>
                <p class="p2 sec-para">
                  User Research &amp; Recommendation, Design Evaluation/Audit,
                  Design Strategy Workshops for companies
                </p>
                <button
                  [routerLink]="['/services/design-research-consultancy']"
                  class="bttn btn-line btn-secondary-custom"
                >
                  <span>Learn more</span>
                </button>
              </div>
            </div>
            <div class="tab-pane fade" id="v-pills-2">
              <div class="comman-card-info">
                <h3 class="sec-heading">User Experience Design</h3>
                <p class="p2 sec-para">
                  Innovation &amp; Prototyping for Digital Products. UX UI
                  Design for Web, Mobile &amp; Enterprise Apps. Conversational
                  UX for Chat Bots &amp; Voice Asst.
                </p>
                <button
                  [routerLink]="['/services/user-experience-design']"
                  class="bttn btn-line btn-secondary-custom"
                >
                  <span>Learn more</span>
                </button>
              </div>
            </div>
            <!-- wajahat code start -->
            <div class="tab-pane fade" id="v-pills-5">
              <div class="comman-card-info">
                <h3 class="sec-heading">
                  Extended Design Force
                </h3>
                <p class="p2 sec-para">
                  Take better control, hire seasoned designers from us to closely work with your in-house team to meet your immediate or long term goals.
                </p>
                <button
                  
                  class="bttn btn-line btn-secondary-custom"
                >
                  <span>Learn more</span>
                </button>
              </div>
            </div>
            <!-- wajahat code end -->
            <div class="tab-pane fade" id="v-pills-3">
              <div class="comman-card-info">
                <h3 class="sec-heading">
                  Brand Strategy &amp; Identity Design
                </h3>
                <p class="p2 sec-para">
                  Developing strategies &amp; designing visual identity,
                  inbound/outbound communication &amp; guidelines for new age
                  brands.
                </p>
                <button
                  [routerLink]="['/services/brand-strategy-identity']"
                  class="bttn btn-line btn-secondary-custom"
                >
                  <span>Learn more</span>
                </button>
              </div>
            </div>
            <div class="tab-pane fade" id="v-pills-4">
              <div class="comman-card-info">
                <h3 class="sec-heading">User Interface Engineering</h3>
                <p class="p2 sec-para">
                  Bringing visual designs to life through code, micro
                  interactions, animations, speed &amp; performance.
                </p>
              </div>
            </div>
          </div>
          <div class="show-mobile accordion-tab-xs">
            <div class="comman-card-info">
              <ul class="list-inline comman-card-top-ul xs-mb0">
                <li
                  class="list-inline-item h6 text-white hide-after xs-mb0"
                  style="font-weight: 300 !important"
                >
                  Our Expertise
                </li>
              </ul>
            </div>
            <div class="accordion" id="accordionexpertise">
              <div class="accordion-card">
                <div class="accordion-card-header">
                  <button
                    class="nav-link h4 st btn-line"
                    type="button"
                    data-toggle="collapse"
                    data-target="#expertiseOne"
                    aria-expanded="true"
                    aria-controls="expertiseOne"
                  >
                    <span>Design Research &amp; Consultancy</span>
                    <i class="fas fa-chevron-up"></i>
                  </button>
                </div>
                <div
                  id="expertiseOne"
                  class="collapse show"
                  data-parent="#accordionexpertise"
                >
                  <div class="card-body">
                    <div class="comman-card-info">
                      <p class="p2 sec-para">
                        Conducting User Research, Design Evaluation/Audit &amp;
                        Design Strategy Workshops for our clients across
                        industries.
                      </p>
                      <a
                        [routerLink]="['/services/design-research-consultancy']"
                        class="bttn btn-line btn-secondary-custom cursor-pointer"
                        ><span>Learn more</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-card">
                <div class="accordion-card-header">
                  <button
                    class="nav-link h4 st btn-line collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#expertiseTwo"
                    aria-expanded="false"
                    aria-controls="expertiseTwo"
                  >
                    <span>User Experience Design</span>
                    <i class="fas fa-chevron-up"></i>
                  </button>
                </div>
                <div
                  id="expertiseTwo"
                  class="collapse"
                  data-parent="#accordionexpertise"
                >
                  <div class="card-body">
                    <div class="comman-card-info">
                      <p class="p2 sec-para">
                        UX UI Design for Web &amp; Mobile Apps/Products,
                        Wearable, AR /VR Apps &amp; Conversational UX for Chat
                        Bots, Voice Assistant . Encompassing B2C, B2B,
                        Enterprise Apps &amp; Corporate Websites.
                      </p>
                      <a
                        [routerLink]="['/services/user-experience-design']"
                        class="bttn btn-line btn-secondary-custom cursor-pointer"
                        ><span>Learn more</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- wajahat code start for service -->
              <div class="accordion-card">
                <div class="accordion-card-header">
                  <button
                    class="nav-link h4 st btn-line collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#extended-design"
                    aria-expanded="false"
                    aria-controls="extended-design"
                  >
                    <span>Extended Design Force</span>
                    <i class="fas fa-chevron-up"></i>
                  </button>
                </div>
                <div
                  id="extended-design"
                  class="collapse"
                  data-parent="#accordionexpertise"
                >
                  <div class="card-body">
                    <div class="comman-card-info">
                      <p class="p2 sec-para">
                        Take better control, hire seasoned designers from us to closely work with your in-house team to meet your immediate or long term goals. 
                      </p>
                      <a
                        [routerLink]="['/services/user-experience-design']"
                        class="bttn btn-line btn-secondary-custom cursor-pointer"
                        ><span>Learn more</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- wajahat code end for service -->

              <div class="accordion-card">
                <div class="accordion-card-header">
                  <button
                    class="nav-link h4 st btn-line collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#expertiseThree"
                    aria-expanded="false"
                    aria-controls="expertiseThree"
                  >
                    <span>Brand Strategy &amp; Identity Design</span>
                    <i class="fas fa-chevron-up"></i>
                  </button>
                </div>
                <div
                  id="expertiseThree"
                  class="collapse"
                  data-parent="#accordionexpertise"
                >
                  <div class="card-body">
                    <div class="comman-card-info">
                      <p class="p2 sec-para">
                        Developing strategies &amp; designing visual identity,
                        inbound/outbound communication &amp; guidelines for new
                        age brands.
                      </p>
                      <a
                        [routerLink]="['/services/brand-strategy-identity']"
                        class="bttn btn-line btn-secondary-custom cursor-pointer"
                        ><span>Learn more</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-card">
                <div class="accordion-card-header">
                  <button
                    class="nav-link h4 st btn-line collapsed bb0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#expertiseFour"
                    aria-expanded="false"
                  >
                    <span>UI Engineering</span>
                    <i class="fas fa-chevron-up"></i>
                  </button>
                </div>
                <div
                  id="expertiseFour"
                  class="collapse"
                  aria-labelledby="expertiseFour"
                  data-parent="#accordionexpertise"
                >
                  <div class="card-body">
                    <div class="comman-card-info">
                      <p class="p2 sec-para">
                        Bringing visual designs to life through code, micro
                        interactions, animations, speed &amp; performance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- client carosual original code start -->
  <!-- <section
    class="comman-section comman-section-100vh scroll-div home-sec4"
    id="section4"
    data-section-name="data-section4"
  >
    <div
      class="ani-wrap50-3 bg-white"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section4"
    ></div>
    <div class="container h-100">
      <div
        class="row vh100-100 align-items-center justify-content-center overflow-hidden"
      >
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div
            class="comman-card-info"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section4"
          >
            <ul class="list-inline comman-card-top-ul">
              <li class="list-inline-item h6 text-white hide-after">Clients</li>
            </ul>
            <h2 class="h2">They Are Our Companions</h2>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 client-logo-wrap-outer-col">
          <div class="client-logo-wrap-outer">
            <div class="client-logo-wrap">
              <ul
                data-aos="fade-in"
                data-aos-delay="1300"
                class="clearfix"
                data-aos-anchor="#section4"
              >
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-adhya.png"
                    alt="adhya"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-afya.png"
                    alt="afya"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-angel.png"
                    alt="angel"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-artofliving.png"
                    alt="artofliving"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-authntick.png"
                    alt="authntick"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-citizen.png"
                    alt="citizen"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-credila.png"
                    alt="credila"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-datamatic.png"
                    alt="datamatic"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-dspmf.png"
                    alt="dspmf"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-dut.png"
                    alt="dut"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-empire.png"
                    alt="empire"
                    class="lazy"
                  />
                </li>

                          <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-indiamart.png"
                    alt="Indiamart"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-humanity.png"
                    alt="humanity"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-interview-mocha.png"
                    alt="interview-mocha"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-mahindra.png"
                    alt="mahindra"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-motilal.png"
                    alt="motilal"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-mse.png"
                    alt="mse"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-mytrux.png"
                    alt="mytrux"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-pharmadesk.png"
                    alt="pharmadesk"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-samaraat.png"
                    alt="samaraat"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-usa.png"
                    alt="usa"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-wns.png"
                    alt="wns"
                    class="lazy"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
   <!-- client carosual original code end -->

   <!-- wajahat client logo slider code start -->
   <section
    class="comman-section comman-section-100vh scroll-div home-sec4"
    id="section4"
    data-section-name="data-section4"
  >
    <div
      class="ani-wrap50-3 bg-white"
      data-aos="new-animation2"
      data-aos-delay="100"
      data-aos-anchor="#section4"
    ></div>
    <div class="container h-100">
      <div
        class="row vh100-100 align-items-center justify-content-center overflow-hidden"
      >
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div
            class="comman-card-info"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section4"
          >
            <ul class="list-inline comman-card-top-ul">
              <li class="list-inline-item h6 text-white hide-after">Clients</li>
            </ul>
            <h2 class="h2">They Are Our Companions</h2>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 client-logo-wrap-outer-col">
          <div class="client-logo-wrap-outer">
            <div class="client-logo-wrap">
              <ul
                data-aos="fade-in"
                data-aos-delay="1300"
                class="clearfix"
                data-aos-anchor="#section4"
              >
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-dspmf-new.png"
                    alt="adhya"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-angel-new.png"
                    alt="afya"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-motilal-new.png"
                    alt="angel"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-icici-new.png"
                    alt="artofliving"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-mse-new.png"
                    alt="authntick"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-jmfinance-new.png"
                    alt="citizen"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-wns.png"
                    alt="credila"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-datamatic-new.png"
                    alt="datamatic"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-indiamart-new.png"
                    alt="dspmf"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-mahindra-new.png"
                    alt="dut"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-pharmadesk-new.png"
                    alt="empire"
                    class="lazy"
                  />
                </li>

                          <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-credila-new.png"
                    alt="Indiamart"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-ardent-new.png"
                    alt="humanity"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-dut-new.png"
                    alt="interview-mocha"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-humanity-new.png"
                    alt="mahindra"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-citizen-new.png"
                    alt="motilal"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-artofliving-new.png"
                    alt="mse"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-blodbnk-new.png"
                    alt="mytrux"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-authntick-new.png"
                    alt="pharmadesk"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-afya-new.png"
                    alt="samaraat"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-adhya-new.png"
                    alt="usa"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-samaraat-new.png"
                    alt="wns"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-interview-mocha-new.png"
                    alt="wns"
                    class="lazy"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/clients/client-kcoverseas-new.png"
                    alt="wns"
                    class="lazy"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
   <!-- wajahat client logo slider code end -->

<!-- wajahat code added start for sameer CEO -->


<!-- abiktacode -->
<section
class="comman-section comman-section-100vh scroll-div home-sec2 pt-adj"
id="section22"
data-section-name="data-section22"
>
<div class="container h-100">
  <div data-aos="fade-in" data-aos-delay="700" data-aos-anchor="#section22">
    
      <ol class="carousel-indicators hide-mobile">
        <li
          data-target="#home-carosual22"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#home-carosual22" data-slide-to="1"></li>
      </ol>
     
      <div class="carousel-inner h-100">
        <div id="firstSlide" class="carousel-item h-100 active">
          <div
            class="row vh100-100 align-items-center justify-content-center bg-white"
          >
            <div class="col-sm-12 col-md-6 col-lg-7 order-xs-last">
              <div class="comman-card-info">
                <ul
                  class="list-inline comman-card-top-ul"
                  data-animation="animated fadeIn delay-03s"
                >
                  <li class="list-inline-item h6">What they say</li>
             
                </ul>
                <h2
                  class="h2 sec-heading"
                  data-animation="animated fadeIn delay-06s"
                >
                Sameer, CEO of<br />
                Ardent Privacy
                </h2>
                <p
                  class="p2-lora sec-para"
                  data-animation="animated fadeIn delay-09s"
                >
                It was our absolute honor to work with Ardent Privacy, USA in strategising & designing seamless experience for their website audience. Here is what our client have to say about their overall experience during the engagement. 
                </p>
                <ul
                  class="list-inline comman-card-bottom-ul"
                  data-animation="animated fadeIn delay-1-2s"
                >
                  <li class="list-inline-item h6" style="color:#ff5500">Less than a minute</li>
                </ul>
                <div data-animation="animated fadeIn delay-1-5s">
                  <a
                    href="https://prismicreflections.com/blog/" target="_blank"
                    class="bttn btn-line btn-primary-custom" data-toggle="modal" data-target="#pr-ceo-video-modal"
                    ><span>Watch Video</span></a>

                  
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-5">
              <div class="comman-card-img-wrap comman-card-img-wrap-adj2 hide-mobile">
                <a href="#" class="pr-video-modal-btn" data-toggle="modal" data-target="#pr-ceo-video-modal">
                <img
                  src="assets/images/home/ardent-privacy-img.png"
                  data-src="assets/images/home/ardent-privacy-img.png"
                  alt="pr-ceo-video-modal"
                  class="home-sec2-1-new lazy delay-1-8s img-fluid"
                  data-animation="animated fadeInLeft30"
                />
              <img class="pr-vidoe-play-icon img-fluid" src="assets/images/home/pr-vidoe-play-icon.png" alt="pr-ceo-video-play-icon"/>
                </a>
                

             
              </div>
              <div
                class="comman-card-img-wrap-xs show-mobile text-center mb10"
              >
              <a href="#" class="pr-video-modal-btn" data-toggle="modal" data-target="#pr-ceo-video-modal">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/home/ardent-privacy-img.png"
                  alt="pr-vidoe-play-icon"
                  class="lazy img-fluid"
                />
                <img class="pr-vidoe-play-icon" src="assets/images/home/pr-vidoe-play-icon.png" alt="pr-video-play-icon"/>
              </a>
              </div>
            </div>
          </div>
        </div>

 

       
      </div>

    
    </div>
  
</div>
</section>


<!-- wajahat code end for sameer CEO -->
  <section
    class="comman-section comman-section-100vh scroll-div home-sec5 home-sec-tab"
    id="section5"
    data-section-name="data-section5"
  >
    <div
      class="ani-wrap50-2 bg-black3"
      data-aos="new-animation2"
      data-aos-delay="1200"
      data-aos-anchor="#section5"
    ></div>
    <div class="container h-100 hide-mobile">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-sm-6 col-md-6 col-lg-6">
          <div
            class="comman-card-info"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section5"
          >
            <ul class="list-inline comman-card-top-ul xs-mb0">
              <li
                class="list-inline-item h6 text-white hide-after xs-mb0 xs-text-center"
              >
                What They Say
              </li>
            </ul>
            <div class="nav flex-column">
              <a
                class="nav-link active h4 st btn-line"
                data-toggle="pill"
                href="#v-pills-12"
                ><span>HDFC Credila</span></a
              >
              <a
                class="nav-link h4 st btn-line"
                data-toggle="pill"
                href="#v-pills-13"
                ><span>EasyPlan</span></a
              >
              <a
                class="nav-link h4 st btn-line"
                data-toggle="pill"
                href="#v-pills-11"
              >
                <span>Prokarma</span></a
              >
              <a
                class="nav-link h4 st btn-line"
                data-toggle="pill"
                href="#v-pills-14"
                ><span>Pincart</span></a
              >
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6">
          <div
            class="tab-content"
            data-aos="fade-in"
            data-aos-delay="2000"
            data-aos-anchor="#section5"
          >
            <div class="tab-pane fade show active" id="v-pills-12">
              <div class="testi-comman-card-info text-left">
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/testimonial/ajay-bohora.png"
                    alt="Mr. Ajay Bohora"
                    class="img-fluid lazy"
                  />
                </div>
                <h6>Mr. Ajay Bohora</h6>
                <h6 class="text-white" style="opacity: 0.5">
                  HDFC Credila Financial Services Pvt. Ltd.
                </h6>
                <p class="p3">
                  <i class="fas fa-quote-left"></i> Its more than 12 years now.
                  They contribute in all our digital needs, from strategy to
                  implementation. We engaged Prismic Reflections&reg; – as our
                  strategic design partner for defining effective communication
                  design. <i class="fas fa-quote-right"></i>
                </p>
              </div>
            </div>
            <div class="tab-pane fade" id="v-pills-13">
              <div class="testi-comman-card-info text-left">
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/testimonial/manisha-pandita.jpg"
                    alt="Mr. Vasukumar Nair"
                    class="img-fluid lazy"
                  />
                </div>
                <h6>Manisha Pandita</h6>
                <h6 class="text-white" style="opacity: 0.5">Co-Founder</h6>
                <p class="p3">
                  <i class="fas fa-quote-left"></i> Vishal and his team have
                  done a terrific job of setting up the core UX paradigms as
                  well as the look and feel of the app. We used a very different
                  chat paradigm for onboarding, which is quite new for finance
                  apps. The Prismic team helped us take that vision and turn it
                  into reality. <i class="fas fa-quote-right"></i>
                </p>
              </div>
            </div>
            <div class="tab-pane fade" id="v-pills-11">
              <div class="testi-comman-card-info text-left">
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/testimonial/sasidhar-valluru.png"
                    alt="Mr. Sasidhar Valluru"
                    class="img-fluid lazy"
                  />
                </div>
                <h6>Mr. Sasidhar Valluru</h6>
                <h6 class="text-white" style="opacity: 0.5">
                  Sr. Product Manager, ProKarma, Omaha
                </h6>
                <p class="p3">
                  <span>
                    <i class="fas fa-quote-left"></i> Prismic Reflections&reg;
                    worked on not just the frontend look and feel, but also gave
                    us a number of digital assets and ideas on which UI style
                    set we should use. The impact was huge, and it helped us
                    speed up our process. Our time to market was reduced a lot.
                    <i class="fas fa-quote-right"></i>
                  </span>
                </p>
              </div>
            </div>
            <div class="tab-pane fade" id="v-pills-14">
              <div class="testi-comman-card-info text-left">
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/testimonial/karan-gandhi.png"
                    alt="Mr. Karan Gandhi"
                    class="img-fluid lazy"
                  />
                </div>
                <h6>Mr. Karan Gandhi</h6>
                <h6 class="text-white" style="opacity: 0.5">CFO, Pincart.</h6>
                <p class="p3">
                  <i class="fas fa-quote-left"></i>Swarup made sure that the
                  team is easily approachable &amp; we have a robust
                  communication process. Prismic Reflections&reg; exhibits the
                  quality &amp; elegance in the designs and expertise in
                  developing cutting edge solutions. We at Pincarts appreciate
                  your commitment and professionalism.
                  <i class="fas fa-quote-right"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container show-mobile">
      <div class="col-lg-12">
        <div
          class="comman-card-info"
          data-aos="fade-in"
          data-aos-delay="600"
          data-aos-anchor="#section5"
        >
          <ul class="list-inline comman-card-top-ul xs-text-center">
            <li class="list-inline-item h6 text-white hide-after">
              What They Say
            </li>
          </ul>
          <owl-carousel-o
            [options]="customOptions"
            class="testimonial-xs-carosual"
            id="testimonial-xs-carosual"
          >
            <ng-template carouselSlide>
              <div class="testi-comman-card-info">
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/testimonial/sasidhar-valluru.png"
                    alt="Mr. Sasidhar Valluru"
                    class="img-fluid lazy"
                  />
                </div>
                <h6>Mr. Sasidhar Valluru</h6>
                <h6 class="text-white" style="opacity: 0.5">
                  Sr. Product Manager, ProKarma, Omaha
                </h6>
                <p class="p3">
                  <span>
                    <i class="fas fa-quote-left"></i> Prismic Reflections&reg;
                    worked on not just the frontend look and feel, but also gave
                    us a number of digital assets and ideas on which UI style
                    set we should use. The impact was huge, and it helped us
                    speed up our process. Our time to market was reduced a lot.
                    <i class="fas fa-quote-right"></i>
                  </span>
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="testi-comman-card-info">
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/testimonial/ajay-bohora.png"
                    alt="Mr. Ajay Bohora"
                    class="img-fluid lazy"
                  />
                </div>
                <h6>Mr. Ajay Bohora</h6>
                <h6 class="text-white" style="opacity: 0.5">
                  HDFC Credila Financial Services Pvt. Ltd.
                </h6>
                <p class="p3">
                  <i class="fas fa-quote-left"></i> Its more than 12 years now.
                  They contribute in all our digital needs, from strategy to
                  implementation. We engaged Prismic Reflections&reg; – as our
                  strategic design partner for defining effective communication
                  design. <i class="fas fa-quote-right"></i>
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="testi-comman-card-info">
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/testimonial/manisha-pandita.jpg"
                    alt="Mr. Vasukumar Nair"
                    class="img-fluid lazy"
                  />
                </div>
                <h6>Manisha Pandita</h6>
                <h6 class="text-white" style="opacity: 0.5">Co-Founder</h6>
                <p class="p3">
                  <i class="fas fa-quote-left"></i> Vishal and his team have
                  done a terrific job of setting up the core UX paradigms as
                  well as the look and feel of the app. We used a very different
                  chat paradigm for onboarding, which is quite new for finance
                  apps. The Prismic team helped us take that vision and turn it
                  into reality. <i class="fas fa-quote-right"></i>
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="testi-comman-card-info">
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/testimonial/karan-gandhi.png"
                    alt="Mr. Karan Gandhi"
                    class="img-fluid lazy"
                  />
                </div>
                <h6>Mr. Karan Gandhi</h6>
                <h6 class="text-white" style="opacity: 0.5">CFO, Pincart.</h6>
                <p class="p3">
                  <i class="fas fa-quote-left"></i>Swarup made sure that the
                  team is easily approachable &amp; we have a robust
                  communication process. Prismic Reflections&reg; exhibits the
                  quality &amp; elegance in the designs and expertise in
                  developing cutting edge solutions. We at Pincarts appreciate
                  your commitment and professionalism.
                  <i class="fas fa-quote-right"></i>
                </p>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>


  <section
    class="comman-section scroll-div home-sec6 pb0"
    id="section6"
    data-section-name="data-section6"
  >
    <div class="container overflow-hidden">
      <div
        class="row align-items-center justify-content-center overflow-hidden"
      >
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5">
          <div class="comman-card-info">
            <ul
              class="list-inline comman-card-top-ul"
              data-aos="fade-in"
              data-aos-delay="300"
              data-aos-anchor="#section6"
            >
              <li class="list-inline-item h6 hide-after">
                Awards &amp; Recognitions
              </li>
            </ul>
            <h2
              class="h2"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor-placement="top-top"
              data-aos-anchor="#section6"
            >
              We Are<br />Blessed With..
            </h2>
          </div>
        </div>
        <div
          class="col-sm-12 col-md-12 col-lg-12 col-xl-7 hide-mobile"
          data-aos="fade-in"
          data-aos-delay="1500"
          data-aos-anchor="#section6"
        >
          <div
            class="comman-scrollbar-wrap"
            malihu-scrollbar
            [scrollbarOptions]="scrollbarOptions"
          >
            <div class="award-logo-wrap clearfix">
              <ul class="clearfix mr40">
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">A' Design Award</h6>
                    <p class="p3 text-orange">2023</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/awards/A-design-award.png"
                        alt="IDA Design Award"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Wins the Prestigious A’ Design Award for designing the UX UI for the Intelehealth Mobile & Web App
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">AWWWARDS</h6>
                    <p class="p3 text-orange">2021</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/awards/awwards.png"
                        alt="AWWWARDS"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Nomination for Best Web Presence created for Prismic
                      Reflections on AWWWARDS.com
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">Design India</h6>
                    <p class="p3 text-orange">2020</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/awards/design-india.png"
                        alt="Design India"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      IBDA 2020, Special Mention for UX/UI Design of Mutual Fund
                      Investment Platform
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">IDA Design Award</h6>
                    <p class="p3 text-orange">2018</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/awards/ida-award.png"
                        alt="IDA Design Award"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Won the IDA Awards 2018 for designing the UX UI for the
                      Authentic Web Application
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">Clutch Top Designers</h6>
                    <p class="p3 text-orange">2018</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/awards/clutch.png"
                        alt="Clutch Top Designers "
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      As on 29 january 2018, Clutch as rated us as one among the
                      top design studios in india.
                    </p>
                  </div>
                </li>
        
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">Digithon</h6>
                    <p class="p3 text-orange">2015</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/awards/angle-broking.png"
                        alt="Digithon"
                        class="img-fluid lazy"
                        style="display: inline; max-width: 120px"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Digithon Angel Broking kick-starts a 'first-ever'
                      partner-centric challenge to foster technology innovations
                      won by Prismic Reflections
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">Design Firms</h6>
                    <p class="p3 text-orange">2011</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/awards/designfirms.png"
                        alt="Design Firms"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      DesignFirms<sup>(TM)</sup> Web Awards by Design Firm
                      <br />
                      Prismic Reflections has won the February 2011
                      DESIGNFIRMS<sup>(TM)</sup> WEB AWARD
                    </p>
                  </div>
                </li>
              </ul>
              <ul class="clearfix mt40">
           
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">Clutch Top Designers</h6>
                    <p class="p3 text-orange">2019</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/awards/clutch.png"
                        alt="IDA Design Award"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Again in 2019, Clutch has rated us as one among the top
                      design studios in india
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">Silicon India</h6>
                    <p class="p3 text-orange">2018</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/awards/silicon.png"
                        alt="Silicon India"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Business and Technology magazine has shortlisted us for 20
                      most promising UX/UI design services providers
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">CSSDA Nominee</h6>
                    <p class="p3 text-orange">2012</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/awards/CSSDA.png"
                        alt="CSSDA Nominee"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Nomination for Web Presence created for Karda
                      Construction, on CSS Design Awards 10 Oct, 2012<br />
                      Role: Concept, Art direction &amp; Designer
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">AWWWARDS</h6>
                    <p class="p3 text-orange">2010</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/awards/awwards.png"
                        alt="AWWWARDS"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Nomination for Best Web Presence created for Prismic
                      Reflections, on AWWWARDS.com
                    </p>
                  </div>
                </li>
                <li>
                  <div class="award-card st">
                    <h6 class="text-black">CSSDA Nominee</h6>
                    <p class="p3 text-orange">2010</p>
                    <div class="card-img-wrap">
                      <img
                        src="assets/images/awards/CSSDA.png"
                        alt="CSSDA Nominee"
                        class="img-fluid lazy"
                      />
                    </div>
                    <p class="p4 text-light-dark-c">
                      Nomination for Web Presence for Bridgewater Nigeria
                      LTD,CSS Design Awards 19 Dec, 2010 <br />
                      Role: Concept, Art direction &amp; Designer
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-12 show-mobile">
          <owl-carousel-o
            [options]="customOptionsMob"
            class="awards-carosual"
            id="awards-carosual"
          >

          <ng-template carouselSlide>
            <div class="award-card st">
              <h6 class="text-black">A' Design Award</h6>
              <p class="p3 text-orange">2023</p>
              <div class="card-img-wrap">
                <img
                  src="assets/images/preloader/ajax-loader.png"
                  data-src="assets/images/awards/A-design-award.png"
                  alt="Design India"
                  class="img-fluid lazy"
                />
              </div>
              <p class="p4 text-light-dark-c">
                Wins the Prestigious A’ Design Award for designing the UX UI for the Intelehealth Mobile & Web App
              </p>
            </div>
          </ng-template>


            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">Design India</h6>
                <p class="p3 text-orange">2020</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/design-india.png"
                    alt="Design India"
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  IBDA 2020, Special Mention for UX/UI Design of Mutual Fund
                  Investment Platform
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">Clutch Top Designers</h6>
                <p class="p3 text-orange">2019</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/clutch.png"
                    alt="Clutch Top Designers"
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Again in 2019, Clutch has rated us as one among the top design
                  studios in india
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">IDA Design Award</h6>
                <p class="p3 text-orange">2018</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/ida-award.png"
                    alt="IDA Design Award"
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Won the IDA Awards 2018 for designing the UX UI for the
                  Authentic Web Application
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">Clutch Top Designers</h6>
                <p class="p3 text-orange">2018</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/clutch.png"
                    alt="Clutch Top Designers "
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  As on 29 january 2018, Clutch as rated us as one among the top
                  design studios in india.
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">Silicon India</h6>
                <p class="p3 text-orange">2018</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/silicon.png"
                    alt="Silicon India"
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Business and Technology magazine has shortlisted us for 20
                  most promising UX/UI design services providers
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">Digithon</h6>
                <p class="p3 text-orange">2015</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/angle-broking.png"
                    alt="Digithon"
                    class="img-fluid lazy"
                    style="display: inline; max-width: inherit !important"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Digithon Angel Broking kick-starts a 'first-ever'
                  partner-centric challenge to foster technology innovations won
                  by Prismic Reflections
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">CSSDA Nominee</h6>
                <p class="p3 text-orange">2012</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/CSSDA.png"
                    alt="CSSDA Nominee"
                    class="img-fluid lazy"
                    style="opacity: 1; max-width: 40px"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Nomination for Web Presence created for Karda Construction, on
                  CSS Design Awards 10 Oct, 2012<br />
                  Role: Concept, Art direction &amp; Designer
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">Design Firms</h6>
                <p class="p3 text-orange">2011</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/designfirms.png"
                    alt="Design Firms"
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  DesignFirms<sup>(TM)</sup> Web Awards by Design Firm <br />
                  Prismic Reflections has won the February 2011 DESIGNFIRMS<sup
                    >(TM)</sup
                  >
                  WEB AWARD
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">AWWWARDS</h6>
                <p class="p3 text-orange">2010</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/awwards.png"
                    alt="AWWWARDS"
                    class="img-fluid lazy"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Nomination for Best Web Presence created for Prismic
                  Reflections, on AWWWARDS.com
                </p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="award-card st">
                <h6 class="text-black">CSSDA Nominee</h6>
                <p class="p3 text-orange">2010</p>
                <div class="card-img-wrap">
                  <img
                    src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/awards/CSSDA.png"
                    alt="CSSDA Nominee"
                    class="img-fluid lazy"
                    style="opacity: 1; max-width: 40px"
                  />
                </div>
                <p class="p4 text-light-dark-c">
                  Nomination for Web Presence for Bridgewater Nigeria LTD,CSS
                  Design Awards 19 Dec, 2010 <br />
                  Role: Concept, Art direction &amp; Designer
                </p>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>


  <!-- industries tab start -->
  <section
  class="comman-section comman-section-100vh scroll-div home-sec7 mt-5"
  id="section7"
  data-section-name="data-section7">
  <div class="container mt-5">
    <div class="row align-items-center justify-content-center center-adj-big">
      <div class="col-sm-12 col-md-12 col-lg-12">


<ul class="nav nav-pills mb-3 tab-scroll-ul-home" id="pills-tab" role="tablist">
  <li class="nav-item">
    <a class="nav-link active bttn btn-link-custom btn-line st " id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
      <img src="assets/images/home/fintech.svg" alt="fintech"> <span>Fintech</span>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link bttn btn-link-custom btn-line st " id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">
      <img src="assets/images/home/health-care-img.svg" alt="health-care-img.svg"><span>Healthcare</span>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link bttn btn-link-custom btn-line st " id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">
      <img src="assets/images/home/enterprise-img.svg" alt="enterprise-img.svg"><span>Enterprise</span>
    </a>
  </li>

  <li class="nav-item">
    <a class="nav-link bttn btn-link-custom btn-line st " id="pills-contact-tab" data-toggle="pill" href="#pills-transport" role="tab" aria-controls="pills-transport" aria-selected="false">
      <img src="assets/images/home/transport-img.svg" alt="transport-img.svg"><span>Transport</span>
    </a>
  </li>
</ul>



<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
    <div class="heading">
      <img src="assets/images/home/fintech-d-img.png" alt="fintech-d-img">
      <p>Fintech</p>
    </div>
    <p class="ind-detail p2-lora">Working with startups to large established fintech companies, we have worked with our clients to reimagine the future of banking, financing, insurnace, online payments, investments and much more. </p>
    <div class="logo-list">
      <img src="assets/images/home/fintech-1.png" alt="fintech-1.png">
      <img src="assets/images/home/fintech-2.png" alt="fintech-2.png">
      <img src="assets/images/home/fintech-3.png" alt="fintech-3.png">
      <img src="assets/images/home/fintech-4.png" alt="fintech-4.png">
      <img src="assets/images/home/fintech-5.png" alt="fintech-5.png">
      <img src="assets/images/home/fintech-6.png" alt="fintech-6.png">
      <img src="assets/images/home/fintech-7.png" alt="fintech-7.png">
    </div>
  </div>
  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
   
    <div class="heading">
      <img src="assets/images/home/healthcare.png" alt="fintech-d-img">
      <p>Healthcare</p>
    </div>
    <p class="ind-detail p2-lora">Making digital interactions easier for patients, healthcare providers, and other stakeholders we have helped our clients to save on time, reduce the cost,  improve the engagement and take healthcare in remote areas with technology platforms. 
    </p>
    <div class="logo-list">
      <img src="assets/images/home/healthcare-1.png" alt="healthcare-1.png">
      <img src="assets/images/home/healthcare-2.png" alt="healthcare-2.png">
      <img src="assets/images/home/healthcare-3.png" alt="healthcare-3.png">
      <img src="assets/images/home/healthcare-4.png" alt="healthcare-4.png">
      <img src="assets/images/home/healthcare-5.png" alt="healthcare-5.png">
    </div>

  </div>
  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
    
    <div class="heading">
      <img src="assets/images/home/enterprises.png" alt="fintech-d-img">
      <p>Enterprise</p>
    </div>
    <p class="ind-detail p2-lora">Helping large enterprises to transform legacy products into experience-rich platforms, improving on user efficiency, productivity, employee satisfaction & making digital products more accessible. 
    </p>
    <div class="logo-list">
      <img src="assets/images/home/enterprise-1.png" alt="enterprise-1.png">
      <img src="assets/images/home/enterprise-2.png" alt="enterprise-2.png">
      <img src="assets/images/home/enterprise-3.png" alt="enterprise-3.png">
      <img src="assets/images/home/enterprise-4.png" alt="enterprise-4.png">
      <img src="assets/images/home/enterprise-5.png" alt="enterprise-5.png">
      <img src="assets/images/home/enterprise-6.png" alt="enterprise-6.png">

    </div>

  </div>


  <div class="tab-pane fade" id="pills-transport" role="tabpanel" aria-labelledby="pills-transport-tab">
    
    <div class="heading">
      <img src="assets/images/home/transport.png" alt="fintech-d-img">
      <p>Transport Logistics</p>
    </div>
    <p class="ind-detail p2-lora">From rout optimisation planning, increasing safety, regularising compliance to adding ease to drivers, dispatchers & end customers, our solutions have helped our clients in many ways. </p>
    <div class="logo-list">
      <img src="assets/images/home/transport-1.png" alt="transport-1">
      <img src="assets/images/home/transport-2.png" alt="transport-2">
      <img src="assets/images/home/transport-3.png" alt="transport-3">
      
    </div>

  </div>
</div>



      </div>
  
    </div>
  </div>
</section>
 
  <!-- industries tab end -->

  <!-- media-coverage start from here -->
  <section
  class="comman-section comman-section-100vh scroll-div home-sec7"
  id="section7"
  data-section-name="data-section7"
>
  <div class="container h-100 ">
    <div class="row vh100-100 align-items-center justify-content-center">
      <div class="col-sm-12 col-md-12 col-lg-6">
        <div class="comman-card-info">
          <ul
            class="list-inline comman-card-top-ul"
            data-aos="fade-in"
            data-aos-delay="300"
            data-aos-anchor="#section7"
          >
            <li class="list-inline-item h6 hide-after">Media Coverage</li>
          </ul>
          <h2
            class="h2 sec-heading position-relative"
            data-aos="fade-in"
            data-aos-delay="600"
            data-aos-anchor="#section7"
          >
          Thanks For<br/>
            Covering Us
          </h2>
          <div
            data-aos="fade-in"
            data-aos-delay="900"
            data-aos-anchor-placement="top-top"
            data-aos-anchor="#section7"
          >
            <!-- <a
              [routerLink]="['/services/ux-ui-design-process']"
              class="bttn btn-line btn-primary-custom cursor-pointer"
              ><span>Dive Deeper</span></a
            > -->
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6" id="process-final-img">
        <img
        src="assets/images/home/media-coverage.jpg"
        data-src="assets/images/home/media-coverage.jpg"
        alt="pr-ceo-video-modal"
        class="img-fluid"
        data-animation="animated fadeInLeft30"
      />
      </div>
    </div>
  </div>
</section>
  <!-- media-coverage end from here -->

  <section
    class="comman-section comman-section-100vh scroll-div home-sec7"
    id="section7"
    data-section-name="data-section7"
  >
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="comman-card-info">
            <ul
              class="list-inline comman-card-top-ul"
              data-aos="fade-in"
              data-aos-delay="300"
              data-aos-anchor="#section7"
            >
              <li class="list-inline-item h6 hide-after">Process</li>
            </ul>
            <h2
              class="h2 sec-heading position-relative"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section7"
            >
              Creativity With <br />
              Disciplined <span class="restlessRegularText">Actions</span>
            </h2>
            <div
              data-aos="fade-in"
              data-aos-delay="900"
              data-aos-anchor-placement="top-top"
              data-aos-anchor="#section7"
            >
              <a
                [routerLink]="['/services/ux-ui-design-process']"
                class="bttn btn-line btn-primary-custom cursor-pointer"
                ><span>Dive Deeper</span></a
              >
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6" id="process-final-img">
          <div class="process-img-wrap hide-mobile">
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/svg/process-final.gif"
              alt="The Rituals We Follow For Mastery"
              class="img-fluid process-iazy-img"
            />
          </div>
          <div class="process-img-wrap show-mobile">
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/svg/process-xs.png"
              alt="The Rituals We Follow For Mastery"
              class="img-fluid process-xs-img lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="comman-section comman-section-100vh scroll-div home-sec8"
    id="section8"
    data-section-name="data-section8"
  >
    <div class="container h-100">
      <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="comman-card-info">
            <ul
              class="list-inline comman-card-top-ul"
              data-aos="fade-in"
              data-aos-delay="300"
              data-aos-anchor="#section8"
            >
              <li class="list-inline-item h6 hide-after">Culture</li>
            </ul>
            <h2
              class="h2 sec-heading position-relative"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-anchor="#section8"
            >
              Daily Rituals <br class="d-block" />
              Shape Our <span class="restlessRegularText">Culture</span>
            </h2>
            <div
              data-aos="fade-in"
              data-aos-delay="900"
              data-aos-anchor-placement="top-top"
              data-aos-anchor="#section8"
            >
              <a
                [routerLink]="['/about']"
                class="bttn btn-line btn-primary-custom"
                ><span>More About Us</span></a
              >
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="comman-card-img-wrap2">
            <div
              id="culture-carousel"
              class="carousel slide carousel-fade"
              data-interval="4000"
              data-ride="carousel"
              
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="assets/images/home/culture1.png"
                    class="d-block"
                    alt="Gallery photo"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/images/home/culture2.png"
                    class="d-block"
                    alt="Gallery photo"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/images/home/culture3.png"
                    class="d-block"
                    alt="Gallery photo"
                  />
                </div>
              </div>
            </div>
            <div
              id="culture-carousel2"
              class="carousel slide carousel-fade"
              data-ride="carousel"
              data-interval="4000"
              
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="assets/images/home/culture-top-1.png"
                    class="d-block"
                    alt="Gallery photo"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/images/home/culture-top-2.png"
                    class="d-block"
                    alt="Gallery photo"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/images/home/culture-top-3.png"
                    class="d-block"
                    alt="Gallery photo"
                  />
                </div>
              </div>
            </div>
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/home/home-sec8-3.svg"
              alt="Gallery photo"
              class="home-sec8-3 lazy floatingX-r"
            />
            <img
              src="assets/images/preloader/ajax-loader.png"
              data-src="assets/images/home/home-sec8-4.svg"
              alt="Gallery photo"
              class="home-sec8-4 lazy floatingX-l"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
