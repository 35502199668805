<div class="privacy-term-page-body">
    <section class="comman-section term-section1 scroll-div top-section-wrapper" data-section-name="data-section1"
        id="section1">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="term-card-wrap-outer row">
                        <div class="col-md-12">
                            <div class="term-card-wrap term-card-wrap1">
                                <h1 class="xs-text-center">Privacy Policy</h1>
                                <h6 class="xs-text-center">YOUR USE OF THIS WEBSITE IS SUBJECT TO THESE TERMS OF USE
                                    &amp; OUR PRIVACY POLICY.</h6>
                                <p class="p2 text-light-dark-c"> This Terms of Use <span>("TOU") </span>and Privacy
                                    Policy is an agreement between Prismic Reflections&reg; Web Solutions LLP
                                    <span>("Prismic Reflections&reg;/Us/Our/We/Vendor/Company"), </span> a Company
                                    incorporated under the Limited Liability Partnership Act, 2008 having its registered
                                    office at Jyoti Telecom, Plot No. IT 21, II Park, MIDC Ambad, Nashik, Maharashtra
                                    422010 , the owner and operator of <a [routerLink]="['/home']" target="_blank"
                                        class="a-link-black">www.prismicreflections.com</a><span>&nbsp;("Website")</span> and
                                    you <span>("you/your/user/viewer")</span>, a user of the Website. <br /><br /> BY
                                    USING THE WEBSITE, YOU ACKNOWLEDGE AND AGREE TO THESE TOU AND PRIVACY POLICY, AS
                                    UPDATED FROM TO TIME TO TIME AND WHICH ARE INCORPORATED HEREIN. IF YOU CHOOSE TO NOT
                                    AGREE WITH ANY OF THESE TERMS, YOU MAY NOT USE THE WEBSITE.</p>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="one"></a>I. <span>WEBSITE</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> The term <span>"Website"</span> includes the content on the Website and all of
                                        services provided on the Website (the <span>"Website Services"</span>). Your use
                                        of the Website (or any part thereof) signifies your agreement to be bound by
                                        these TOU and the Privacy Policy. These TOU are a binding legal agreement
                                        between you and Prismic Reflections&reg;.</li>
                                    <li> Your use of certain Website Services, features, functionality or programs
                                        (including, without limitation, contests, sweepstakes, promotions, wireless
                                        marketing opportunities, RSS feeds, blogs etc.) offered on or through the
                                        Website may be subject to additional terms and conditions, and before you use
                                        any such services, features, functionality or other programs you may be required
                                        to indicate your acceptance of such additional terms and conditions.</li>
                                    <li> The information which is displayed on the Website shall not be treated or
                                        construed to be an offer to the user. The same shall be treated as general
                                        information which can be accessed as per the terms of this TOU.</li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="two"></a>II. <span>CONSENT</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> By accessing and using this Website, you provide your specific consent to the
                                        Website to use and collect the information provided by you. Please Note that for
                                        any information provided by you on the Website, it will be deemed that:<ol
                                            class="ol-list p2" type="a">
                                            <li> You are above 18 years of age.</li>
                                            <li> You understand that you have provided specific consent to Prismic
                                                Reflections to collect and process the User Submission (defined below)
                                                provided by you.</li>
                                            <li> The consent provided by you is the basis for lawful processing of User
                                                Submission by Prismic Reflections&reg;.</li>
                                            <li> You have read and understood the terms and conditions of the TOU and
                                                Privacy Policy and are aware that the collection and processing of your
                                                User Submission will be subject to the same.</li>
                                            <li> You have, by way of these Terms of use and privacy policy and/or by any
                                                other communications sent by Prismic Reflections&reg; been made aware of
                                                the categories of the User Submissions/personal data collected by the
                                                Prismic Reflections, the purpose and nature for which the User
                                                Submissions/personal data has been collected, the sources used for
                                                collection of User Submissions/personal data, your rights with respect
                                                to the User Submission/personal data, etc.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="three"></a>III. <span>MODIFICATIONS</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> Prismic Reflections&reg; reserves the rights, at its sole discretion, to
                                        change, modify, and/or add to these TOU and the Privacy Policy, in whole or in
                                        part, at any time and without any prior notice to any person including you.
                                        Changes to the TOU and Privacy Policy will be effective prospectively from when
                                        they are posted. You agree to review the TOU and Privacy Policy periodically to
                                        become aware of any changes. Your use of the Website or any part thereof after
                                        any changes to these TOU and/or Privacy Policy are posted will be deemed
                                        acceptance of those changes and will constitute your agreement to be bound
                                        thereby. If you object to any such changes, your sole recourse will be to not
                                        subscribe or stop using the Website.</li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="four"></a>IV. <span>INTELLECTUAL PROPERTY</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> The ownership of the content of the Websites along with all text, graphics,
                                        audio, designs, screen shots, logos, images, copyrights, patents, trademarks,
                                        service marks, trade names and all other intellectual property rights are vested
                                        in Prismic Reflections unless otherwise indicated. All rights in relation to
                                        such works shall be reserved with Prismic Reflections&reg; and shall be its sole
                                        property.</li>
                                    <li> Content on the Website is for personal use only and may be downloaded provided
                                        the material is kept intact and there is no violation of the copyrights,
                                        trademarks, and other proprietary rights. Any alteration of the material or use
                                        of the material contained in the website for any other purpose shall constitute
                                        a violation of the copyright of Prismic Reflections&reg; and / or its affiliates
                                        or associates or of its third-party information providers.</li>
                                    <li> Unless you have otherwise obtained Prismic Reflections&reg; written permission,
                                        you agree not to copy, reproduce, duplicate, stream, capture, record, access
                                        through technology, transfer, sell, resell, upload, archive, license to others,
                                        edit, modify, reverse engineer, decode, manipulate, create derivative works from
                                        or based upon, publish, republish, post, transmit, publicly display, frame, link
                                        from or to, distribute, share, embed, translate, decompile, translate,
                                        incorporate into any hardware or software application, use for commercial
                                        purposes, or otherwise use or exploit the Website, its content, Website Services
                                        or any component or part thereof. Any of the unauthorized uses referred to above
                                        would constitute an infringement of the copyrights and other proprietary rights
                                        of Prismic Reflections&reg; and/or its licensors and a violation of these TOU
                                        and may subject you to civil and/or criminal liability under applicable laws.
                                    </li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="five"></a>V. <span>LICENSES GRANTED TO YOU</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> Subject to these TOU (including, without limitation to the Section IV set
                                        forth), Prismic Reflections&reg; hereby grants you, if and only to the extent
                                        the necessary functionality is provided to you on or through the Website, the
                                        following limited, revocable, non-exclusive, non-transferable, non- assignable,
                                        worldwide, royalty-free rights and licenses (each a <span>"License"</span>):<ol
                                            class="ol-list p2" type="a">
                                            <li> the License to access, view and otherwise use the Website for your
                                                personal, non-commercial, lawful use only, as intended through the
                                                normal functionality of the Website;</li>
                                            <li> if the Website includes a "Download" link next to a piece of Website
                                                content, the License to download a single copy of such content to a
                                                single computer or other permitted device for your personal,
                                                non-commercial use only;</li>
                                            <li> License to use any other functionality expressly provided by Prismic
                                                Reflections on or through the Website for use by users, subject to these
                                                TOU.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="six"></a>VI. <span>THIRD PARTY COMMENTS AND LINKS</span>
                                </h6>
                                <ol class="ol-list p2" type="1">
                                    <li> The Website may provide links to and/or contain information about other
                                        websites and companies which may not be owned or controlled by Prismic
                                        Reflections&reg;. The Website may also contain or display comments or contents
                                        of other companies or third parties which may or may not relate to the services
                                        provided by Prismic Reflections&reg;. You acknowledge and understand that
                                        Prismic Reflections&reg; does not endorse or sponsor such other third party
                                        websites, companies, services, products or other internet resources. Prismic
                                        Reflections&reg;expressly and specifically disclaims that any responsibility and
                                        liability for any content, software, functionality and advertisements found on
                                        the Webiste or related to any such third party website, companies, services,
                                        products or other internet resources shall not be placed upon Prismic
                                        Reflections&reg;.</li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="seven"></a>VII. <span>PRIVACY POLICY</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> Your privacy is important to us. To better protect your privacy, we provide
                                        this notice explaining our online information practices and the choices you can
                                        make about the way your information is collected and used. To make this notice
                                        easy to find, we make it available on our home page and at every point where
                                        personally identifiable information may be requested.</li>
                                    <li> The Website may ask you to provide information that will enable us to verify
                                        whether you are entitled to access and use certain information available from
                                        the Website. This information may also be used to enable us to enhance your
                                        Website visit, to assist you with customer service or technical support issues,
                                        or to follow up with you after your visit, or to otherwise support your
                                        relationship with us. It is completely optional for you to participate, however
                                        if you chose to provide your information it shall be considered to be given by
                                        your free consent.</li>
                                    <li> Collection of data/information: The Website may enable you to send, post,
                                        upload, transmit or otherwise submit to the Website or otherwise display or
                                        exchange (including, without limitation, with other Website users) on or via the
                                        Website (collectively, <span>"Submit")</span>, any information, images, or other
                                        content including but not limited to your personal and sensitive information
                                        including but not limited to your Name, Occupation, Age, Sex, E-mail Address,
                                        Phone, Mailing Address, Interests, Interested Services, budget, URLs you came
                                        from and go to, your browser and operating system information, your internet
                                        protocol address, your mobile provider, your mobile device, your Internet
                                        Service Provider, etc (collectively, your <span>"User Submissions"</span>).
                                        These User Submissions are generally provided by you when you register on the
                                        Website, subscribe to our newsletter, respond to a survey, fill out a form, use
                                        live chat, provide your feedback on our products or services or are specifically
                                        requested to enter User Submissions on the Website in any other manner. Further,
                                        for the purpose of collecting User Submissions we use cookies, chat Google,
                                        Google Analytics, Google Adword, Google AdSense Advertising amongst other
                                        technologies. We hereby inform that your User Submissions may be shared with any
                                        third party or used by us to contact you with respect to marketing and other
                                        purposes. You hereby expressly and specifically consent to share User
                                        Submissions with Prismic Reflections&reg;. When you Submit (or attempt to
                                        Submit) your User Submissions on or through the Website, you automatically grant
                                        Prismic Reflections&reg; and its affiliates, perpetual, worldwide,
                                        unconditional, unrestricted, fully-paid, royalty-free, transferable, fully
                                        sub-licensable to multiple tiers of sub-licensees, non-exclusive right and
                                        license to display, publicly perform, distribute (including, without limitation,
                                        through third- party websites, blogs, microblogs and other social networking
                                        media and Internet resources), store, transcode, host, cache, maintain,
                                        broadcast, webcast, podcast, transmit, distribute, tag, track, reproduce, edit,
                                        modify, format, re-format, link to and from, translate, delete, create
                                        derivative works, combine with other content, categorize, and/or otherwise use,
                                        reuse and/or otherwise exploit your User Submissions without the requirement for
                                        any compensation to you or anyone else, or the need to require additional
                                        consent from you or anyone else, or the need to provide prior notification or
                                        any credit to you or anyone else.</li>
                                    <li> Please keep in mind that whenever you voluntarily disclose User Submissions
                                        through the Website, that information can be collected and used by any other
                                        third party as well. Ultimately, you are solely responsible for maintaining the
                                        secrecy of your personal information and you sharing your personal information
                                        itself shall be considered and consenting to the collection and use of the same
                                        by others through the Website.</li>
                                    <li> If any part of the User Submission includes any content that is covered by
                                        intellectual property rights, you specifically give us the permission and grant
                                        us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide
                                        license to use the same.</li>
                                    <li> You acknowledge and agree that:<ol class="ol-list p2" type="a">
                                            <li> you have no expectation of confidentiality of any nature with respect
                                                to any of your User Submissions, and</li>
                                            <li> Prismic Reflections&reg; and/or any of its affiliates may already have
                                                projects under consideration that are similar to your User Submissions
                                                or may independently develop projects that are similar to your User
                                                Submissions, or other User Submissions transmitted by other users of the
                                                Website may be similar or the same as your User Submissions.</li>
                                        </ol>
                                    </li>
                                    <li> Prismic Reflections&reg; is not responsible for any loss, theft or damage of
                                        any kind to any User Submissions. Prismic Reflections&reg; shall not be held
                                        liable for any third party liabilities in connection with any and all User
                                        Submissions, and you agree to waive any legal or equitable rights or remedies
                                        you may have against Prismic Reflections or any of its affiliates with respect
                                        to such User Submissions.</li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="eight"></a>VIII. <span>YOUR RESPONSIBILITY</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> Your rights under any one or more of the Licenses set forth above and under
                                        this TOU are conditioned upon your compliance, with each of the following:<ol
                                            class="ol-list p2" type="a">
                                            <li> You shall not to host, display, upload, modify, edit, delete, publish,
                                                transmit, update or share any information that -<ol class="ol-list p2"
                                                    type="i">
                                                    <li> belongs to another person and to which the user does not have
                                                        any right to;</li>
                                                    <li> is grossly harmful, harassing, blasphemous defamatory, obscene,
                                                        pornographic, paedophilic, libellous, invasive of another's
                                                        privacy, hateful, or racially, ethnically objectionable,
                                                        disparaging, relating or encouraging money laundering or
                                                        gambling, or otherwise unlawful in any manner whatever;</li>
                                                    <li> harm minors in any way;</li>
                                                    <li> infringes any intellectual property rights which include but
                                                        not limit to patent, trademark, copyright or other proprietary
                                                        rights;</li>
                                                    <li> violates any law for the time being in force;</li>
                                                    <li> deceives or misleads the addressee about the origin of such
                                                        messages or communicates any information which is grossly
                                                        offensive or menacing in nature;</li>
                                                    <li> impersonate another person;</li>
                                                    <li> contains software viruses or any other computer code, files or
                                                        programs designed to interrupt, destroy or limit the
                                                        functionality of any computer resource;</li>
                                                    <li> threatens the unity, integrity, defence, security or
                                                        sovereignty of India, friendly relations with foreign states, or
                                                        public order or causes incitement to the commission of any
                                                        cognizable offence or prevents investigation of any offence or
                                                        is insulting any other nation.</li>
                                                </ol>
                                            </li>
                                            <li> You will not remove, alter, interfere with or circumvent any of
                                                copyright, trademark, watermark, or other proprietary notices
                                                marked/displayed on the Website.</li>
                                            <li> You will not bundle or combine any design, item/s in design and or
                                                artwork provided to you by Prismic Reflections&reg; or appearing on the
                                                Website with other items for the purposes including but not limited to
                                                re-distributing.</li>
                                            <li> You will not use any design provided to you by Prismic Reflections&reg;
                                                or appearing on the Website to create more than one unique end product.
                                            </li>
                                            <li> You will not remove, alter, interfere with or circumvent any digital
                                                rights management mechanism, device or other content protection or
                                                access control measure (including, without limitation geofiltering
                                                and/or encryption) associated with Website.</li>
                                            <li> you will not use any of the rights granted to you or any of the content
                                                on the Website in a manner that suggests an association with any of
                                                Prismic Reflections services or brand, unless otherwise specifically
                                                permitted by Prismic Reflections&reg;.</li>
                                            <li> You will not use bots, spiders, offline readers or other automated
                                                systems to access or use the Website in a manner that sends more request
                                                messages to the Website's servers in a given period of time than a human
                                                can reasonably produce in the same period by using a conventional web
                                                browser, unless you are a lawfully operating a public search engine, in
                                                which case (subject to Prismic Reflections&reg; full discretion to
                                                revoke this exception at any time), you may use spiders to index
                                                materials from the Website for the sole purpose of creating publicly
                                                available searchable indices (but not caches or archives) of such
                                                materials.</li>
                                            <li> You will not transmit any material that contains adware, malware,
                                                spyware, software viruses, timebombs, cancelbots, worms, trojan horses,
                                                or any other computer code, files, or programs designed to interrupt,
                                                destroy, or limit the functionality of any computer software or hardware
                                                or telecommunications equipment.</li>
                                            <li> You will not do anything that is likely to adversely affect or reflect
                                                negatively upon or harm the goodwill or reputation of Prismic
                                                Reflections or any of its affiliates, or the Website, or any of the
                                                content running or being promoted on the Website.</li>
                                            <li> You will not do anything on the Website that would prevent other user'
                                                access to or use of the Website or any part thereof.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="nine"></a>IX. <span>DISCLAIMER OF WARRANTIES</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> By using the Website, you agree and understand that such use is at your own
                                        risk and that there is no warranty that this Website will be free of any
                                        computer viruses. The Website (and any portion of the Website) is provided "as
                                        is" "with all faults" and "as available" to the fullest extent permitted by law.
                                        Prismic Reflections&reg; and its affiliates expressly disclaim any and all
                                        warranties of any kind, whether express or implied (including, without
                                        limitation, the implied warranties of merchantability, fitness for a particular
                                        use or purpose and non-infringement). Neither Prismic Reflections&reg; nor any
                                        of its affiliates make any warranties or representations about the accuracy or
                                        completeness of information available on or through the Website or other
                                        internet resources linked to the Website and assume no liability or
                                        responsibility for any:<ol class="ol-list p2" type="a">
                                            <li> errors, mistakes, or inaccuracies of content;</li>
                                            <li> personal injury or property damage, of any nature whatsoever, resulting
                                                from your access to or use of the Website;</li>
                                            <li> any unauthorized access to any and all personal information stored in
                                                the Website;</li>
                                            <li> any interruption or cessation of transmission to or from the Website;
                                            </li>
                                            <li> any bugs, viruses, Trojan horses, or the like which may be transmitted
                                                to or through the Website (or any parts thereof) by any third party;
                                                and/or</li>
                                            <li> for any loss or damage of any kind incurred as a result of the use of
                                                any user information and/or other Website content posted, shared,
                                                forwarded, emailed, transmitted, or otherwise made available on or
                                                through the Website.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="ten"></a>X. <span>LIMITATION OF LIABILITY</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAWS, IN NO EVENT
                                        WILL PRISMIC REFECTIONS, OR ANY OF ITS AFFILIATES OR ANY OF THEIR RESPECTIVE
                                        OFFICERS, EMPLOYEES, DIRECTORS, SHAREHOLDERS, AGENTS, OR LICENSORS BE LIABLE TO
                                        YOU OR ANYONE ELSE UNDER ANY THEORY OF LIABILITY (WHETHER IN CONTRACT, TORT,
                                        STATUTORY, OR OTHERWISE) FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                                        CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR
                                        LOSS OF REVENUES, PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN
                                        IF SUCH PARTIES WERE ADVISED OF, KNEW OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY
                                        OF SUCH DAMAGES), RESULTING FROM YOUR USE (OR THE USE OF ANYONE USING AN ACCOUNT
                                        REGISTERED TO YOU) OF THE WEBSITE OR ANY PARTS THEREOF.</li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="eleven"></a>XI. <span>LIMITATION OF SERVICE</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> If you choose to engage Prismic Reflections&reg; to perform consulting, UI UX
                                        design, graphic design, web development, testing, deployment and project
                                        management services or any other service, you will also abide by the following
                                        terms:<ol class="ol-list p2" type="a">
                                            <li> Authorization of Services: A written (Email / fax) authorization from
                                                you to the Prismic Reflections&reg; is required to commence the project
                                                or parts of it and for Prismic Reflections&reg; to begin work. Each
                                                project would get started only after receiving a written purchase
                                                order/contract by mail from you.</li>
                                            <li> Compensation: In consideration for the services to be performed by
                                                Prismic Reflections, you will agree to pay Prismic Reflections&reg;
                                                based on the quoted lump sum or hourly or weekly or any other rate
                                                structure as suggested by Prismic Reflections&reg;. Prismic
                                                Reflections&reg; acknowledges that no other compensation will be payable
                                                by you, and that all of Prismic Reflections's compensation will depend
                                                on actual additional hours worked by Prismic Reflections and approved by
                                                you.</li>
                                            <li> Expenses: You shall reimburse Prismic Reflections&reg; for approved
                                                expenses incurred for the completion of the project work. Typical
                                                reimbursable expenses include: Purchase of any required special fonts,
                                                stock photography, design proofing print-outs, travel reimbursement and
                                                any long distance charges incurred related to the project, as needed for
                                                successful completion of the project. Prismic Reflections&reg; shall be
                                                responsible for all other expenses incurred while performing services
                                                under this Agreement or the service agreement. This includes any salary,
                                                expenses and other compensation paid to employees, agents, affiliates or
                                                contract personnel of Prismic Reflections hired to complete the project.
                                            </li>
                                            <li> Ownership &amp; Usage Rights of Deliverables:<ol class="ol-list p2"
                                                    type="i">
                                                    <li> Until full payment has been made, Prismic Reflections&reg;
                                                        retains ownership of all original artwork or parts contained
                                                        therein, whether preliminary or final. Upon full payment, you
                                                        may obtain ownership of the final artwork. Usage rights of the
                                                        artwork &amp; design files are non-exclusive and allowed to be
                                                        used in one single end product, under one domain name and owned
                                                        by one company or one entity or one individual. For example:
                                                        Single End Product is one Website or one web Application of a
                                                        single domain.</li>
                                                    <li> Prismic Reflections&reg; retains the right to use the completed
                                                        project and any preliminary designs for the purpose of design
                                                        competitions, future publications on design, educational
                                                        purposes, marketing materials, and portfolio. Where applicable,
                                                        you will be given any necessary credit for usage of the project
                                                        elements. Once the design or code is live, Prismic
                                                        Reflections&reg; retains the right to use "Site Designed &amp;
                                                        Developed by Prismic Reflections&reg; Web Solutions LLP" or a
                                                        similar caption on your website as per mutually agreed and
                                                        generally acceptable standard guidelines.</li>
                                                </ol>
                                            </li>
                                            <li> Production Schedule/Delivery of Project: Prismic Reflections&reg; and
                                                the Client must work together to complete the project in a timely
                                                manner. Much of this depends on receiving the appropriate images and
                                                text, as applicable, from the client as well as timely client feedback.
                                                We agree to work expeditiously to complete the project in a professional
                                                and timely fashion. The client will assume any shipping or insurance
                                                costs related to the project. Any alteration or deviation from the
                                                original specifications involving extra costs will be executed only upon
                                                approval of the client.</li>
                                            <li> Third Party Shipping: In the event any material necessary for the
                                                production of the project must be shipped to a third party for
                                                additional processing, typesetting, photographic work, color separation,
                                                press work, or binding, Prismic Reflections&reg; will incur no liability
                                                for losses incurred in transit, or due to delay of the shipper of the
                                                third party.</li>
                                            <li> Proofing of the Final Product: Prismic Reflections&reg; shall make
                                                every effort to ensure the final product is free of any grammatical and
                                                spelling errors, before giving the final product to the client. However,
                                                it is agreed that it is your responsibility to ensure that there are no
                                                spelling or grammatical errors contained in the final product. It is
                                                agreed that Prismic Reflections is not responsible or would not be held
                                                liable for any errors contained in the final product after the final
                                                product has been committed to print or posted in view of the public.
                                            </li>
                                            <li> Cancellation: In the event of cancellation of the project, all the
                                                original artworks and disks along with the ownership of their copyrights
                                                shall be returned by you and shall be retained by Prismic
                                                Reflections&reg;. A fee for the work completed, based on the contract
                                                price and expenses already incurred, shall be paid by you.<ol
                                                    class="ol-list p2" type="i">
                                                    <li> Independent Contractor Status: Prismic Reflections&reg; is an
                                                        independent contractor and not your employee. Prismic
                                                        Reflections&reg; has the right to perform services for others
                                                        during the term that the project is executed. Subject to any
                                                        restrictions contained in TOU or service agreement, Prismic
                                                        Reflections&reg; has the right to perform the services required
                                                        at any location or time.</li>
                                                    <li> Confidentiality: All correspondence and documents exchanged
                                                        after you choose to engage Prismic Solutions for any services
                                                        will be treated as confidential between you and the Prismic
                                                        Reflections&reg;, unless both parties involved have granted
                                                        consent.</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="twelve"></a>XII. <span>SERVICE AGREEMENT</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> If you choose to engage Prismic Reflections&reg; to perform consulting, UI UX
                                        design, graphic design, web development, testing, deployment and project
                                        management services or any other service, you will in addition to this TOU and
                                        Privacy Policy also abide by the Service Agreement which shall be separately
                                        executed between you and Prismic Reflections.</li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="thirteen"></a>XIII. <span>NO PROFESSIONAL ADVICE</span>
                                </h6>
                                <ol class="ol-list p2" type="1">
                                    <li> Material, content or information contained on or made available through the
                                        Website is not intended to and does not constitute any legal, financial or tax
                                        related advice nor does it, in any manner establish a Professional - Client
                                        relationship.</li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="fourteen"></a>XIV. <span>GRIEVANCE OFFICER</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> As per the Information Technology (Intermediaries guidelines) Rules, 2011,
                                        Prismic Reflections has appointed Mr. Vishal Jadhav as the Grievance Officer,
                                        his contact details are as follows: <br> Email: <a
                                            href="mailto:info@prismicreflections.com" target="_top"
                                            class="a-link-black">info@prismicreflections.com</a> <br> Contact: <a
                                            href="tel:+917887888765" target="_top" class="a-link-black">+91 788 788
                                            8765</a></li>
                                    <li> You also have certain rights with respect to the User Submission/information
                                        provided by you. You may request Prismic Reflections&reg;to
                                        remove/update/rectify/edit/complete/port/restrict from processing your User
                                        Submissions. You may send your requests to the above stated Grievance Officer
                                        and your requests will be processed by Prismic Reflections&reg; within one month
                                        of receiving the requests, unless communicated otherwise.</li>
                                    <li> Users or any victim who suffers as a result of access or usage of Website by
                                        any person can notify their complaints against such access or usage of the
                                        Website to the Grievance Officer or to the Supervisory Authority as the case may
                                        be. The Grievance Officer shall redress the complaints within one month from the
                                        date of receipt of complaint.</li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="fifteen"></a>XV. <span>INDEMNIFICATION</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> You agree to indemnify and hold harmless Prismic Reflections&reg; and its
                                        affiliates and their respective officers, directors, employees, representatives,
                                        licensees, authorized designees, successors and assigns from any and all claims,
                                        liabilities, damages, losses, costs, expenses, fees (including reasonable
                                        outside attorneys' fees and costs) that such parties may incur as a result of or
                                        arising from:<ol class="ol-list p2" type="a">
                                            <li> any of your wrong/false user information,</li>
                                            <li> your unauthorized exercise of any of your rights granted under the
                                                these TOU and Privacy Policy,</li>
                                            <li> the breach of any of your warranties, representations, covenants,
                                                responsibilities or other obligations set forth in these TOU and Privacy
                                                Policy,</li>
                                            <li> your violation of any person's intellectual property, privacy,
                                                publicity or other right,</li>
                                            <li> the violation of any applicable laws and/or these TOU and Privacy
                                                Policy by you or</li>
                                            <li> your misconduct or negligence by your or anyone accessing the Website
                                                in connection with your (and/or such other person's) use of the Website
                                                (in whole or in part).</li>
                                        </ol>
                                    </li>
                                    <li> Prismic Reflections&reg; reserves the right to assume the exclusive defense and
                                        control of any matter otherwise subject to indemnification by you, in which
                                        event you will cooperate with Prismic Reflections&reg; in asserting any
                                        available defenses. This provision shall remain in full force and effect
                                        notwithstanding any termination of your use of the Website.</li>
                                    <li> Further, in no event shall Prismic Reflections&reg; or any of its subsidiaries
                                        or affiliates be liable to any entity for any direct, indirect, special,
                                        consequential or other damages (including, without limitation, any lost profits,
                                        business interruption, loss of information or programs or other data on your
                                        information handling system) that are related to the use of, or the inability to
                                        use, the content, materials, and functions of the Website or any linked Website.
                                    </li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="sixteen"></a>XVI. <span>TERMINATION</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> If you violate the letter or spirit of the TOU and Privacy Policy, or otherwise
                                        create risk or possible legal exposure for Prismic Reflections&reg; or any of
                                        its directors/employees/affiliates, we can stop providing access to all or part
                                        of the Website to you.</li>
                                    <li> All provisions of this TOU shall remain in full force and effect in perpetuity
                                        notwithstanding any termination of your use of the Website.</li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="seventeen"></a>XVII. <span>SEVERABILITY AND WAIVER
                                    </span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> If any provision of the TOU shall be held unlawful, void, or for any reason
                                        unenforceable, then that provision shall be deemed severable from these TOU and
                                        shall not affect the validity and enforceability of any remaining provisions.
                                        The failure of Prismic Reflections&reg; to exercise or enforce any right or
                                        provision of these TOU shall not constitute a waiver of such right or provision.
                                    </li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="eighteen"></a>XVIII. <span>FORCE MAJEURE </span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> Prismic Reflections&reg; performance under this TOU is subject to interruption
                                        and delay due to causes beyond its reasonable control such as acts of God, acts
                                        of any Government, war or other hostility, civil disorder, the elements, fire,
                                        explosion, power failure, failure of the Internet and other networks beyond the
                                        control of Prismic Reflections, equipment failure, industrial or labour dispute,
                                        inability to obtain essential supplies and the like. Prismic Reflections&reg;
                                        shall not be held liable if services with respect to the Website are interrupted
                                        or improper at any point of time due to Force Majeure events specified above.
                                    </li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a id="nineteen"></a>XIX. <span>ARBITRATION AND GOVERNING
                                        LAW</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> You will resolve any claim, cause of action or dispute (claim) you have with
                                        Prismic Reflections arising out of or relating to this TOU and Privacy Policy
                                        document exclusively in India, regardless of where you reside. The laws of the
                                        Republic of India will govern this TOU and Privacy Policy document, as well as
                                        any claim that might arise between you and Prismic Reflections&reg;, without
                                        regard to conflict of law provisions.</li>
                                    <li> All disputes or differences of any nature whatsoever, arising between you and
                                        Prismic Reflections&reg; shall be settled by mutual discussion. Prismic
                                        Reflections&reg;or you may, at its own discretion, appoint an authorised
                                        representative for the sake of mutual discussion. If the dispute remains
                                        unresolved for a period exceeding thirty (30) days from the date of reference of
                                        the dispute, then all actions or proceedings arising in connection with,
                                        touching upon or relating to these TOU, or the Website or the breach of these
                                        TOU and/or the scope of the provisions of this arbitration clause, shall be
                                        submitted for final and binding arbitration under Arbitration and Conciliation
                                        Act, 1996 (as amended) to a sole arbitrator. The venue of the arbitration shall
                                        be at Nashik, India and the language of the arbitration shall be English. The
                                        decision of the Arbitrator shall be final and binding on both the parties. The
                                        Arbitration proceedings shall be subject to the jurisdiction of the Courts in
                                        Nashik. Both you and Prismic Reflections&reg;, to the extent it is reasonably
                                        possible shall continue to discharge their respective obligations during the
                                        pendency of Arbitration proceedings.</li>
                                    <li> If anyone brings a claim against us related to your actions, content or
                                        information on the Website, you will indemnify and hold Prismic Reflections&reg;
                                        and its directors, agents, representatives, partners, employees and consultants
                                        harmless from and against all damages, losses, and expenses of any kind
                                        (including reasonable legal fees and costs) related to such claim.</li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a></a>XX. <span>OTHER</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> You agree and confirm that we do not control any direct users' actions on the
                                        Website and are not responsible for the content or information users transmit or
                                        share on the Website. Prismic Reflections&reg;is not responsible for any
                                        offensive, obscene, unlawful or otherwise objectionable content or information
                                        you may encounter on the Website. We are not responsible for the conduct,
                                        whether online or offline, of any user of the Website.</li>
                                    <li> This TOU and Privacy Policy, makes up the entire agreement between the parties
                                        regarding Prismic Reflections&reg; and supersedes any prior agreements.</li>
                                    <li> You will not transfer any of your rights or obligations under this Terms of Use
                                        and Privacy Policy to anyone else without our consent.</li>
                                    <li> All our rights and obligations under this TOU and Privacy Policy are freely
                                        assignable by us in connection with a merger, acquisition, or sale of assets, or
                                        by operation of law or otherwise.</li>
                                    <li> Nothing in this TOU and Privacy Policy shall prevent us from complying with the
                                        law.</li>
                                    <li> This TOU and Privacy Policy do not confer any third party beneficiary rights.
                                    </li>
                                    <li> We reserve all rights not expressly granted to you.</li>
                                    <li> You will comply with all applicable and governing laws when using or accessing
                                        the Website.</li>
                                </ol>
                            </div>
                            <div class="term-card-wrap-inner">
                                <h6 class="h4 clearfix"><a></a><span>Disclaimer</span></h6>
                                <ol class="ol-list p2" type="1">
                                    <li> This TOU and Privacy Policy has been published in accordance with the
                                        provisions of Rule 3 (1) of the Information Technology (Intermediaries
                                        guidelines) Rules, 2011, that require publishing the rules and regulations and
                                        Terms of Use for access or usage of the Company's Website and in accordance with
                                        the General Data Protection Regulation. These Terms of Use is an electronic
                                        record under the laws of India and need not be signed physically or digitally.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>