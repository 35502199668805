import {Component, OnInit} from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-pvcypolcy',
  templateUrl: './pvcypolcy.component.html',
  styleUrls: ['./pvcypolcy.component.scss']
})
export class PvcypolcyComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    $(document).ready(function(): void {
      function createCookie(name, value, days): void {
        let expires = '';
        if (days) {
          const date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = '; expires=' + date.toUTCString();
        } else {
          expires = '';
        }
        document.cookie = name + '=' + value + expires + '; path=/';
      }

      function readCookie(name): string {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let c of ca) {
          while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
          }
          if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
          }
        }
        return null;
      }

      function eraseCookie(name): void {
        createCookie(name, '', -1);
      }

      function checkCookie(name): boolean {
        return readCookie(name) != null;
      }

      $(document).ready(function(): void {
        if (checkCookie('prismics')) {
          $('div.privacy-policy-sticky').hide();
        } else {
          $('div.privacy-policy-sticky').show();
        }
        $('.btn-success').click(function(): boolean {
          createCookie('prismics', 'yes', 5000);
          $('div.privacy-policy-sticky').slideUp();
          return false;
        });
        $('#spanYear').html(new Date().getFullYear());
        $('#agree-btn').click(function(e7): void {
          e7.preventDefault();
          $('.privacy-policy-sticky').delay(100).animate({
            bottom: '-250px',
          }, 800);
        });
      });

      function setCookie(cname, cvalue, exdays): void {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
        $('.privacy-policy-sticky').hide();
      }

      function getCookie(cname): string {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let c of ca) {
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return '';
      }

    });
  }

}
