<div class="portfolio-branding-concerro-body">
    <section class="comman-section comman-section-100vh scroll-div concerro-sec1" id="section1"
        data-section-name="data-section1">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section1" style="background-color: #ffffff;"></div>
        <div class="container h-100 position-relative z-index5">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 text-center">
                    <div class="concerro-center-wrap xs-mt30">
                        <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-branding-concerro/concerro-logo.svg"
                                alt="Concerro - Logo" class="concerro-logo lazy logo-img img-fluid rotate-ani" />
                        </div>
                        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section1">
                            <h1>
                                A Brand with a Promise for Quality, Assurance &amp; Affordability in Medical Testing Kits.
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="concerro-pattern-wrap" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section1">
            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-branding-concerro/concerro-pattern.svg"
                                alt="Concerro - Logo" class="concerro-pattern lazy img-fluid" />
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">
                Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div concerro-sec2" id="section2"
        data-section-name="data-section2">
        <div class="ani-wrap100-top" data-aos="new-animation3" data-aos-delay="300" data-aos-anchor="#section2"
            style="background-color: #252C3C;"></div>
            <div class="concerro-pattern-wrap" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section2">
                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-branding-concerro/concerro-pattern2.svg"
                                    alt="Concerro - Logo" class="concerro-pattern lazy img-fluid" />
            </div>
        <div class="container h-100 position-relative z-index5">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="overview-sec-center portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                            Overview</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2">
                            Concerro Health provides high quality LC-MS/MS calibration standards worldwide. 
                            More than a decade of experience in this field, the company manufactures standard chemical kits with High/Low concentrations of polypropylene glycol  (PPG's), having longer stability and 2x volume which ensures your mass spec is performing at its best.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge scroll-div concerro-sec3" id="section3"
        data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #fff;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3"></div>
        <div class="ani-wrap50" style="background: #186CD4;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section3"></div>       
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <div class="concerro-left-wrap portfolio-info-wrap2 lg-mt-60">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3" class="text-white">
                            Challenges</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">
                            Medical kits are very complex & technical in nature. Trust and quality assurance in this industry plays an important role, hence client wanted to create a sense of trust & dependability among the audience along with strong promise of quality in producing medical kits. 
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 bg-white-adj">
                    <div class="concerro-right-wrap portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section3">Creative Brief</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section3">
                            Targeting Indian as well as international Pharma services & Medical device companies. The brand is expected to connect to the people who will likely be CEO or President of Marketing of these companies. Overall objective was to create a new identity to communicate a sense of companionship between customers when they are executing complex processes of medical kit manufacturing. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div concerro-sec4" id="section4"
        data-section-name="data-section4">
        <div class="ani-wrap50-2" style="background-color: #252C3C;" data-aos="new-animation2" data-aos-delay="1200" data-aos-anchor="#section4"></div>
            <div class="ani-wrap50-b" style="background-color: #1BD8A6;" data-aos="new-animation2" data-aos-delay="2100" data-aos-anchor="#section4"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <div class="concerro-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4">Brainstorming & Ideas</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section4">
                            Focusing on core brand attributes i.e trustworthy, assuring and friendly, Prismic Reflections came up with the logo mark concept that uses different types of symbols, marks & shapes to convey a certain message. 
                        </p>                        
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="concerro-right-wrap" data-aos="fade-in" data-aos-delay="2500" data-aos-anchor="#section4">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-concerro/concerro-brainstorming-1.svg"
                            class="concerro-brainstorming-1 lazy img-fluid" alt="Concerro - Brainstorming"/>


                            <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-concerro/concerro-brainstorming-2.svg"
                            class="concerro-brainstorming-2 lazy img-fluid rotate-ani" alt="Concerro - Brainstorming"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div concerro-sec5 no-padding comman-bg-section"
    id="section5" data-section-name="data-section5">
    <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
        data-aos-anchor="#section5" style="background-color: #1BD8A6;"></div>
    <div class="container h-100"></div>
</section>    
    <section class="comman-section comman-section-100vh scroll-div concerro-sec6" id="section6"
        data-section-name="data-section6">
        <div class="ani-wrap100-top" data-aos="new-animation3" data-aos-delay="300" data-aos-anchor="#section6"
        style="background-color: #186CD4;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="concerro-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6" class="xs-text-center text-white">
                            Logo Construction</h3>                         
                    </div>
                </div>
                <div class="col-md-12">
                    <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section6" class="concerro-right-wrap">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-concerro/concerro-logo-const.svg"
                            class="concerro-sec4-1 lazy img-fluid" alt="Concerro - Logo Construction" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div concerro-sec7 no-padding" id="section7"
    data-section-name="data-section7" style="background-color: #eaeae9;">
    <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
        data-aos-anchor="#section7" style="background-color: #040f25;"></div>
        <div data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section7" class="concerro-center-wrap text-center">
            <video autoplay muted loop playsinline class="img-fluid">
                <source src="assets/images/portfolio/portfolio-branding-concerro/concerro-health.mp4" type="video/mp4">
              </video>
            <!-- <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-branding-concerro/concerro-health.mp4" class="concerro-sec4-1 lazy img-fluid" alt="Concerro - Logo Construction" /> -->
        </div>
</section>
<section class="comman-section comman-section-100vh scroll-div concerro-sec8 no-padding comman-bg-section"
    id="section8" data-section-name="data-section8">
        <div data-aos="comman-bg-overlay-animation" data-aos-delay="300" data-aos-anchor="#section8" class="comman-bg-overlay" style="background-color: #ffffff;"></div>
    <div class="container h-100"></div>
</section>  
<section class="comman-section comman-section-100vh scroll-div concerro-sec9 no-padding comman-bg-section"
    id="section9" data-section-name="data-section9">
    <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
        data-aos-anchor="#section9" style="background-color: #ffffff;"></div>
    <div class="container h-100"></div>
</section>  
<section class="comman-section comman-section-100vh scroll-div concerro-sec10 no-padding comman-bg-section"
    id="section10" data-section-name="data-section10">
    <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section10" style="background-color: #ffffff;"></div>
    <div class="container h-100"></div>
</section>  
    <section class="comman-section comman-section-100vh crubba-sec10 impact-section-upd scroll-div xs-pt0"
        id="section-testimonial" data-section-name="data-section-testimonial">
        <div class="ani-wrap100-top-2" style="background-color: #242424;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="comman-impact-bottom" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section-testimonial"
                        style="margin: auto !important; max-width: 650px !important;">
                        <!-- <div class="card-img-wrap"><img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/testimonial/placeholder.png" alt="Director, Concerro Health" class="comman-impact-img lazy" /></div> -->
                        <!-- <h6 class="text-white">Mr. XXXXX</h6> -->
                        
                        <p class="p3 text-white">
                            <span style="margin-bottom: 15px; display: block;">
                                <i class="fas fa-quote-left"></i> 
                                We have been working with Prismic Reflections for the last 5 years. Initially, we invested a lot of time in identifying the design partner which would be the right fit for us in terms of quality and pricing. After a lot of discussions, we finally went ahead with Prismic Reflections and we are glad to have made that decision. Since then we haven't thought of any other design studio and are happy with our partnership with them. If you are also looking for a design partner, I would highly recommend Prismic Reflections and you must definitely give it a try.
                                 <i class="fas fa-quote-right"></i>
                            </span>
                        </p>
                        <h6 class="text-white" style="opacity: 0.5; text-align: right;">- Director, Concerro Health</h6>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/branding/phychem"> <span>Phychem</span> </a>
                        <p class="p2">Reimagining Face of Manufacturing Brand</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>