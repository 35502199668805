import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import AOS from 'aos';

declare let $: any;

@Component({
  selector: 'app-ux-ui-design-process',
  templateUrl: './ux-ui-design-process.component.html',
  styleUrls: ['./ux-ui-design-process.component.scss'],
})
export class UxUiDesignProcessComponent implements OnInit {


 
  // new description
  descrip = 'Prismic Reflections is a strategic ux ui design company that specializes in user experience & ui engineering. Let`s discuss!';

  
  constructor(private titleService: Title, private metaService: Meta) {
    this.setTitle('UI UX Design Process | Prismic Reflections');
      this.metaService.updateTag({name: 'description', content: this.descrip});
    this.metaService.updateTag({
      name: 'keywords',
      content: 'web site design, conceptual designs, website logo design, logo branding, corporate identity design, concept design, mumbai, india'
    });
    this.metaService.updateTag({property: 'og:type', content: 'business.business'});
     this.metaService.updateTag({property: 'og:title', content: 'UI UX Design Process | Prismic Reflections'});
    this.metaService.updateTag({property: 'og:description', content: this.descrip});
  }

  ngOnInit(): void {
    $(function(): void {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });
    $('.comman-header-wrap').show();
    $('#comman-footer').show();
    $(document).ready(function(): void {
      $('.logo-wrap-our-team').hide();
      $('.logo-wrap-a').show();
      $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
      $('.arrow-scroll-abs').hide();
      $('.comman-top-menu').removeClass('comman-top-menu-dark');
      const windowWidth = $(window).width();
      if (windowWidth >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          overflowscroll: true,
          touchScroll: false,
          before(i, panels): void {
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.pagination-wrap').find('a[href="#' + ref + '"]').addClass('active');
            $('.logo-wrap-a .company-name-mobile').removeClass('active-white-text').hide();
            $('.comman-top-menu').removeClass('comman-top-menu-dark');
            if (ref === 'data-section1') {
              $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.arrow-scroll-abs').show();
            } else if (ref === 'data-comman-footer') {
              $('.comman-header').addClass('active-white').removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
            } else {
              $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.arrow-scroll-abs').show();
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            }
          },
          afterRender(): void {
            aosinit();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i): void {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
            // $('.comman-scrollbar-wrap').mCustomScrollbar({ theme: 'rounded-dots', scrollInertia: 400 });
          },
        });
        $('.arrow-scroll-abs .next').click(function(e1): void {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2): void {
          e2.preventDefault();
          $.scrollify.prev();
        });
      }
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }

      function aosinit(): void {
        AOS.init({
          duration: 400,
          anchorPlacement: 'top-top',
          disable(): boolean {
            const maxWidth = 1199;
            return window.innerWidth < maxWidth;
          },
          once: true,
          offset: 20,
        });
      }
    });
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

}
