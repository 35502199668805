import {Component, OnInit, Renderer2} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import AOS from 'aos';
// declare var ajaxRequest: any;
import { OwlOptions } from 'ngx-owl-carousel-o';
import {ScrollifyAniService} from 'src/app/appServices/scrollify-ani.service';

import Swal from 'sweetalert2';

declare let $: any;
declare let grecaptcha: any;

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss'],
})
export class CareerComponent implements OnInit {

  
  //new description
  descrip = 'Wish to pursue a bright and promising career in ui ux design? Want to work with a strategic ux ui design company? Let`s discuss!';

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  public aFormGroup: FormGroup;

  foundOn = [
    'Sr. UX Research & Design Lead',
    'Sr. Visual & UI Design Lead',
    'UX Designer & Researcher',
    'UX UI Designer',
    'UI & Graphics Designer',
    'UI / Frontend Developer',
    'Project Manager',
    'Interaction Designer',
    'Business Development & Sales Executive'
  ];

  validateApplyHasError = true;
  formisinvalid = false;
  showErrors = false;

  careName = '';
  careEmail = '';
  carePhone = '';
  careExp = '';
  careCurrSts = '';
  careAbt = '';
  carePort = '';
  carePolicy = false;
  property = 'default';

  public captchaHasError = false;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private http: HttpClient,
    private router: Router,
    private renderer: Renderer2,
    private formBuilder: FormBuilder
  ) {
    
    this.setTitle('Career in UI UX Design | Prismic Reflections');
    this.metaService.updateTag({name: 'description', content: this.descrip});
    this.metaService.updateTag({
      name: 'keywords',
      content: 'web developer mumbai, web designer mumbai, seo expert mumbai, ' +
        'web developer thane, web designer thane, seo expert thane, website design ' +
        'development mumbai, search engine optimization mumbai'
    });
    this.metaService.updateTag({property: 'og:type', content: 'business.business'});
    this.metaService.updateTag({
     

      
      property: 'og:title', content: 'Career in UI UX' +
      'Design | Prismic Reflections'
    });
    this.metaService.updateTag({property: 'og:description', content: this.descrip});
  }




  
  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

  first(): void {
    try {
      const errorField = this.renderer.selectRootElement('.scrollhere');
      errorField.scrollIntoView();
    } catch (err) {
    }
  }
  

  handleSuccess(e): void {
    console.log('ReCaptcha', e);
  }

  customOptionsMob: OwlOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1,
    dots: false,
    autoWidth: true,
    autoplay: true,
    autoplayTimeout: 4000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    center: true,
    mergeFit: true,
    responsive: {
      0: {
        items: 1,
      },
      520: {
        items: 2,
      }
    },
  };

 
  public scrollbarOptions = {theme: 'light-thick', scrollInertia: 400};


  customOptions3: OwlOptions = {
    loop: true,
    items: 3,
    autoWidth: false,
    pullDrag: false,
    navSpeed: 700,
    dots: false,
    nav: false,
    margin: 30,
    autoplay: true,
    autoHeight: true,
    mouseDrag: true,
    touchDrag: true,
    lazyLoad: true,
    autoplayTimeout : 2000,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
        dots: false
      },
      767: {
        items: 2,
        dots: false
      },
      1050: {
        items: 3,
      },
    },
  };







  
  ngOnInit(): void {
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
    $(function(): void {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });
    $('.comman-header-wrap').show();
    $('#comman-footer').show();
    // $('#careerForm').validate({
    //   rules: {
    //     applyingfor: {
    //       required: true,
    //     },
    //     full_name: {
    //       required: true,
    //     },
    //     email: {
    //       required: true,
    //       email: true,
    //     },
    //     mobile_no: {
    //       required: true,
    //       number: true,
    //       minlength: 10,
    //       maxlength: 10,
    //     },
    //     experience: {
    //       required: true,
    //     },
    //     current_status: {
    //       required: true,
    //     },
    //     career_journey: {
    //       required: true,
    //     },
    //     career_portfolio: {
    //       required: true,
    //     },
    //     i_agree: {
    //       required: true,
    //     },
    //     hiddenRecaptcha: {
    //       required: function () {
    //         if (grecaptcha.getResponse() == '') {
    //           return true;
    //         } else {
    //           return false;
    //         }
    //       },
    //     },
    //   },
    //   messages: {
    //     applyingfor: 'Please select option',
    //     full_name: 'Please enter your full name',
    //     email: 'Please enter your valid email address',
    //     mobile_no: 'Please enter your mobile number without dashes',
    //     experience: 'Please enter your work experience',
    //     current_status: 'Please enter your current status',
    //     career_journey: 'Please enter your career journey',
    //     career_portfolio: 'Please enter your career portfolio',
    //     i_agree: 'Please agree Terms of use and privacy policy',
    //   },
    //   submitHandler: function (form) {
    //     $('#btn_test').click();
    //   },
    // });
    $(document).ready(function(): void {

      // $.validator.setDefaults({ ignore: ':hidden:not(.hiddenRecaptcha)' });


      // $('#btn_test').click(function () {
      //   var applyingfor = $('#applyingfor').val();
      //   var full_name = $('#full_name').val();
      //   var email = $('#email').val();
      //   var mobile_no = $('#mobile_no').val();
      //   var experience = $('#experience').val();
      //   var current_status = $('#current_status').val();
      //   var career_journey = $('#career_journey').val();
      //   var career_portfolio = $('#career_portfolio').val();

      //   ajaxRequest = $.ajax({
      //     url: 'php/prenquiry.php',
      //     type: 'POST',
      //     data: {
      //       applyingfor: applyingfor,
      //       email: email,
      //       full_name: full_name,
      //       mobile_no: mobile_no,
      //       experience: experience,
      //       current_status: current_status,
      //       career_journey: career_journey,
      //       career_portfolio: career_portfolio,
      //       flag: 'career',
      //     },
      //     success: function (data, textStatus, jQxhr) {
      //       // alert("Res"+data);
      //       if (data == 1) {
      //         Swal.fire({
      //           icon: 'success',
      //           title:
      //             'Thanks for submitting your request. <br class="d-block"/> We will get back to you soon!',
      //           showConfirmButton: false,
      //           timer: 1500,
      //         });
      //         $('#btn_reset').click();
      //       }
      //     },
      //     error: function (jqXhr, textStatus, errorThrown) {
      //       console.log(errorThrown);
      //     },
      //   });
      // });

      const wido = $(window).width();
      $('.logo-wrap-our-team').hide();
      $('.logo-wrap-a').show();
      $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
      $('.arrow-scroll-abs').show();
      $('.arrow-scroll-abs .next').removeClass('text-white');
      $('.comman-top-menu').removeClass('comman-top-menu-dark');
      if (wido >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          overflowscroll: true,
          before(i, panels): void {
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.pagination-wrap')
              .find('a[href="#' + ref + '"]')
              .addClass('active');
            $('.logo-wrap-a .company-name-mobile')
              .removeClass('active-white-text')
              .hide();
            $('.arrow-scroll-abs').fadeIn();
            $('.comman-top-menu').removeClass('comman-top-menu-dark');
            $('.logo-wrap-a .company-name-mobile')
              .removeClass('active-white-text')
              .hide();
            if (ref === 'data-section1') {
              $('.comman-header').removeClass(
                'active-logo-white active-white active-menu-white'
              );
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').removeClass('text-white');
            } else if (ref === 'data-section1-2') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').addClass('text-white');
            } else if (ref === 'data-section2') {
              $('.comman-header').removeClass(
                'active-logo-white active-white active-menu-white'
              );
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').removeClass('text-white');
            } else if (ref === 'data-section3') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').removeClass('text-white');
            } else 
            if (ref === 'data-section4') {
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-white active-menu-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              
              $('.comman-scrollbar-wrap, .comman-scrollbar-wrap2').mCustomScrollbar({theme: 'rounded-dots', scrollInertia: 400});
            } else
            if (ref === 'data-section7') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').show();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
                        } else
            if (ref === 'data-comman-footer') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              // $(".comman-footer .comman-footer-row1 h2").html("Want to Join the Force?");
            } else {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').show();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            }
          },
          afterRender(): void {
            aosinit();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i): void {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
          },
        });
        $('.arrow-scroll-abs .next').click(function(e1): void {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2): void {
          e2.preventDefault();
          $.scrollify.prev();
        });
        $('.apply-btn').on('click', function(e3): void {
          e3.preventDefault();
          $.scrollify.move($(this).attr('data-target'));
        });
      }

      // google map init
      $('#nashik-map-open-btn').click(function(e1): void {
        e1.preventDefault();
        $('#nashik-map').addClass('activeMap');
      });

      $('#nashik-map-close-btn').click(function(e2): void {
        e2.preventDefault();
        $('#nashik-map').removeClass('activeMap');
      });

      $('#mumbai-map-open-btn').click(function(e3): void {
        e3.preventDefault();
        $('#mumbai-map').addClass('activeMap');
      });

      $('#mumbai-map-close-btn').click(function(e4): void {
        e4.preventDefault();
        $('#mumbai-map').removeClass('activeMap');
      });
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }

      function aosinit(): void {
        AOS.init({
          duration: 400,
          anchorPlacement: 'top-top',
          disable(): boolean {
            const maxWidth = 1199;
            return window.innerWidth < maxWidth;
          },
          once: true,
          offset: 20,
        });
      }
    });
  }

  captchaClicked(e): void {
    console.log(e);
  }

  // textAreaEmpty(e){
  //   if (this.careName === '' || this.careEmail === '' || this.carePhone === '' || this.careExp === '' || this.careCurrSts === '' ||
  //   this.careAbt === '' || this.carePort === '' || this.carePolicy === false) {
  //     console.log('error');
  //     this.formisinvalid = false;
  //     this.showErrors = true;
  //   } else {
  //     console.log('no error');
  //     this.formisinvalid = true;
  //     this.showErrors = false;
  //   }
  // }

  validateApplyFor(value): void {
    this.validateApplyHasError = value === 'default';
  }

  submitContact(frm): void {
    console.log('Career submit clicked!');

    this.captchaHasError = !((!this.aFormGroup.controls.recaptcha.pristine) && (this.aFormGroup.controls.recaptcha.status === 'VALID'));

    const career = {
      flag: 'career',
      applyingfor: frm.value.applyingfor,
      full_name: frm.value.career_name,
      email: frm.value.career_email,
      mobile_no: frm.value.career_mobile_no,
      experience: frm.value.career_experience,
      current_status: frm.value.career_current_status,
      career_journey: frm.value.career_journey,
      career_portfolio: frm.value.career_portfolio
    };

    if (this.validateApplyHasError || (frm.value.career_name === '') || (frm.value.career_email === '') || (frm.value.career_mobile_no ===
        '') || (frm.value.career_experience === '') || (frm.value.career_experience === '') || (frm.value.career_current_status === '') ||
      (frm.value.career_journey === '') || (frm.value.career_portfolio === '') || (frm.value.i_agree === false) || this.captchaHasError) {
      this.showErrors = true;
      // console.log('captcha', this.aFormGroup.controls, this.aFormGroup.controls.recaptcha, this.aFormGroup.controls.recaptcha.pristine,
      // this.aFormGroup.controls.recaptcha.status )
    } else {
      this.showErrors = false;
      console.log('Career form Submitted!');
      frm.reset();
      this.aFormGroup.reset();
      console.log(career);
      this.http.post('https://prismicreflections.com/prenquiry.php', career).subscribe(
        (response) => {
          console.log('Got Response', response);
        }
      );
      Swal.fire({
        icon: 'success',
        title: 'Thanks for submitting your request. <br class="d-block"/> We will get back to you soon!',
        showConfirmButton: false,
        timer: 1800
      });
    }

  }

}
