<div class="portfolio-webapp-mytrux-body">
    <section class="comman-section comman-section-100vh overflow-hidden scroll-div top-section-wrapper mytrux-webapp-sect1"
        id="section1" data-section-name="data-section1">
        <div class="ani-wrap50" style="background-color: #0F2541;" data-aos="new-animation7" data-aos-delay="100"
            data-aos-anchor="#section1"></div>
        <div class="container h-100">
            <div class="row vh100-100 mt60-ratina-lg align-items-center justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-8">
                    <div class="comman-card-info" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section1"
                        style="z-index: 9; position: relative;">
                        <h1 style="color: #DD0B51;" class="no-wrap-lg"> Redefining UX for <br> Digital Transportation
                        </h1>
                        <p class="p1 text-white"> Mytrux : A cloud based product with Mobile App developed to address
                            the challenges in transportation Ecosystem.</p>
                    </div> <img src="assets/images/portfolio/portfolio-webapp-mytrux/mytrux-webapp-sect1-2.png"
                        alt="Redefining Digital Transportation" class="img-fluid lazy mytrux-webapp-sect1-2">
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                    <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section1"
                        class="mytrux-webapp-sect1-1-wrap">
                        <img src="assets/images/portfolio/portfolio-webapp-mytrux/mytrux-webapp-sect1-1.png"
                            alt="Redefining Digital Transportation" class="img-fluid lazy mytrux-webapp-sect1-1">
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">
                Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div mytrux-webapp-sect2" id="section2"
        data-section-name="data-section2">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section2" style="background-color: #0F2541;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="overview-sec-center portfolio-info-wrap2" style="z-index: 9; position: relative;">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2">Overview</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2"> Logistics is
                            the key driver of a nation's economy. Mytrux - a startup with solid leadership, has their
                            own vision and offering to redefine the current transportation with the help of technology,
                            where one can get real time information of movement of goods and on demand sourcing of
                            fleets.</p>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2"> Mytrux was in
                            need of a design partner who can understand the logistics space and improve upon the
                            product's overall user experience for web and mobile apps.</p>
                    </div>
                </div>
            </div>
        </div>
        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2"> <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-mytrux/mytrux-website-sect2-1.png"
                alt="MyTrux - Overview" class="img-fluid mytrux-website-sect2-1 lazy" /></div>
    </section>
    <section class="comman-section comman-section-100vh mytrux-webapp-sect3 scroll-div comman-solution-challenge"
        id="section3" style="overflow: hidden;" data-section-name="data-section3">
        <div class="ani-wrap50" style="background: #0F2541;" data-aos="new-animation2" data-aos-delay="600"
            data-aos-anchor="#section3"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-end">
                <div class="col-md-12 col-lg-6 col-sm-12">
                    <div class="mytrux-webapp-sect3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3" class="text-white">
                            Challenges</h3>
                        <p class="p2 text-white" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section3">
                            App flow and data presentation can make or break the deal for users. The challenge was to
                            create a journey that surfaces the key data points and facilitates user & stake holders
                            right from onboarding process in an intuitive way.</p>
                        <div class="mytrux-webapp-sect3-left-list">
                            <ul class="list-inline">
                                <li class="list-inline-item" data-aos="fade-in" data-aos-delay="1500"
                                    data-aos-anchor="#section3"> <img src="assets/images/preloader/ajax-loader.png"
                                        data-src="assets/images/portfolio/portfolio-webapp-mytrux/scheduling.svg"
                                        alt="MyTrux - Overview" class="img-fluid scheduling-img lazy" /></li>
                                <li class="list-inline-item" data-aos="fade-in" data-aos-delay="1700"
                                    data-aos-anchor="#section3"> <img src="assets/images/preloader/ajax-loader.png"
                                        data-src="assets/images/portfolio/portfolio-webapp-mytrux/tracking.svg"
                                        alt="MyTrux - Overview" class="img-fluid tracking-img lazy" /></li>
                                <li class="list-inline-item" data-aos="fade-in" data-aos-delay="1900"
                                    data-aos-anchor="#section3"> <img src="assets/images/preloader/ajax-loader.png"
                                        data-src="assets/images/portfolio/portfolio-webapp-mytrux/driver.svg"
                                        alt="MyTrux - Overview" class="img-fluid driver-img lazy" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-sm-12">
                    <div class="mytrux-webapp-sect8-b portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3"> Solution</h3>
                        <div data-aos="fade-in" data-aos-delay="2500" data-aos-anchor="#section3">
                            <ul class="p2 mytrux-webapp-sect3-right-list">
                                <li> Simplified and Progressive onboarding.</li>
                                <li> Delivering a seamless product experience for all users : Transporters, Fleet Owners
                                    and drivers.</li>
                                <li> Simplifying the flow of fleet booking features and functionality that were less
                                    obvious and potentially less confusing to users.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh mytrux-webapp-sect4 scroll-div"
        data-section-name="data-section4" id="section4" data-aos-anchor="#section4" style="background-color: #ffffff;">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section4" style="background-color: #0F2541;"></div>
        <div class="container h-100">
            <div class="row vh100-100">
                <div class="col-md-12 col-lg-6 col-sm-12">
                    <div class="portfolio-info-wrap2 text-md-center-adj">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section4"> Wireframing</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4"> Optimum use
                            of prime real estate to empower users with primary data points.</p>
                    </div>
                    <div class="mytrux-webapp-sect4-1-wrap" data-aos="fade-in" data-aos-delay="1200"
                        data-aos-anchor="#section4"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-mytrux/mytrux-webapp-sect4-1.svg"
                            alt="Wireframing" class="mytrux-webapp-sect4-1 img-fluid lazy" /></div>
                </div>
                <div class="col-md-12 col-lg-6 col-sm-12 text-md-center-adj">
                    <div class="mytrux-webapp-sect4-2-wrap" data-aos="fade-in" data-aos-delay="1500"
                        data-aos-anchor="#section4"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-mytrux/mytrux-webapp-sect4-2.svg"
                            alt="Wireframing" class="mytrux-webapp-sect4-2 img-fluid lazy" /></div>
                    <div class="mytrux-webapp-sect4-3-wrap" data-aos="fade-in" data-aos-delay="2000"
                        data-aos-anchor="#section4"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-mytrux/mytrux-webapp-sect4-3.svg"
                            alt="Wireframing" class="mytrux-webapp-sect4-3 img-fluid lazy" /></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh mytrux-webapp-sect5 scroll-div"
        data-section-name="data-section5" id="section5">
        <div class="ani-wrap100-top" style="background-color: #AE294C;" data-aos="new-animation3" data-aos-delay="300"
            data-aos-anchor="#section5"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12 col-lg-6 col-sm-12">
                    <div class="portfolio-info-wrap2" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section5">
                        <h3 class="text-white no-wrap-lg">Dashboard <br> Designing for Ease</h3>
                    </div>
                    <div class="mytrux-webapp-sect5-2-wrap" data-aos="fade-in" data-aos-delay="1500"
                        data-aos-anchor="#section5"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-mytrux/mytrux-webapp-sect5-2.svg"
                            alt="Designing for Ease" class="mytrux-webapp-sect5-2 img-fluid lazy" /></div>
                </div>
                <div class="col-md-12 col-lg-6 col-sm-12">
                    <div class="mytrux-webapp-sect5-1-wrap" data-aos="fade-in" data-aos-delay="2000"
                        data-aos-anchor="#section5"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-mytrux/mytrux-webapp-sect5-1.png"
                            alt="Designing for Ease" class="mytrux-webapp-sect5-1 img-fluid lazy" /></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh mytrux-webapp-sect6 scroll-div xs-pt0"
        data-section-name="data-section6" id="section6" style="background-color: #AE294C;">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-md-12 col-lg-6 col-sm-12">
                    <div class="mytrux-webapp-sect6-1-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section6">
                        <p>Fleet Schedule</p> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-mytrux/mytrux-webapp-sect6-1.png"
                            alt="Designing for Ease" class="mytrux-webapp-sect6-1 img-fluid lazy" />
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-sm-12">
                    <div class="mytrux-webapp-sect6-2-wrap" data-aos="fade-in" data-aos-delay="1200"
                        data-aos-anchor="#section6">
                        <p>Instant Tracking</p> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-mytrux/mytrux-webapp-sect6-2.png"
                            alt="Designing for Ease" class="mytrux-webapp-sect6-2 img-fluid lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh mytrux-webapp-sect7 scroll-div xs-pt0"
        data-section-name="data-section7" id="section7" style="background-color: #AE294C;">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-md-12">
                    <div class="mytrux-webapp-sect7-1-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section7"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-mytrux/mytrux-webapp-sect7-1.png"
                            alt="Designing for Ease" class="mytrux-webapp-sect7-1 img-fluid lazy" /></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh impact-section-upd scroll-div" id="section-testimonial"
        data-section-name="data-section-testimonial">
        <div class="ani-wrap100-top" style="background-color: #1E1E1E;" data-aos="new-animation3" data-aos-delay="900"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="moneyguru-sec13-left portfolio-info-wrap2 text-center" data-aos="fade-in"
                        data-aos-delay="1500" data-aos-anchor="#section-testimonial" style="max-width: inherit;">
                        <ul class="list-inline comman-card-top-ul">
                            <li class="list-inline-item h6 text-white hide-after">Success Metrics</li>
                        </ul>
                        <h3 class="text-white">Anchoring customers</h3>
                        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section-testimonial">
                            <ul class="list-inline comman-impact-list-inline">
                                <li class="list-inline-item" style="margin: auto !important;"> <img
                                        src="assets/images/preloader/ajax-loader.png"
                                        data-src="assets/images/svg/impact-dowload.svg" alt="Downloads" class="lazy">
                                    <h3 class="text-white">0<span class="counter1">3</span></h3>
                                    <p class="text-white p2">Acquired</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh insisive-website-sec11 failure-section scroll-div"
        id="section-learning" data-section-name="data-section-learning">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section-learning" style="background-color: #1E1E1E;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-7 col-lg-6 col-sm-12">
                    <div class="insisive-sec-left portfolio-info-wrap2 failure-sec-left">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section-learning">Failure &amp;
                            Learnings</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section-learning"> Give
                            users their identity within your product or website. Users need identity to understand and
                            perform their tasks smoothly.</p>
                    </div>
                </div>
                <div class="col-md-5 col-lg-6"></div>
            </div>
        </div>
        <div class="failure-sec-right" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section-learning">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-sec14-bg.png"
                alt="Failure &amp; Learnings" class="img-fluid lazy failure-sec-right-img" /></div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6> <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/web-app/authntick">
                            <span>Authntick</span> </a>
                        <p class="p2">Security Meets Simplicity</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>