<div class="portfolio-branding-vimson-body">
    <section class="comman-section comman-section-100vh scroll-div notion-edge-sec1" id="section1"
        data-section-name="data-section1">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 text-center">
                    <div class="notion-edge-left-wrap xs-mt30">
                        <div data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section1">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-branding-vimson/vimson-logo-1.svg"
                                alt="Vimson - Logo" class="smartlinks-logo lazy img-fluid mb30" />
                        </div>
                        <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1"
                            style="position: relative; z-index: 5;">Helping Manufacturing Companies Go Global</h1>
                        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section1">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-branding-vimson/vimson-logo-bg.png"
                                alt="Vimson - Logo" class="notion-edge-logo-bg lazy" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">
                Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div smartlinks-sect2" id="section2"
        data-section-name="data-section2" style="background: #122c4f url('assets/images/svg/pattern.png') repeat 0 0;">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section2" style="background-color: #ffffff;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="smartlinks-sec2-center overview-sec-center portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                            Overview</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2">
                            Designing brand identity to match client's business vision.The future plan was the first
                            step as it will be used in further marketing activities in domestic and overseas markets for
                            exporting their products.
                            Vimson Coatings Pvt. Ltd. being a new company, did not have any brand identity.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge scroll-div smartlinks-sect3"
        id="section3" data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #1161df;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3">Challenges</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">
                            The company is almost 6+ years old. They deal in labels (Sticker Paper) manufacturing in the
                            forms of rolls and sheets. To craft a brand mark which will stand in the market to compete
                            with global competition
                            was scary for our team initially. The task was to design an innovative logo which will
                            represent a label manufacturing company mainly dealing in the paper industry.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="crubba-sec3-right portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3" class="text-white">
                            Creative Brief</h3>
                        <p class="p2 text-white" data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3">
                            Based on our initial strategic research and consecutive discussions we landed up on a few
                            basics in agreement with clients. We decided to design something simple but unique. It
                            should represent the
                            management's core principles of quality, aggression and growth.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div smartlinks-sect4 pb0" id="section4"
        data-section-name="data-section4">
        <div class="ani-wrap50-2" style="background-color: #37bbfe;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section4"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="smartlinks-sec4-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section4">Brainstorming and Ideas
                        </h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4">
                            Scratching our heads and scribbling papers for several rapid concepts and sketches helped us
                            to boil down a few unique and trendy symbols for logos. It should have the story, connect
                            and have the persona to
                            connect with the audience profile.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12"></div>
            </div>
        </div>
        <div class="smartlinks-sec4-right" data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section4">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-branding-vimson/vimson-idea.png"
                alt="Vimson - Brainstorming" class="img-fluid smartlinks-sect4-1 lazy xl-w-100" />
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div smartlinks-sec4" id="section5"
        data-section-name="data-section5">
        <div class="ani-wrap100-top" style="background-color: #122c4f;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section5"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6">
                    <div class="bagshaha-sec6-left portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section5">Logo
                            Mark</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5">
                            Simple, bold and progressive design. Symboling product, quality assurance and aggressive
                            growth - personality and brand attributes of business.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section5"
                        class="bagshaha-sec9-2-outer">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-vimson/vimson-mark.png"
                            alt="Vimson - Logo Mark" class="smartlinks-sec4-1 img-fluid lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div vimson-sec6" id="section6"
        data-section-name="data-section6">
        <div class="comman-bg-overlay-2" style="background-color: #122c4f !important;"
            data-aos="comman-bg-overlay-animation" data-aos-delay="300" data-aos-anchor="#section6"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12 text-center">
                    <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-vimson/vimson-compare.svg"
                            alt="Vimson - Logo Mark" class="vimson-compare img-fluid lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div comman-bg-section notion-edge-sec9"
        style="background-image: url('assets/images/portfolio/portfolio-branding-vimson/vimson-branding.jpg'); background-size: cover;"
        id="section7" data-section-name="data-section7">
        <div class="comman-bg-overlay" style="background-color: #ffffff !important;"
            data-aos="comman-bg-overlay-animation" data-aos-delay="300" data-aos-anchor="#section7"></div>
        <div class="container h-100"></div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div comman-bg-section notion-edge-sec10"
        style="background-image: url('assets/images/portfolio/portfolio-branding-vimson/vimson-branding2.jpg'); background-size: cover;"
        id="section8" data-section-name="data-section8">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section8"></div>
        <div class="container h-100"></div>
    </section>
    <section class="comman-section comman-section-100vh crubba-sec10 impact-section-upd scroll-div xs-pt0"
        id="section-testimonial" data-section-name="data-section-testimonial">
        <div class="ani-wrap100-top-2" style="background-color: #242424;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="comman-impact-bottom" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section-testimonial"
                        style="margin: auto !important; max-width: 650px !important;">
                        <div class="card-img-wrap"><img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/testimonial/sachin-more.png" alt="Sachin V. More"
                                class="comman-impact-img lazy" /></div>
                        <h6 class="text-white">Sachin V. More</h6>
                        <h6 class="text-white" style="opacity: 0.5;">Founder and CEO</h6>
                        <p class="p3 text-white">
                            <span style="margin-bottom: 15px; display: block;">
                                <i class="fas fa-quote-left"></i> I had very high expectations for our new brand
                                identity design. Prismic Reflections with the help of their brand identity design
                                process grasped our business values,
                                vision and delivered a great identity design for our brand. <i
                                    class="fas fa-quote-right"></i>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/branding/mytrux"> <span>MyTrux</span> </a>
                        <p class="p2">Identity Redesign for Connected Digital Transport</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>