import {Component, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';

declare let $: any;
declare let ga: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'prismic-app';
  showLoadingIndicator = true;

  constructor(public router: Router) {
    // subscribe to router events and send page views to Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // console.log('NavigationStart loaded');
        // $(".loader-api").delay(0).fadeIn(0);
        // AOS.init({disable: true});
      }
      if (event instanceof NavigationEnd) {
        // console.log('NavigationEnd loaded');
        // $(".loader-api").delay(1000).fadeOut(1600);
        // AOS.init({
        //   disable: false
        // });
        // AOS.refreshHard();
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');

      }
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      document.body.scrollTop = 0;
      const windowWidth = $(window).width();
      if (windowWidth >= 1199) {
        $.scrollify.instantMove('#data-section1');
      }
    });
  }

}
