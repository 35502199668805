<div class="portfolio-mobile-app-alloch-body">
    <section class="comman-section comman-section-100vh scroll-div alloch-sec1 top-section-wrapper" id="section1"
        data-section-name="data-section1">
        <div class="ani-wrap100-top" style="background: #5c202e;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section1"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-6 order-xxs-last">
                    <div class="alloch-sec1-right portfolio-info-wrap2 portfolio-info-banner-l xxs-text-center">
                        <ul class="list-inline comman-card-top-ul xs-text-center" data-aos="fade-in"
                            data-aos-delay="900" data-aos-anchor="#section1">
                            <li class="list-inline-item h6 text-white">Aaloch</li>
                            <li class="list-inline-item h6 text-white">Social Connectivity</li>
                        </ul>
                        <h1 data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section1"
                            class="text-white hide-mobile-xxs">
                            Let's Track Our<br />
                            Nasty Friends.
                        </h1>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6">
                    <h1 data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section1"
                        class="text-white show-mobile-xxs xs-text-center">
                        Let's Track Our<br />
                        Nasty Friends.
                    </h1>
                    <div class="alloch-right-wrap">
                        <div data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section1">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec1-1.png"
                                alt="Aaloch - The Mobile App" class="img-fluid alloch-sec1-1 lazy" />
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec1-2.svg"
                                alt="Aaloch - The Mobile App" class="img-fluid alloch-sec1-2 lazy" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">
                Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div alloch-sec2" id="section2"
        data-section-name="data-section2">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <div class="alloch-left-wrap portfolio-info-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2"
                            class="font-family-sans-serif">Overview</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2"
                            style="max-width: 390px;">
                            A crazy mobile app to track your nasty friends, who keep you waiting for hours. Invite your
                            friends for fun-filled events and catch their live location on "Aaloch" with notifications
                            and map based updates.
                            You can download it for Android and iOS platforms.
                        </p>
                        <div class="alloch-sec2-2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec2-2.png"
                                alt="Aaloch - Overview" class="img-fluid alloch-sec2-2 lazy" />
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="alloch-right-wrap portfolio-info-wrap" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section2">
                        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2" class="alloch-sec2-1">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec2-1.png"
                                alt="Aaloch - Overview" class="img-fluid alloch-sec2-1-inner lazy" />
                        </div>
                        <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section2" class="alloch-sec2-3">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec2-3.svg"
                                alt="Aaloch - Overview" class="img-fluid alloch-sec2-3-inner lazy" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div alloch-u-sec2 comman-solution-challenge"
        id="section3" data-section-name="data-section3">
        <div class="ani-wrap50-2" data-aos="new-animation2" data-aos-delay="1200" data-aos-anchor="#section3"
            style="background-color: #ffffff !important;"></div>
        <div class="ani-wrap50" data-aos="new-animation2" data-aos-delay="100" data-aos-anchor="#section3"
            style="background-color: #5c202e !important;"></div>
        <div class="container h-100">
            <div class="row h-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <div class="alloch-u-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3" class="text-white">
                            Challenges</h3>
                        <p class="p2 text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">
                            Aaloch team was looking for an upmarket design and experience for their product. During our
                            initial analysis, we found that the app is built by targeting young audiences - those who
                            spend time with their
                            friends. To design something engaging for the youth which will connect and yet be usable was
                            the real quest for our team.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="alloch-u-right-wrap ml85 portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3">Solution</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3">
                            Considering the age group of audience and overall analysis of purpose and use cases
                            developed in application we brought the necessary flow for users inline with other trending
                            apps. Like bringing informative
                            onboarding, logical distribution of tasks and workflow along with simplifying the
                            navigations to deliver more simple yet meanigful UX.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div alloch-sec7" id="section4" data-section-name="data-section4">
        <div class="ani-wrap100-top-2" style="background-color: #5c202e;" data-aos="new-animation3" data-aos-delay="300"
            data-aos-anchor="#section4"></div>
        <div class="alloch-sec7-4-outer" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section4">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec7-4.svg"
                alt="Aaloch - Design Strategy" class="img-fluid alloch-sec7-4 lazy" />
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-start justify-content-center">
                <div class="col-md-6">
                    <div class="alloch-sec7-left portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section4">
                            Design Strategy</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4">
                            App is meant for the millennials, who are young, energetic and savvy. For teh UX UI design
                            ,considering their persona, motivations and features provided by the app ; we decided to go
                            with dynamic, playful and
                            energetic colors and shades. Unfolding the features of the app with the help of storytelling
                            through characters and contextual illustrations were our key ingredients.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="alloch-sec7-right portfolio-info-wrap">
                        <div class="alloch-sec7-1-outer" data-aos="fade-in" data-aos-delay="1200"
                            data-aos-anchor="#section4">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec7-1.png"
                                alt="Alloch Design Strategy" class="img-fluid alloch-sec7-1 lazy" />
                        </div>
                        <div class="alloch-sec7-2-outer" data-aos="fade-in" data-aos-delay="1500"
                            data-aos-anchor="#section4">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec7-2.svg"
                                alt="Alloch Design Strategy" class="img-fluid alloch-sec7-2 lazy floatingX-r" />
                        </div>
                        <div class="alloch-sec7-3-outer" data-aos="fade-in" data-aos-delay="1800"
                            data-aos-anchor="#section4">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec7-3.svg"
                                alt="Alloch Design Strategy" class="img-fluid alloch-sec7-3 lazy floatingX-l" />
                        </div>
                        <div class="alloch-sec7-5-outer" data-aos="fade-in" data-aos-delay="2100"
                            data-aos-anchor="#section4">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec7-5.svg"
                                alt="Alloch Design Strategy" class="img-fluid alloch-sec7-5 lazy" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div alloch-sec6 comman-bg-section" id="section5"
        data-section-name="data-section5">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section5" style="background-color: #5c202e;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center"></div>
        </div>
    </section>
    <section class="comman-section scroll-div alloch-sec17" id="section6" data-section-name="data-section6">
        <div class="comman-bg-overlay-2" style="background-color: #1d1d1e !important;"
            data-aos="comman-bg-overlay-animation" data-aos-delay="300" data-aos-anchor="#section6"></div>
        <div class="alloch-sec3-4-outer" data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section6">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec3-4.svg"
                alt="alloch Design Strategy" class="img-fluid alloch-sec3-4 lazy" />
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-7 order-xs-last alloch-sec7-right-outer-r">
                    <div class="alloch-sec7-right portfolio-info-wrap">
                        <table>
                            <tr>
                                <td>
                                    <div class="alloch-sec3-3-outer-upd" data-aos="fade-in" data-aos-delay="900"
                                        data-aos-anchor="#section6">
                                        <img src="assets/images/preloader/ajax-loader.png"
                                            data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec3-3.png"
                                            alt="Alloch Design Strategy" class="img-fluid alloch-sec3-3 lazy" />
                                    </div>
                                </td>
                                <td>
                                    <div class="alloch-sec3-2-outer-upd" data-aos="fade-in" data-aos-delay="1200"
                                        data-aos-anchor="#section6">
                                        <img src="assets/images/preloader/ajax-loader.png"
                                            data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec3-2.png"
                                            alt="Alloch Design Strategy" class="img-fluid alloch-sec3-2 lazy" />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="alloch-sec7-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section6">
                            Informed <br />
                            Onboarding
                        </h3>
                        <p class="text-light-dark-c p2" data-aos="fade-in" data-aos-delay="1700"
                            data-aos-anchor="#section6">With options to set your prefered language - English or Marathi.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div alloch-sec18 overflow-hidden" id="section7"
        data-section-name="data-section7">
        <div class="container-fluid h-100 no-padding">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="alloch-sec7-left portfolio-info-wrap2" style="max-width: 540px;">
                        <h3 data-aos="fade-in" data-aos-delay="300" data-aos-anchor="#section7">Iconography</h3>
                        <p class="text-light-dark-c p2" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section7">
                            From paper to digital canvas. To help users unfold the context of their tasks and add some
                            flavors to the story.
                        </p>
                    </div>
                    <div class="alloch-sec7-carosual" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section7">
                        <owl-carousel-o [options]="customOptions" class="owl-caroual-alloch-iconography" id="owl-caroual-alloch-iconography">
                            <ng-template carouselSlide>
                                <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-iconography1.svg" alt="Alloch Iconography" />
                            </ng-template>
                            <ng-template carouselSlide>
                                <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-iconography2.svg" alt="Alloch Iconography" />
                            </ng-template>
                            <ng-template carouselSlide>
                                <img class="owl-lazy img-fluid"  src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-iconography3.svg" alt="Alloch Iconography"/>
                            </ng-template>
                            <ng-template carouselSlide>
                                <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-iconography4.svg" alt="Alloch Iconography" />
                            </ng-template>
                            <ng-template carouselSlide>
                                <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-iconography5.svg" alt="Alloch Iconography" />
                            </ng-template>
                            <ng-template carouselSlide>
                                <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-iconography6.svg" alt="Alloch Iconography" />
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div alloch-sec10" id="section8" data-section-name="data-section8">
        <div class="alloch-sec10-5-outer" data-aos="fade-in" data-aos-delay="2100" data-aos-anchor="#section8">
            <img alt="Alloch Create an Event" class="alloch-sec10-5 lazy img-fluid floatingX-l"
                src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec10-5.svg"
                style="display: inline-block;" />
        </div>
        <div class="alloch-sec10-4-outer" data-aos="fade-in" data-aos-delay="1700" data-aos-anchor="#section8">
            <img alt="Alloch Create an Event" class="alloch-sec10-4 lazy img-fluid floatingX-r"
                src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec10-4.svg"
                style="display: inline-block;" />
        </div>
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-md-6 order-xs-last">
                    <div class="portfolio-info-wrap alloch-sec10-left">
                        <div class="alloch-sec10-1-outer" data-aos="fade-in" data-aos-delay="300"
                            data-aos-anchor="#section8">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec10-1.png"
                                alt="Alloch Create an Event" class="alloch-sec10-1 lazy img-fluid" />
                        </div>
                        <div class="alloch-sec10-6-outer" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section8">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-alloch/alloch-sec10-6.svg"
                                alt="Alloch Create an Event" class="alloch-sec10-6 lazy img-fluid lazy" />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="alloch-sec7-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section8">Create an Event</h3>
                        <p class="text-light-dark-c p2" data-aos="fade-in" data-aos-delay="1200"
                            data-aos-anchor="#section8">
                            Inspired by industry leaders - as they say: <br />
                            "Less is More". Simplifying the task.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/mobile-app/easy-plan"> <span>Easyplan</span> </a>
                        <p class="p2">Enhancing India's #1 Goal-Based Saving App</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>