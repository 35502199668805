<div class="portfolio-webapp-esop-body intelhealth">

    

   


    <section class="comman-section comman-section-100vh scroll-div esop-sec1 top-section-wrapper intelhealth-banner1" id="section1"
        data-section-name="data-section1">
        <!-- <div class="ani-wrap100-top" style="background: #15269B;" ></div>
            <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-esop/esop-triangle1.svg" alt="ESOP - fingerprint" class="esop-triangle1 lazy">
            -->
            <div _ngcontent-sfi-c98="" data-aos="new-animation2" data-aos-delay="100" data-aos-anchor="#section1" class="ani-wrap50" style="background: #2E1E8F;"></div>
            
         <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-1/strok-new.svg"
                            alt="Intelhealth-banner" class="img-fluid lazy intelhealth-header-img">

                            <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-1/intelhealth-banner-new-1.png"
                            alt="Intelhealth-banner" class="img-fluid lazy intelhealth-header-img intelhealth-header-img-adj-lg">


        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div class="esop-left-wrap">
                        <ul class="list-inline comman-card-top-ul" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section1">
                            <li class="list-inline-item text-white h6">Intelehealth</li>
                            <li class="list-inline-item text-white h6">Telemedicine </li>
                        </ul>
                        <h1 class="xs-mt-15-adj hide-mobile text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                            Delivering Quality Healthcare Beyond Reach
                        </h1>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div class="esop-right-wrap">
                        <!-- <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-1/intelhealth-banner.png"
                            alt="Intelhealth-banner" class="img-fluid lazy intelhealth-header-img"> -->
                    </div>
                </div>
            </div>
        </div>
        <img src="assets/images/preloader/ajax-loader.png"
        data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-1/intelhealth-mob-banner.png"
        alt="Intelhealth-banner" class="img-fluid lazy intelhealth-header-img show-mobile hide-desktop">
        <div class="arrow-scroll-abs"> <a href="#" class="next">Scroll<div class="c-scrolldown">
                    <div class="c-line"></div>
                </div> </a></div>
    </section>
    
    <section class="comman-section comman-section-100vh scroll-div " id="section2"
        data-section-name="data-section2">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="overview-sec-center portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section2">
                            Overview</h3>
                        <p data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2" class="p2">                         
Intelehealth is a not-for-profit tech organization that has developed a comprehensive technology platform that Governments, NGOs, and Hospitals can use to deliver telemedicine-based care to their beneficiaries.
                        </p>
                        <p data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2" class="p2">                         
                        Prismic Reflections was engaged to redesign and make Mobile and Web Application to improve upon user engagement, usability & overall user experience.
                    </p>
                        
                       
                    </div>
                </div>
               
            </div>
        </div>
        <div>

            <img src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-1/stroke.svg"
            alt="Intelhealth-banner-Strokes" class="img-fluid lazy intelhealth-header-img w-100 show-desktop hide-mobile" data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section2">

            <img src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-1/dots.png"
            alt="Intelhealth dots" class="img-fluid lazy dots-img show-desktop hide-mobile" data-aos="fade-in" data-aos-delay="1100" data-aos-anchor="#section2">
        
            <img src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-1/over-view-bottom.jpg"
            alt="Intelhealth-banner-Strokes" class="img-fluid lazy intelhealth-header-img w-100 show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section2">

            <img src="assets/images/preloader/ajax-loader.png"
            data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-1/over-view-top.jpg"
            alt="Intelhealth dots" class="img-fluid lazy dots-img show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1100" data-aos-anchor="#section2">
        
        
        </div>
    </section>

    <section class="comman-section comman-section-100vh scroll-div esop-sec3 comman-solution-challenge overflow-hidden" id="section3"
        data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background-color: #2E1E8F;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3">
        </div>
        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-1/Strokes.svg"
                            alt="Intelhealth-banner" class="img-fluid lazy intelhealth-header-img">
        <div class="ani-wrap50 bg-white" data-aos="new-animation2" data-aos-delay="100" data-aos-anchor="#section3">
        </div>
        <div class="container h-100">
            <div class="row h-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-6">
                    <div class="esop-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3"> Challenges</h3>
                        <p class="text-light-dark-c p2" data-aos="fade-in" data-aos-delay="900"
                            data-aos-anchor="#section3">
                            Making essential data to be easily accessible with lesser clicks. Making complex and longer tasks expected to appear effortless and hassle-free. Making further important actions obvious & more intuitive. Reducing the training time for users to get familiar with the app.
                        </p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="esop-right-wrap ml85 portfolio-info-wrap2 black-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section3">
                            Solutions</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section3">
                            Based on extensive design audit and user research (Doctors and Health Workers) design team came up with the revised architecture, navigation system, critical task flows and more contextual dashboards.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <section class="comman-section comman-section-100vh scroll-div overflow-hidden" id="section4"
    data-section-name="data-section4">
    <div class="container h-100">
        <div class="row vh100-100 align-items-center justify-content-center">
            <div class="col-md-12">
                <div class="overview-sec-center portfolio-info-wrap2">
                    <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section4">
                        Activities Covered</h3>                      
                </div>
            </div>
          

          <div class="col-md-12">
            <div class="activity-cover-wrappe">

                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-4/activities-covered-img.jpg"
                alt="Intelhealth-activity-cover-img.png" class="img-fluid lazy intelhealth-activity-cover-img hide-desktop show-mobile" data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section4">


                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-4/activity-cover-img.png"
                alt="Intelhealth-activity-cover-img.png" class="img-fluid lazy intelhealth-activity-cover-img hide-mobile show-desktop" data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section4">
                
                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-4/discovery.png"
                alt="elhealth-Activities-discovery" class="img-fluid lazy intelhealth-Activities-discovery hide-mobile show-desktop" data-aos="fade-in" data-aos-delay="1300" data-aos-anchor="#section4">
                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-4/define.png"
                alt="intelhealth-Activities-define" class="img-fluid lazy intelhealth-Activities-define hide-mobile show-desktop" data-aos="fade-in" data-aos-delay="1800" data-aos-anchor="#section4">
                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-4/design.png"
                alt="intelhealth-Activities-design" class="img-fluid lazy intelhealth-Activities-design hide-mobile show-desktop" data-aos="fade-in" data-aos-delay="2200" data-aos-anchor="#section4">
                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-4/test.png"
                alt="y intelhealth-Activities-test" class="img-fluid lazy intelhealth-Activities-test hide-mobile show-desktop" data-aos="fade-in" data-aos-delay="2600" data-aos-anchor="#section4">
                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-4/support.png"
                alt="ntelhealth-Activities-support" class="img-fluid lazy intelhealth-Activities-support hide-mobile show-desktop" data-aos="fade-in" data-aos-delay="3000" data-aos-anchor="#section4">
            </div>
          </div>

        </div>
    </div>
</section>

 <section class="comman-section comman-section-100vh scroll-div overflow-hidden section5-adj" id="section5"
    data-section-name="data-section5">
    <div class="container-fluid h-100 p-0">
        <div class="row vh100-100 align-items-center justify-content-center">
            <div class="col-md-12">
                <div class="overview-sec-center portfolio-info-wrap2">
                    <h3 data-aos="fade-up" data-aos-delay="1000" data-aos-anchor="#section5">
                        Primary & Secondary Research</h3>                      
                </div>
            </div>
          <div class="col-md-12">
            <div data-aos="fade-up" data-aos-delay="2000" data-aos-anchor="#section5">
                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-5/primary-secondary-research.png"
                alt="primary-secondary-research.png" class="img-fluid lazy intelhealth-Activities-covered show-desktop hide-mobile">

                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-5/primary-secondary-mob.png"
                alt="primary-secondary-research.png" class="img-fluid lazy intelhealth-Activities-covered hide-desktop show-mobile" style="width:100vw;">
            </div>
          </div>
        </div>
    </div>
</section>


<section class="comman-section comman-section-100vh scroll-div overflow-hidden section6-adj" id="section6"
data-section-name="data-section6">
<div class="container-fluid h-100 p-0">
    <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-12">
            <div class="overview-sec-center portfolio-info-wrap2">
                <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section6">
                    Persona - Community Health Workers</h3>                      
            </div>
        </div>
      <div class="col-md-12">
        <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6">
            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/community-part-1.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-1 hide-mobile show-desktop" data-aos="fade-in" data-aos-delay="800" data-aos-anchor="#section6">

            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/community-part-2.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-2 hide-mobile show-desktop" data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section6">

            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/community-part-3.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-3 hide-mobile show-desktop" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section6">

            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/activiies-top-img.jpg"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-1 show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="800" data-aos-anchor="#section6">

            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/activities-midle-img.jpg"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-2 show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section6">

            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/activities-bottom-img.jpg"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-3 show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section6">
       

            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/activiies2-top-img.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-1 show-mobile hide-desktop pt-5" data-aos="fade-in" data-aos-delay="800" data-aos-anchor="#section6">

            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/activiies2-middle-img.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-2 show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section6">

            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/activiies2-bottom-img.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-3 show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section6">
       
        </div>
      </div>
    </div>
</div>
</section>


<section class="comman-section comman-section-100vh scroll-div esop-sec3 comman-solution-challenge overflow-hidden" id="section7"
data-section-name="data-section7">
<div class="ani-wrap50 show-section" style="background: #EA315B;" data-aos="new-animation2" data-aos-delay="100"
    data-aos-anchor="#section7">
</div>

<div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-6">
            <div class="esop-left-wrap portfolio-info-wrap2">
                <h3 class="text-white lg-pt-150" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section7">Navigation Simplified</h3>
                <p class="text-white p2" data-aos="fade-in" data-aos-delay="900"
                    data-aos-anchor="#section7">
                    Introduced a new structure in the app by giving primary and secondary navigation after carefully understanding user tasks for easier findability.          </p>
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="esop-right-wrap ml85 portfolio-info-wrap2 show-desktop hide-mobile" data-aos="fade-in" data-aos-delay="1200">
                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/Information-architecture.png"
                alt="Information-architecture.png" class="img-fluid lazy intelhealth-Activities-covered" >
            </div>
        </div>
    </div>
</div>


</section>
<img src="assets/images/preloader/ajax-loader.png"
data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/Information-architecture.png"
alt="Information-architecture.png" class="img-fluid lazy intelhealth-Activities-covered show-mobile hide-desktop">

<section class="comman-section comman-section-100vh scroll-div overflow-hidden section8-adj" id="section8"
    data-section-name="data-section8">
    <div class="container-fluid h-100 p-0">
        <div class="row vh100-100 align-items-center justify-content-center">
            <div class="col-md-12">
                <div class="overview-sec-center portfolio-info-wrap2">
                    <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section8">
                        User Flow Diagram</h3>                      
                </div>
            </div>
          <div class="col-md-12">
            <div data-aos="fade-up" data-aos-delay="2000" data-aos-anchor="#section8">
                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-7/user-flow-diagram.png"
                alt="user-flow-diagram.png" class="img-fluid lazy intelhealth-Activities-covered-1 show-desktop hide-mobile">


                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-7/user-flow-mob.png"
                alt="user-flow-diagram.png" class="img-fluid lazy intelhealth-Activities-covered-1 show-mobile hide-desktop" style="width:100vw;">
                
                
            </div>
          </div>
        </div>
    </div>
</section>

<section class="comman-section comman-section-100vh scroll-div esop-sec7 bottom-col-section overflow-hidden"
id="section9" data-section-name="data-section9">

<h3 data-aos="fade-up" data-aos-delay="800" data-aos-anchor="#section9" class="text-center show-mobile hide-desktop">
    Mobile App Wireframes</h3>

<img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-8/mobile-app-wire-img-1.jpg"
alt="mobile-wireframes.png"
class="img-fluid lazy mobile-wireframes show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1500"
data-aos-anchor="#section9"/>

<div class="container h-100 show-desktop hide-mobile">
    <div class="row align-items-center justify-content-center">
        <div class="col-md-12">
            <div class="esop-center-wrap xxl-pt80">
                <h3 data-aos="fade-up" data-aos-delay="800" data-aos-anchor="#section9">
                    Mobile App Wireframes</h3>
            </div>
        </div>
    
    </div>

    <div class="row mobile-app-wireframes-img-adj">

        <!-- <div class="col-12 show-mobile hide-desktop ">
            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-8/mobile-app-wire-img-1.jpg"
            alt="mobile-wireframes.png"
            class="img-fluid lazy mobile-wireframes " data-aos="fade-in" data-aos-delay="1500"
            data-aos-anchor="#section9"/>
        </div> -->


        <div class="col-md-3 col-xxl-3"> 
            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-8/mobile-wireframes-1.png"
            alt="mobile-wireframes.png"
            class="img-fluid lazy mobile-wireframes hide-mobile show-desktop" data-aos="fade-in" data-aos-delay="1500"
            data-aos-anchor="#section9"/>
        </div>
        <div class="col-md-3 .col-xxl-3"> <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-8/mobile-wireframes-2.png"
            alt="mobile-wireframes.png"
            class="img-fluid lazy mobile-wireframes hide-mobile show-desktop" data-aos="fade-in" data-aos-delay="2000"
            data-aos-anchor="#section9"/></div>
        <div class="col-md-3 col-xxl-3">   <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-8/mobile-wireframes-3.png"
            alt="mobile-wireframes.png"
            class="img-fluid lazy mobile-wireframes hide-mobile show-desktop" data-aos="fade-in" data-aos-delay="2500"
            data-aos-anchor="#section9"/></div>
        <div class="col-md-3 col-xxl-3">  <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-8/mobile-wireframes-4.png"
            alt="mobile-wireframes.png"
            class="img-fluid lazy mobile-wireframes hide-mobile show-desktop" data-aos="fade-in" data-aos-delay="3000"
            data-aos-anchor="#section9"/></div>
    </div>

    
</div>
</section>

<section class="comman-section comman-section-100vh scroll-div overflow-hidden section10-adj" id="section10"
    data-section-name="data-section10">
    <div class="container-fluid h-100 p-0">
        <div class="row vh100-100 align-items-center justify-content-center">
            <div class="col-md-12">
                <div class="overview-sec-center portfolio-info-wrap2">
                    <h3 data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section9">
                        Web App Wireframes</h3>                      
                </div>
            </div>
          <div class="col-md-12">
            <div data-aos="fade-up" data-aos-delay="2000" data-aos-anchor="#section9">
                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-9/webapp-wireframes.png"
                alt="webapp-wireframes.png" class="img-fluid lazy webapp-wireframes show-desktop hide-mobile">


                <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-9/webapp-mob.png"
                alt="webapp-wireframes.png" class="img-fluid lazy webapp-wireframes show-mobile hide-desktop" style="width:100vw ;">
            </div>
          </div>
        </div>
    </div>
</section>

<section class="comman-section comman-section-100vh scroll-div esop-sec5" id="section11"
data-section-name="data-section11">
<div class="ani-wrap50" style="background: #15269B;" data-aos="new-animation2" data-aos-delay="100"
    data-aos-anchor="#section11">
</div>
<div class="container h-100">
    <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-6 col-sm-12">
            <div class="esop-left-wrap portfolio-info-wrap2">
                <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section11" class="text-white mb40">
                   
                </h3>
                <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section11">
                    <img src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-9/dm-sans-img.png"
                        class="img-fluid lazy esop-hits-img all-visual-img-adj show-desktop hide-mobile" alt="ESOP - It's Time to Hit Refresh!" />

                        <img src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-9/DM-Sans-img-1.png"
                        class="img-fluid lazy esop-hits-img all-visual-img-adj show-mobile hide-desktop" alt="ESOP - It's Time to Hit Refresh!" />
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <!-- <div data-aos="zoom-in" data-aos-delay="1500" data-aos-anchor="#section11" class="text-center show-mobile">
                <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-9/dm-sans-img.png" class="img-fluid lazy esop-hits-map-img" alt="ESOP - Findability First" />
            </div> -->

         

            <div class="hide-mobile">
                <div class="chart-circle-wrap">
                    <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-10/Red.png" class="chart-img1" alt="Intelehealth" data-aos="zoom-in" data-aos-delay="1500" data-aos-anchor="#section11"/>
                    <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-10/blue.png" class="chart-img2" alt="Intelehealth" data-aos="zoom-in" data-aos-delay="1800" data-aos-anchor="#section11" />
                    <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-10/Green.png" class="chart-img3" alt="Intelehealth" data-aos="zoom-in" data-aos-delay="2000" data-aos-anchor="#section11" /> 
                </div>                        
            </div>
        </div>
    </div>
</div>
</section>



<section class="comman-section comman-section-100vh scroll-div esop-sec3 comman-solution-challenge" id="section12"
data-section-name="data-section12">
<div class="dm-sans-chart show-mobile hide-desktop">
    <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-9/DM-Sans-img-2.jpg" class="" alt="Intelehealth" data-aos="zoom-in" data-aos-delay="1500" data-aos-anchor="#section11"/>  
</div>
<div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-6">
            <div class="esop-left-wrap portfolio-info-wrap2 hide-mobile show-desktop">
             <div class="humanized-illustrations-img-wrap">

                
                <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-11/humanized-bg.png" class="humanized-bg" alt="Intelehealth humanized-bg.png" data-aos="zoom-in" data-aos-delay="1000" data-aos-anchor="#section12"/>

                <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-11/illustration-1.png" class="illustration-img1" alt="Intelehealth" data-aos="zoom-in" data-aos-delay="1400" data-aos-anchor="#section12"/>
                <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-11/Illustration-2.png" class="illustration-img2" alt="Intelehealth" data-aos="zoom-in" data-aos-delay="1800" data-aos-anchor="#section12" />
                <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-11/Illustration-3.png" class="illustration-img3" alt="Intelehealth" data-aos="zoom-in" data-aos-delay="2000" data-aos-anchor="#section12" /> 


             </div>  
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="esop-right-wrap ml85 portfolio-info-wrap2">
                <h3  data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section12">
                    Humanized With Illustrations</h3>
                <p class="p2" data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section12">
                    Introduced all new customized illustrations and icons to the mobile and web app that conveys the functionalities well and look playful and human but sophisticated at the same time.
                </p>
            </div>
        </div>
    </div>
</div>
</section>


<div class="show-mobile hide-desktop humanized-img-adj">
    <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-11/humanized-img.jpg" class="humamized-mob-adj" alt="Intelehealth humanized-bg.png" data-aos="zoom-in" data-aos-delay="1000" data-aos-anchor="#section12"/>
</div>

<section class="comman-section comman-section-100vh scroll-div esop-sec5 overflow-hidden" id="section13"
data-section-name="data-section13">

<div class="ani-wrap50" style="background: #0FD197;" data-aos="new-animation2" data-aos-delay="100"
    data-aos-anchor="#section13">
</div>
<div class="container h-100">
    <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-6">
            <div class="esop-left-wrap portfolio-info-wrap2">
                <h3 class="text-white" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section13">
                    Engaging & Simple Onboarding</h3>
                <p class="text-white p2" data-aos="fade-in" data-aos-delay="1400" data-aos-anchor="#section13">
                    A guiding onboarding experience followed by a set-up activity with much lesser taps that requires almost no training for users to adapt as opposed to the previous one which required a lot of training as well as support afterward.
                </p>
            </div>
        </div>
        <div class="col-md-6">
            <div data-aos="zoom-in" data-aos-delay="1600" data-aos-anchor="#section5" class="text-center ">
                <!-- <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-esop/esop-time-hits-map.svg" class="img-fluid lazy esop-hits-map-img" alt="ESOP - Findability First" /> -->
                <!-- <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-13/onboarding-img.jpg" data-src="assets/images/portfolio/portfolio-webapp-esop/onboarding-img.jpg" class="img-fluid lazy onboarding-img" alt="intelhealth onboarding-img" /> -->
            
            
                <video muted="muted" autoplay loop="loop" onloadedmetadata="this.muted = true"
                autoplay class="comman-video onboarding-img hide-mobile show-desktop" id="video1"
                poster="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-13/onboarding-img.jpg">
                <source type="video/mp4"
                    src="assets/images/portfolio/portfolio-webapp-intelhealth/video/onboarding.mp4">
            </video>

            </div>
         
        </div>
    </div>
</div>
</section>

<div class="onboarding mob-adj show-mobile hide-desktop">
    <video muted="muted" autoplay loop="loop" onloadedmetadata="this.muted = true"
    autoplay class="comman-video onboarding-mob-adj" id="video2"
    poster="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-13/onboarding-img.jpg">
    <source type="video/mp4"
        src="assets/images/portfolio/portfolio-webapp-intelhealth/video/onboarding.mp4">
</video>
</div>

<!-- new code added -->
<section class="comman-section comman-section-100vh scroll-div overflow-hidden" id="section14"
    data-section-name="data-section14">
    <div class="container-fluid h-100">
        <div class="row vh100-100 align-items-center justify-content-center">
            <div class="col-md-12">
                <div class="overview-sec-center portfolio-info-wrap2">
                    <h3 data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section14">
                        Introducing An All-New Version of The Dashboard
                    </h3>                      
                </div>
            </div>
          <div class="col-md-12">
            <div data-aos="fade-in" data-aos-delay="1300" data-aos-anchor="#section14" class="all-new-version-bg-outer">
                <!-- <div class="rotate-ani"></div> -->
                <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-14/all-new-version-bg.png"
                 alt="webapp-wireframes" class="lazy all-new-version-bg rotate-ani mobile-adj-img" style="animation-duration: 20s;">

                 <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-14/all-new-version.png"
                alt="webapp-wireframes.png" class="img-fluid lazy all-new-version show-desktop hide-mobile">

                <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-14/all-new-ver-mob.png"
                alt="webapp-wireframes.png" class="img-fluid lazy all-new-version show-mobile hide-desktop">

            </div>
          </div>
        </div>
    </div>
</section>
<!-- new code end -->



<section class="comman-section comman-section-100vh scroll-div esop-sec3 comman-solution-challenge" id="section15"
data-section-name="data-section15" style="height:650px ;">
<div class="ani-wrap50-2" style="background-color: #2E1E8F;" data-aos="new-animation2" data-aos-delay="1200"
    data-aos-anchor="#section15">
</div>
<div class="ani-wrap50 bg-white" data-aos="new-animation2" data-aos-delay="100" data-aos-anchor="#section15">
</div>
<div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-6">
            <div class="esop-left-wrap portfolio-info-wrap2 hide-mobile show-desktop">
                <div data-aos="zoom-in" data-aos-delay="600" data-aos-anchor="#section15" class="text-center">
                   
                    <!-- <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-15/navigation-implement-img.jpg"  class="img-fluid lazy navigation-implement-img" alt="intelhealth navigation-implement-img" /> -->
                

                    <video muted="muted" autoplay loop="loop" onloadedmetadata="this.muted = true"
                autoplay class="comman-video v-scale-0-8 navigation-implement-img img-fluid" id="video3"
                    poster="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-15/navigation-implement-img.jpg">
                    <source type="video/mp4"
                        src="assets/images/portfolio/portfolio-webapp-intelhealth/video/Navigation-implemented.mp4">
                </video>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="esop-right-wrap ml85 portfolio-info-wrap2 xs-pt-adj">
                <h2 class="text-white mt-150" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section15">
                    Navigation Implemented</h2>
              
            </div>
        </div>
    </div>
</div>
</section>


<section class="comman-section comman-section-100vh scroll-div esop-sec5 overflow-hidden" id="section16"
data-section-name="data-section16">
<div class="navigation-implemnted-img hide-desktop show-mobile">
    <video muted="muted" autoplay loop="loop" onloadedmetadata="this.muted = true"
                autoplay class="comman-video navigation-implement-img img-fluid" id="video4"
    poster="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-15/navigation-implement-img.jpg">
    <source type="video/mp4"
        src="assets/images/portfolio/portfolio-webapp-intelhealth/video/Navigation-implemented.mp4">
</video>
</div>
<div class="container h-100">
    <div class="row vh100-100 align-items-center justify-content-center diagnosis-adj">
        <div class="col-md-6">
            <div class="esop-left-wrap portfolio-info-wrap2">
                <h3 class="" data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section16">
                    Diagnosis Made Guided & Conversational</h3>
                <p class="p2" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section16">
                    A minimalistic, intuitive, and conversational UI that gives users a feel of the presence of a digital assistant conversing with them along with suggestions to select primary symptoms without having to look too hard for them.
                </p>
            </div>
        </div>
        <div class="col-md-6">
            <div data-aos="zoom-in" data-aos-delay="1900" data-aos-anchor="#section16" class="text-center ">
                <!-- <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-esop/esop-time-hits-map.svg" class="img-fluid lazy esop-hits-map-img" alt="ESOP - Findability First" /> -->
                <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-16/diagnosis-img2.jpg"  class="img-fluid lazy diagnosis-img" alt="intelhealth diagnosis-img" />
            
            </div>
            <div class="hide-mobile">
                <!-- <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-13/onboarding-img.jpg" data-src="assets/images/portfolio/portfolio-webapp-esop/esop-time-hits-map.svg" class="img-fluid lazy esop-hits-map-img" alt="ESOP - Findability First" /> -->
                <div class="chart-circle-wrap">
                    <!-- <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-16/diagnosis-img.jpg"  class="img-fluid lazy diagnosis-img.jpg" alt="intelhealth diagnosis-img" /> -->
                    <!-- <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-10/Red.png" class="chart-img1" alt="Intelehealth"/>
                    <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-10/Blue.png" class="chart-img2" alt="Intelehealth" data-aos="zoom-in" data-aos-delay="1800" data-aos-anchor="#section5" />
                    <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-10/Green.png" class="chart-img3" alt="Intelehealth" data-aos="zoom-in" data-aos-delay="2000" data-aos-anchor="#section5" />  -->
                </div>                        
            </div>
        </div>
    </div>
</div>
</section>



<section class="comman-section comman-section-100vh scroll-div esop-sec5 overflow-hidden" id="section17"
data-section-name="data-section17">
<div class="ani-wrap50" style="background: #EA315B;;" data-aos="new-animation2" data-aos-delay="100"
    data-aos-anchor="#section17">
</div>
<div class="container h-100">
    <div class="row vh100-100 align-items-center justify-content-center appointments-adj">
        <div class="col-md-6">
            <div class="esop-left-wrap portfolio-info-wrap2">
                <h3 class="text-white" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section17">
                    Appointments Easier like Never Before</h3>
                <p class="text-white p2" data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section17">
                    Easy to find upcoming appointments and better management of appointments overall. 
                    Better calling experience within the app so that the users don't have to rely on other alternatives for taking appointments.                    
                </p>
            </div>
        </div>
        <div class="col-md-6">
            <div data-aos="zoom-in" data-aos-delay="2000" data-aos-anchor="#section17" class="text-center">
                <!-- <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-esop/esop-time-hits-map.svg" class="img-fluid lazy esop-hits-map-img" alt="ESOP - Findability First" /> -->
                <!-- <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-17/apointment-img.jpg" class="img-fluid lazy apointment-img" alt="intelhealth apointment-img" />
             -->
                
                 <video muted="muted" autoplay loop="loop" onloadedmetadata="this.muted = true"
                autoplay class="comman-video navigation-implement-img show-desktop hide-mobile mt-50" id="video5"
                    poster="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-15/navigation-implement-img.jpg">
                    <source type="video/mp4"
                        src="assets/images/portfolio/portfolio-webapp-intelhealth/video/appointment.mp4">
                </video>
            </div>
            <div class="hide-mobile">
                <!-- <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-13/onboarding-img.jpg" data-src="assets/images/portfolio/portfolio-webapp-esop/esop-time-hits-map.svg" class="img-fluid lazy esop-hits-map-img" alt="ESOP - Findability First" /> -->
                <div class="chart-circle-wrap">
                    <!-- <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-16/apointment-img.jpg" class="img-fluid lazy apointment-img.jpg" alt="intelhealth apointment-img" /> -->
                    <!-- <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-10/Red.png" class="chart-img1" alt="Intelehealth"/>
                    <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-10/Blue.png" class="chart-img2" alt="Intelehealth" data-aos="zoom-in" data-aos-delay="1800" data-aos-anchor="#section5" />
                    <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-10/Green.png" class="chart-img3" alt="Intelehealth" data-aos="zoom-in" data-aos-delay="2000" data-aos-anchor="#section5" />  -->
                </div>                        
            </div>
        </div>
    </div>
</div>
</section>

<div class="appointment-mob-img-adj show-mobile hide-desktop ">
    <video muted="muted" autoplay loop="loop" onloadedmetadata="this.muted = true"
                autoplay class="comman-video navigation-implement-img img-fluid" id="video6"
    poster="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-15/navigation-implement-img.jpg">
    <source type="video/mp4"
        src="assets/images/portfolio/portfolio-webapp-intelhealth/video/Navigation-implemented.mp4">
</video>

</div>

<!-- new code -->
<section class="comman-section comman-section-100vh scroll-div " id="section18"
data-section-name="data-section18">
<div class="container h-100">
    <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-12">
            <div class="overview-sec-center portfolio-info-wrap2">
                <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section18">
                    Doctor's Web App</h3>
                <p data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section18" class="p2">
                    The doctor's web app had UX challenges like less secure login, non-flexible planning of the doctor's schedule, no visibility of upcoming important tasks, way to look for help within the web app if needed without having to reach out to support. 
                    On the UI part, the web app seemed to be very system-centric and lacked a human touch. It also wasn't representing the Interlehealth organization well.                       
                </p>
               
            </div>
        </div>
       
    </div>
</div>
<div class="strok-adj">
    <img src="assets/images/preloader/ajax-loader.png"
    data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-1/stroke.svg"
    alt="Intelhealth-banner-Strokes" class="img-fluid lazy intelhealth-header-img w-100" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section18">

    <img src="assets/images/preloader/ajax-loader.png"
    data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-1/over-view-bottom.jpg"
    alt="Intelhealth-banner-Strokes" class="img-fluid lazy doctor-stroke w-100 show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section2">

    <img src="assets/images/preloader/ajax-loader.png"
    data-src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-1/over-view-top.jpg"
    alt="Intelhealth dots" class="img-fluid lazy doc-dots  show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1100" data-aos-anchor="#section2">

</div>
</section>
<!-- end code -->

<section class="comman-section comman-section-100vh scroll-div overflow-hidden " id="section19"
data-section-name="data-section19">
<div class="container-fluid h-100 p-0">
    <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-12">
            <div class="overview-sec-center portfolio-info-wrap2">
                <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section19">
                    Persona - Doctors</h3>                      
            </div>
        </div>
      <div class="col-md-12">
        <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section19">
            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/persona-dr-1.png.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy persona-dr-img1 hide-mobile show-desktop" data-aos="fade-right" data-aos-delay="800" data-aos-anchor="#section19">
            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/persona-dr-2.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy persona-dr-img2 hide-mobile show-desktop" data-aos="fade-right" data-aos-delay="1000" data-aos-anchor="#section19">
            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/persona-dr-3.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy persona-dr-img3 hide-mobile show-desktop" data-aos="fade-right" data-aos-delay="1200" data-aos-anchor="#section19">



            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/dr-persona-top.png.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-1 show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="800" data-aos-anchor="#section6">

            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/dr-persona-middle.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-2 show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section6">

            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/dr-persona-bottom.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-3 show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section6">
       

            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/dr-persona2-top.png.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-1 show-mobile hide-desktop pt-5" data-aos="fade-in" data-aos-delay="800" data-aos-anchor="#section6">

            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/dr-persona2-middle.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-2 show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1000" data-aos-anchor="#section6">

            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-6/dr-persona2-bottom.png"
            alt="Intelhealth-Activities-covered.png" class="img-fluid lazy community-part-3 show-mobile hide-desktop" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section6">
        </div>
      </div>
    </div>
</div>
</section>


<section class="comman-section comman-section-100vh scroll-div esop-sec3 comman-solution-challenge comman-solution-challenge-xs" id="section20"
data-section-name="data-section20">
<div class="ani-wrap50-2" style="background-color: #0FD197;" data-aos="new-animation2" data-aos-delay="1200"
    data-aos-anchor="#section20">
</div>
<div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-6">
            <div class="esop-left-wrap portfolio-info-wrap2">
                <div data-aos="zoom-in" data-aos-delay="1000" data-aos-anchor="#section5" class="">
                    <h3 class="" data-aos="fade-in" data-aos-delay="1300" data-aos-anchor="#section20">
                        Doctor's Onboarding</h3>
                        <p class="p2 " data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section20">Added a human touch to the log-in screens by giving visuals that show Intelehealth’s vision, impact stats, and some idea of inner screens.  
                            <br>Login is made more secure by OTP verification and captcha.</p>
                  
                   
                
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 mob-adj">
            <div class="esop-right-wrap ml85 portfolio-info-wrap2">
                <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-20/doctor-onboarding.png"  class=" lazy doctor-onboarding doctor-onboarding-right-img" alt="intelhealth doctor-onboarding.png" data-aos="fade-left" data-aos-delay="2000" data-aos-anchor="#section20"/>              
            </div>
        </div>
    </div>
</div>
</section>

<div class="doctor-dashboard-mobile-adj show-mobile hide-desktop">
    <div class="right-wrap">
        <h3 class="" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section21">
            Doctor's Dashboard & Navigation</h3>
        <p class="p2" data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section21">
            The doctor's dashboard had a system-centric feel to it which was humanized with icons and illustrations and an overall layout. A new navigation bar is given to the left and a header at the top to improve the overall architecture. 
           <br> FAB is added to help the doctor reach out to the support team whenever needed throughout the app.                                    
        </p>
    </div>
</div>


<section class="comman-section comman-section-100vh scroll-div esop-sec5 overflow-hidden" id="section21"
data-section-name="data-section21">
<div class="ani-wrap50 ani-mob-adj" style="background: #2E1E91;;" data-aos="new-animation2" data-aos-delay="100"
    data-aos-anchor="#section10">
</div>
<div class="container h-100">
    <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-6">
            <div class="left-wrap">
               <div class="text-center">
     
                <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-20/doctor-dashboard.png" class="img-fluid lazy doctor-dashboard" alt="intelhealth doctor-dashboard" data-aos="fade-right" data-aos-delay="1600" data-aos-anchor="#section21"/>
            
            </div>
           
            </div>
        </div>
        <div class="col-md-6">
         <div class="right-wrap show-desktop hide-mobile">
            <h3 class="" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section21">
                Doctor's Dashboard & Navigation</h3>
            <p class="p2" data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section21">
                The doctor's dashboard had a system-centric feel to it which was humanized with icons and illustrations and an overall layout. A new navigation bar is given to the left and a header at the top to improve the overall architecture. 
               <br> FAB is added to help the doctor reach out to the support team whenever needed throughout the app.                                    
            </p>
        </div>
            
        </div>
    </div>
</div>
</section>




<section class="comman-section comman-section-100vh scroll-div esop-sec3 comman-solution-challenge " id="section22"
data-section-name="data-section22">
<div class="ani-wrap50-2" style="background-color: #EA315B;" data-aos="new-animation2" data-aos-delay="1200"
    data-aos-anchor="#section22">
</div>
<div class="ani-wrap50 bg-white" data-aos="new-animation2" data-aos-delay="100" data-aos-anchor="#section22">
</div>
<div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-6">
            <div class="esop-left-wrap portfolio-info-wrap2">
                <div data-aos="zoom-in" data-aos-delay="1500" data-aos-anchor="#section5" class="">
                    <h3 class="" data-aos="fade-in" data-aos-delay="1400" data-aos-anchor="#section22">
                        Doctor's Calendar</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section22">The doctors using the web app are very busy and hence a flexible scheduling system is very crucial for them which was missing. Improvement was done to give doctors the option to add workdays, work hours, and days off with utmost flexibility.                        </p>
                                
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 mob-adj-1">
            <div class="esop-right-wrap ml85 portfolio-info-wrap2">
                <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-20/doctor-calendar.png"  class="img-fluid lazy doctor-calendar doctor-calendar-right-img" alt="intelhealth doctor-calendar.png" data-aos="fade-left" data-aos-delay="1900" data-aos-anchor="#section22"/>              
            </div>
        </div>
    </div>
</div>
</section>

<section class="comman-section comman-section-100vh scroll-div esop-sec5 overflow-hidden" id="section23"
data-section-name="data-section23">
<div class="ani-wrap50" style="background: #2E1E91;;" data-aos="new-animation2" data-aos-delay="100"
    data-aos-anchor="#section23">
</div>
<div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-6 xs-order-1 mob-adj-3">
            <div class="left-wrap">
               <div data-aos="zoom-in" data-aos-delay="1500" data-aos-anchor="#section23" class="text-center">
                <!-- <img src="assets/images/preloader/ajax-loader.png" data-src="assets/images/portfolio/portfolio-webapp-esop/esop-time-hits-map.svg" class="img-fluid lazy esop-hits-map-img" alt="ESOP - Findability First" /> -->
                <!-- <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-20/patient-intraction.png" class="img-fluid lazy patient-intraction" alt="intelhealth patient-intraction" data-aos="fade-right" data-aos-delay="1900" data-aos-anchor="#section23"/> -->

                 <video muted="muted" autoplay loop="loop" onloadedmetadata="this.muted = true"
                autoplay class="comman-video patient-intraction video-intelehealth-width-auto" id="video7"
                            poster="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-20/patient-intraction.png">
                            <source type="video/mp4"
                                src="assets/images/portfolio/portfolio-webapp-intelhealth/video/Patient-interaction.mp4">
                        </video>
            </div>
            <div class="hide-mobile">
                <!-- <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-13/onboarding-img.jpg" data-src="assets/images/portfolio/portfolio-webapp-esop/esop-time-hits-map.svg" class="img-fluid lazy esop-hits-map-img" alt="ESOP - Findability First" /> -->
                <div class="chart-circle-wrap">
                    <!-- <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-16/apointment-img.jpg" class="img-fluid lazy apointment-img.jpg" alt="intelhealth apointment-img" /> -->
                    <!-- <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-10/Red.png" class="chart-img1" alt="Intelehealth"/>
                    <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-10/Blue.png" class="chart-img2" alt="Intelehealth" data-aos="zoom-in" data-aos-delay="1800" data-aos-anchor="#section5" />
                    <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-10/Green.png" class="chart-img3" alt="Intelehealth" data-aos="zoom-in" data-aos-delay="2000" data-aos-anchor="#section5" />  -->
                </div>                        
            </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="right-wrap">
            <h3 class="" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section3">
                Patient Interaction <br>Made Intuitve</h3>
            </div>
          
        </div>
    </div>
</div>
</section>


<section class="comman-section comman-section-100vh scroll-div overflow-hidden pb-0 contextual-help-n-sec " id="section24"
data-section-name="data-section24">
<div class="container-fluid h-100 p-0">
    <div class="row vh100-100 align-items-center justify-content-center mob-adj">
        <div class="col-md-12 mt-5">
            <div class="overview-sec-center portfolio-info-wrap2">
                <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section24">
                    Comprehensive Contextual Help
                </h3>                      
            </div>
        </div>
      <div class="col-md-12">
        <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section24" class="text-center " style="background-color: #0FD197; height: 100vh;">
            <img src="assets/images/portfolio/portfolio-webapp-intelhealth/Slide-20/contextual-help-n.png"
            alt="Intelhealth contextual-help" class="img-fluid lazy contextual-help-n">
         
        </div>
      </div>
    </div>
</div>
</section>


<section class="comman-section comman-section-100vh impact-section-upd scroll-div" id="section-testimonial"
data-section-name="data-section-testimonial">
<div class="ani-wrap100-top" style="background-color: #242424;" data-aos="new-animation3" data-aos-delay="100"
    data-aos-anchor="#section-testimonial"></div>
<div class="container h-100">
    <div class="row vh100-100 align-items-center justify-content-center">
        <div class="col-md-12">
            <div class="comman-impact-bottom" data-aos="fade-in" data-aos-delay="900"
                data-aos-anchor="#section-testimonial" style="margin: auto !important;">
                <div class="card-img-wrap"><img src="assets/images/preloader/ajax-loader.png"
                        data-src="assets/images/testimonial/intelhealth-client-profile.png" alt="CTO & Co Founder, Intelehealth"
                        class="comman-impact-img lazy" /></div>
                <h6 class="text-white">Neha Verma</h6>
                <h6 class="text-white" style="opacity: 0.5;">CEO & Co Founder, Intelehealth</h6>
                <p class="p3 text-white">
                    <span>
                       The Prismic team was very thorough in their user research and experience design. They took time to understand the user personas, pain points and prepared a thorough design brief. The team was very professional and incorporated all our feedback in a timely manner. We were very pleased with the end product and our completely redesigned app & web portals. I would highly recommend working with them!
                    </span>
                </p>
            </div>
        </div>
    </div>
</div>
</section>


<section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
data-section-name="data-section-next-page">
<div class="container h-100">
    <div class="row">
        <div class="col-sm-12">
            <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                data-aos-anchor="#section-next-page">
                <h6>Next</h6>
                <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/mobile-app/easy-plan"> <span>Easyplan</span> </a>
                <p class="p2">Enhancing India's #1 Goal-Based Saving App</p>
            </div>
        </div>
    </div>
</div>
</section>

</div>
