import { Component, OnInit } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import scrollify from 'jquery-scrollify';
// import 'src/assets/widgets/touch-scroll/js/TouchScroll.js';
import AOS from 'aos';
declare let $: any;
declare let value: any;
declare let play: any;
declare let TouchScroll: any;
declare let Waypoint: any;
import counterUp from 'counterup2';

@Component({
  selector: 'app-moneyguru',
  templateUrl: './moneyguru.component.html',
  styleUrls: ['./moneyguru.component.scss']
})
export class MoneyguruComponent implements OnInit {

  outOfBounds = {
    top: false,
    bottom: false,
    right: false,
    left: true
  };

  customOptions: OwlOptions = {
    dots: true,
    nav: false,
    margin: 10,
    autoplay: true,
    autoHeight: true,
    mouseDrag: true,
    touchDrag: true,
    loop: true,
    autoWidth: false,
    lazyLoad: true,
    items: 1,
    autoplayTimeout : 2000,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
  };

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

 
  //new desc
  descrip = 'Check how we delivered Money guru - a fintech enterprise its strategic mobile application ux ui design. The next mobile app could be yours. Let`s discuss!';

  constructor( private titleService: Title, private metaService: Meta) {
    this.setTitle('Mobile App UX UI Design for Fintech Enterprise | Money Guru - Casestudy');
    this.metaService.updateTag({name: 'description', content: this.descrip});
    this.metaService.updateTag({name: 'keywords', content: 'UI Design for Corporates, UX for Startup, User Experience Design (UX), User Experience Design, Top Design Studio India, Best Design Agency Mumbai, Pune, Nashik, UI UX Design for Angel Broking, User Interface Design HDFC Credila, User Experience Design (UX) for Fintech, UI/UX for eCommerce, eLearning, User Interface Design for LMS, UI/UX Design Mobile App, UX Design for Mobility, Logo Design, Logo Branding, UI for Food Tech, UI/UX for Leading Real estate Company'});
    this.metaService.updateTag({property: 'og:type', content: 'business.business'});
    this.metaService.updateTag({property: 'og:title', content: 'Mobile App UX UI Design for Fintech Enterprise | Money Guru - Casestudy'});
    this.metaService.updateTag({property: 'og:description', content: this.descrip});
  }

  ngOnInit(): void {
    const numcounter1 = document.querySelector('.counter1');
    const numcounter2 = document.querySelector('.counter2');
    counterUp(numcounter1, {duration: 1500, delay: 20});
    counterUp(numcounter2, {duration: 1500, delay: 20});
    $(function() {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });
    $('.comman-header-wrap').show();
    $('#comman-footer').show();
    // all video play
    const vid6 = document.getElementById('vidoe6');
    const vid7 = document.getElementById('vidoe7');
    const vid8 = document.getElementById('vidoe8');
    const vid9 = document.getElementById('vidoe9');
    const vid11 = document.getElementById('vidoe11');
    const vid12_1 = document.getElementById('vidoe12-1');
    const vid12_2 = document.getElementById('vidoe12-2');
    const vid12_3 = document.getElementById('vidoe12-3');
    const vid12_4 = document.getElementById('vidoe12-4');
    $(document).ready(function() {
      $('.logo-wrap-our-team').hide();
      $('.logo-wrap-a').show();
      $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
      $('.arrow-scroll-abs').show();
      $('.arrow-scroll-abs .next').removeClass('text-white');
      $('.comman-top-menu').removeClass('comman-top-menu-dark');
      const wido = $(window).width();
      if (wido >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          overflowscroll: true,
          before(i, panels) {
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.pagination-wrap').find('a[href="#' + ref + '"]').addClass('active');
            $('.comman-top-menu').removeClass('comman-top-menu-dark');
            if (ref === 'data-section3') {
              $('.comman-header')
                .addClass('active-menu-white')
                .removeClass('active-white active-logo-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              // vidplay8();
              $('#moneyguru-wireframes-top').removeClass('active');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            } else if (ref === 'data-section4') {
              $('.comman-header').removeClass('active-white active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('#moneyguru-wireframes-top').addClass('active');
              // vidplay8();
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              const viewer = new TouchScroll();
              viewer.init({id: 'viewer',draggable: true,wait: false});
            } else if (ref === 'data-section5') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('#moneyguru-wireframes-top').removeClass('active');
              // vidplay8();
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            } else if (ref === 'data-section6') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              // vidplay6();
              // vidplay8();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
            } else if (ref === 'data-section7') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              // vidplay7();
              // vidplay8();
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            } else if (ref === 'data-section8') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              // vidplay8();
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            } else if (ref === 'data-section9') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              // vidplay9();
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            } else if (ref === 'data-section-learning') {
              $('.comman-header')
                .addClass('active-menu-white')
                .removeClass('active-logo-white active-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.arrow-scroll-abs').fadeIn();
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            } else if (ref === 'data-section-testimonial') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              // alert('sec');
              // counterNum();
              const waypoint1 = new Waypoint({
                element: numcounter1,
                handler() {
                  counterUp(numcounter1, {duration: 1500, delay: 20});
                  this.destroy();
                }, offset: 'bottom-in-view',
              });
              const waypoint2 = new Waypoint({
                element: numcounter2,
                handler() {
                  counterUp(numcounter2, {duration: 1500, delay: 20});
                  this.destroy();
                }, offset: 'bottom-in-view',
              });
            } else if (ref === 'data-comman-footer') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
            } else {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
            }
          },
          afterRender() {
            aosinit();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i) {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
          },
        });
        $('.arrow-scroll-abs .next').click(function(e1) {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2) {
          e2.preventDefault();
          $.scrollify.prev();
        });
      }
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }

      function aosinit() {
        AOS.init({
          duration: 400,
          anchorPlacement: 'top-top',
          disable() {
            const maxWidth = 1199;
            return window.innerWidth < maxWidth;
          },
          once: true,
          offset: 20,
        });
      }
      // if (wido <= 1025) {
      //   vidplay6();
      //   vidplay7();
      //   vidplay8();
      //   vidplay9();
      //   vidplay10();
      //   vidplay11();
      //   vidplay12();
      //   vidplay12_1();
      //   vidplay12_2();
      //   vidplay12_3();
      //   vidplay12_4();
      // }
    });
  }

}
