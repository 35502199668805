<div class="portfolio-mobile-app-easy-plan-body">
    <section class="comman-section comman-section-100vh scroll-div easy-plan-sec1 top-section-wrapper" id="section1"
        data-section-name="data-section1">
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-8 order-xs-last">
                    <div class="easy-plans1-right portfolio-info-wrap2 portfolio-info-banner-l xs-text-center">
                        <ul class="list-inline comman-card-top-ul" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <li class="list-inline-item h6">EasyPlan</li>
                            <li class="list-inline-item h6">Fintech, Money Management</li>
                        </ul>
                        <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1"
                            class="no-wrap-md hide-mobile">
                            Enhancing India's #1<br />
                            Goal-Based Saving App
                        </h1>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1"
                        class="no-wrap-md show-mobile xs-text-center">
                        Enhancing India's #1<br />
                        Goal-Based Saving App
                    </h1>
                    <div class="easy-plan-right-wrap xs-mt25" data-aos="fade-in" data-aos-delay="1200"
                        data-aos-anchor="#section1">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-banner.svg"
                            alt="Easy plan - Saving App" class="img-fluid easy-plan-banner-img lazy" />
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">
                Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div easy-plans-sect2" id="section2"
        data-section-name="data-section2">
        <div class="ani-wrap100-top" data-aos="new-animation3" data-aos-delay="300" data-aos-anchor="#section2"
            style="background-color: #7972e8;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="easy-plans2-center overview-sec-center portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                            Overview</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2">
                            Easyplan is a platform that motivates young Indians to build &amp; increase their saving
                            habits in a simpler &amp; smarter way. This mobile app offers various engaging features like
                            setting up saving plans
                            based on different user goals, instant withdrawals, in app KYC, chat and so on.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge scroll-div easy-plans-sec3 xs-pt0"
        id="section3" data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #212043;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3"></div>
        <div class="ani-wrap50" style="background: #7972e8;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section3"></div>
        <div class="easy-plans-sec3-1-outer1" data-aos="fade-in" data-aos-delay="2100" data-aos-anchor="#section2">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/easy-plans/easy-plans-sec3-1.svg" alt="Easy plan - Challenges"
                class="easy-plans-sec2-1 lazy img-fluid" />
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <div class="easy-plans-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3" class="text-white">
                            Challenges</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">
                            Prismic Reflections&reg; were engaged by the client to continue, adopt the existing ready
                            design guidelines of the product and work upon improving experience of existing user
                            journeys, features &amp; UX UI
                            design of the platform.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="easy-plans-right-wrap portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section3" class="text-white">
                            Solution</h3>
                        <p data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section3" class="text-white p2">
                            To improve the engagement, the design team introduced a conversational UX (chatbot) for goal
                            planning and setting user flow. To bring transparency &amp; provide detailed insights to the
                            advanced user, goal
                            &amp; investment tracking dashboards were introduced.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div easy-plan-sec4" id="section4" data-section-name="data-section4">
        <div class="ani-wrap100-top-2 bg-blue6" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section4"></div>
        <div class="container">
            <div class="row">
                <div class="col-md-5 col-lg-5 col-xl-4">
                    <div class="easy-plan-strategy-right portfolio-info-wrap">
                        <h3 class="text-white font-family-sans-serif" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section4">Onboarding</h3>
                        <p class="p2 text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4">
                            To communicate the benefits of the mobile app and to answer the WHY of the user, an
                            illustrated story is created in the beginning.
                        </p>
                    </div>
                </div>
                <div class="col-md-7 col-lg-7 col-xl-8">
                    <div class="easy-plan-strategy-left clearfix hide-mobile">
                        <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section4">
                            <div class="easy-plan-strategy-left-inner">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-onboarding1.svg"
                                    alt="Easy plan - Onboarding" class="img-fluid easy-plan-onboarding1 lazy" />
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-onboarding2.svg"
                                    alt="Easy plan - Onboarding" class="img-fluid easy-plan-onboarding2 lazy" />
                            </div>
                            <div class="easy-plan-strategy-left-inner2">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-onboarding-slider.png"
                                    alt="Easy plan - Onboarding" class="img-fluid lazy" />
                            </div>
                            <owl-carousel-o [options]="customOptions" class="owl-caroual-comman">
                                <ng-template carouselSlide>
                                    <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-mobile-app-easy-plan/owl-caroual-easy-plan1-lg.svg" alt="Easy plan - Onboarding" />
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-mobile-app-easy-plan/owl-caroual-easy-plan2-lg.svg" alt="Easy plan - Onboarding" />
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-mobile-app-easy-plan/owl-caroual-easy-plan3-lg.svg" alt="Easy plan - Onboarding" />
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-mobile-app-easy-plan/owl-caroual-easy-plan4-lg.svg" alt="Easy plan - Onboarding" />
                                </ng-template>
                            </owl-carousel-o>                   
                        </div>
                    </div>
                    <div class="easy-plan-xs-left show-mobile hide-desktop">
                        <owl-carousel-o [options]="customOptions2" class="owl-caroual-comman">
                            <ng-template carouselSlide>
                                <div class="easy-plan-xs-4-inner">
                                    <div class="easy-plan-xs-4-img">
                                        <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-mobile-app-easy-plan/owl-caroual-easy-plan1.svg" alt="Easy plan - Save Regularly from your Phone" />
                                    </div>
                                    <p class="p2">Save Regularly from your Phone</p>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="easy-plan-xs-4-inner">
                                    <div class="easy-plan-xs-4-img">
                                        <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-mobile-app-easy-plan/owl-caroual-easy-plan2.svg" alt="Easy plan - Withdraw instantly, anytime from the app, 24x7" />
                                    </div>
                                    <p class="p2">Withdraw instantly, anytime from the app, 24x7</p>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="easy-plan-xs-4-inner">
                                    <div class="easy-plan-xs-4-img">
                                        <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-mobile-app-easy-plan/owl-caroual-easy-plan3.svg" alt="Easy plan - Skip or change contribution without penalty any time" />
                                    </div>
                                    <p class="p2">Skip or change contribution without penalty any time</p>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="easy-plan-xs-4-inner">
                                    <div class="easy-plan-xs-4-img">
                                        <img class="owl-lazy img-fluid" src="assets/images/portfolio/portfolio-mobile-app-easy-plan/owl-caroual-easy-plan4.svg" alt="Easy plan - Earn up to 2x a regular bank account" />
                                    </div>
                                    <p class="p2">Earn up to 2x a regular bank account, in a low-risk ICICI Prudential mutual fund</p>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div easy-plan-sec5" id="section5"
        data-section-name="data-section5">
        <div class="ani-wrap100-top bg-grey5" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section5"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-end">
                <div class="col-md-5">
                    <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5" class="hide-mobile">
                        <div class="ease-plan-chat-wrap">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-chat1.svg"
                                alt="Easy plan - Goal planning" class="img-fluid easy-plan-chat1 lazy" />
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-chat2.svg"
                                alt="Easy plan - Goal planning" class="img-fluid easy-plan-chat2 lazy" />
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-chat3.svg"
                                alt="Easy plan - Goal planning" class="img-fluid easy-plan-chat3 lazy" />
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-chat4.svg"
                                alt="Easy plan - Goal planning" class="img-fluid easy-plan-chat4 lazy" />
                            <div class="ease-plan-chat-mobile-wrap lazy" data-aos="fade-in" data-aos-delay="1200"
                                data-aos-anchor="#section5">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-chat-mobile.png"
                                    alt="Easy plan - Goal planning" class="img-fluid easy-plan-chat-mobile lazy" />
                                <div class="ease-plan-chat-mobile-hover"></div>
                            </div>
                        </div>
                    </div>
                    <div class="easy-plan-xs-right show-mobile hide-desktop mb30 text-center">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec5-xs1.png"
                            alt="Easy plan - Goal planning" class="lazy img-fluid xs-ml50" />
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="easy-plan-strategy-right portfolio-info-wrap">
                        <h3 class="font-family-sans-serif" data-aos="fade-in" data-aos-delay="1500"
                            data-aos-anchor="#section5">Goal Planning Made Conversational</h3>
                        <p class="p2 text-light-dark-c" data-aos="fade-in" data-aos-delay="1900"
                            data-aos-anchor="#section5">
                            Introduced a chatbot to make the user's goal planning &amp; setting journey more interactive
                            &amp; engaging.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div easy-plan-sec6" id="section6" data-section-name="data-section6">
        <div class="container">
            <div class="row vh100-100 align-items-center justify-content-end">
                <div class="col-md-7 order-xs-last">
                    <div class="easy-plan-goal-right portfolio-info-wrap">
                        <h3 class="font-family-sans-serif xs-text-center" data-aos="fade-in" data-aos-delay="300"
                            data-aos-anchor="#section6">
                            Goal Dashboard &amp; <br />
                            Tracking Made Easy
                        </h3>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="easy-plan-sec6-inner hide-mobile">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec6-inner1.png"
                            alt="Goal Dashboard &amp; Tracking made Easy" class="img-fluid easy-plan-sec6-inner1 lazy"
                            data-aos="fade-down" data-aos-delay="900" data-aos-anchor="#section6" />
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec6-inner2.png"
                            alt="Goal Dashboard &amp; Tracking made Easy" class="img-fluid easy-plan-sec6-inner2 lazy"
                            data-aos="fade-down" data-aos-delay="1200" data-aos-anchor="#section6" />
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec6-inner5.svg"
                            alt="Goal Dashboard &amp; Tracking made Easy"
                            class="img-fluid easy-plan-sec6-inner5 lazy" />
                    </div>
                    <div class="easy-plan-xs-right show-mobile hide-desktop mb30 text-center">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec6-xs1.png"
                            alt="Easy plan - Goal Dashboard" class="lazy img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div easy-plan-sec7" id="section7" data-section-name="data-section7">
        <div class="ani-wrap100-top" style="background-color: #212043 !important;" data-aos="new-animation3"
            data-aos-delay="100" data-aos-anchor="#section7"></div>
        <div class="container">
            <div class="row justify-content-end">
                <div class="col-md-7 order-xs-last">
                    <div class="easy-plan-goal-right portfolio-info-wrap">
                        <h3 class="font-family-sans-serif text-white xs-text-center" data-aos="fade-in"
                            data-aos-delay="300" data-aos-anchor="#section7">
                            In App <br class="d-block" />
                            Digital KYC Process
                        </h3>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="easy-plan-sec6-inner hide-mobile">
                        <div data-aos="fade-in" data-aos-delay="1600" data-aos-anchor="#section7" class="z-index5 position-relative">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sect6-inner3.png"
                                alt="Easy plan - Goal Dashboard &amp; Tracking made Easy"
                                class="img-fluid easy-plan-sect6-inner3 lazy" />
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sect6-inner2.png"
                                alt="Easy plan - Goal Dashboard &amp; Tracking made Easy"
                                class="img-fluid easy-plan-sect6-inner2 lazy" />
                        </div>
                        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section7">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sect6-inner1.svg"
                                alt="Easy plan - Goal Dashboard &amp; Tracking made Easy"
                                class="img-fluid easy-plan-sect6-inner1 lazy" />
                        </div>
                    </div>
                    <div class="easy-plan-xs-right show-mobile hide-desktop mb30 text-center">
                        <ul>
                            <li>
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sect6-inner3.png"
                                    alt="Easy plan - Goal Dashboard &amp; Tracking made Easy" class="img-fluid lazy" />
                            </li>
                            <li>
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sect6-inner-xs-3.png"
                                    alt="Easy plan - Goal Dashboard &amp; Tracking made Easy" class="img-fluid lazy" />
                            </li>
                            <li>
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sect6-inner-xs-2.png"
                                    alt="Easy plan - Goal Dashboard &amp; Tracking made Easy" class="img-fluid lazy" />
                            </li>
                            <li>
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sect6-inner-xs-1.png"
                                    alt="Easy plan - Goal Dashboard &amp; Tracking made Easy" class="img-fluid lazy" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div easy-plan-sec8" id="section8" data-section-name="data-section8">
        <div class="container">
            <div class="row align-items-center justify-content-end">
                <div class="col-md-6">
                    <div data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section8">
                        <div class="easy-plan-sec8-inner hide-mobile">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec8-inner1.png"
                                alt="Goal Dashboard &amp; Tracking made Easy"
                                class="img-fluid easy-plan-sec8-inner1 lazy" />
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec8-inner2.png"
                                alt="Goal Dashboard &amp; Tracking made Easy"
                                class="img-fluid easy-plan-sec8-inner2 lazy" />
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec8-inner3.png"
                                alt="Goal Dashboard &amp; Tracking made Easy"
                                class="img-fluid easy-plan-sec8-inner3 lazy" />
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec8-inner4.png"
                                alt="Goal Dashboard &amp; Tracking made Easy"
                                class="img-fluid easy-plan-sec8-inner4 lazy" />
                        </div>
                        <div class="easy-plan-xs-right show-mobile hide-desktop mb30 text-center">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec8-xs.png"
                                alt="Easy plan - Track your returns" class="img-fluid lazy" />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="easy-plan-track-right portfolio-info-wrap">
                        <h3 class="font-family-sans-serif xs-text-center" data-aos="fade-in" data-aos-delay="900"
                            data-aos-anchor="#section8">Tracking Your Returns</h3>
                        <p class="p2 text-light-dark-c" data-aos="fade-in" data-aos-delay="1200"
                            data-aos-anchor="#section8">Investment tracking dashboard built for regular and advanced
                            users to bring transparency.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div easy-plan-sec9" id="section9" data-section-name="data-section9">
        <div class="ani-wrap100-top bg-purple2" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section9"></div>
        <div class="container">
            <div class="row vh100-100 align-items-center justify-content-end">
                <div class="col-md-6 order-xs-last">
                    <div class="easy-plan-track-left portfolio-info-wrap">
                        <h3 class="font-family-sans-serif text-white no-wrap-md xs-text-center" data-aos="fade-in"
                            data-aos-delay="600" data-aos-anchor="#section9">
                            Tracking Transactions <br class="d-block" />
                            On the Go..
                        </h3>
                    </div>
                </div>
                <div class="col-md-6">
                    <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section9">
                        <div class="easy-plan-sec9-inner hide-mobile">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec9-inner1.png"
                                alt="Goal Dashboard &amp; Tracking made Easy"
                                class="img-fluid easy-plan-sec9-inner1 lazy" data-aos="fade-left" data-aos-delay="1900"
                                data-aos-anchor="#section9" />
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec9-inner2.png"
                                alt="Goal Dashboard &amp; Tracking made Easy"
                                class="img-fluid easy-plan-sec9-inner2 lazy" data-aos="fade-up" data-aos-delay="1600"
                                data-aos-anchor="#section9" />
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec9-inner3.svg"
                                alt="Goal Dashboard &amp; Tracking made Easy"
                                class="img-fluid easy-plan-sec9-inner3 lazy" />
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec9-inner4.svg"
                                alt="Goal Dashboard &amp; Tracking made Easy"
                                class="img-fluid easy-plan-sec9-inner4 lazy" />
                        </div>
                        <div class="easy-plan-xs-right show-mobile hide-desktop mb30 text-center">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec9-xs.png"
                                alt="Easy plan - Track your transaction history at one go" class="img-fluid lazy" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div easy-plan-sec10" id="section10" data-section-name="data-section10">
        <div class="bg-purple2 comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section10" style="background-color: #1e1e1e;"></div>
        <div class="container">
            <div class="row justify-content-end align-items-start">
                <div class="col-md-6">
                    <div class="easy-plan-sec10-inner hide-mobile">
                        <div data-aos="fade-up" data-aos-delay="900" class="z-index5 position-relative"
                            data-aos-anchor="#section10">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec10-inner1.png"
                                alt="Goal Dashboard &amp; Tracking made Easy"
                                class="img-fluid easy-plan-sec10-inner1 lazy" />
                        </div>
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec10-inner2.svg"
                            alt="Goal Dashboard &amp; Tracking made Easy"
                            class="img-fluid easy-plan-sec10-inner2 lazy" />
                    </div>
                    <div class="easy-plan-xs-right show-mobile hide-desktop mb30 text-center">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-sec10-xs.png"
                            alt="Goal Dashboard" class="img-fluid lazy" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="easy-plan-track-left portfolio-info-wrap">
                        <h3 class="font-family-sans-serif" data-aos="fade-in" data-aos-delay="1200"
                            data-aos-anchor="#section10">
                            Celebrating <br />
                            User's Achievement
                        </h3>
                        <p class="p2 text-light-dark-c" data-aos="fade-in" data-aos-delay="1500"
                            data-aos-anchor="#section10">To generate a sense of achievement, animated motivating
                            graphics introduced in specific user journeys.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh impact-section-upd scroll-div" id="section-testimonial"
        data-section-name="data-section-testimonial">
        <div class="ani-wrap50-2" style="background-color: #1e1e1e;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section-testimonial"></div>
        <div class="ani-wrap50" style="background-color: #242424;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <div class="moneyguru-sec13-left portfolio-info-wrap2">
                        <ul class="list-inline comman-card-top-ul">
                            <li class="list-inline-item h6 text-white hide-after">Success Metrics</li>
                        </ul>
                        <h3 class="text-white">Impact</h3>
                        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section-testimonial">
                            <ul class="list-inline comman-impact-list-inline">
                                <li class="list-inline-item">
                                    <img src="assets/images/preloader/ajax-loader.png"
                                        data-src="assets/images/svg/impact-dowload.svg" alt="Downloads" class="lazy" />
                                    <h3 class="text-white"><span class="counter1">100</span>k+</h3>
                                    <p class="text-white p2">Downloads</p>
                                </li>
                                <li class="list-inline-item">
                                    <img src="assets/images/preloader/ajax-loader.png"
                                        data-src="assets/images/svg/impact-star.png" alt="Downloads" class="lazy" />
                                    <h3 class="text-white"><span class="counter2">4.5</span></h3>
                                    <p class="text-white p2">Star Rating</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="comman-impact-bottom insisive-website-sec13-right ml85" data-aos="fade-in"
                        data-aos-delay="1500" data-aos-anchor="#section-testimonial">
                        <div class="card-img-wrap"><img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/testimonial/manisha-pandita.jpg" alt="Manisha Pandita"
                                class="comman-impact-img lazy" /></div>
                        <h6 class="text-white">Manisha Pandita</h6>
                        <h6 class="text-white" style="opacity: 0.5;">Co-Founder</h6>
                        <p class="p3 text-white">
                            <span>
                                <i class="fas fa-quote-left"></i> Vishal and his team have done a terrific job of
                                setting up the core UX paradigms as well as the look and feel of the app. We used a very
                                different chat paradigm for
                                onboarding, which is quite new for finance apps. The Prismic team helped us take that
                                vision and turn it into reality. <i class="fas fa-quote-right"></i>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/mobile-app/moneyguru"> <span>Moneyguru </span> </a>
                        <p class="p2">Empowering Smart Savers with Tech &amp; Insights</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>