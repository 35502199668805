<div class="portfolio-branding-vigan-body">
    <section class="comman-section comman-section-100vh scroll-div vigan-sec1" id="section1"
        data-section-name="data-section1">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section1" style="background-color: #ffffff;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 text-center">
                    <div class="vigan-center-wrap xs-mt30">
                        <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-branding-vigan/vigan-logo.svg"
                                alt="Vigan - Logo" class="vigan-logo lazy logo-img img-fluid" />
                        </div>
                        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section1">
                            <h1 class="text-white">
                                People-Focused <br />
                                Technology
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-scroll-abs">
            <a href="#" class="next">
                Scroll
                <div class="c-scrolldown">
                    <div class="c-line"></div>
                </div>
            </a>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div vigan-sec2" id="section2"
        data-section-name="data-section2">
        <div class="ani-wrap100-top" data-aos="new-animation3" data-aos-delay="300" data-aos-anchor="#section2"
            style="background-color: #292930;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="easy-plans2-center overview-sec-center portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">
                            Overview</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2">
                            Vigan technologies is a new edge two year young 24X7 Tech Support startup, working with the
                            enterprises, specializing in providing technical expertise to the IT sector. As a future
                            expansion plan, the client
                            is aspiring to enter into Artificial Intelligence, hence felt the need of a fresh identity
                            to appeal to its new set of clients and other stakeholders in the business.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh comman-solution-challenge scroll-div vigan-sec3" id="section3"
        data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #fff;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3"></div>
        <div class="ani-wrap50" style="background: #2453c5;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section3"></div>
        <div class="vigan-sec3-1-outer1" data-aos="fade-in" data-aos-delay="2100" data-aos-anchor="#section2">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-branding-vigan/vigan-sec3-1.svg" alt="Vigan - Challenges"
                class="vigan-sec2-1 lazy img-fluid" />
        </div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <div class="vigan-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3" class="text-white">
                            Challenges</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3">
                            Vigan is derived from a Sanskrit word called Viganan - which means computing. Since the
                            brand is deeply rooted in tradition with aspirations to embrace futuristic technology,
                            combining them together in a
                            unique &amp; relevant brand identity was a challenge.
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="vigan-right-wrap portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section3">Creative Brief</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section3">
                            Focusing on mainly the international market space, a new identity was expected to reflect a
                            techno traditional look. Targeted audience - all corporate decision makers, live in a fast
                            paced environment where
                            trust &amp; technological advancement matters the most, the brand was also expected to
                            emphasise on people centric approach to help them in their daily lives.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div vigan-sec4" id="section4"
        data-section-name="data-section4">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section4" style="background-color: #ffffff;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12 col-lg-6">
                    <div class="vigan-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section4">Brainstorming</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section4">
                            Keeping the brand attributes in mind, we produced logo mark concepts that use different
                            types of symbols, marks &amp; shapes to convey certain messages. During the discovery we
                            discovered following brand
                            attributes which can be focused and are common for both line of businesses.
                        </p>
                        <div class="concept-wrap" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section4">
                            <h5>Key Attributes</h5>
                            <p class="p2" style="color: #2453c5;">
                                Collaboration + Technology + Precision <br />
                                + Rooted in Tradition
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                    <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section4" class="vigan-right-wrap">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-vigan/brainstorming.png"
                            class="vigan-sec6-2 lazy img-fluid" alt="Vigan - Brainstorming" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div vigan-sec6" id="section5"
        data-section-name="data-section5">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section5" style="background-color: #eee;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12 col-lg-6">
                    <div class="vigan-left-wrap portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5">Logo
                            Mark</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section5">
                            Combination of tradition and technology, a mark constructed with golden proportion with a
                            very minimal &amp; elegant look. Colors used like neon to show radiance &amp; vibrancy to
                            reflect modern innovation.
                        </p>
                        <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section5">
                            <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/portfolio/portfolio-branding-vigan/vigan-color.svg"
                                class="vigan-color lazy img-fluid" alt="Vigan - Logo Mark" />
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                    <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section5" class="vigan-right-wrap">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-vigan/vigan-logo-mark.svg"
                            class="vigan-sec6-2 lazy img-fluid" alt="Vigan - Logo Mark" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div vigan-sec7 xs-pt0" id="section6"
        data-section-name="data-section6">
        <div class="ani-wrap100-top-2" data-aos="new-animation3" data-aos-delay="100" data-aos-anchor="#section6"
            style="background: #040f25;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12 col-lg-6">
                    <div class="vigan-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section6" class="text-white">Type
                            customization</h3>
                        <div data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6">
                            <p class="mb30 text-white p2">
                                Saira is a sans serif system, features a huge range of weights and widths, ready for all
                                kinds of typographic challenges. <br />
                                No rough edges with smooth inner functioning. Tweaked letter G &amp; A to reflect
                                growth.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                    <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section6" class="vigan-right-wrap">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-vigan/vigan-sec7-1.png"
                            class="vigan-sec4-1 lazy img-fluid" alt="Vigan - Custom Styling" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div vigan-sec7-1" id="section7"
        data-section-name="data-section7">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section7" style="background-color: #040f25;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12 col-lg-6">
                    <div class="vigan-left-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section7" class="xs-text-center">
                            Logo Construction</h3>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                    <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section7" class="vigan-right-wrap">
                        <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-branding-vigan/vigan-sec7-upd.png"
                            class="vigan-sec4-1 lazy img-fluid" alt="Vigan - Logo Construction" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div vigan-sec8 no-padding comman-bg-section"
        id="section8" data-section-name="data-section8">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section8" style="background-color: #ffffff;"></div>
        <div class="container h-100"></div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div vigan-sec9 comman-bg-section" id="section9"
        data-section-name="data-section9">
        <div class="comman-bg-overlay" style="background-color: #1c419a !important;"
            data-aos="comman-bg-overlay-animation" data-aos-delay="300" data-aos-anchor="#section9"></div>
        <div class="container h-100"></div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div vigan-sec10 comman-bg-section" id="section10"
        data-section-name="data-section10">
        <div class="comman-bg-overlay-2" style="background-color: #1c419a !important;"
            data-aos="comman-bg-overlay-animation" data-aos-delay="300" data-aos-anchor="#section10"></div>
        <div class="container h-100"></div>
    </section>
    <section class="comman-section comman-section-100vh comman-impact-section vigan-sec12 scroll-div comman-bg-section"
        data-section-name="data-section11" id="section11">
        <div class="comman-bg-overlay" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section11"></div>
        <div class="container h-100"></div>
    </section>
    <section class="comman-section comman-section-100vh crubba-sec10 impact-section-upd scroll-div xs-pt0"
        id="section-testimonial" data-section-name="data-section-testimonial">
        <div class="ani-wrap100-top-2" style="background-color: #242424;" data-aos="new-animation3" data-aos-delay="100"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-12">
                    <div class="comman-impact-bottom" data-aos="fade-in" data-aos-delay="900"
                        data-aos-anchor="#section-testimonial"
                        style="margin: auto !important; max-width: 650px !important;">
                        <div class="card-img-wrap"><img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/testimonial/vigan-client.png" alt="Mr. Vikram Moule"
                                class="comman-impact-img lazy" /></div>
                        <h6 class="text-white">Mr. Vikram Moule</h6>
                        <h6 class="text-white" style="opacity: 0.5;">Founder</h6>
                        <p class="p3 text-white">
                            <span style="margin-bottom: 15px; display: block;">
                                <i class="fas fa-quote-left"></i> Initially, when we started the project, there were a
                                lot of misunderstandings from both sides. I had almost made up my mind and assured
                                myself that I made a mistake by
                                giving this project to Prismic Reflections&reg;.
                            </span>
                            <span>
                                But deep within, I always had and will always have faith in Vishal, so instead of giving
                                up, I continued and we both bridged the gaps. Once the sky was clear, Prismic
                                Reflections&reg; team came back
                                strongly and fulfilled the expectations I had. Now I can say I am willing to work with
                                the team for my next project. <i class="fas fa-quote-right"></i>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6>
                        <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/branding/vimson"> <span>Vimson</span> </a>
                        <p class="p2">Helping Manufacturing Company to Go Global</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>