import { Component, OnInit } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import scrollify from 'jquery-scrollify';
import AOS from 'aos';
declare let $: any;
declare let value: any;
declare let Waypoint: any;
import counterUp from 'counterup2';

@Component({
  selector: 'app-mytrux-app',
  templateUrl: './mytrux-app.component.html',
  styleUrls: ['./mytrux-app.component.scss'],
})
export class MytruxAppComponent implements OnInit {

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

 
 //new desc
  descrip ='Check how we delivered Mytrux - a logistics company its strategic web application ux ui design. The next web app could be yours. Let`s discuss!';

  constructor(private titleService: Title, private metaService: Meta) {
    

    this.setTitle('Web App UX UI Design for Logistics Company | Mytrux - Casestudy');
    this.metaService.updateTag({ name: 'description', content: this.descrip });
    this.metaService.updateTag({ name: 'keywords', content: 'UI Design for Corporates, UX for Startup, User Experience Design (UX), User Experience Design, Top Design Studio India, Best Design Agency Mumbai, Pune, Nashik, UI UX Design for Angel Broking, User Interface Design HDFC Credila, User Experience Design (UX) for Fintech, UI/UX for eCommerce, eLearning, User Interface Design for LMS, UI/UX Design Mobile App, UX Design for Mobility, Logo Design, Logo Branding, UI for Food Tech, UI/UX for Leading Real estate Company' });
    this.metaService.updateTag({ property: 'og:type', content: 'business.business' });
    this.metaService.updateTag({ property: 'og:title', content: 'Web App UX UI Design for Logistics Company | Mytrux - Casestudy' });
    this.metaService.updateTag({ property: 'og:description', content: this.descrip });
  }

  ngOnInit(): void {
    const numcounter1 = document.querySelector('.counter1');
    counterUp(numcounter1, {duration: 400, delay: 20});
    $(function() {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });
    $('.comman-header-wrap').show();
    $('#comman-footer').show();
    $(document).ready(function() {
      $('.logo-wrap-our-team').hide();
      $('.logo-wrap-a').show();
      $('.comman-header').removeClass('active-white active-logo-white active-menu-white').addClass('active-logo-white');
      $('.arrow-scroll-abs').show();
      $('.arrow-scroll-abs .next').removeClass('text-white').addClass('text-white');
      $('.comman-top-menu').removeClass('comman-top-menu-dark');
      const wido = $(window).width();
      if (wido >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          standardScrollElements: true,
          overflowscroll: true,
          before(i, panels) {
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.logo-wrap-our-team').removeClass('active-white-text').show();
            $('.pagination-wrap')
              .find('a[href="#' + ref + '"]')
              .addClass('active');
            $('.arrow-scroll-abs').fadeIn();
            $('.comman-top-menu').removeClass('comman-top-menu-dark');
            $('.comman-header .logo-wrap-a').show();
            if (ref === 'data-section1') {
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-menu-white active-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').hide();
              $('.comman-header .logo-wrap-a').show();
            } else if (ref === 'data-section2') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section3') {
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-menu-white active-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section5') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-menu-white active-logo-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section6') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-menu-white active-logo-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section7') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-menu-white active-logo-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section-testimonial') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
              // counterNum();
              const waypoint1 = new Waypoint({
                element: numcounter1,
                handler() {
                  counterUp(numcounter1, {duration: 400, delay: 20});
                  this.destroy();
                }, offset: 'bottom-in-view',
              });
            } else if (ref === 'data-comman-footer') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section-next-page') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').hide();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.comman-header .logo-wrap-a').hide();
            } else {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').show();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
          },
          afterRender() {
            $('.logo-wrap-our-team').removeClass('active-white-text').hide();
            aosinit();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i) {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
          },
        });
        $('.arrow-scroll-abs .next').click(function(e1) {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2) {
          e2.preventDefault();
          $.scrollify.prev();
        });
      } else {
        $('.comman-header').removeClass('active-white active-logo-white active-menu-white').addClass('active-white');
      }
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }

      function aosinit() {
        AOS.init({
          duration: 400,
          anchorPlacement: 'top-top',
          disable() {
            const maxWidth = 1199;
            return window.innerWidth < maxWidth;
          },
          once: true,
          offset: 20,
        });
      }
    });
  }
}
