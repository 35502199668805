<div class="portfolio-website-bynry-body">
    <section class="comman-section comman-section-100vh insisive-website-sec1 bynry-website-sec1 scroll-div top-section-wrapper"
        data-section-name="data-section1" id="section1">
        <div class="ani-wrap100-top" style="background-color: #011845;" data-aos="new-animation3" data-aos-delay="300"
            data-aos-anchor="#section1"></div>
        <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1"
            class="text-white show-mobile-xxs xs-text-center mt30"> Utility <br /> Management <br /> Company</h1>
        <div class="bynry-banner-wrap" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section1"> <img
                src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-website-bynry/bynry-banner.gif"
                alt="Bynry - Marketing Website" class="img-fluid bynry-banner lazy" /></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-7 col-md-7 order-xxs-last">
                    <div
                        class="insisive-website-sec1-right portfolio-info-wrap2 portfolio-info-banner-l xxs-text-center">
                        <ul class="list-inline comman-card-top-ul" data-aos="fade-in" data-aos-delay="600"
                            data-aos-anchor="#section1">
                            <li class="list-inline-item h6 text-white">Bynry</li>
                            <li class="list-inline-item h6 text-white">Energy, Utility &amp; IoT</li>
                        </ul>
                        <h1 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section1"
                            class="text-white hide-mobile-xxs"> Enhancing the Web Presence for Utility Management
                            Company</h1>
                    </div>
                </div>
                <div class="col-sm-5 col-md-5"></div>
            </div>
        </div>
        <div class="arrow-scroll-abs"> <a href="#" class="next">Scroll<div class="c-scrolldown">
                    <div class="c-line"></div>
                </div> </a></div>
    </section>
    <section class="comman-section comman-section-100vh moneyguru-sec2 scroll-div bynry-website-sec2 xs-pb0"
        id="section2" data-section-name="data-section2" style="background-color: #fff !important;">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section2" style="background-color: #011845;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <div class="moneyguru-sec2-right portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section2">Overview</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section2"> Bynry is an
                            Indian startup, working in the Energy space. Utility market is way behind when it comes to
                            technological advancement &amp; adoption if we compare it with other business domains such
                            as Retail or Banking &amp; Finance. Bynry helps their customers to bridge this gap by
                            providing affordable tech driven solutions.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="moneyguru-sec2-left" data-aos="fade-in" data-aos-delay="1500"
                        data-aos-anchor="#section2"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-bynry/bynry-overview.png"
                            alt="Bynry - Overview" class="moneyguru-sec2-3 img-fluid lazy"></div>
                </div>
            </div>
        </div>
    </section>
    <section
        class="comman-section comman-section-100vh comman-solution-challenge insisive-website-sec3 bynry-website-sec3 scroll-div"
        id="section3" style="overflow: hidden;" data-section-name="data-section3">
        <div class="ani-wrap50-2" style="background: #051941;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section3"></div>
        <div class="container h-100">
            <div class="row h-100 align-items-center justify-content-end">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="insisive-website-sec3-left portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section3">Challenges</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section3"> Bynry's team
                            approached us with a decent amount of UX research, performed by their inhouse UX designers.
                            Documents and information was good enough but pulling out critical insights and translating
                            them into suitable strategic designs was missing at their end. Our team took this challenge
                            and delivered a perfect design solution.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="insisive-website-sec3-right portfolio-info-wrap2 ml85">
                        <h3 data-aos="fade-in" data-aos-delay="2000" data-aos-anchor="#section3" class="text-white">
                            Solution</h3>
                        <p data-aos="fade-in" data-aos-delay="2300" data-aos-anchor="#section3" class="text-white p2">
                            Ability to think critically helped us to pull out necessary insights to map the weak links
                            within Bynry's existing website and a prototype designed by their internal team. Creating a
                            necessary environment for potential visitors and assuring them about the company's core
                            strength with social proofs was our main strategy when we initiated design exercise.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div authntick-sec9 bottom-col-section bynry-website-sec4" id="section4"
        data-section-name="data-section4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="authntick-center-wrap portfolio-info-wrap2">
                        <h3 data-aos="fade-in" data-aos-delay="300" data-aos-anchor="#section4">Engaging Navigation &
                            Content</h3>
                        <p data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section4"
                            class="p2 text-light-dark-c"> Content is king. Indeed! To engage users and to make them
                            maneuver smoothly, <br> we restructured the navigation and content architecture.</p>
                    </div>
                </div>
                <div class="col-md-12 bottom-col-set" data-aos="fade-in" data-aos-delay="900"
                    data-aos-anchor="#section4">
                    <div class="authntick-sec9-2-outer"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-bynry/bynry-navigation.png"
                            alt="Bynry - Engaging Navigation &amp; Content" class="lazy img-fluid"></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh insisive-website-sec8 bynry-website-sec5 scroll-div"
        data-section-name="data-section5" id="section5">
        <div class="ani-wrap50" style="background-color: #051941;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section5"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="insisive-website-sec8-left portfolio-info-wrap2">
                        <h3 class="text-white" data-aos="fade-in" data-aos-delay="600" data-aos-anchor="#section5">
                            Differentiating<br /> Energy Sectors</h3>
                        <p class="text-white p2" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section5">
                            Each sector is designed with its own color derived from the substance it deals with - Water,
                            Gas, Power etc.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12"></div>
            </div>
            <div class="insisive-website-sec8-right" data-aos="fade-in" data-aos-delay="1500"
                data-aos-anchor="#section5"> <img src="assets/images/preloader/ajax-loader.png"
                    data-src="assets/images/portfolio/portfolio-website-bynry/bynry-diff.jpg"
                    alt="Bynry - Differentiating Energy Sectors" class="img-fluid lazy insisive-website-sec8-1" /></div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh bynry-website-sec6 scroll-div xs-pb0"
        data-section-name="data-section6" id="section6">
        <div class="ani-wrap100-top" style="background-color: #051941;" data-aos="new-animation3" data-aos-delay="300"
            data-aos-anchor="#section6"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-6 order-xs-last">
                    <div class="insisive-website-sec7-1-outer" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section6"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-bynry/bynry-landing-page.png"
                            alt="Bynry - The Landing Page" class="img-fluid bynry-landing-page lazy" /></div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="insisive-website-sec7-right portfolio-info-wrap2 ml75 xs-no-margin">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section6" class="text-white"> The
                            Landing Page</h3>
                        <p class="p2 text-white" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section6">
                            Website is designed to make you feel more of an established market player and to lead
                            through the dense competition with well established tech giants.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh scroll-div bagshaha-sec9 bynry-website-sec7" id="section7"
        data-section-name="data-section7">
        <div class="ani-wrap100-top-2" style="background-color: #F0F4FA;" data-aos="new-animation3" data-aos-delay="300"
            data-aos-anchor="#section7"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6">
                    <div class="bagshaha-sec6-left portfolio-info-wrap2">
                        <h3 class="no-wrap-md" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section7">
                            Consistency Across <br /> the Devices</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section7"> Responsive
                            design with subtle intuitive feedback, interactions and transitions.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section7"
                        class="bagshaha-sec9-2-outer"> <img src="assets/images/preloader/ajax-loader.png"
                            data-src="assets/images/portfolio/portfolio-website-bynry/bynry-devices.png"
                            class="bynry-devices img-fluid lazy" alt="Bynry - Consistency Across the Devices" /></div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh impact-section-upd scroll-div" id="section-testimonial"
        data-section-name="data-section-testimonial">
        <div class="ani-wrap50-2" style="background-color: #1E1E1E;" data-aos="new-animation2" data-aos-delay="1200"
            data-aos-anchor="#section-testimonial"></div>
        <div class="ani-wrap50" style="background-color: #242424;" data-aos="new-animation2" data-aos-delay="100"
            data-aos-anchor="#section-testimonial"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <div class="moneyguru-sec13-left portfolio-info-wrap2">
                        <ul class="list-inline comman-card-top-ul">
                            <li class="list-inline-item h6 text-white hide-after">Success Metrics</li>
                        </ul>
                        <h3 class="text-white">Impact</h3>
                        <div data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section-testimonial">
                            <ul class="list-inline comman-impact-list-inline">
                                <li class="list-inline-item xs-mr0"> <img src="assets/images/preloader/ajax-loader.png"
                                        data-src="assets/images/svg/impact-dowload.svg" alt="Downloads" class="lazy">
                                    <h3 class="text-white"><span class="counter1">57</span>%</h3>
                                    <p class="p2 text-white">Initial bounce rate reduced</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="comman-impact-bottom insisive-website-sec13-right ml85" data-aos="fade-in"
                        data-aos-delay="1500" data-aos-anchor="#section-testimonial">
                        <div class="card-img-wrap"> <img src="assets/images/preloader/ajax-loader.png"
                                data-src="assets/images/testimonial/nilesh-gudhe.jpg" alt="Nilesh Gudhe"
                                class="comman-impact-img lazy" /></div>
                        <h6 class="text-white">Nilesh Gudhe</h6>
                        <h6 class="text-white" style="opacity: 0.5;">Founder &amp; CEO</h6>
                        <p class="p3 text-white"> <span> <i class="fas fa-quote-left"></i> It was crucial to understand
                                our business for designing our web presence and this was the exact reason one of our
                                friend referred Prismic Reflections to us. The ability to understand business
                                requirements and expressing the same through the design work - it makes them diligent
                                design consultants who are always receptive to our needs and feedback. <i
                                    class="fas fa-quote-right"></i> </span></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section comman-section-100vh insisive-website-sec11 failure-section scroll-div"
        id="section-learning" data-section-name="data-section-learning">
        <div class="comman-bg-overlay-2" data-aos="comman-bg-overlay-animation" data-aos-delay="300"
            data-aos-anchor="#section-learning" style="background-color: #1E1E1E;"></div>
        <div class="container h-100">
            <div class="row vh100-100 align-items-center justify-content-center">
                <div class="col-md-7 col-lg-6 col-sm-12">
                    <div class="insisive-sec-left portfolio-info-wrap2 failure-sec-left">
                        <h3 data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#section-learning">Failure &amp;
                            Learnings</h3>
                        <p class="p2" data-aos="fade-in" data-aos-delay="1200" data-aos-anchor="#section-learning"> It's
                            great to learn from other's mistakes. While performing the initial analysis and designing,
                            we realised that just following the UX process without right knowledge, skills and empathy
                            results into some aimless design solution.</p>
                    </div>
                </div>
                <div class="col-md-5 col-lg-6"></div>
            </div>
        </div>
        <div class="failure-sec-right" data-aos="fade-in" data-aos-delay="1500" data-aos-anchor="#section-learning">
            <img src="assets/images/preloader/ajax-loader.png"
                data-src="assets/images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-sec14-bg.png"
                alt="Failure &amp; Learnings" class="img-fluid lazy failure-sec-right-img" /></div>
    </section>
    <section class="comman-section comman-section-100vh next-page-section normal-scroll" id="section-next-page"
        data-section-name="data-section-next-page">
        <div class="container h-100">
            <div class="row">
                <div class="col-sm-12">
                    <div class="join-team-wrap" data-aos="fade-in" data-aos-delay="600"
                        data-aos-anchor="#section-next-page">
                        <h6>Next</h6> <a class="page-foot-link h3 st btn-line" routerLink="/portfolio/website/crubba"> <span>Crubba</span>
                        </a>
                        <p class="p2">A Personalised &amp; Engaging Take on Home Cooking</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>