import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {OwlOptions} from 'ngx-owl-carousel-o';
import AOS from 'aos';

declare let $: any;
declare let value: any;

@Component({
  selector: 'app-insisive',
  templateUrl: './insisive.component.html',
  styleUrls: ['./insisive.component.scss'],
})
export class InsisiveComponent implements OnInit, OnDestroy {
  navigationSubscription;

  customOptions: OwlOptions = {
    dots: true,
    nav: false,
    margin: 10,
    autoplay: true,
    autoHeight: true,
    mouseDrag: false,
    touchDrag: false,
    loop: true,
    autoWidth: false,
    lazyLoad: true,
    items: 1,
    autoplayTimeout: 2000,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
  };

  
  //new desc
  descrip = 'Check how we delivered Insisive - a technology enterprise its strategic website ux ui design. The next ux ui design could be yours. Let`s discuss!';

  

  constructor(private titleService: Title, private metaService: Meta, private router: Router) {
    
    this.setTitle('Website UX UI Design for Technology Enterprise | Insisive - Casestudy');
    this.metaService.updateTag({name: 'description', content: this.descrip});
    this.metaService.updateTag({
      name: 'keywords',
      content: 'UI Design for Corporates, UX for Startup, User Experience Design (UX), User Experience Design, Top Design Studio India, Best Design Agency Mumbai, Pune, Nashik, UI UX Design for Angel Broking, User Interface Design HDFC Credila, User Experience Design (UX) for Fintech, UI/UX for eCommerce, eLearning, User Interface Design for LMS, UI/UX Design Mobile App, UX Design for Mobility, Logo Design, Logo Branding, UI for Food Tech, UI/UX for Leading Real estate Company'
    });
    this.metaService.updateTag({property: 'og:type', content: 'business.business'});
    this.metaService.updateTag({property: 'og:title', content: 'Website UX UI Design for Technology Enterprise | Insisive - Casestudy'});
    this.metaService.updateTag({property: 'og:description', content: this.descrip});

    // this.navigationSubscription = this.router.events.subscribe((e: any) => {
    //   // If it is a NavigationEnd event re-initalise the component
    //   if (e instanceof NavigationEnd) {
    //     this.initialiseInvites();
    //   }
    // })

  }

  // initialiseInvites() {
  //   this.ngOnInit();
  //   // window.location.reload();
  //   // $.scrollify.destroy();
  // }

  ngOnInit(): void {
    $(function(): void {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });
    $('.comman-header-wrap').show();
    $('#comman-footer').show();

    function aosinit(): void {
      AOS.init({
        duration: 400,
        anchorPlacement: 'top-top',
        disable(): boolean {
          const maxWidth = 1199;
          return window.innerWidth < maxWidth;
        },
        once: true,
        offset: 20,
      });
    }

    $(document).ready(function(): void {
      $('.logo-wrap-our-team').hide();
      $('.logo-wrap-a').show();
      $('.comman-header').removeClass('active-white active-logo-white active-menu-white').addClass('active-white');
      $('.arrow-scroll-abs').show();
      $('.arrow-scroll-abs .next').removeClass('text-white').addClass('text-white');
      $('.comman-top-menu').removeClass('comman-top-menu-dark').addClass('comman-top-menu-dark');
      const wido = $(window).width();
      if (wido >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          standardScrollElements: true,
          overflowscroll: true,
          before(i, panels): void {
            // aosinit();
            // alert('before aosinit');
            console.log('before aosinit' + i);
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.logo-wrap-our-team').removeClass('active-white-text').show();
            $('.pagination-wrap')
              .find('a[href="#' + ref + '"]')
              .addClass('active');
            $('.arrow-scroll-abs').fadeIn();
            $('.comman-top-menu').removeClass('comman-top-menu-dark');
            $('.comman-header .logo-wrap-a').show();
            if (ref === 'data-section1') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').hide();
              $('.comman-header .logo-wrap-a').show();
            } else if (ref === 'data-section2') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team')
                .removeClass('active-white-text')
                .fadeIn();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section3') {
              $('.comman-header')
                .addClass('active-menu-white')
                .removeClass('active-white active-logo-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section4') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-menu-white active-logo-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section8') {
              $('.comman-header').removeClass(
                'active-logo-white active-menu-white active-white'
              );
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section11') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section12') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
              $.scrollify({setHeights: false});
            } else if (ref === 'data-section13') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section-testimonial') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section-learning') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-comman-footer') {
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section-next-page') {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').hide();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.comman-header .logo-wrap-a').hide();
            } else {
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').show();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
          },
          afterRender(): void {
            console.log('before afterRender');
            $('.logo-wrap-our-team').removeClass('active-white-text').hide();
            aosinit();
            // alert('afterRender aosinit');
            // counterNum();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i): void {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
          },
        });
        $('.arrow-scroll-abs .next').click(function(e1): void {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2): void {
          e2.preventDefault();
          $.scrollify.prev();
        });
      }
      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }
    });
  }

  ngOnDestroy(): void {
    const wido = $(window).width();
    if (wido >= 1199) {
      $.scrollify.destroy();
      AOS.init({disable: true});
      console.log('ngOnDestroy');
      // avoid memory leaks here by cleaning up after ourselves. If we
      // don't then we will continue to run our initialiseInvites()
      // method on every navigationEnd event.
      // if (this.navigationSubscription) {
      //    this.navigationSubscription.unsubscribe();
      // }
    }
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

}
