<div class="our-work-page">
    <section class="comman-section work-sec1 top-section-wrapper normal-scroll pb0 clearfix"
        data-section-name="data-section1" id="section1">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="comman-card-info work-comman-card-info" style="max-width: inherit;">
                        <h1 class="h1 sec-heading xs-text-center"> Design Experiments</h1>
                        <p class="p1 sec-para">
                            We believe making and breaking things is the only way to progress, innovate &amp; bring the
                            change.
                            <br />
                            Here are some experiments and design explorations we did. Hope you will enjoy them. You can
                            view, subscribe to our youtube channel &amp; share your comments.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="comman-section scroll-div clearfix" data-section-name="data-section2" id="section2">
        <div class="container h-100">
            <div class="row our-work-row">

                <!-- wajahat code start for modern-Ai-V1 -->
                <div class="col-md-6 col-sm-12 portfolio-thumb-all">
                    <a href="#design-expe-card-modal-moderAi-V1" class="d-block" data-toggle="modal" data-backdrop="static"
                        data-keyboard="false">
                        <div class="work-card">
                            <div class="work-card-img design-expe-card1-wrap">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/design-expe/ModernAi_thumb.png" alt="Design Experiments Video"
                                    class="design-expe-card1 lazy img-fluid" />
                                <div class="circle st">
                                    <p class="text st">
                                        <i class="fas fa-play"></i>
                                    </p>
                                </div>
                            </div>
                            <div class="work-card-body w-100">
                                <ul>
                                    <li>UX UI Design, Animation</li>
                                </ul>
                                <h5>Modern AI Landing Page V1</h5>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- wajahat code end for modern-Ai-V1 -->

                <!-- wajahat code start for modern-Ai-V2 -->
                <div class="col-md-6 col-sm-12 portfolio-thumb-all">
                    <a href="#design-expe-card-modal-moderAi-V2" class="d-block" data-toggle="modal" data-backdrop="static"
                        data-keyboard="false">
                        <div class="work-card">
                            <div class="work-card-img design-expe-card1-wrap">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/design-expe/ModernAi-V2.png" alt="Design Experiments Video"
                                    class="design-expe-card1 lazy img-fluid" />
                                <div class="circle st">
                                    <p class="text st">
                                        <i class="fas fa-play"></i>
                                    </p>
                                </div>
                            </div>
                            <div class="work-card-body w-100">
                                <ul>
                                    <li>UX UI Design, Animation</li>
                                </ul>
                                <h5>Modern AI Landing Page V2</h5>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- wajahat code end for modern-Ai-V2 -->
                <div class="col-md-6 col-sm-12 portfolio-thumb-all">
                    <a href="#design-expe-card1-modal" class="d-block" data-toggle="modal" data-backdrop="static"
                        data-keyboard="false">
                        <div class="work-card">
                            <div class="work-card-img design-expe-card1-wrap">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/design-expe/design-expe-card3.jpg" alt="Design Experiments Video"
                                    class="design-expe-card1 lazy img-fluid" />
                                <div class="circle st">
                                    <p class="text st">
                                        <i class="fas fa-play"></i>
                                    </p>
                                </div>
                            </div>
                            <div class="work-card-body w-100">
                                <ul>
                                    <li>UX UI Design, Animation</li>
                                </ul>
                                <h5>Online Pizza Ordering Journey Concept</h5>
                            </div>
                        </div>
                    </a>
                </div>
    
                <div class="col-md-6 col-sm-12 portfolio-thumb-all">
                    <a href="#design-expe-card2-modal" class="d-block" data-toggle="modal" data-backdrop="static"
                        data-keyboard="false">
                        <div class="work-card">
                            <div class="work-card-img design-expe-card2-wrap">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/design-expe/design-expe-card1.jpg" alt="Design Experiments Video"
                                    class="design-expe-card1 lazy img-fluid" />
    
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/design-expe/design-expe-card1-after.svg"
                                    alt="Design Experiments Video" class="design-expe-card1-after lazy img-fluid" />
                                <div class="circle st">
                                    <p class="text st">
                                        <i class="fas fa-play"></i>
                                    </p>
                                </div>
                            </div>
                            <div class="work-card-body w-100">
                                <ul>
                                    <li>UX UI Design, Animation</li>
                                </ul>
                                <h5>WhatsApp UI Redesign (Concept)</h5>
                            </div>
                        </div>
                    </a>
                </div>
    
                <div class="col-md-6 col-sm-12 portfolio-thumb-all">
                    <a href="#design-expe-card3-modal" class="d-block" data-toggle="modal" data-backdrop="static"
                        data-keyboard="false">
                        <div class="work-card">
                            <div class="work-card-img design-expe-card3-wrap">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/design-expe/design-expe-card2.jpg" alt="Design Experiments Video"
                                    class="design-expe-card1 lazy img-fluid" />
    
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/design-expe/design-expe-card2-after.svg"
                                    alt="Design Experiments Video" class="design-expe-card2-after lazy img-fluid" />
    
                                <div class="circle st">
                                    <p class="text st">
                                        <i class="fas fa-play"></i>
                                    </p>
                                </div>
                            </div>
                            <div class="work-card-body w-100">
                                <ul>
                                    <li>UX UI Design, 3D, Animation</li>
                                </ul>
                                <h5>Voice Based Search in E-Commerce Mobile App</h5>
                            </div>
                        </div>
                    </a>
                </div>
    
                <div class="col-md-6 col-sm-12 portfolio-thumb-all">
                    <a href="#design-expe-card4-modal" class="d-block" data-toggle="modal" data-backdrop="static"
                        data-keyboard="false">
                        <div class="work-card">
                            <div class="work-card-img design-expe-card4-wrap">
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/design-expe/design-expe-card4.jpg" alt="Design Experiments Video"
                                    class="design-expe-card1 lazy img-fluid" />
    
                                <img src="assets/images/preloader/ajax-loader.png"
                                    data-src="assets/images/design-expe/design-expe-card4-after.svg"
                                    alt="Design Experiments Video" class="design-expe-card4-after lazy img-fluid" />
                                <div class="circle st">
                                    <p class="text st">
                                        <i class="fas fa-play"></i>
                                    </p>
                                </div>
                            </div>
                            <div class="work-card-body w-100">
                                <ul>
                                    <li>New Launch Video</li>
                                </ul>
                                <h5>We've evolved, here is the quick intro</h5>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</div>

<!-- design-expe-card1-modal -->
<div class="modal fade design-expe-modal" id="design-expe-card1-modal" tabindex="-1" aria-hidden="true"
    data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h6 class="modal-title">Online Pizza Ordering Journey Concept</h6>
                
            </div> -->
            <div class="modal-body">

                <!-- <button type="button" class="close close-adj-for-user-experience" data-dismiss="modal" aria-label="Close">
                    <img src="assets/images/svg/mega-menu-close.svg" alt="Mega Menu" class="mega-menu-img lazy" />
                </button> -->

                <button type="button" class="close close-adj" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>

                <iframe class="youtube-iframe" style="width: 100%;height: 370px" src="https://www.youtube.com/embed/qaYLS5ZRDyk"
                    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>

<!-- design-expe-card2-modal -->
<div class="modal fade design-expe-modal" id="design-expe-card2-modal" tabindex="-1" aria-hidden="true"
    data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h6 class="modal-title">WhatsApp UI Redesign (Concept)</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="assets/images/svg/mega-menu-close.svg" alt="Mega Menu" class="mega-menu-img lazy" />
                </button>
            </div> -->
            <div class="modal-body">

                <button type="button" class="close close-adj" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>

                <iframe class="youtube-iframe" style="width: 100%;height: 370px" src="https://www.youtube.com/embed/aQyli_yDdT8"
                    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>

<!-- design-expe-card3-modal -->
<div class="modal fade design-expe-modal" id="design-expe-card3-modal" tabindex="-1" aria-hidden="true"
    data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h6 class="modal-title">Voice Based Search in E-Commerce Mobile App</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="assets/images/svg/mega-menu-close.svg" alt="Mega Menu" class="mega-menu-img lazy" />
                </button>
            </div> -->
            <div class="modal-body">

                <button type="button" class="close close-adj" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>

                <iframe class="youtube-iframe" style="width: 100%;height: 370px" src="https://www.youtube.com/embed/koD1Qlguxq8"
                    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>

<!-- design-expe-card4-modal -->
<div class="modal fade design-expe-modal" id="design-expe-card4-modal" tabindex="-1" aria-hidden="true"
    data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h6 class="modal-title">We've evolved, here is the quick intro</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="assets/images/svg/mega-menu-close.svg" alt="Mega Menu" class="mega-menu-img lazy" />
                </button>
            </div> -->
            <div class="modal-body">
                <button type="button" class="close close-adj" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>

                <iframe class="youtube-iframe" width="560" height="315" src="https://www.youtube.com/embed/AepHiWEAV48"
                    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>

<!-- wajahat code added -->
<!-- design-expe-card-modal-moderAi-V1 -->
<div class="modal fade design-expe-modal" id="design-expe-card-modal-moderAi-V1" tabindex="-1" aria-hidden="true"
    data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h6 class="modal-title">Modern AI Landing Page V1</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="assets/images/svg/mega-menu-close.svg" alt="Mega Menu" class="mega-menu-img lazy" />
                </button>
            </div> -->
            <div class="modal-body">
                <button type="button" class="close close-adj" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>

                <iframe class="youtube-iframe" style="width: 100%;height: 370px" src="https://www.youtube.com/embed/eRRV2WCg3bk"
                 title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>
<!-- wajahat code ened here -->

<!-- wajahat code added -->
<!-- design-expe-card-modal-moderAi-V2 -->
<div class="modal fade design-expe-modal" id="design-expe-card-modal-moderAi-V2" tabindex="-1" aria-hidden="true"
    data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h6 class="modal-title">Modern AI Landing Page V2</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="assets/images/svg/mega-menu-close.svg" alt="Mega Menu" class="mega-menu-img lazy" />
                </button>
            </div> -->
            <div class="modal-body">

                <button type="button" class="close close-adj" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>

                <iframe class="youtube-iframe" style="width: 100%;height: 370px" src="https://www.youtube.com/embed/NjUlf0-0aZw"
                 title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                 allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>
<!-- wajahat code ened here -->