import { Component, OnInit } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import scrollify from 'jquery-scrollify';
import AOS from 'aos';
declare let $: any;
declare let value: any;
declare let Waypoint: any;
import counterUp from 'counterup2';

@Component({
  selector: 'app-intelehealth',
  templateUrl: './intelehealth.component.html',
  styleUrls: ['./intelehealth.component.scss']
})
export class IntelehealthComponent implements OnInit {

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

   // new desc
   descrip = 'Check how we redesigned a web & mobile application for Intelehealth Inc - a Healthtech startup bringing health care solutions in remote areas. The next could be yours. Let`s discuss!';

 


   constructor(private titleService: Title, private metaService: Meta) {
   
    this.setTitle('Mobile App Web App UX UI Design for Telehealth and Telemedicine | Health Tech Company Intelehealth USA');
     this.metaService.updateTag({ name: 'description', content: this.descrip });
     this.metaService.updateTag({ name: 'keywords', content: 'UI Design for Corporates, UX for Startup, User Experience Design (UX), User Experience Design, Top Design Studio India, Best Design Agency Mumbai, Pune, Nashik, UI UX Design for Angel Broking, User Interface Design HDFC Credila, User Experience Design (UX) for Fintech, UI/UX for eCommerce, eLearning, User Interface Design for LMS, UI/UX Design Mobile App, UX Design for Mobility, Logo Design, Logo Branding, UI for Food Tech, UI/UX for Leading Real estate Company' });
     this.metaService.updateTag({ property: 'og:type', content: 'business.business' });
     this.metaService.updateTag({ property: 'og:title', content: 'Web App UX UI Design for Technology Business | Intelehealth Inc - Casestudy' });
     this.metaService.updateTag({ property: 'og:description', content: this.descrip });
   }

   ngOnInit(): void {
    // const numcounter1 = document.querySelector('.counter1');
    // const numcounter2 = document.querySelector('.counter2');
    // const numcounter3 = document.querySelector('.counter3');
    // counterUp(numcounter1, {duration: 1500, delay: 20});
    // counterUp(numcounter2, {duration: 1500, delay: 20});
    // counterUp(numcounter3, {duration: 1500, delay: 20});
    
    $(function() {
      $('.lazy').lazy({
        effect: 'fadeIn',
        placeholder: 'assets/images/lazy-preloader.png',
      });
    });
    $('.comman-header-wrap').show();
    $('#comman-footer').show();
    $(document).ready(function() {

      const video1 = document.getElementById('video1');
      const video2 = document.getElementById('video2');
      const video3 = document.getElementById('video3');
      const video4 = document.getElementById('video4');
      const video5 = document.getElementById('video5');
      const video6 = document.getElementById('video6');
      const video7 = document.getElementById('video7');

      // var elements = document.querySelectorAll(".comman-video");
      // alert(i);
      // for (var i= 0; i < elements.length; i++) {
      //     elements[i].play();
      // }

      // $.each( $(".comman-video"), function( i, val ) {
      //   alert(i);
      //   $(this).play();
      //   // $( ".comman-video" + val ).text( "Mine is " + val + "." );
       
      //   // // Will stop running after "three"
      //   // return ( val !== "three" );
      // });




      
      $('.logo-wrap-our-team').hide();
      $('.logo-wrap-a').show();
      $('.comman-header').removeClass('active-white active-logo-white active-menu-white').addClass('active-white');
      $('.arrow-scroll-abs').show();
      $('.arrow-scroll-abs .next').removeClass('text-white').addClass('text-white');
      $('.comman-top-menu').removeClass('comman-top-menu-dark').addClass('comman-top-menu-dark');
      const wido = $(window).width();
      if (wido >= 1199) {
        $.scrollify({
          section: '.scroll-div',
          interstitialSection: '.normal-scroll',
          offset: 10,
          scrollbars: true,
          updateHash: false,
          setHeights: true,
          overflowscroll: true,
          before(i, panels) {
            // aosinit();
            const ref = panels[i].attr('data-section-name');
            $('.pagination-wrap .active').removeClass('active');
            $('.logo-wrap-our-team').removeClass('active-white-text').show();
            $('.pagination-wrap')
              .find('a[href="#' + ref + '"]')
              .addClass('active');
            $('.arrow-scroll-abs').fadeIn();
            $('.arrow-scroll-abs .next').removeClass('text-white');
            $('.comman-top-menu').removeClass('comman-top-menu-dark');
            $('.comman-header .logo-wrap-a').show();
            
            if (ref === 'data-section1') {
              $("#video1")[0].play();
              $("#video2")[0].play();
              $("#video3")[0].play();
              $("#video4")[0].play();
              $("#video5")[0].play();
              $("#video6")[0].play();
              $("#video7")[0].play();
              $('.comman-header').addClass('active-white').removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').hide();
              $('.comman-header .logo-wrap-a').show();
            } else if (ref === 'data-section3') {
              $("#video1")[0].play();
              $("#video2")[0].play();
              $("#video3")[0].play();
              $("#video4")[0].play();
              $("#video5")[0].play();
              $("#video6")[0].play();
              $("#video7")[0].play();
              $('.comman-header')
                .addClass('active-menu-white')
                .removeClass('active-logo-white active-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }  
            
            else if (ref === 'data-section7') {
              $("#video1")[0].play();
              $("#video2")[0].play();
              $("#video3")[0].play();
              $("#video4")[0].play();
              $("#video5")[0].play();
              $("#video6")[0].play();
              $("#video7")[0].play();
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-menu-white active-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
            else if (ref === 'data-section11') {
              $("#video1")[0].play();
              $("#video2")[0].play();
              $("#video3")[0].play();
              $("#video4")[0].play();
              $("#video5")[0].play();
              $("#video6")[0].play();
              $("#video7")[0].play();
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-menu-white active-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
            else if (ref === 'data-section13') {
              $("#video1")[0].play();
              $("#video2")[0].play();
              $("#video3")[0].play();
              $("#video4")[0].play();
              $("#video5")[0].play();
              $("#video6")[0].play();
              $("#video7")[0].play();
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-menu-white active-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
            // else if (ref === 'data-section13') {
            //   $('.comman-header')
            //     .addClass('active-logo-white')
            //     .removeClass('active-menu-white active-white');
            //   $('.arrow-scroll-abs .next').addClass('text-white');
            //   $('.comman-top-menu').removeClass('comman-top-menu-dark');
            //   $('.logo-wrap-our-team').addClass('active-white-text').show();
            //   $('.comman-header .logo-wrap-a').hide();
            //   $('#onboarding-video').play();
            //  // alert("hello");
            // }
            else if (ref === 'data-section15') {
              $("#video1")[0].play();
              $("#video2")[0].play();
              $("#video3")[0].play();
              $("#video4")[0].play();
              $("#video5")[0].play();
              $("#video6")[0].play();
              $("#video7")[0].play();
              $('.comman-header')
                .addClass('active-menu-white')
                .removeClass('active-logo-white active-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }  
            else if (ref === 'data-section17') {
              $("#video1")[0].play();
              $("#video2")[0].play();
              $("#video3")[0].play();
              $("#video4")[0].play();
              $("#video5")[0].play();
              $("#video6")[0].play();
              $("#video7")[0].play();
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-menu-white active-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
            // else if (ref === 'data-section20') {
            //   $('.comman-header')
            //   .addClass('active-logo-white')
            //   .removeClass('active-menu-white active-white');
            // $('.arrow-scroll-abs .next').addClass('text-white');
            // $('.comman-top-menu').removeClass('comman-top-menu-dark');
            // $('.logo-wrap-our-team').addClass('active-white-text').show();
            // $('.comman-header .logo-wrap-a').hide();
            // }  
            else if (ref === 'data-section21') {
              $("#video1")[0].play();
              $("#video2")[0].play();
              $("#video3")[0].play();
              $("#video4")[0].play();
              $("#video5")[0].play();
              $("#video6")[0].play();
              $("#video7")[0].play();
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-menu-white active-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }  
            else if (ref === 'data-section22') {
              $("#video1")[0].play();
              $("#video2")[0].play();
              $("#video3")[0].play();
              $("#video4")[0].play();
              $("#video5")[0].play();
              $("#video6")[0].play();
              $("#video7")[0].play();
              $('.comman-header')
                .addClass('active-menu-white')
                .removeClass('active-logo-white active-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }  
            else if (ref === 'data-section23') {
              $("#video1")[0].play();
              $("#video2")[0].play();
              $("#video3")[0].play();
              $("#video4")[0].play();
              $("#video5")[0].play();
              $("#video6")[0].play();
              $("#video7")[0].play();
              $('.comman-header')
                .addClass('active-logo-white')
                .removeClass('active-menu-white active-white');
              $('.arrow-scroll-abs .next').addClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
         else if (ref === 'data-section-learning') {
          $("#video1")[0].play();
          $("#video2")[0].play();
          $("#video3")[0].play();
          $("#video4")[0].play();
          $("#video5")[0].play();
          $("#video6")[0].play();
          $("#video7")[0].play();
              $('.comman-header')
                .addClass('active-menu-white')
                .removeClass('active-logo-white active-white');
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.arrow-scroll-abs').fadeIn();
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-comman-footer') {
              $("#video1")[0].play();
              $("#video2")[0].play();
              $("#video3")[0].play();
              $("#video4")[0].play();
              $("#video5")[0].play();
              $("#video6")[0].play();
              $("#video7")[0].play();
              $('.comman-header')
                .addClass('active-white')
                .removeClass('active-logo-white active-menu-white');
              $('.arrow-scroll-abs').hide();
              $('.comman-top-menu').addClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').addClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            } else if (ref === 'data-section-next-page') {
              $("#video1")[0].play();
              $("#video2")[0].play();
              $("#video3")[0].play();
              $("#video4")[0].play();
              $("#video5")[0].play();
              $("#video6")[0].play();
              $("#video7")[0].play();
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').hide();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.comman-header .logo-wrap-a').hide();
            } else {
              $("#video1")[0].play();
              $("#video2")[0].play();
              $("#video3")[0].play();
              $("#video4")[0].play();
              $("#video5")[0].play();
              $("#video6")[0].play();
              $("#video7")[0].play();
              $('.comman-header').removeClass(
                'active-white active-logo-white active-menu-white'
              );
              $('.arrow-scroll-abs').show();
              $('.arrow-scroll-abs .next').removeClass('text-white');
              $('.comman-top-menu').removeClass('comman-top-menu-dark');
              $('.logo-wrap-our-team').removeClass('active-white-text').show();
              $('.comman-header .logo-wrap-a').hide();
            }
          },
          afterRender() {
            aosinit();
            $("#video1")[0].play();
            $("#video2")[0].play();
            $("#video3")[0].play();
            $("#video4")[0].play();
            $("#video5")[0].play();
            $("#video6")[0].play();
            $("#video7")[0].play();
            // alert()
            $('.logo-wrap-our-team').removeClass('active-white-text').hide();
            const pagination = '<ul class="pagination-wrap">';
            let activeClass = '';
            $('.scroll-div').each(function(i) {
              activeClass = '';
              if (i === 0) {
                activeClass = 'active';
              }
            });
          },
        });

        $('.arrow-scroll-abs .next').click(function(e1) {
          e1.preventDefault();
          $.scrollify.next();
        });
        $('.arrow-scroll-abs .prev').click(function(e2) {
          e2.preventDefault();
          $.scrollify.prev();
        });
      }


      if ($(window).width() < 1199) {
        $('[data-aos]').addClass('aos-init aos-animate');
      }

      function aosinit() {
        AOS.init({
          duration: 400,
          anchorPlacement: 'top-top',
          disable() {
            const maxWidth = 1199;
            return window.innerWidth < maxWidth;
          },
          once: true,
          offset: 20,
        });
      }

     
  



    });
  }

}
